import {
  SeatStateSingleDayComponnentProgressiveLoad,
  SpaceStateComponentProgressiveLoad,
  ResourceStateFilterComponent,
  SeatStateMultiDayComponnentProgressiveLoad,
  YourDeskMarker,
  SeatDetailsComponent,
  GenericDeskMarker,
} from '@robinpowered/perseus';
import { useTimezone } from 'atoms/resource';
import {
  useCurrentFilter,
  useSpaceFilterInitialized,
  useFilteredSpaceIds,
  useMapInteractiveLayers,
  useDeskFilterInitialized,
  useFilteredDeskIds,
  useMapMode,
} from 'atoms/mapInteractions';
import { useAuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import {
  useEditDeskPotentialDeskId,
  useEditDeskNewDeskId,
} from 'atoms/editDesk';
import { useDeskAvailabilityQueryParameters } from 'hooks/useDeskAvailabilityParameters';
import moment from 'moment';
import { useSpaceAvailabilityParameters } from 'hooks/useSpaceAvailabilityParameters';
import { ISO_DATE_FORMAT } from 'constants/timeFormat';

export const MapData = () => {
  const { t } = useTranslation('map');

  const { timezone } = useTimezone();
  const { currentUser, permissions } = useAuthContext();

  const mapMode = useMapMode();
  const mapInteractiveLayers = useMapInteractiveLayers();

  //Desk edit mode
  const potentialDeskId = useEditDeskPotentialDeskId();
  const newDeskId = useEditDeskNewDeskId();

  //Resource filters
  const currentFilter = useCurrentFilter();
  const filteredSpaceIds = useFilteredSpaceIds();
  const spaceFilterInitialized = useSpaceFilterInitialized();
  const filteredDeskIds = useFilteredDeskIds();
  const deskFilterInitialized = useDeskFilterInitialized();

  const {
    dates,
    startTimes,
    endTime: deskEndTime,
    durationInMinutes,
  } = useDeskAvailabilityQueryParameters();
  const { durationInMinutes: spaceDurationInMinutes } =
    useSpaceAvailabilityParameters();

  const excludedUsers = currentUser ? [currentUser.id] : [];

  // Format the start and end times to remove the milliseconds, API doesn't like them
  const formattedStartTime =
    startTimes !== null && startTimes.length > 0
      ? moment(startTimes[0])
          .clone()
          .tz('UTC')
          .endOf('minute')
          .format(ISO_DATE_FORMAT)
      : null;

  const formattedDeskEndTime =
    deskEndTime !== undefined
      ? moment(deskEndTime)
          .clone()
          .tz('UTC')
          .startOf('minute')
          .format(ISO_DATE_FORMAT)
      : null;

  return (
    <>
      {mapInteractiveLayers.has('seats') &&
        dates.length === 1 &&
        formattedStartTime &&
        formattedDeskEndTime && (
          <SeatDetailsComponent
            excludeUserIdentities={new Set(excludedUsers)}
            startTime={formattedStartTime}
            endTime={formattedDeskEndTime}
            zoomLimit={{
              low: 2,
              medium: 3,
              high: 4.5,
            }}
            showAnonymous={permissions.hasPublicSeatReservationPermission}
          />
        )}
      {mapInteractiveLayers.has('seats') &&
        currentUser &&
        currentUser.id &&
        (startTimes && dates.length > 1 ? (
          <SeatStateMultiDayComponnentProgressiveLoad
            startTime={startTimes[0].format('H:m')}
            durationInMinutes={durationInMinutes}
            dates={dates}
            timezone={timezone}
            seatPerLoadCycle={10}
            userId={Number(currentUser.id)}
          />
        ) : dates.length === 1 && formattedStartTime && formattedDeskEndTime ? (
          <SeatStateSingleDayComponnentProgressiveLoad
            seatPerLoadCycle={10}
            startTime={formattedStartTime}
            endTime={formattedDeskEndTime}
            userId={Number(currentUser.id)}
          />
        ) : (
          <></>
        ))}

      {(mapInteractiveLayers.has('spaces') ||
        mapInteractiveLayers.has('space_labels')) &&
        currentUser &&
        startTimes &&
        formattedStartTime && (
          <SpaceStateComponentProgressiveLoad
            spacePerLoadCycle={10}
            startTime={formattedStartTime}
            duration={spaceDurationInMinutes}
            dates={startTimes.map((x) => x.toISOString().replace(/\.\d+/, ''))}
            userId={Number(currentUser.id)}
          />
        )}

      {currentFilter === 'spaces' && spaceFilterInitialized && (
        <ResourceStateFilterComponent
          resourceIds={filteredSpaceIds}
          layer="spaces"
        />
      )}

      {currentFilter === 'seats' && deskFilterInitialized && (
        <ResourceStateFilterComponent
          resourceIds={filteredDeskIds}
          layer="seats"
        />
      )}

      {formattedStartTime && formattedDeskEndTime && currentUser && (
        <YourDeskMarker
          startTime={formattedStartTime}
          endTime={formattedDeskEndTime}
          userId={currentUser.id}
          text={t('your_desk')}
        />
      )}

      {mapMode === 'edit-desk-reservation' && (
        <>
          {potentialDeskId && (
            <GenericDeskMarker
              deskIds={[potentialDeskId]}
              text={t('updated_booking')}
            />
          )}

          {!potentialDeskId && newDeskId && (
            <GenericDeskMarker
              deskIds={[newDeskId]}
              text={t('updated_booking')}
            />
          )}
        </>
      )}
    </>
  );
};
