import { Select } from '@robinpowered/ui-kit';
import { ClockCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment-timezone';
import { useMemo, useState, useCallback } from 'react';
import {
  generateTimes,
  locationDateTimeMoment,
  LocationDateTimeMoment,
  momentToLocationDateTime,
} from 'utils';

import {
  SHORT_TIME_FORMAT,
  SUPPORTED_TIME_FORMATS,
} from 'constants/timeFormat';
import { useTimezone } from 'atoms/resource';
import { useTranslation } from 'react-i18next';

interface TimeDropdownProps {
  minimum: LocationDateTimeMoment | null;
  maximum: LocationDateTimeMoment | null;
  selectedTime: LocationDateTimeMoment | null;
  onTimeSelect?: (time: LocationDateTimeMoment) => void;
  status?: '' | 'warning' | 'error' | undefined;
}

export const TimeDropdown = ({
  minimum,
  maximum,
  selectedTime,
  onTimeSelect,
  status,
}: TimeDropdownProps): JSX.Element => {
  const { t } = useTranslation('mapControls');

  const { timezone, longName } = useTimezone();
  const [searchTime, setSearchTime] = useState<LocationDateTimeMoment | null>(
    null
  );
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isInvalidSearch, setIsInvalidSearch] = useState<boolean>(false);

  const times = useMemo(() => {
    const baseTimes = generateTimes(
      minimum || locationDateTimeMoment(timezone).startOf('minute'),
      maximum || locationDateTimeMoment(timezone).startOf('day').add(1, 'day'),
      15,
      true
    );

    // Bit of a workaround here but remove pre-existing values and add them back so we can search them
    const filteredTimes = baseTimes.filter((time) =>
      time
        .format(SHORT_TIME_FORMAT)
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );

    // If there's a valid search time and dropdown is open, add it to the beginning of the times list
    // TODO: fix a bug here if current time 12:00 pm , searching 4:00 will not return 4:00 pm , until you search '4:00 p'
    if (
      dropdownOpen &&
      searchTime &&
      searchTime.isAfter(minimum) &&
      !filteredTimes.some((time) => time.isSame(searchTime))
    ) {
      return [searchTime, ...filteredTimes];
    }

    return filteredTimes;
  }, [minimum, maximum, timezone, searchTime, dropdownOpen, searchValue]);

  const timeItems = useMemo(() => {
    const group = {
      label: <span>{longName}</span>,
      title: longName,
      options: times.map((time) => ({
        label: time.format(SHORT_TIME_FORMAT),
        value: time.toISOString(),
      })),
    };

    return [group];
  }, [times, longName]);

  const handleChange = useCallback(
    (value: string) => {
      const time = momentToLocationDateTime(moment(value), timezone);
      if (onTimeSelect) {
        onTimeSelect(time);
        setIsInvalidSearch(false);
      }
    },
    [onTimeSelect, timezone]
  );

  const handleSearch = useCallback(
    (value: string) => {
      //Dont search undefined or blank values and clear the error state
      if (!value) {
        setIsInvalidSearch(false);
        return;
      }

      setSearchValue(value);

      let parsedTime: Moment | null = null;
      for (const format of SUPPORTED_TIME_FORMATS) {
        parsedTime = moment(value, format, true);
        if (parsedTime.isValid()) {
          break;
        }
      }

      if (parsedTime && parsedTime.isValid()) {
        const searchTimeInDuration = moment.duration({
          hours: parsedTime.hours(),
          minutes: parsedTime.minutes(),
        });

        const startOfToday = locationDateTimeMoment(timezone).startOf('day');
        // Add the duration to the start of today
        const calculatedTime = startOfToday.add(searchTimeInDuration);

        setSearchTime(calculatedTime);
        setIsInvalidSearch(false);
      } else {
        setSearchTime(null);
        setIsInvalidSearch(true);
      }
    },
    [timezone]
  );

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownOpen(open);
    // If dropdown is closing, reset the search time so the list is sorted
    if (!open) {
      setSearchTime(null);
      setSearchValue('');
    }
  };

  const selectedTimeValue = useMemo(
    () => selectedTime?.toISOString(),
    [selectedTime]
  );

  return (
    <Select
      suffixIcon={<ClockCircleOutlined />}
      value={selectedTimeValue}
      defaultValue={selectedTimeValue}
      onChange={handleChange}
      onSearch={handleSearch}
      placeholder={selectedTimeValue || t('time_dropdown.placeholder')}
      dropdownStyle={{ minWidth: 160 }}
      style={{ flexGrow: 1 }}
      filterOption={false}
      notFoundContent={false}
      showSearch
      onDropdownVisibleChange={handleDropdownVisibleChange}
      status={isInvalidSearch || status ? 'error' : undefined}
      options={timeItems}
    />
  );
};
