import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ArrivalDisplayRegistrationKey: { input: unknown; output: unknown; }
  BigInt: { input: number; output: number; }
  CurrencyCodeISO4217: { input: unknown; output: unknown; }
  CustomResourceSubCategoryType: { input: unknown; output: unknown; }
  Date: { input: string; output: string; }
  DateTime: { input: unknown; output: unknown; }
  Decimal: { input: unknown; output: unknown; }
  DocumentBody: { input: unknown; output: unknown; }
  IANATimezone: { input: unknown; output: unknown; }
  IPAddress: { input: unknown; output: unknown; }
  IPAddressCIDR: { input: unknown; output: unknown; }
  ISO8601Duration: { input: string; output: string; }
  ISO8601_Duration: { input: unknown; output: unknown; }
  ISODateTime: { input: unknown; output: unknown; }
  IsoDateOnlyString: { input: unknown; output: unknown; }
  IsoLocalTimeString: { input: unknown; output: unknown; }
  Json: { input: unknown; output: unknown; }
  LocalDate: { input: string; output: string; }
  LocalEndTime: { input: unknown; output: unknown; }
  LocalTime: { input: unknown; output: unknown; }
  Markdown: { input: string; output: string; }
  QueryOrchestratorPlaceholder: { input: unknown; output: unknown; }
  TODO: { input: unknown; output: unknown; }
  UUIDv4: { input: unknown; output: unknown; }
  UtcTime: { input: unknown; output: unknown; }
  Void: { input: void; output: void; }
  WorkflowEmail: { input: unknown; output: unknown; }
};

export type AbstractAnsweredTicketQuestionExpectingChoices = {
  choices: Array<AbstractTicketAnswerChoice>;
  question: AbstractTicketQuestionExpectingChoices;
};

export type AbstractTicketAnswerChoice = TicketAnswerMenuChoice | TicketQuestionOption;

export type AbstractTicketQuestionExpectingChoices = {
  chooseOnlyOneOption: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  options: Array<AbstractTicketQuestionOption>;
  prompt: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type AbstractTicketQuestionOption = {
  description?: Maybe<Scalars['Markdown']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type AccessPassResolvedPlaces = Campus | Level | Location | Organization | Space;

export type AccessPlace = {
  __typename?: 'AccessPlace';
  id: Scalars['ID']['output'];
  type: AccessPlaceType;
};

export enum AccessPlaceType {
  Campus = 'CAMPUS',
  Level = 'LEVEL',
  Location = 'LOCATION',
  Organization = 'ORGANIZATION',
  Space = 'SPACE'
}

export type AccountReservee = {
  __typename?: 'AccountReservee';
  email: Scalars['String']['output'];
  participationStatus?: Maybe<ParticipationStatus>;
  user?: Maybe<AccountUser>;
  visitorId?: Maybe<Scalars['ID']['output']>;
};

export type AccountUser = {
  __typename?: 'AccountUser';
  assignments?: Maybe<Array<Maybe<DraftDeskAssignmentSchedule>>>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  defaultLocation?: Maybe<Location>;
  department?: Maybe<Scalars['String']['output']>;
  draftAccessLevel?: Maybe<DraftAccessLevel>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isMyFavorite?: Maybe<Scalars['Boolean']['output']>;
  managerId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<Email>;
  slug?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  workingHours?: Maybe<Array<Maybe<UserWorkingHour>>>;
};


export type AccountUserAssignmentsArgs = {
  draftId: Scalars['ID']['input'];
};


export type AccountUserDraftAccessLevelArgs = {
  draftId: Scalars['ID']['input'];
};

export type ActivitiesByLocation = {
  __typename?: 'ActivitiesByLocation';
  activities: Array<ActivityForCurrentUser>;
  locationId?: Maybe<Scalars['String']['output']>;
};

export type ActivitiesForDateByLocationIdsInput = {
  date: Scalars['LocalDate']['input'];
  joinedLimit?: InputMaybe<Scalars['Int']['input']>;
  locationIds: Array<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type ActivitiesForTimeRangeByLocationIdsInput = {
  end: Scalars['ISODateTime']['input'];
  joinedLimit?: InputMaybe<Scalars['Int']['input']>;
  locationIds: Array<Scalars['String']['input']>;
  start: Scalars['ISODateTime']['input'];
  timezone: Scalars['String']['input'];
};

export type ActivitiesForTimeRangeByLocationIdsPagingInput = {
  activityLimitPerDay?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCoverImagesObject = {
  __typename?: 'ActivityCoverImagesObject';
  birthday: Scalars['String']['output'];
  celebration: Scalars['String']['output'];
  drinks: Scalars['String']['output'];
  funAndGames: Scalars['String']['output'];
  funAndMusic: Scalars['String']['output'];
  gathering: Scalars['String']['output'];
  holiday: Scalars['String']['output'];
  lunch: Scalars['String']['output'];
};

export type ActivityForCurrentUser = {
  __typename?: 'ActivityForCurrentUser';
  activityLocation?: Maybe<Scalars['String']['output']>;
  audienceLocationId: Scalars['ID']['output'];
  canceledAt?: Maybe<Scalars['String']['output']>;
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  creator: User;
  description?: Maybe<Scalars['String']['output']>;
  end: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  joined: ActivityJoinersList;
  start: Scalars['ISODateTime']['output'];
  timezone: Scalars['String']['output'];
  title: Scalars['String']['output'];
  userRelationship: ActivityUserRelationship;
  virtualLocation?: Maybe<Scalars['String']['output']>;
};

export type ActivityJoinersList = {
  __typename?: 'ActivityJoinersList';
  joiners: Array<User>;
  totalCount: Scalars['Int']['output'];
};

export type ActivityUserRelationship = {
  __typename?: 'ActivityUserRelationship';
  canManage: Scalars['Boolean']['output'];
  isAttending: Scalars['Boolean']['output'];
};

export type AddLevelToLocationResponse = {
  __typename?: 'AddLevelToLocationResponse';
  id: Scalars['Int']['output'];
};

export type AddLevelsToLocationResponse = {
  __typename?: 'AddLevelsToLocationResponse';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AddLevelsWithDetailsToLocationResponse = {
  __typename?: 'AddLevelsWithDetailsToLocationResponse';
  id: Scalars['ID']['output'];
  levelNumber?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type AddLocationToOrganizationResponse = {
  __typename?: 'AddLocationToOrganizationResponse';
  id: Scalars['Int']['output'];
};

export type AddMeetingServiceErrorResponse = {
  __typename?: 'AddMeetingServiceErrorResponse';
  reason?: Maybe<AddMeetingServiceErrorResponseReason>;
};

export enum AddMeetingServiceErrorResponseReason {
  CategoryMissing = 'CATEGORY_MISSING'
}

export type AddMeetingServiceInput = {
  /** The existence of approvers means approval is required before notifying assignees */
  approvers?: InputMaybe<UserOrGroupInput>;
  /** At least one user or group is required */
  assignees: UsersAndGroupsInput;
  /** Provide one object per building, describing the spaces where this service is available. Must have permission to manage services in these buildings. */
  availableInSpacesByBuilding?: InputMaybe<Array<MeetingServiceAvailableInSpacesByBuildingInput>>;
  categoryId: Scalars['ID']['input'];
  /** The currency for all monetary amounts in this service, like on menu item unitPrice. Required, but can be omitted for backwards compatibility. Clients should always pick a default currency, even if the logic is "always USD". */
  currencyCode?: InputMaybe<Scalars['CurrencyCodeISO4217']['input']>;
  description?: InputMaybe<Scalars['Markdown']['input']>;
  name: Scalars['String']['input'];
  /** List of questions to ask when this service is requested, in order */
  questions: Array<TicketQuestionInput>;
};

export type AddMeetingServiceResponse = AddMeetingServiceErrorResponse | AddMeetingServiceSuccessResponse;

export type AddMeetingServiceSuccessResponse = {
  __typename?: 'AddMeetingServiceSuccessResponse';
  meetingServiceId: Scalars['ID']['output'];
};

export enum AddMeetingServicesCategoryErrorReason {
  NameIsNotUnique = 'NAME_IS_NOT_UNIQUE'
}

export type AddMeetingServicesCategoryErrorResponse = {
  __typename?: 'AddMeetingServicesCategoryErrorResponse';
  reason: AddMeetingServicesCategoryErrorReason;
};

export type AddMeetingServicesCategoryInput = {
  /** 'CAT', 'TEC', etc. */
  key: Scalars['String']['input'];
  /** Name must be unique in org among meeting services categories */
  name: Scalars['String']['input'];
};

export type AddMeetingServicesCategoryResponse = AddMeetingServicesCategoryErrorResponse | AddMeetingServicesCategorySuccessResponse;

export type AddMeetingServicesCategorySuccessResponse = {
  __typename?: 'AddMeetingServicesCategorySuccessResponse';
  meetingServicesCategoryId: Scalars['ID']['output'];
};

export type AddOrganizationResponse = {
  __typename?: 'AddOrganizationResponse';
  id: Scalars['Int']['output'];
};

export type AddSpaceToEventRequest = {
  endTime: Scalars['Date']['input'];
  eventId: Scalars['String']['input'];
  spaceId: Scalars['String']['input'];
  startTime: Scalars['Date']['input'];
};

export type AllSchedules = {
  __typename?: 'AllSchedules';
  schedules?: Maybe<Array<Maybe<WeeklyScheduleResult>>>;
};

export type AllocateStickersToOrgByDisplayCodeRequest = {
  displayCodes: Array<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type AllocateStickersToOrgRequest = {
  orgId: Scalars['ID']['input'];
  stickerIds: Array<Scalars['ID']['input']>;
};

export type Amenity = {
  __typename?: 'Amenity';
  /** If the account ID is null, this means it's a global amenity and available to all orgs */
  accountId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ownerTypes: Array<OwnerType>;
  updatedAt: Scalars['Date']['output'];
};

export type AmenitySelectionConfiguration = {
  isDeskAmenity: Scalars['Boolean']['input'];
  isSpaceAmenity: Scalars['Boolean']['input'];
};

export type AnalyticsAuthorizationResponse = {
  __typename?: 'AnalyticsAuthorizationResponse';
  token: Scalars['String']['output'];
};

/** An Announcement is an office message created for given location(s) in an organization. It can be acknowledged by each user in a given org/location, and updated/archived by an admin(?). */
export type Announcement = {
  __typename?: 'Announcement';
  /** Datetime of when annoucnement was instantly archived */
  archivedAt?: Maybe<Scalars['Date']['output']>;
  /** User that created the announcement */
  author: User;
  /** Current content of the announcement */
  content: Scalars['String']['output'];
  /** Datetime of creation */
  createdAt: Scalars['Date']['output'];
  /** Datetime of end of announcement */
  endAt?: Maybe<Scalars['Date']['output']>;
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** List of locations for which the announcement has been published */
  locations: Array<Maybe<Location>>;
  /** Display name */
  name: Scalars['String']['output'];
  /** Standard or priority announcement */
  priorityStatus: AnnouncementPriorityStatus;
  /** Datetime of start of announcement */
  startAt: Scalars['Date']['output'];
  /** Current status of the announcement */
  status: AnnouncementStatus;
};

/** Acknowledgement of an announcement for a user at a location. */
export type AnnouncementAcknowledgement = {
  __typename?: 'AnnouncementAcknowledgement';
  announcementId: Scalars['ID']['output'];
  date: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type AnnouncementCreateInput = {
  content: Scalars['String']['input'];
  distributions?: InputMaybe<Array<DistributionInput>>;
  endAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  locations: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  priorityStatus?: InputMaybe<AnnouncementPriorityStatus>;
  shouldSendPushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  startAt?: InputMaybe<Scalars['ISODateTime']['input']>;
};

/** Each announcement distribution will represent a service the announcement is cross posted to along with its recipients. */
export type AnnouncementDistribution = {
  __typename?: 'AnnouncementDistribution';
  distributionService: DistributionService;
  id: Scalars['ID']['output'];
  recipients: Array<DistributionRecipient>;
  sentAt?: Maybe<Scalars['Date']['output']>;
};

/** Each announcement distribution for location will represent a service the announcement is cross posted to along with its recipients and the announcement with its location. */
export type AnnouncementDistributionForLocation = {
  __typename?: 'AnnouncementDistributionForLocation';
  announcement?: Maybe<AnnouncementForLocation>;
  distributionService: DistributionService;
  id: Scalars['ID']['output'];
  recipients: Array<DistributionRecipient>;
  sentAt?: Maybe<Scalars['Date']['output']>;
};

/** An Announcement within a context of a specific location. */
export type AnnouncementForLocation = {
  __typename?: 'AnnouncementForLocation';
  /** Datetime of when annoucnement was instantly archived */
  archivedAt?: Maybe<Scalars['Date']['output']>;
  /** User that created the announcement */
  author: User;
  /** Current content of the announcement */
  content: Scalars['String']['output'];
  /** Datetime of creation */
  createdAt: Scalars['Date']['output'];
  /** Datetime of end of announcement */
  endAt?: Maybe<Scalars['Date']['output']>;
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** List of locations for which the announcement has been published */
  locations: Array<Maybe<Location>>;
  /** Display name */
  name: Scalars['String']['output'];
  /** Standard or priority announcement */
  priorityStatus: AnnouncementPriorityStatus;
  /** Datetime of start of announcement */
  startAt: Scalars['Date']['output'];
  /** Current status of the announcement */
  status: AnnouncementStatus;
  /** When the requesting user has first viewed the announcement at this location */
  viewedAt?: Maybe<Scalars['String']['output']>;
};

export enum AnnouncementPriorityStatus {
  Priority = 'PRIORITY',
  Standard = 'STANDARD'
}

export enum AnnouncementStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Scheduled = 'SCHEDULED'
}

export type AnnouncementUpdateInput = {
  content: Scalars['String']['input'];
  endAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  locations: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  startAt?: InputMaybe<Scalars['ISODateTime']['input']>;
};

/** View of an announcement for a user at a location. */
export type AnnouncementView = {
  __typename?: 'AnnouncementView';
  announcementId: Scalars['ID']['output'];
  date: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type AnnouncementViewAcknowledgeCount = {
  __typename?: 'AnnouncementViewAcknowledgeCount';
  acknowledgeCount: Scalars['Int']['output'];
  viewCount: Scalars['Int']['output'];
};

export type AnnouncementViewAcknowledgeCountWithId = {
  __typename?: 'AnnouncementViewAcknowledgeCountWithId';
  acknowledgeCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  viewCount: Scalars['Int']['output'];
};

export type AnonymityModeToggleInput = {
  organizationId: Scalars['ID']['input'];
};

export type AnsweredTicketQuestion = {
  question: TicketQuestion;
};

export type AnsweredTicketQuestionExpectingChoices = AbstractAnsweredTicketQuestionExpectingChoices & AnsweredTicketQuestion & {
  __typename?: 'AnsweredTicketQuestionExpectingChoices';
  choices: Array<TicketQuestionOption>;
  question: TicketQuestionExpectingChoices;
};

export type AnsweredTicketQuestionExpectingMenuChoices = AbstractAnsweredTicketQuestionExpectingChoices & AnsweredTicketQuestion & {
  __typename?: 'AnsweredTicketQuestionExpectingMenuChoices';
  choices: Array<TicketAnswerMenuChoice>;
  question: TicketQuestionExpectingMenuChoices;
  /** The total price for everything selected on this menu, after any surcharges/taxes. */
  totalPrice?: Maybe<Money>;
};

export type AnsweredTicketQuestionExpectingText = AnsweredTicketQuestion & {
  __typename?: 'AnsweredTicketQuestionExpectingText';
  answer: Scalars['String']['output'];
  question: TicketQuestionExpectingText;
};

export type AnsweredTicketQuestionInput = {
  choices?: InputMaybe<TicketAnswerExpectingChoicesInput>;
  menuChoices?: InputMaybe<TicketAnswerExpectingMenuChoicesInput>;
  text?: InputMaybe<TicketAnswerExpectingTextInput>;
  /** Optional questions are allowed to omit their answers entirely, but this is useful if you want to remove an existing answer. */
  unanswered?: InputMaybe<UnansweredTicketQuestionInput>;
};

export type ApiOrganizationExternalAccount = {
  __typename?: 'ApiOrganizationExternalAccount';
  accessToken?: Maybe<Scalars['String']['output']>;
  accountId: Scalars['ID']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum ApproveMeetingServiceRequestErrorReason {
  Conflict = 'CONFLICT'
}

export type ApproveMeetingServiceRequestErrorResponse = {
  __typename?: 'ApproveMeetingServiceRequestErrorResponse';
  reason: ApproveMeetingServiceRequestErrorReason;
};

export type ApproveMeetingServiceRequestInput = {
  latestChangeId: Scalars['ID']['input'];
  meetingServiceRequestId: Scalars['ID']['input'];
};

export type ApproveMeetingServiceRequestResponse = ApproveMeetingServiceRequestErrorResponse | ApproveMeetingServiceRequestSuccessResponse;

export type ApproveMeetingServiceRequestSuccessResponse = {
  __typename?: 'ApproveMeetingServiceRequestSuccessResponse';
  _?: Maybe<Scalars['Void']['output']>;
};

export type ArrivalDisplay = {
  __typename?: 'ArrivalDisplay';
  /** @deprecated No longer supported */
  arrivalDisplayDeviceStatusReport?: Maybe<DeviceStatusReport>;
  badgePrinter?: Maybe<BadgePrinter>;
  /**
   * the device status of the associated badge printer
   * @deprecated No longer supported
   */
  badgePrinterDeviceStatusReport?: Maybe<DeviceStatusReport>;
  /** @deprecated prefer traversing tree to badge printer to get IP address */
  badgePrinterIPAddress: Scalars['String']['output'];
  /** The date this arrival display was created */
  createdAt: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  isGuestImageCaptureEnabled: Scalars['Boolean']['output'];
  isGuestSelfRegistrationEnabled: Scalars['Boolean']['output'];
  /** The last time the device reported in */
  lastCheckinTime?: Maybe<Scalars['ISODateTime']['output']>;
  /** location where display resides */
  location: Location;
  /** Name of this arrival display */
  name: Scalars['String']['output'];
  /** True if the registration key has been consumed */
  registrationConsumed: Scalars['Boolean']['output'];
  /** ISO datetime representing when the registration key was consumed */
  registrationConsumedAt?: Maybe<Scalars['ISODateTime']['output']>;
  /** ISO datetime encoded expiration of the active device registration key */
  registrationExpiresAt?: Maybe<Scalars['ISODateTime']['output']>;
  /** One-time registration key used to connect arrival display for first authentication, nullable */
  registrationKey?: Maybe<Scalars['ArrivalDisplayRegistrationKey']['output']>;
};

export type ArrivalDisplayRegistrationResponse = {
  __typename?: 'ArrivalDisplayRegistrationResponse';
  authorization: DeviceAuthorizationResponse;
  certKey: Scalars['String']['output'];
  device: ArrivalDisplay;
  id: Scalars['ID']['output'];
};

export type ArrivalDisplayRescueResponse = {
  __typename?: 'ArrivalDisplayRescueResponse';
  authorization?: Maybe<DeviceAuthorizationResponse>;
  id: Scalars['ID']['output'];
  isPaired: Scalars['Boolean']['output'];
};

export type ArrivalDisplaySettings = {
  __typename?: 'ArrivalDisplaySettings';
  badgePrinterIPAddress: Scalars['String']['output'];
  guestImageCaptureEnabled: Scalars['Boolean']['output'];
  guestSelfRegistrationEnabled: Scalars['Boolean']['output'];
};

export type AssignStickerRequest = {
  assignments: Array<StickerAssignmentInput>;
};

export type AssignStickerResponse = {
  __typename?: 'AssignStickerResponse';
  assignments: Array<StickerAssignment>;
};

export type AssignStickerToDeskByShortCodeRequest = {
  deskId: Scalars['Int']['input'];
  stickerShortCode: Scalars['String']['input'];
};

export type AssignStickerToDeskByShortCodeResponse = {
  __typename?: 'AssignStickerToDeskByShortCodeResponse';
  deskId: Scalars['Int']['output'];
  stickerShortCode: Scalars['String']['output'];
};

export type AssignedUser = {
  __typename?: 'AssignedUser';
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type Assignee = {
  __typename?: 'Assignee';
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AssigneeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type Assignment = {
  __typename?: 'Assignment';
  assignee: Assignee;
  id?: Maybe<Scalars['ID']['output']>;
  recurrence: Scalars['String']['output'];
};

export type AssignmentInput = {
  assignee: AssigneeInput;
  recurrence: Scalars['String']['input'];
};

export enum AuthContextAccountUserRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export enum AuthContextAppScope {
  BasicRead = 'BASIC_READ',
  BasicWrite = 'BASIC_WRITE',
  Privileged = 'PRIVILEGED'
}

export enum AuthContextOrganizationScope {
  BasicRead = 'BASIC_READ',
  BasicWrite = 'BASIC_WRITE'
}

export type AuthMethod = {
  __typename?: 'AuthMethod';
  type: Scalars['String']['output'];
  uri?: Maybe<Scalars['String']['output']>;
};

export type AuthorizationResponse = {
  __typename?: 'AuthorizationResponse';
  bearerToken: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  refreshToken: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type AutoAllocateStickersToOrgRequest = {
  orgId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  startingDisplayCode: Scalars['Int']['input'];
};

export type AutoDeskBookingAttemptedResponseType = {
  __typename?: 'AutoDeskBookingAttemptedResponseType';
  autoDeskBookingAttemptFailed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  localDate: Scalars['LocalDate']['output'];
};

export type AutoDeskBookingInternalResponse = {
  __typename?: 'AutoDeskBookingInternalResponse';
  locationIds: Array<Scalars['ID']['output']>;
  orgId: Scalars['ID']['output'];
};

export type AutoDeskBookingOrgInput = {
  enabled: Scalars['Boolean']['input'];
  locationIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AutoDeskBookingOrgResponse = {
  __typename?: 'AutoDeskBookingOrgResponse';
  enabled: Scalars['Boolean']['output'];
  locationIds: Array<Scalars['String']['output']>;
};

/** Auto desk booking run failure report */
export type AutoDeskBookingProcessFailureReportCommand = {
  /** Explanation of failure. Open field. Stack trace could also be provided. */
  failureBody: Scalars['String']['input'];
  /** Code for convenient grouping of failures by type. Convention is entirely up to the client software. */
  failureCode: Scalars['String']['input'];
};

export type AutoDeskBookingResponse = {
  __typename?: 'AutoDeskBookingResponse';
  autoDeskBooking: Scalars['Boolean']['output'];
};

export type AutoDeskBookingScheduleResponse = {
  __typename?: 'AutoDeskBookingScheduleResponse';
  localDate: Scalars['LocalDate']['output'];
  reservation?: Maybe<Reservation>;
};

/** Failed booking a user report */
export type AutoDeskBookingUserBatchFailureReportCommand = {
  /** Explanation of failure. Open field. Stack trace could also be provided. */
  failureBody: Scalars['String']['input'];
  /** Code for convenient grouping of failures by type. Convention is entirely up to the client software. */
  failureCode: Scalars['String']['input'];
  /** Date of attempted booking */
  localBookingDate: Scalars['LocalDate']['input'];
  /** ID of failed booking location */
  locationId: Scalars['ID']['input'];
  /** ID of organization */
  tenantId: Scalars['ID']['input'];
  /** ID of user */
  userId: Scalars['ID']['input'];
};

export enum Availability {
  /** Currently available */
  Available = 'AVAILABLE',
  /** Resource has been booked */
  Booked = 'BOOKED',
  /** Resource marked as unavailable for booking */
  Unavailable = 'UNAVAILABLE',
  /** Resource marked as unknown due to desk inexistence */
  Unknown = 'UNKNOWN'
}

export type BadgeCsvUploadRow = {
  __typename?: 'BadgeCSVUploadRow';
  email: Scalars['String']['output'];
  timestamp: Scalars['Int']['output'];
};

export type BadgeCsvUploadStatusError = {
  __typename?: 'BadgeCSVUploadStatusError';
  reason: Scalars['String']['output'];
  row?: Maybe<BadgeCsvUploadRow>;
};

export type BadgePrinter = {
  __typename?: 'BadgePrinter';
  arrivalDisplays?: Maybe<Array<Maybe<ArrivalDisplay>>>;
  /** The date this badge printer was created */
  createdAt: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  ipAddress: Scalars['String']['output'];
  location: Location;
  /** Name of this badge printer */
  name: Scalars['String']['output'];
};

export type BatchUnlinkKisiLocksFromRobinLevelsInput = {
  remoteLockIds: Array<Scalars['String']['input']>;
};

export type BatchUnlinkKisiLocksFromRobinLevelsResponse = {
  __typename?: 'BatchUnlinkKisiLocksFromRobinLevelsResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type BatchUnlinkKisiLocksFromRobinLocationsInput = {
  remoteLockIds: Array<Scalars['String']['input']>;
};

export type BatchUnlinkKisiLocksFromRobinLocationsResponse = {
  __typename?: 'BatchUnlinkKisiLocksFromRobinLocationsResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type BookedSchedulingAggregateOfDesks = {
  __typename?: 'BookedSchedulingAggregateOfDesks';
  /** Total number of desks of type Assigned that are booked */
  deskAssignedBookedTotal: Scalars['Int']['output'];
  /** Total number of booked desks */
  deskBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hot that are booked */
  deskHotBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hotel that are booked */
  deskHotelBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Shared that are booked */
  deskSharedBookedTotal: Scalars['Int']['output'];
  /** Represents the end time window for assessed desk booking status */
  endTime: Scalars['Date']['output'];
  /** Represents the start time window for assessed desk booking status */
  startTime: Scalars['Date']['output'];
};

export enum BookingPolicyType {
  /** Indicate the desk may be indefinitely assigned to a particular user. */
  Assigned = 'ASSIGNED',
  /** Indicate that the desk is available to be booked for short term use. */
  Hot = 'HOT',
  /** Indicate that the desk may be reserved for a defined period duration. */
  Hoteled = 'HOTELED',
  /** Indicate that the desk may be reverse hoteled on an excluded assigned reservation. */
  ReverseHotel = 'REVERSE_HOTEL',
  /** Indicate that more people can be assigned to a single desk. Only used in combination with either ASSIGNED or HOTELED types */
  Shared = 'SHARED'
}

export enum BookingStatus {
  ApprovalRequired = 'APPROVAL_REQUIRED',
  Assigned = 'ASSIGNED',
  NonReservable = 'NON_RESERVABLE',
  OnDemand = 'ON_DEMAND',
  Reservable = 'RESERVABLE'
}

export enum BookingType {
  ApprovalRequired = 'APPROVAL_REQUIRED',
  Assigned = 'ASSIGNED',
  NonReservable = 'NON_RESERVABLE',
  OnDemand = 'ON_DEMAND',
  Reservable = 'RESERVABLE'
}

export type BookingWindowPolicy = {
  __typename?: 'BookingWindowPolicy';
  affectedGroups: Array<BookingWindowPolicyAffectedGroup>;
  affectedUsers: Array<BookingWindowPolicyAffectedUser>;
  defaultDuration: Scalars['ISO8601Duration']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  targets: Array<BookingWindowPolicyTarget>;
};

export type BookingWindowPolicyAffectedGroup = {
  __typename?: 'BookingWindowPolicyAffectedGroup';
  anchorDays: Array<DayOfWeek>;
  duration: Scalars['ISO8601Duration']['output'];
  group: Group;
};

export type BookingWindowPolicyAffectedGroupInput = {
  anchorDays: Array<DayOfWeek>;
  duration: Scalars['ISO8601Duration']['input'];
  id: Scalars['ID']['input'];
};

export type BookingWindowPolicyAffectedUser = {
  __typename?: 'BookingWindowPolicyAffectedUser';
  anchorDays: Array<DayOfWeek>;
  duration: Scalars['ISO8601Duration']['output'];
  user: AccountUser;
};

export type BookingWindowPolicyAffectedUserInput = {
  anchorDays: Array<DayOfWeek>;
  duration: Scalars['ISO8601Duration']['input'];
  id: Scalars['ID']['input'];
};

export type BookingWindowPolicyDefinitionInput = {
  affectedGroups: Array<BookingWindowPolicyAffectedGroupInput>;
  affectedUsers: Array<BookingWindowPolicyAffectedUserInput>;
  defaultDuration: Scalars['ISO8601Duration']['input'];
  name: Scalars['String']['input'];
  targets: Array<BookingWindowPolicyTargetInput>;
};

export type BookingWindowPolicyInfo = {
  __typename?: 'BookingWindowPolicyInfo';
  defaultAnchorDays?: Maybe<Array<Maybe<DayOfWeek>>>;
  defaultDuration?: Maybe<Scalars['String']['output']>;
  policyAnchorDays?: Maybe<Array<Maybe<DayOfWeek>>>;
  policyDuration?: Maybe<Scalars['String']['output']>;
};

export type BookingWindowPolicyTarget = Level | Zone;

export type BookingWindowPolicyTargetInput = {
  id: Scalars['ID']['input'];
  type: BookingWindowPolicyTargetType;
};

export enum BookingWindowPolicyTargetType {
  Level = 'LEVEL',
  Zone = 'ZONE'
}

export enum BrivoTenancy {
  Eu = 'EU',
  Us = 'US'
}

export type Building = {
  __typename?: 'Building';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type ByWeekDayValue = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  weekday: RecurringReservationDayOfWeek;
};

export type Calendar = {
  __typename?: 'Calendar';
  calendarId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  remoteType: Scalars['String']['output'];
  spaceId: Scalars['ID']['output'];
  spaceResourceEmail?: Maybe<Scalars['String']['output']>;
  spaceResourceId?: Maybe<Scalars['String']['output']>;
  subscriberExpiresAt?: Maybe<Scalars['Date']['output']>;
  subscriberId?: Maybe<Scalars['String']['output']>;
  syncToken?: Maybe<Scalars['String']['output']>;
};

export enum CalendarHealthEnum {
  Healthy = 'HEALTHY',
  Unhealthy = 'UNHEALTHY'
}

export type Campus = {
  __typename?: 'Campus';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  /** Total desks within the campus that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  id: Scalars['ID']['output'];
  locations: Array<Location>;
  name: Scalars['String']['output'];
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type CancelledVisitOrReservationResult = {
  __typename?: 'CancelledVisitOrReservationResult';
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  success: Scalars['Boolean']['output'];
};

export enum ChangeAnswersOnMeetingServiceRequestErrorReason {
  Conflict = 'CONFLICT'
}

export type ChangeAnswersOnMeetingServiceRequestErrorResponse = {
  __typename?: 'ChangeAnswersOnMeetingServiceRequestErrorResponse';
  reason: ChangeAnswersOnMeetingServiceRequestErrorReason;
};

export type ChangeAnswersOnMeetingServiceRequestInput = {
  /** The answers to the questions on the MeetingService, like in RequestMeetingServiceForDraftEventAtSpaceInput. Supports partial updates by omitting answers, but it's fine to include unchanged answers. */
  answers: Array<AnsweredTicketQuestionInput>;
  meetingServiceRequestId: Scalars['ID']['input'];
  /** The completed form ID before any changes - for concurrency control. If there was no completed form due to lack of answers, pass in null. Omitting this field is an error. */
  previousCompletedFormId?: InputMaybe<Scalars['ID']['input']>;
};

export type ChangeAnswersOnMeetingServiceRequestResponse = ChangeAnswersOnMeetingServiceRequestErrorResponse | ChangeAnswersOnMeetingServiceRequestSuccessResponse;

/** Clients should refetch affected service request to get the latest data */
export type ChangeAnswersOnMeetingServiceRequestSuccessResponse = {
  __typename?: 'ChangeAnswersOnMeetingServiceRequestSuccessResponse';
  _?: Maybe<Scalars['Void']['output']>;
};

export type ChangeBrivoLockLinksInput = {
  locks: Array<ChangeBrivoLockLinksInputLocks>;
};

export type ChangeBrivoLockLinksInputLocks = {
  remoteLockId: Scalars['ID']['input'];
  robinLevelId?: InputMaybe<Scalars['Int']['input']>;
  robinLocationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ChangeBrivoLockLinksResponse = {
  __typename?: 'ChangeBrivoLockLinksResponse';
  uuid: Scalars['ID']['output'];
};

export type ChangeGeneaDoorLinksInput = {
  doors: Array<ChangeGeneaDoorLinksInputLocks>;
};

export type ChangeGeneaDoorLinksInputLocks = {
  remoteDoorId: Scalars['ID']['input'];
  robinLevelId?: InputMaybe<Scalars['Int']['input']>;
  robinLocationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ChangeGeneaLockLinksResponse = {
  __typename?: 'ChangeGeneaLockLinksResponse';
  uuid: Scalars['ID']['output'];
};

export type ChangeKisiLockLinkToRobinLevelInput = {
  remoteLockId: Scalars['String']['input'];
  robinLevelId: Scalars['Int']['input'];
};

export type ChangeKisiLockLinkToRobinLevelResponse = {
  __typename?: 'ChangeKisiLockLinkToRobinLevelResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type ChangeKisiLockLinkToRobinLocationInput = {
  remoteLockId: Scalars['String']['input'];
  robinLocationId: Scalars['Int']['input'];
};

export type ChangeKisiLockLinkToRobinLocationResponse = {
  __typename?: 'ChangeKisiLockLinkToRobinLocationResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type ChangeOpenpathLockLinksInput = {
  locks: Array<ChangeOpenpathLockLinksInputLocks>;
};

export type ChangeOpenpathLockLinksInputLocks = {
  remoteLockId: Scalars['String']['input'];
  robinLevelId?: InputMaybe<Scalars['Int']['input']>;
  robinLocationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ChangeOpenpathLockLinksResponse = {
  __typename?: 'ChangeOpenpathLockLinksResponse';
  uuid: Scalars['ID']['output'];
};

export type ChangeVerkadaDoorLinksInput = {
  doors: Array<ChangeVerkadaDoorLinksInputDoors>;
};

export type ChangeVerkadaDoorLinksInputDoors = {
  remoteDoorId: Scalars['ID']['input'];
  robinLevelId?: InputMaybe<Scalars['Int']['input']>;
  robinLocationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ChangeVerkadaLockLinksResponse = {
  __typename?: 'ChangeVerkadaLockLinksResponse';
  uuid: Scalars['ID']['output'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  sentAt: Scalars['ISODateTime']['output'];
  sentByUser: UserWithDefault;
};

export type CheckInStatus = {
  __typename?: 'CheckInStatus';
  checkInComplete: Scalars['Boolean']['output'];
  checkInPossibleFuture: Scalars['Boolean']['output'];
  checkInPossibleNow: Scalars['Boolean']['output'];
};

export type CheckOrganizationNameAvailabilityResponse = {
  __typename?: 'CheckOrganizationNameAvailabilityResponse';
  available: Scalars['Boolean']['output'];
};

export type ClientOptionsInput = {
  notifyRecipient?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ClientType {
  DashboardWeb = 'DASHBOARD_WEB',
  Kiosk = 'KIOSK',
  Other = 'OTHER',
  RobinMobile = 'ROBIN_MOBILE'
}

export type CompletedTicketForm = {
  __typename?: 'CompletedTicketForm';
  id: Scalars['ID']['output'];
  questions: Array<AnsweredTicketQuestion>;
};

export enum ConfirmationMethod {
  Local = 'LOCAL',
  Remote = 'REMOTE'
}

export type ConnectGeneaAccountForCurrentTenantInput = {
  apiKey: Scalars['String']['input'];
  tenancy?: InputMaybe<GeneaTenancy>;
};

export type ConnectGeneaAccountForCurrentTenantResponse = {
  __typename?: 'ConnectGeneaAccountForCurrentTenantResponse';
  uuid: Scalars['ID']['output'];
};

export type ConnectGenericConnectorAccountInput = {
  vendor: Scalars['String']['input'];
};

export type ConnectGenericConnectorAccountResponse = {
  __typename?: 'ConnectGenericConnectorAccountResponse';
  uuid: Scalars['ID']['output'];
};

export type ConnectKisiAccountInput = {
  apiKey: Scalars['String']['input'];
};

export type ConnectKisiAccountResponse = {
  __typename?: 'ConnectKisiAccountResponse';
  uuid: Scalars['ID']['output'];
};

export type ConnectOpenpathAccountInput = {
  email: Scalars['String']['input'];
  otp?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type ConnectOpenpathAccountResponse = {
  __typename?: 'ConnectOpenpathAccountResponse';
  uuid: Scalars['ID']['output'];
};

export type ConnectRipplingAccountForOrganizationAuthDataInput = {
  access_token: Scalars['String']['input'];
  account_id: Scalars['String']['input'];
  expires_in: Scalars['Int']['input'];
  id_token: Scalars['String']['input'];
  refresh_token: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  token_type: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};

export type ConnectRipplingAccountForOrganizationInput = {
  authData: ConnectRipplingAccountForOrganizationAuthDataInput;
  organizationId: Scalars['ID']['input'];
};

export type ConnectRipplingAccountForOrganizationResponse = {
  __typename?: 'ConnectRipplingAccountForOrganizationResponse';
  id: Scalars['ID']['output'];
};

export type ConnectVerkadaAccountForCurrentTenantInput = {
  apiKey: Scalars['String']['input'];
  tenancy: VerkadaTenancy;
};

export type ConnectVerkadaAccountForCurrentTenantResponse = {
  __typename?: 'ConnectVerkadaAccountForCurrentTenantResponse';
  uuid: Scalars['ID']['output'];
};

export type ConvertGroupToExternalRipplingGroupInput = {
  slug: Scalars['ID']['input'];
};

export type ConvertGroupToExternalRipplingGroupResponse = {
  __typename?: 'ConvertGroupToExternalRipplingGroupResponse';
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type CountMeetingServiceRequestsInput = {
  filters?: InputMaybe<ListMeetingServiceRequestsFiltersInput>;
};

export type CountMeetingServiceRequestsResponse = {
  __typename?: 'CountMeetingServiceRequestsResponse';
  count: Scalars['Int']['output'];
};

export type CountMeetingServicesCategoriesInput = {
  filters?: InputMaybe<ListMeetingServicesCategoriesFiltersInput>;
};

export type CountMeetingServicesCategoriesResponse = {
  __typename?: 'CountMeetingServicesCategoriesResponse';
  count: Scalars['Int']['output'];
};

export type CountMeetingServicesInput = {
  filters?: InputMaybe<ListMeetingServicesFiltersInput>;
};

export type CountMeetingServicesResponse = {
  __typename?: 'CountMeetingServicesResponse';
  count: Scalars['Int']['output'];
};

export type CreateActivityInput = {
  activityLocation?: InputMaybe<Scalars['String']['input']>;
  audienceLocationId: Scalars['ID']['input'];
  /** Should be a URL */
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['ISODateTime']['input'];
  externalEventId?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['ISODateTime']['input'];
  timezone: Scalars['String']['input'];
  title: Scalars['String']['input'];
  /** Should be a URL */
  virtualLocation?: InputMaybe<Scalars['String']['input']>;
};

export type CreateActivityReturnType = {
  __typename?: 'CreateActivityReturnType';
  id: Scalars['String']['output'];
};

export type CreateAndMapBuildingsFromRipplingBuildingsInput = {
  address: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ripplingBuildingId?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type CreateAndMapBuildingsFromRipplingInput = {
  buildings: Array<CreateAndMapBuildingsFromRipplingBuildingsInput>;
  organizationId: Scalars['ID']['input'];
};

export type CreateAndMapBuildingsFromRipplingResponse = {
  __typename?: 'CreateAndMapBuildingsFromRipplingResponse';
  id: Scalars['ID']['output'];
};

export type CreateBadgeCsvUploadLinkInput = {
  locationId: Scalars['Int']['input'];
  vendor: Scalars['String']['input'];
};

export type CreateBadgeCsvUploadLinkResponse = {
  __typename?: 'CreateBadgeCSVUploadLinkResponse';
  link: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type CreateBookingWindowPolicyInput = {
  locationId: Scalars['ID']['input'];
  policy?: InputMaybe<BookingWindowPolicyDefinitionInput>;
};

export type CreateDeliveryInput = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>;
  base64LabelImage?: InputMaybe<Scalars['String']['input']>;
  carrier?: InputMaybe<Scalars['String']['input']>;
  deliveryZoneId: Scalars['String']['input'];
  recipient?: InputMaybe<RecipientInput>;
  status?: InputMaybe<DeliveryStatus>;
};

export type CreateDeliveryZoneInput = {
  levelId?: InputMaybe<Scalars['ID']['input']>;
  locationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  spaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateDraftResponse = {
  __typename?: 'CreateDraftResponse';
  id: Scalars['ID']['output'];
};

export type CreateGenericHealthCheckpointResponse = {
  __typename?: 'CreateGenericHealthCheckpointResponse';
  id: Scalars['String']['output'];
};

export type CreateGroupAndMakeItExternalRipplingGroupInput = {
  description: Scalars['String']['input'];
  isPrivate: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type CreateGroupAndMakeItExternalRipplingGroupResponse = {
  __typename?: 'CreateGroupAndMakeItExternalRipplingGroupResponse';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type CreateLevelInput = {
  levelNumber: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CreateRobinOrganizationFromRipplingOAuthDataInput = {
  code: Scalars['String']['input'];
};

export type CreateRobinOrganizationFromRipplingOAuthDataResponse = {
  __typename?: 'CreateRobinOrganizationFromRipplingOAuthDataResponse';
  organizationId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type CreateWorkAreaInput = {
  deskIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CurrentUserAndOrgs = {
  __typename?: 'CurrentUserAndOrgs';
  /** If true, user can see approval notifications for the organization specified in the tenant-id header. */
  canSeeApprovalNotificationsForCurrentTenant: Scalars['Boolean']['output'];
  hasActivitiesCreateForCurrentTenant: Scalars['Boolean']['output'];
  hasActivitiesManageForCurrentTenant: Scalars['Boolean']['output'];
  hasAmenitiesManageForCurrentTenant: Scalars['Boolean']['output'];
  hasAnalyticsPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasAnnouncementsManageForCurrentTenant: Scalars['Boolean']['output'];
  hasBillingPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasCheckpointsViewPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasDevicesManageForCurrentTenant: Scalars['Boolean']['output'];
  hasDevicesViewForCurrentTenant: Scalars['Boolean']['output'];
  hasGroupsManageForCurrentTenant: Scalars['Boolean']['output'];
  hasListUserPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasManageUserPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasOfficeAccessManagePermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasOfficeAccessViewPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasPlacesManageForCurrentTenant: Scalars['Boolean']['output'];
  hasRolesManagePermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasSeatsCoworkingReserveForCurrentTenant: Scalars['Boolean']['output'];
  hasSeatsReservePublicPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasSpacesReservePublicPermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasViewVisitsTabForCurrentTenant: Scalars['Boolean']['output'];
  hasVisitsManageForCurrentTenant: Scalars['Boolean']['output'];
  hasWorkPoliciesManagePermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasWorkplaceServicesManagePermissionForCurrentTenant: Scalars['Boolean']['output'];
  hasWorkweekViewForCurrentTenant: Scalars['Boolean']['output'];
  organizations: Array<Organization>;
  totalPendingApprovalNotificationsForOrganization: Scalars['Int']['output'];
  user: User;
  userId: Scalars['ID']['output'];
};

export type CursoredPaginationResponse = {
  __typename?: 'CursoredPaginationResponse';
  cursor?: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
};

export type CustomField = {
  __typename?: 'CustomField';
  choices?: Maybe<Array<CustomFieldChoice>>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
  showOnBadge?: Maybe<Scalars['Boolean']['output']>;
  type: CustomFieldType;
  value?: Maybe<Scalars['Json']['output']>;
};

export type CustomFieldChoice = {
  __typename?: 'CustomFieldChoice';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type CustomFieldInput = {
  choices?: InputMaybe<Array<CustomFieldInputChoice>>;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  required?: InputMaybe<Scalars['Boolean']['input']>;
  showOnBadge?: InputMaybe<Scalars['Boolean']['input']>;
  type: CustomFieldType;
  value?: InputMaybe<Scalars['Json']['input']>;
};

export type CustomFieldInputChoice = {
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};

export enum CustomFieldType {
  LongText = 'LONG_TEXT',
  ShortText = 'SHORT_TEXT',
  SingleSelect = 'SINGLE_SELECT'
}

export type CustomResource = {
  __typename?: 'CustomResource';
  amenityIds: Array<Scalars['ID']['output']>;
  bookingType?: Maybe<WeeklyBookingType>;
  capacity: Scalars['Int']['output'];
  customResourceInstanceId: Scalars['ID']['output'];
  floorId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  originatingCustomResourceTemplateId: Scalars['ID']['output'];
  policyConfiguration?: Maybe<CustomResourcePolicyDocumentType>;
  resourceGroupId?: Maybe<Scalars['ID']['output']>;
  resourceGroupName?: Maybe<Scalars['String']['output']>;
  subCategory: Scalars['String']['output'];
};

export type CustomResourceInstance = {
  __typename?: 'CustomResourceInstance';
  amenityIds?: Maybe<Array<Scalars['ID']['output']>>;
  bookingType?: Maybe<ResourceWeeklyBookingType>;
  capacity?: Maybe<Scalars['Int']['output']>;
  decorId: Scalars['ID']['output'];
  floorId?: Maybe<Scalars['ID']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  ownerId: Scalars['ID']['output'];
  ownerType: Scalars['String']['output'];
  policies?: Maybe<ResourcePolicy>;
  subCategory?: Maybe<Scalars['String']['output']>;
  zoneId?: Maybe<Scalars['ID']['output']>;
};

export type CustomResourceInstanceInput = {
  amenityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  bookingType?: InputMaybe<ResourceWeeklyBookingTypeInput>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  decorId: Scalars['ID']['input'];
  floorId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
  ownerType: Scalars['String']['input'];
  policies?: InputMaybe<LayoutResourcePolicyInput>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomResourceInstancePrePublishChangeType = {
  __typename?: 'CustomResourceInstancePrePublishChangeType';
  amenityIds: Array<Scalars['ID']['output']>;
  bookingType?: Maybe<WeeklyBookingType>;
  capacity: Scalars['Int']['output'];
  customResourceInstanceId: Scalars['ID']['output'];
  floorId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  originatingCustomResourceTemplateId: Scalars['ID']['output'];
  policyConfiguration?: Maybe<CustomResourcePolicyDocumentType>;
  resourceGroupId?: Maybe<Scalars['ID']['output']>;
  resourceGroupName?: Maybe<Scalars['String']['output']>;
  subCategory: Scalars['String']['output'];
};

export type CustomResourcePolicyDocument = {
  __typename?: 'CustomResourcePolicyDocument';
  abandonedResourceProtection: PolicyEnabledWithDurationType;
  advanceCheckInWindow: PolicyEnabledWithDurationType;
  advancedBookingThreshold: PolicyEnabledWithDurationType;
  allowOverlappingBookings: Scalars['Boolean']['output'];
  bookingPrivacy: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  localCheckInOnly: Scalars['Boolean']['output'];
  maxBookingLength: PolicyEnabledWithDurationType;
  preventBookingFromKiosk: Scalars['Boolean']['output'];
  recurrence: Scalars['Boolean']['output'];
  requireCheckIn: Scalars['Boolean']['output'];
  resourceSharing: Scalars['Boolean']['output'];
  restrictBookingToWorkingHours: Scalars['Boolean']['output'];
};

export type CustomResourcePolicyDocumentCommand = {
  abandonedResourceProtection: PolicyEnabledWithDurationCommand;
  advanceCheckInWindow: PolicyEnabledWithDurationCommand;
  advancedBookingThreshold: PolicyEnabledWithDurationCommand;
  allowOverlappingBookings: Scalars['Boolean']['input'];
  bookingPrivacy: Scalars['Boolean']['input'];
  localCheckInOnly: Scalars['Boolean']['input'];
  maxBookingLength: PolicyEnabledWithDurationCommand;
  preventBookingFromKiosk: Scalars['Boolean']['input'];
  recurrence: Scalars['Boolean']['input'];
  requireCheckIn: Scalars['Boolean']['input'];
  resourceSharing: Scalars['Boolean']['input'];
  restrictBookingToWorkingHours: Scalars['Boolean']['input'];
};

export type CustomResourcePolicyDocumentType = {
  __typename?: 'CustomResourcePolicyDocumentType';
  abandonedResourceProtection: PolicyEnabledWithDurationType;
  advanceCheckInWindow: PolicyEnabledWithDurationType;
  advancedBookingThreshold: PolicyEnabledWithDurationType;
  allowOverlappingBookings: Scalars['Boolean']['output'];
  bookingPrivacy: Scalars['Boolean']['output'];
  localCheckInOnly: Scalars['Boolean']['output'];
  maxBookingLength: PolicyEnabledWithDurationType;
  preventBookingFromKiosk: Scalars['Boolean']['output'];
  recurrence: Scalars['Boolean']['output'];
  requireCheckIn: Scalars['Boolean']['output'];
  resourceSharing: Scalars['Boolean']['output'];
  restrictBookingToWorkingHours: Scalars['Boolean']['output'];
};

export type CustomResourceReservationLinkType = {
  __typename?: 'CustomResourceReservationLinkType';
  id: Scalars['ID']['output'];
  reservations: Array<ResourceReservation>;
  resourceInstanceId: Scalars['ID']['output'];
};

export type CustomResourceReservationsRangeResponseType = {
  __typename?: 'CustomResourceReservationsRangeResponseType';
  id: Scalars['ID']['output'];
  localRangeEndDate: Scalars['IsoDateOnlyString']['output'];
  localRangeStartDate: Scalars['IsoDateOnlyString']['output'];
  resourceReservations: Array<CustomResourceReservationLinkType>;
};

export type CustomResourceTemplate = {
  __typename?: 'CustomResourceTemplate';
  associatedAmenities: Array<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<IconType>;
  id: Scalars['ID']['output'];
  policyDocument: CustomResourcePolicyDocument;
  resourceCategory: Scalars['String']['output'];
  subCategories: Array<Scalars['CustomResourceSubCategoryType']['output']>;
};

export type CustomVisitForm = {
  __typename?: 'CustomVisitForm';
  guestInviteFields?: Maybe<Array<CustomField>>;
  id?: Maybe<Scalars['ID']['output']>;
  locationIds?: Maybe<Array<Scalars['ID']['output']>>;
  locations?: Maybe<Array<Location>>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISODateTime']['output']>;
  updatedBy?: Maybe<UserWithDefault>;
  visitFields?: Maybe<Array<CustomField>>;
};

export type CustomVisitFormInput = {
  guestInviteFields?: InputMaybe<Array<CustomFieldInput>>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  visitFields?: InputMaybe<Array<CustomFieldInput>>;
};

export type CustomVisitFormLocationResponse = {
  __typename?: 'CustomVisitFormLocationResponse';
  linkedLocations?: Maybe<Array<Location>>;
  unlinkedLocations?: Maybe<Array<Location>>;
};

export type CustomVisitFormOrderBy = {
  field?: InputMaybe<CustomVisitFormOrderByField>;
  ordinality?: InputMaybe<Ordinality>;
};

export enum CustomVisitFormOrderByField {
  FormName = 'FormName',
  NumberOfLinkedLocations = 'NumberOfLinkedLocations',
  UpdatedAt = 'UpdatedAt'
}

export type CustomWorkingHours = DateSpecificWorkingHours & {
  __typename?: 'CustomWorkingHours';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  timeFrames: Array<TimeFrame>;
};

export type CustomWorkingHoursBulkUpdateInput = {
  date: Scalars['LocalDate']['input'];
  end?: InputMaybe<Scalars['LocalEndTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['LocalEndTime']['input']>;
};

/** `date` is formated as YYYY-MM-DD while `start` and `end` are formated as HH:MM from 00:00 -> 24:00. `start` is inclusive and `end` is exclusive in the time range If `start` and `end` is `null`, that denotes an all day closure */
export type CustomWorkingHoursInput = {
  date: Scalars['LocalDate']['input'];
  end?: InputMaybe<Scalars['LocalEndTime']['input']>;
  locationId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['LocalEndTime']['input']>;
};

/** `date` is formated as YYYY-MM-DD while `start` and `end` are formated as HH:MM from 00:00 -> 24:00. `start` is inclusive and `end` is exclusive in the time range If `start` and `end` is `null`, that denotes an all day closure */
export type CustomWorkingHoursUpdateInput = {
  date: Scalars['LocalDate']['input'];
  end?: InputMaybe<Scalars['LocalEndTime']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['LocalEndTime']['input']>;
};

export type Customizations = {
  __typename?: 'Customizations';
  failBody?: Maybe<Scalars['String']['output']>;
  failHeadline?: Maybe<Scalars['String']['output']>;
  failureEmails: Array<Scalars['String']['output']>;
  passBody?: Maybe<Scalars['String']['output']>;
  passHeadline?: Maybe<Scalars['String']['output']>;
};

export enum Device_Type {
  Unknown = 'UNKNOWN',
  VisitorArrival = 'VISITOR_ARRIVAL',
  VisitorBadgePrinter = 'VISITOR_BADGE_PRINTER'
}

export type DailyActivitiesSummary = {
  __typename?: 'DailyActivitiesSummary';
  activities: Array<ActivityForCurrentUser>;
  date: Scalars['Date']['output'];
  totalActivities: Scalars['Int']['output'];
};

export type DateFilterInput = {
  endTimeInclusive?: InputMaybe<Scalars['ISODateTime']['input']>;
  startTimeExclusive?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type DateFilterMandatoryInput = {
  endInclusivity?: InputMaybe<TimeInclusivityType>;
  endTime?: InputMaybe<Scalars['ISODateTime']['input']>;
  startInclusivity?: InputMaybe<TimeInclusivityType>;
  startTime?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type DateFilterOptionalInput = {
  endTime?: InputMaybe<Scalars['ISODateTime']['input']>;
  startTime?: InputMaybe<Scalars['ISODateTime']['input']>;
};

export type DateGroupedUserReservations = {
  __typename?: 'DateGroupedUserReservations';
  reservationsByDate: Array<UserDeskReservationByDate>;
  users: Array<Reservee>;
};

export type DateRangeInput = {
  endTime: Scalars['ISODateTime']['input'];
  startTime: Scalars['ISODateTime']['input'];
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type DateRangeWithTimeZone = {
  end: DateWithTimezone;
  start: DateWithTimezone;
};

export type DateSpecificWorkingHours = {
  date: Scalars['Date']['output'];
  timeFrames: Array<TimeFrame>;
};

export type DateTimezone = {
  __typename?: 'DateTimezone';
  date: Scalars['Date']['output'];
  timezone: Scalars['String']['output'];
};

export type DateWithTimezone = {
  dateTime: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DefaultLocationAndLevel = {
  __typename?: 'DefaultLocationAndLevel';
  levelId?: Maybe<Scalars['ID']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  suggestedLevelId?: Maybe<Scalars['ID']['output']>;
  suggestedLocationId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteExternalRipplingGroupInput = {
  slug: Scalars['String']['input'];
};

export type DeleteExternalRipplingGroupResponse = {
  __typename?: 'DeleteExternalRipplingGroupResponse';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type DeliveriesOrderBy = {
  field?: InputMaybe<DeliveriesOrderByField>;
  ordinality?: InputMaybe<Ordinality>;
};

export enum DeliveriesOrderByField {
  CreatedAt = 'createdAt',
  DeliveryZoneName = 'deliveryZoneName',
  LastNotifiedAt = 'lastNotifiedAt',
  RecipientName = 'recipientName',
  Status = 'status'
}

export type DeliveriesPermittedLocations = {
  __typename?: 'DeliveriesPermittedLocations';
  canManage: Array<DeliveryLocation>;
  canView: Array<DeliveryLocation>;
};

export type Delivery = {
  __typename?: 'Delivery';
  additionalNotes?: Maybe<Scalars['String']['output']>;
  carrier?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  deliveryZone: DeliveryZone;
  id: Scalars['ID']['output'];
  labelImageURL?: Maybe<Scalars['String']['output']>;
  lastNotifiedAt?: Maybe<Scalars['Date']['output']>;
  magicLink?: Maybe<Scalars['String']['output']>;
  pickedUpAt?: Maybe<Scalars['Date']['output']>;
  recipient?: Maybe<Recipient>;
  status: DeliveryStatus;
};

export type DeliveryFilterOptions = {
  deliveryZoneIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate?: InputMaybe<Scalars['ISODateTime']['input']>;
  idContains?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  recipientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['ISODateTime']['input']>;
  statuses?: InputMaybe<Array<DeliveryStatus>>;
};

export type DeliveryLevel = {
  __typename?: 'DeliveryLevel';
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  spaces: Array<DeliverySpace>;
};

export type DeliveryLocation = {
  __typename?: 'DeliveryLocation';
  id: Scalars['ID']['output'];
  levels: Array<DeliveryLevel>;
  name?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type DeliverySpace = {
  __typename?: 'DeliverySpace';
  id: Scalars['ID']['output'];
  levelId?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum DeliveryStatus {
  Pending = 'PENDING',
  PickedUp = 'PICKED_UP',
  UnknownRecipient = 'UNKNOWN_RECIPIENT',
  WrongRecipient = 'WRONG_RECIPIENT'
}

export enum DeliveryTextType {
  PhotoRecognizer = 'PhotoRecognizer'
}

export type DeliveryZone = {
  __typename?: 'DeliveryZone';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  level?: Maybe<DeliveryLevel>;
  levelId?: Maybe<Scalars['ID']['output']>;
  location?: Maybe<DeliveryLocation>;
  locationId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  space?: Maybe<DeliverySpace>;
  spaceId?: Maybe<Scalars['ID']['output']>;
  tenantId: Scalars['ID']['output'];
};

export type DeliveryZoneFilterOptions = {
  levelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  spaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Desk = {
  __typename?: 'Desk';
  amenities: Array<DeskAmenity>;
  assignees: Array<AccountUser>;
  availabilityFor?: Maybe<Availability>;
  /** @deprecated Deprecated in favor of availabilityFor */
  availableAt?: Maybe<Availability>;
  canDelegate: Scalars['Boolean']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  instantAvailabilityAt: InstantAvailability;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicate whether the desk has been explicitly marked as not available. */
  isReservable: Scalars['Boolean']['output'];
  level?: Maybe<Level>;
  location: Location;
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  rawType: Array<DeskType>;
  reservationPolicies?: Maybe<DeskReservationPolicies>;
  settings?: Maybe<Array<Setting>>;
  space: Space;
  state: DeskState;
  stickers: Array<Sticker>;
  /** Specify the type of booking supported for this desk (Assigned, Hot, Hoteled). */
  type: BookingPolicyType;
  updatedAt: Scalars['Date']['output'];
  windowedAvailability: Array<WindowAvailability>;
  zone?: Maybe<Zone>;
};


export type DeskAvailabilityForArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['Date']['input'];
  userId: Scalars['ID']['input'];
};


export type DeskAvailableAtArgs = {
  time?: InputMaybe<Scalars['Date']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type DeskCanDelegateArgs = {
  endTime: Scalars['Date']['input'];
  startTime: Scalars['Date']['input'];
};


export type DeskInstantAvailabilityAtArgs = {
  time?: InputMaybe<Scalars['Date']['input']>;
};


export type DeskStateArgs = {
  endTime: Scalars['Date']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type DeskWindowedAvailabilityArgs = {
  intervalInMinutes: Scalars['Int']['input'];
  numberOfWindows: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type DeskAmenity = {
  __typename?: 'DeskAmenity';
  amenityId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type DeskAssignee = {
  __typename?: 'DeskAssignee';
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeskAssignmentSchedule = {
  __typename?: 'DeskAssignmentSchedule';
  floor: Floor;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  recurrence: Scalars['String']['output'];
};

export type DeskAvailability = {
  __typename?: 'DeskAvailability';
  id: Scalars['ID']['output'];
  nextAvailableMeta: NextAvailableMeta;
  status: Availability;
  unbookableReasons: Array<DeskunbookableReasons>;
};

export type DeskAvailabilityByLevelId = {
  __typename?: 'DeskAvailabilityByLevelId';
  availableDeskIds: Array<Scalars['Int']['output']>;
  busyDeskIds: Array<Scalars['Int']['output']>;
  forbiddenDeskIds: Array<Scalars['Int']['output']>;
};

export type DeskAvailabilityLocalizedDate = {
  __typename?: 'DeskAvailabilityLocalizedDate';
  availability: Availability;
  dates: Array<Scalars['LocalDate']['output']>;
  deskId: Scalars['ID']['output'];
  timezone: Scalars['IANATimezone']['output'];
  /** Specify the type of booking supported for this desk (Assigned, Hot, Hoteled). */
  type: BookingPolicyType;
  unbookableReasons: Array<Maybe<DeskunbookableReasons>>;
};

export type DeskChangeInput = {
  id: Scalars['ID']['input'];
  state?: InputMaybe<DeskEntityStateInput>;
  type: EntityType;
};

export type DeskConfirmation = {
  __typename?: 'DeskConfirmation';
  confirmationWindowEnd?: Maybe<Scalars['Date']['output']>;
  confirmationWindowStart?: Maybe<Scalars['Date']['output']>;
  confirmedAt?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['ID']['output']>;
  method?: Maybe<ConfirmationMethod>;
  seatReservationId: Scalars['ID']['output'];
  status: DeskConfirmationStatus;
  unconfirmableReasons: Array<UnconfirmableReason>;
  user?: Maybe<User>;
};

export enum DeskConfirmationStatus {
  /** Desk reservation cannot be confirmed */
  CannotConfirm = 'CANNOT_CONFIRM',
  /** Desk reservation has been confirmed */
  Confirmed = 'CONFIRMED',
  /** Desk reservation has been declined */
  Declined = 'DECLINED',
  /** Desk reservation has not been confirmed */
  Unconfirmed = 'UNCONFIRMED'
}

export enum DeskConfirmationType {
  Compass = 'Compass',
  Dashboard = 'Dashboard',
  DoorBadgeSwipe = 'DoorBadgeSwipe',
  Email = 'Email',
  Ip = 'IP',
  Nfc = 'NFC',
  Qr = 'QR',
  Slack = 'Slack',
  Teams = 'Teams'
}

export type DeskEntityData = {
  __typename?: 'DeskEntityData';
  amenities?: Maybe<Array<Maybe<DeskAmenity>>>;
  floor?: Maybe<Level>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  zone: Zone;
};

export type DeskEntityState = {
  __typename?: 'DeskEntityState';
  assignments: Array<Assignment>;
  id: Scalars['ID']['output'];
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  isReservable: Scalars['Boolean']['output'];
  reservationTypes: Array<Scalars['String']['output']>;
};

export type DeskEntityStateInput = {
  assignments: Array<AssignmentInput>;
  isReservable: Scalars['Boolean']['input'];
  reservationTypes: Array<DeskType>;
};

export enum DeskFilters {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type DeskReservationCheckinPolicies = {
  __typename?: 'DeskReservationCheckinPolicies';
  assignedCheckInEnabled?: Maybe<Scalars['Boolean']['output']>;
  checkInWindow?: Maybe<Scalars['String']['output']>;
  /** there are other checkin policies available, but we only expose this one */
  enforceLocalCheckinOnly: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type DeskReservationPolicies = {
  __typename?: 'DeskReservationPolicies';
  abandonedDeskProtectionEnabled?: Maybe<Scalars['Boolean']['output']>;
  abandonedDeskProtectionThreshold?: Maybe<Scalars['String']['output']>;
  allowExclusions?: Maybe<Scalars['Boolean']['output']>;
  allowOverlappingReservations?: Maybe<Scalars['Boolean']['output']>;
  bookingWindowPolicyDuration?: Maybe<Scalars['String']['output']>;
  bookingWindowPolicyInfo?: Maybe<BookingWindowPolicyInfo>;
  /** If this is null, checkin policies are not enabled for the desk */
  checkinPolicies?: Maybe<DeskReservationCheckinPolicies>;
  id: Scalars['ID']['output'];
  seatReservationAdvancedBookingThreshold?: Maybe<Scalars['String']['output']>;
  seatReservationMaxLength?: Maybe<Scalars['String']['output']>;
  seatReservationTypesAllowed?: Maybe<Scalars['String']['output']>;
  seatReservationVisibilityEnabled?: Maybe<Scalars['Boolean']['output']>;
};


export type DeskReservationPoliciesBookingWindowPolicyDurationArgs = {
  startTime: Scalars['Date']['input'];
};

export enum DeskReservationVisibility {
  Everyone = 'EVERYONE',
  JustMe = 'JUST_ME'
}

export type DeskSchedule = {
  __typename?: 'DeskSchedule';
  avatar?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  recurrence: Scalars['String']['output'];
  uniqueId: Scalars['ID']['output'];
};

export type DeskState = {
  __typename?: 'DeskState';
  availability: DeskAvailability;
  exclusions: Array<Exclusion>;
  id: Scalars['ID']['output'];
  primaryReservation?: Maybe<Reservation>;
  reservations: Array<Reservation>;
};

export type DeskStatistics = {
  __typename?: 'DeskStatistics';
  new: Statistics;
  previous: Statistics;
};

export enum DeskType {
  Assigned = 'assigned',
  Hot = 'hot',
  Hoteled = 'hoteled',
  Shared = 'shared'
}

export enum DeskUnbookableReasonType {
  BookingWindowPolicy = 'BOOKING_WINDOW_POLICY',
  LackingPermissions = 'LACKING_PERMISSIONS',
  MaxCapacityReached = 'MAX_CAPACITY_REACHED',
  NoAccess = 'NO_ACCESS',
  ScheduleViolated = 'SCHEDULE_VIOLATED',
  SeatReservationAdvancedBookingThreshold = 'SEAT_RESERVATION_ADVANCED_BOOKING_THRESHOLD',
  SeatReservationMaxLength = 'SEAT_RESERVATION_MAX_LENGTH',
  SeatReservationRecurrenceAllowed = 'SEAT_RESERVATION_RECURRENCE_ALLOWED',
  SeatReservationWorkingHoursRestricted = 'SEAT_RESERVATION_WORKING_HOURS_RESTRICTED',
  Unknown = 'UNKNOWN',
  ViolateHotDeskPolicy = 'VIOLATE_HOT_DESK_POLICY'
}

export type DeskWithAssignees = {
  __typename?: 'DeskWithAssignees';
  assignees: Array<DeskAssignee>;
  isDisabled: Scalars['Boolean']['output'];
  isReservable: Scalars['Boolean']['output'];
  permissions: Array<Permission>;
  seatId: Scalars['ID']['output'];
  seatType: Array<BookingPolicyType>;
};

export type DeskunbookableReasons = {
  __typename?: 'DeskunbookableReasons';
  reason: DeskUnbookableReasonType;
  value?: Maybe<Scalars['String']['output']>;
};

export type DeviceAuthorizationResponse = {
  __typename?: 'DeviceAuthorizationResponse';
  bearerToken: Scalars['String']['output'];
  deviceName: Scalars['String']['output'];
  deviceType: Device_Type;
  /** device ID */
  id: Scalars['ID']['output'];
  location: Location;
  organizationId: Scalars['ID']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum DeviceFeatureFlagType {
  /** Feature flag for enabling badge printing from an arrival display */
  ArrivalDisplayBadgePrinting = 'ARRIVAL_DISPLAY_BADGE_PRINTING',
  /** Enables using custom visit types on devices like the Arrival Display or GuestUI */
  CustomVisitTypes = 'CUSTOM_VISIT_TYPES',
  /** Language selection feature flag for project global */
  LanguageSelectionEnabled = 'LANGUAGE_SELECTION_ENABLED',
  /** Language selection feature flag for a location global */
  LocationLanguageSelectionEnabled = 'LOCATION_LANGUAGE_SELECTION_ENABLED',
  /** Enables the ability to capture images to be used on visitor badges */
  VisitorBadgeImages = 'VISITOR_BADGE_IMAGES'
}

export enum DeviceStatus {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Unknown = 'UNKNOWN'
}

export enum DeviceStatusInput {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type DeviceStatusReport = {
  __typename?: 'DeviceStatusReport';
  lastCheckinMessage?: Maybe<Scalars['String']['output']>;
  lastCheckinResult?: Maybe<DeviceStatusInput>;
  lastCheckinTime?: Maybe<Scalars['String']['output']>;
  status: DeviceStatus;
};

export type DisableSpacesResults = {
  __typename?: 'DisableSpacesResults';
  failedIds: Array<Scalars['Int']['output']>;
  successfulIds: Array<Scalars['Int']['output']>;
};

export type DisconnectBrivoAccountResponse = {
  __typename?: 'DisconnectBrivoAccountResponse';
  uuid: Scalars['ID']['output'];
};

export type DisconnectGeneaAccountByIntegrationUuidInput = {
  integrationUuid: Scalars['ID']['input'];
};

export type DisconnectGeneaAccountByIntegrationUuidResponse = {
  __typename?: 'DisconnectGeneaAccountByIntegrationUuidResponse';
  uuid: Scalars['ID']['output'];
};

export type DisconnectOpenpathAccountResponse = {
  __typename?: 'DisconnectOpenpathAccountResponse';
  uuid: Scalars['ID']['output'];
};

export type DisconnectVerkadaAccountByIntegrationUuidInput = {
  integrationUuid: Scalars['ID']['input'];
};

export type DisconnectVerkadaAccountByIntegrationUuidResponse = {
  __typename?: 'DisconnectVerkadaAccountByIntegrationUuidResponse';
  uuid: Scalars['ID']['output'];
};

export type DisconnnectKisiAccountResponse = {
  __typename?: 'DisconnnectKisiAccountResponse';
  uuid: Scalars['ID']['output'];
};

export type DistributionInput = {
  distributionService: DistributionService;
  id?: InputMaybe<Scalars['ID']['input']>;
  recipients: Array<DistributionRecipientInput>;
};

/** display -> what is shown to user (ie slack channel name) value -> identifier data used to cross post (ie slack channel conversationId) */
export type DistributionRecipient = {
  __typename?: 'DistributionRecipient';
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type DistributionRecipientInput = {
  display?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  value: Scalars['String']['input'];
};

export enum DistributionService {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Teams = 'TEAMS'
}

export type Draft = {
  __typename?: 'Draft';
  floorName?: Maybe<Scalars['String']['output']>;
  hasConflicts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastUpdated?: Maybe<DraftAuditInfo>;
  levelId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  owner?: Maybe<DraftAuditInfo>;
  settings: Array<DraftSettings>;
  status: DraftStatus;
  version: Scalars['Int']['output'];
};

export enum DraftAccessLevel {
  Editor = 'editor',
  Viewer = 'viewer'
}

export type DraftAccountUser = {
  __typename?: 'DraftAccountUser';
  assignments?: Maybe<Array<Maybe<DraftDeskAssignmentSchedule>>>;
  avatar?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<PrimaryEmail>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type DraftAuditInfo = {
  __typename?: 'DraftAuditInfo';
  avatar?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<PrimaryEmail>;
  userId: Scalars['ID']['output'];
};

export type DraftConflict = {
  __typename?: 'DraftConflict';
  draftChange?: Maybe<EntityState>;
  entityData?: Maybe<EntityData>;
  entityId: Scalars['ID']['output'];
  entityType: DraftConflictEntityType;
  id: Scalars['ID']['output'];
  liveState?: Maybe<EntityState>;
  reason: DraftConflictReason;
  type: DraftConflictType;
};

export enum DraftConflictEntityType {
  Desk = 'desk'
}

export enum DraftConflictReason {
  DeskDeleted = 'desk_deleted',
  SnapshotLiveMismatch = 'snapshot_live_mismatch',
  UserDeleted = 'user_deleted'
}

export enum DraftConflictType {
  Actionable = 'actionable',
  Informative = 'informative'
}

export type DraftDeskAssignmentSchedule = {
  __typename?: 'DraftDeskAssignmentSchedule';
  deskId: Scalars['ID']['output'];
  floor?: Maybe<Level>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  recurrence: Scalars['String']['output'];
  zone?: Maybe<Zone>;
};

export type DraftFiltersInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  floorId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<InputMaybe<DraftStatus>>>;
};

export type DraftInput = {
  levelId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  settings: Array<DraftSettingsInput>;
};

export type DraftLayoutInputEntity = {
  deletedResources?: InputMaybe<Array<Scalars['ID']['input']>>;
  resourceGroups?: InputMaybe<Array<ResourceGroupInput>>;
  updatedResources?: InputMaybe<Array<DraftLayoutUpdateResourceInputEntity>>;
};

export type DraftLayoutUpdateResourceInputEntity = {
  id: Scalars['ID']['input'];
  parcel?: InputMaybe<LayoutParcelInput>;
};

export type DraftMetadata = {
  __typename?: 'DraftMetadata';
  hasChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastUpdated?: Maybe<DraftAuditInfo>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<DraftAuditInfo>;
  version: Scalars['Int']['output'];
};

export enum DraftOrderByValues {
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  FloorName = 'floorName',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy'
}

export type DraftPagingResult = {
  __typename?: 'DraftPagingResult';
  drafts: Array<Draft>;
  draftsTotal: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  nextPageOffset: Scalars['Int']['output'];
};

export type DraftSettings = {
  __typename?: 'DraftSettings';
  type: DraftSettingsType;
  value?: Maybe<Scalars['String']['output']>;
};

export type DraftSettingsInput = {
  booleanValue?: InputMaybe<Scalars['Boolean']['input']>;
  name: DraftSettingsName;
  numberValue?: InputMaybe<Scalars['Float']['input']>;
  stringValue?: InputMaybe<Scalars['String']['input']>;
};

export enum DraftSettingsName {
  Assignments = 'ASSIGNMENTS',
  Conflicts = 'CONFLICTS',
  Default = 'DEFAULT',
  Layout = 'LAYOUT'
}

export type DraftSettingsType = {
  __typename?: 'DraftSettingsType';
  name: Scalars['String']['output'];
  valueType: SettingValueType;
};

export enum DraftStatus {
  Discarded = 'discarded',
  Open = 'open',
  Published = 'published',
  Publishing = 'publishing',
  PublishingFailed = 'publishing_failed'
}

export type DraftStatusResponse = {
  __typename?: 'DraftStatusResponse';
  id: Scalars['ID']['output'];
  status: DraftStatus;
};

export type DraftsSort = {
  field: DraftOrderByValues;
  order: SortDirection;
};

export type Email = {
  __typename?: 'Email';
  email: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
};

/** Emails of users who are deactivated or not existing in the tenant will be grouped in `invalid` */
export type EmailCheckResponse = {
  __typename?: 'EmailCheckResponse';
  invalid: Array<Scalars['String']['output']>;
  unverified: Array<Scalars['String']['output']>;
  verified: Array<Scalars['String']['output']>;
};

export type EmailConfiguration = {
  __typename?: 'EmailConfiguration';
  headerBackgroundColorHexCode?: Maybe<Scalars['String']['output']>;
  headerFontColorHexCode?: Maybe<Scalars['String']['output']>;
  headerImageURL?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['ID']['output'];
};

export type EmailConfigurationInput = {
  base64EncodedHeaderImage?: InputMaybe<Scalars['String']['input']>;
  headerBackgroundColorHexCode?: InputMaybe<Scalars['String']['input']>;
  headerFontColorHexCode?: InputMaybe<Scalars['String']['input']>;
  headerImageURL?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeCredentialProvisioningProvider = {
  __typename?: 'EmployeeCredentialProvisioningProvider';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type EmployeeRegisteredForWaitlistVisitInput = {
  /** The employee ID. */
  employeeUserId: Scalars['ID']['input'];
  /** The waitlist location ID. */
  waitlistLocationId: Scalars['ID']['input'];
  /** The waitlist visit ID. */
  waitlistVisitId: Scalars['ID']['input'];
};

export type EmployeeVisit = {
  __typename?: 'EmployeeVisit';
  confirmation: EmployeeVisitConfirmation;
  endTime: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  scheduleId?: Maybe<Scalars['ID']['output']>;
  startTime: Scalars['ISODateTime']['output'];
  status: EmployeeVisitStatus;
  userId: Scalars['ID']['output'];
};

export type EmployeeVisitConfirmation = {
  __typename?: 'EmployeeVisitConfirmation';
  confirmed_at?: Maybe<Scalars['ISODateTime']['output']>;
  employeeVisitId: Scalars['ID']['output'];
  status: EmployeeVisitConfirmationStatus;
};

export enum EmployeeVisitConfirmationStatus {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Unknown = 'UNKNOWN'
}

export enum EmployeeVisitFilterSetting {
  ActiveOnly = 'ACTIVE_ONLY',
  All = 'ALL',
  CancelledOnly = 'CANCELLED_ONLY'
}

export type EmployeeVisitMutations = {
  __typename?: 'EmployeeVisitMutations';
  excludeDaysFromAllSchedules?: Maybe<Scalars['Void']['output']>;
  setWeeklySchedule?: Maybe<Scalars['ID']['output']>;
};


export type EmployeeVisitMutationsExcludeDaysFromAllSchedulesArgs = {
  input?: InputMaybe<ExcludeAllDaysFromScheduleInput>;
};


export type EmployeeVisitMutationsSetWeeklyScheduleArgs = {
  input: SetWeeklyScheduleInput;
};

export type EmployeeVisitQueries = {
  __typename?: 'EmployeeVisitQueries';
  /** Get all of an employee's visits during a specific time range within a specific org */
  getAllEmployeeVisitsForEmployee: Array<EmployeeVisit>;
  getAllWeeklySchedulesForUser?: Maybe<AllSchedules>;
  getWeeklyScheduleForUser?: Maybe<WeeklyScheduleResult>;
};


export type EmployeeVisitQueriesGetAllEmployeeVisitsForEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
  endTime: Scalars['ISODateTime']['input'];
  filterSetting?: InputMaybe<EmployeeVisitFilterSetting>;
  startTime: Scalars['ISODateTime']['input'];
};


export type EmployeeVisitQueriesGetAllWeeklySchedulesForUserArgs = {
  userId: Scalars['String']['input'];
};


export type EmployeeVisitQueriesGetWeeklyScheduleForUserArgs = {
  locationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type EmployeeVisitSchedule = {
  __typename?: 'EmployeeVisitSchedule';
  id?: Maybe<Scalars['String']['output']>;
};

export enum EmployeeVisitStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Unconfirmed = 'UNCONFIRMED'
}

export type EmployeeVisitsByDate = {
  __typename?: 'EmployeeVisitsByDate';
  date: Scalars['Date']['output'];
  employeeVisits: Array<EmployeeVisit>;
};

export type EndOrCancelDelegatedReservationResponse = {
  __typename?: 'EndOrCancelDelegatedReservationResponse';
  reservationId: Scalars['ID']['output'];
};

export type EndOrCancelReservationResponse = {
  __typename?: 'EndOrCancelReservationResponse';
  reservationId: Scalars['ID']['output'];
};

export type EndReservationOrVisitInRangeResponse = {
  __typename?: 'EndReservationOrVisitInRangeResponse';
  reservationsCancelledAttempts?: Maybe<Array<Maybe<CancelledVisitOrReservationResult>>>;
  /** If true, all visits and reservations in range were successfully cancelled. If false there are one or more failures */
  success: Scalars['Boolean']['output'];
  visitsCancelledAttempts?: Maybe<Array<Maybe<CancelledVisitOrReservationResult>>>;
};

export type Entity = {
  __typename?: 'Entity';
  data: EntityData;
  id: Scalars['ID']['output'];
  previousState?: Maybe<EntityState>;
  state: EntityState;
  type: EntityType;
};

export type EntityData = DeskEntityData;

export type EntityState = DeskEntityState;

export enum EntityType {
  Desk = 'DESK'
}

export type Evaluations = {
  workingHours?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Event = {
  __typename?: 'Event';
  creationType: EventCreationType;
  creatorEmail?: Maybe<Scalars['String']['output']>;
  creatorId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  eventWithCheckInStatus?: Maybe<EventWithCheckInStatus>;
  hasMeetingServiceRequests?: Maybe<Scalars['Boolean']['output']>;
  /** Event Id */
  id: Scalars['ID']['output'];
  invitees: Array<EventInvitee>;
  isAllDay: Scalars['Boolean']['output'];
  isCandidateForSpaceSuggestions: Scalars['Boolean']['output'];
  organizerEmail?: Maybe<Scalars['String']['output']>;
  organizerId?: Maybe<Scalars['ID']['output']>;
  recurrence?: Maybe<Scalars['String']['output']>;
  seriesId?: Maybe<Scalars['String']['output']>;
  spaces: Array<Space>;
  startTime?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
  whosInData: WhosInData;
};


export type EventWhosInDataArgs = {
  locationIds: Array<Scalars['ID']['input']>;
};

export type EventConfirmation = {
  __typename?: 'EventConfirmation';
  confirmedAt?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['Int']['output']>;
  eventId: Scalars['ID']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export enum EventCreationType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Proxied = 'PROXIED',
  Synced = 'SYNCED',
  Unknown = 'UNKNOWN'
}

/** When dealing with events that may not exist yet, the robinEventId is unknown. In this case, we only know the ical UID that we will set on the event when we create it. Note that referencing recurring events is not allowed, must reference a specific instance. */
export type EventInstanceReference = {
  ical?: InputMaybe<IcalInstanceReference>;
  robinEventId?: InputMaybe<Scalars['ID']['input']>;
};

export type EventInvitee = {
  __typename?: 'EventInvitee';
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  eventId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isOrganizer: Scalars['Boolean']['output'];
  isResource: Scalars['Boolean']['output'];
  participationRole: EventParticipationRole;
  responseStatus: EventResponseStatus;
  spaceId?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<User>;
};

export enum EventParticipationRole {
  Chair = 'CHAIR',
  NonParticipant = 'NON_PARTICIPANT',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
  Unknown = 'UNKNOWN'
}

export enum EventResponseStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Delegated = 'DELEGATED',
  NotResponded = 'NOT_RESPONDED',
  Tentative = 'TENTATIVE',
  Unknown = 'UNKNOWN'
}

export enum EventUpdateFailReasons {
  LackingEventUpdatePermissions = 'LACKING_EVENT_UPDATE_PERMISSIONS',
  SpaceDeclined = 'SPACE_DECLINED',
  SpaceUnavailable = 'SPACE_UNAVAILABLE',
  Unknown = 'UNKNOWN'
}

export type EventUpdatePermission = {
  __typename?: 'EventUpdatePermission';
  eventId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPermitted: Scalars['Boolean']['output'];
};

export type EventWithCheckInStatus = {
  __typename?: 'EventWithCheckInStatus';
  checkInEndTime: Scalars['String']['output'];
  checkInStartTime: Scalars['String']['output'];
  checkInStatus: CheckInStatus;
  inviteeEvent?: Maybe<InviteeEvent>;
  spaceId: Scalars['String']['output'];
};

export type ExcludeAllDaysFromScheduleInput = {
  fri?: InputMaybe<Scalars['Boolean']['input']>;
  mon?: InputMaybe<Scalars['Boolean']['input']>;
  sat?: InputMaybe<Scalars['Boolean']['input']>;
  sun?: InputMaybe<Scalars['Boolean']['input']>;
  thu?: InputMaybe<Scalars['Boolean']['input']>;
  tue?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
  wed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Exclusion = {
  __typename?: 'Exclusion';
  endTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated temporally broken because API currently provides series master id instead of the instance id */
  reservation?: Maybe<Reservation>;
  startTime: Scalars['String']['output'];
};

export type ExternalEvent = {
  __typename?: 'ExternalEvent';
  description: Scalars['String']['output'];
  endDate: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sourceUrl: Scalars['String']['output'];
  startDate: Scalars['ISODateTime']['output'];
  ticketUrl?: Maybe<Scalars['String']['output']>;
  venue: ExternalEventVenue;
};

export type ExternalEventVenue = {
  __typename?: 'ExternalEventVenue';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  region: Scalars['String']['output'];
};

export type ExternalIntegration = {
  __typename?: 'ExternalIntegration';
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integrationJson?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum FeatureFlagType {
  /** Enables category images for activities */
  ActivitiesCategoryImagesSupport = 'ACTIVITIES_CATEGORY_IMAGES_SUPPORT',
  /** Enable Chat Functionality on Activities */
  ActivityChat = 'ACTIVITY_CHAT',
  /** Enables add a space modal on web */
  AddSpaceModalWeb = 'ADD_SPACE_MODAL_WEB',
  /** Enables the ui for the auto desk booking for employee and admin */
  AutoDeskBooking = 'AUTO_DESK_BOOKING',
  /** Enables the ui for booking multiples days in a week */
  BookDeskAhead = 'BOOK_DESK_AHEAD',
  /** Enables the bulk map uploads */
  BulkMapUploads = 'BULK_MAP_UPLOADS',
  /** Feature sends out notifications out when a calendar integration or space calendars are detected to be in a bad state */
  CalendarHealthNotifications = 'CALENDAR_HEALTH_NOTIFICATIONS',
  /** Enables the schedule Information tab for campus app */
  CampusApp = 'CAMPUS_APP',
  /** New booking flow */
  ConsolidatedBookingFlow = 'CONSOLIDATED_BOOKING_FLOW',
  /** Enables creating, editing, and using custom fields for guest visits */
  CustomVisitFields = 'CUSTOM_VISIT_FIELDS',
  /** Enables adding/removing custom visit types */
  CustomVisitTypes = 'CUSTOM_VISIT_TYPES',
  /** Enables the create org functionality in the dashboard apps project */
  DashboardAppsCreateOrganization = 'DASHBOARD_APPS_CREATE_ORGANIZATION',
  /** Allow user to view the new onboarding flow */
  DashboardAppsOnboarding = 'DASHBOARD_APPS_ONBOARDING',
  /** Enables the new schedule tab in the dashboard apps project */
  DashboardAppsScheduleTab = 'DASHBOARD_APPS_SCHEDULE_TAB',
  /** Enables the dashboard deliveries tab */
  DeliveriesTab = 'DELIVERIES_TAB',
  /** Enables desk issue reporting */
  DeskIssueReporting = 'DESK_ISSUE_REPORTING',
  /** Document agreements (NDAs, liability waivers, etc.) */
  DocumentAgreements = 'DOCUMENT_AGREEMENTS',
  /** Enables desk reservation edits */
  EditDeskReservations = 'EDIT_DESK_RESERVATIONS',
  /** Enables the employee visits feature set */
  EmployeeVisits = 'EMPLOYEE_VISITS',
  /** Enables the enforcement of workplace service licensing */
  EnforceWorkplaceServiceLicensing = 'ENFORCE_WORKPLACE_SERVICE_LICENSING',
  /** Enables external event sync from OccasionGenius */
  ExternalOccasionGeniusEventSync = 'EXTERNAL_OCCASION_GENIUS_EVENT_SYNC',
  /** Enables Global Hybrid Index page */
  HybridIndex = 'HYBRID_INDEX',
  /** Enables new analytics visualizations */
  InsightsUiRedesign = 'INSIGHTS_UI_REDESIGN',
  /** Enables issue reporting V2 - report issues with spaces */
  IssueReportingV2 = 'ISSUE_REPORTING_V2',
  /** Language selection feature flag for project Global */
  LanguageSelectionEnabled = 'LANGUAGE_SELECTION_ENABLED',
  /** Enables the mobile week view home tab */
  MobileHomeTabWeekView = 'MOBILE_HOME_TAB_WEEK_VIEW',
  /** Enables the meetings/space functionality on the Schedule tab in MS Teams */
  MsTeamsSpaceBooking = 'MS_TEAMS_SPACE_BOOKING',
  /** Enables the use of office activities */
  OfficeActivities = 'OFFICE_ACTIVITIES',
  /** Enables the use of office activities for mobile */
  OfficeActivitiesMobile = 'OFFICE_ACTIVITIES_MOBILE',
  /** Enables components intented to improve the office tab performance */
  OfficeTabPerformance = 'OFFICE_TAB_PERFORMANCE',
  /** Enables the onboarding map upload flow */
  OnboardingMapUpload = 'ONBOARDING_MAP_UPLOAD',
  /** Enables passive check in */
  PassiveCheckIn = 'PASSIVE_CHECK_IN',
  /** Enables the new People tab within insights */
  PeopleInsights = 'PEOPLE_INSIGHTS',
  /** Optimizes people search */
  PeopleSearchOptimization = 'PEOPLE_SEARCH_OPTIMIZATION',
  /** Enables people tab in dashboard apps */
  PeopleTabDashboardApps = 'PEOPLE_TAB_DASHBOARD_APPS',
  /** Enables team phoenix to toggle features that are in mid development and still release as needed */
  PhoenixDev = 'PHOENIX_DEV',
  /** Enables Office Utilization insights page */
  RealEstateOptimizationInsights = 'REAL_ESTATE_OPTIMIZATION_INSIGHTS',
  /** Allow user to view the new schedule desk booking flow */
  ScheduleDeskBookingEnabled = 'SCHEDULE_DESK_BOOKING_ENABLED',
  /** Sharptooth team feature flag for development */
  SharptoothDevelopment = 'SHARPTOOTH_DEVELOPMENT',
  /** Deprecate old work place service features across the platform */
  ShowLegacyWorkplaceService = 'SHOW_LEGACY_WORKPLACE_SERVICE',
  /** Enables suggested spaces on mobile */
  SpaceSuggestionsMobile = 'SPACE_SUGGESTIONS_MOBILE',
  /** Enables space recommendations */
  SuggestedSpaces = 'SUGGESTED_SPACES',
  /** Enables the ability to capture images to be used on visitor badges */
  VisitorBadgeImages = 'VISITOR_BADGE_IMAGES',
  /** Enables the ability to add badge printing functionality to arrival displays */
  VisitorBadgePrinting = 'VISITOR_BADGE_PRINTING',
  /** Enables visit list table csv export */
  VisitorCsvExport = 'VISITOR_CSV_EXPORT',
  /** Enables the ability to select a range of dates for a visitor to export */
  VisitorExportDateRange = 'VISITOR_EXPORT_DATE_RANGE',
  /** Visitor managment location enablement */
  VisitorLocationEnablement = 'VISITOR_LOCATION_ENABLEMENT',
  /** Enables permissions checks to gate certain visitor management features */
  VisitorManagementPermissions = 'VISITOR_MANAGEMENT_PERMISSIONS',
  /** Enables who's in sidebar on week view page */
  WeekviewWhosinSidebarEnabled = 'WEEKVIEW_WHOSIN_SIDEBAR_ENABLED',
  /** Enables the ui for workplace services page and request forms to admins and users */
  WorkplaceServices = 'WORKPLACE_SERVICES',
  /** Reveals features for managing work areas */
  WorkAreas = 'WORK_AREAS',
  /** Reveals feature for work areas on mobile */
  WorkAreasMobile = 'WORK_AREAS_MOBILE',
  /** Reveals features for work area insights */
  WorkAreaInsights = 'WORK_AREA_INSIGHTS',
  /**
   * Toggles the updated work week UX/UI
   * @deprecated Feature has been released and is permanently on, flag no longer needed
   */
  WorkWeekImprovementEnabled = 'WORK_WEEK_IMPROVEMENT_ENABLED',
  /** Toggles the updated (V2) work week UX/UI */
  WorkWeekImprovementV2Enabled = 'WORK_WEEK_IMPROVEMENT_V2_ENABLED'
}

export enum FeatureType {
  ActivatedUsers = 'ACTIVATED_USERS',
  ActiveUsers = 'ACTIVE_USERS',
  BookableSeats = 'BOOKABLE_SEATS',
  BookableSpaces = 'BOOKABLE_SPACES',
  ExportableDataDays = 'EXPORTABLE_DATA_DAYS',
  Feature = 'Feature',
  Levels = 'LEVELS',
  Locations = 'LOCATIONS',
  RipplingUsers = 'RIPPLING_USERS',
  Users = 'USERS',
  ViewableDataDays = 'VIEWABLE_DATA_DAYS',
  VisitorEnabledLocations = 'VISITOR_ENABLED_LOCATIONS',
  WorkplaceServicesLocations = 'WORKPLACE_SERVICES_LOCATIONS'
}

export enum FeedbackRating {
  Negative = 'negative',
  Positive = 'positive'
}

export type Floor = {
  __typename?: 'Floor';
  building: Building;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type ForwardPageInfo = {
  __typename?: 'ForwardPageInfo';
  /** Pass this into `after` to get results after this page. Null if no results. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** True if there are more results after endCursor */
  hasNextPage: Scalars['Boolean']['output'];
};

export enum GeneaTenancy {
  Us = 'US'
}

export type GenerateStickerBatchRequest = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type GenerateStickerBatchResponse = {
  __typename?: 'GenerateStickerBatchResponse';
  batch: StickerBatch;
};

export enum GenericHealthCheckpointEntityType {
  GuestInvite = 'GUEST_INVITE'
}

export type GenericHealthCheckpointResponse = {
  __typename?: 'GenericHealthCheckpointResponse';
  id: Scalars['ID']['output'];
  status: GenericHealthCheckpointStatus;
};

export enum GenericHealthCheckpointStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  Passed = 'PASSED',
  Unknown = 'UNKNOWN'
}

export type Geometry = {
  __typename?: 'Geometry';
  coordinates: Array<Array<Array<Scalars['Float']['output']>>>;
  type: GeometryType;
};

export type GeometryInput = {
  coordinates: Array<Array<Array<Scalars['Float']['input']>>>;
  type: GeometryType;
};

export enum GeometryType {
  Polygon = 'Polygon'
}

export type GetBadgeCsvUploadStatusForCurrentTenantError = {
  __typename?: 'GetBadgeCSVUploadStatusForCurrentTenantError';
  failedRecordsCount: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
};

export type GetBadgeCsvUploadStatusInput = {
  uuid: Scalars['ID']['input'];
};

export type GetBadgeCsvUploadStatusResponse = {
  __typename?: 'GetBadgeCSVUploadStatusResponse';
  errors: Array<BadgeCsvUploadStatusError>;
  status: Scalars['String']['output'];
};

export type GetBadgeCsvUploadsForCurrentTenantPagination = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type GetBadgeCsvUploadsForCurrentTenantResponse = {
  __typename?: 'GetBadgeCSVUploadsForCurrentTenantResponse';
  pagination: PagedPaginationResponse;
  records: Array<GetBadgeCsvUploadsForCurrentTenantResponseRecords>;
};

export type GetBadgeCsvUploadsForCurrentTenantResponseRecords = {
  __typename?: 'GetBadgeCSVUploadsForCurrentTenantResponseRecords';
  canForceRetry: Scalars['Boolean']['output'];
  downloadLink?: Maybe<Scalars['String']['output']>;
  errors: Array<GetBadgeCsvUploadStatusForCurrentTenantError>;
  fileName: Scalars['String']['output'];
  location?: Maybe<Location>;
  status: Scalars['String']['output'];
  uploadedAt: Scalars['String']['output'];
  uploader?: Maybe<AccountUser>;
  userActor: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};

export type GetBrivoAccountStatusForCurrentTenant = {
  __typename?: 'GetBrivoAccountStatusForCurrentTenant';
  isHealthy: Scalars['Boolean']['output'];
  unhealthyReasons: Array<Scalars['String']['output']>;
};

export type GetBrivoLocksForCurrentTenant = {
  __typename?: 'GetBrivoLocksForCurrentTenant';
  sites: Array<GetBrivoLocksForCurrentTenantLock>;
};

export type GetBrivoLocksForCurrentTenantLock = {
  __typename?: 'GetBrivoLocksForCurrentTenantLock';
  remoteLockId: Scalars['ID']['output'];
  remoteLockName: Scalars['String']['output'];
  remotePlaceId: Scalars['String']['output'];
  remotePlaceName: Scalars['String']['output'];
  robinLevelId?: Maybe<Scalars['Int']['output']>;
  robinLocationId?: Maybe<Scalars['Int']['output']>;
};

export type GetCurrentActorBadgePermissionsForCurrentTenantReponse = {
  __typename?: 'GetCurrentActorBadgePermissionsForCurrentTenantReponse';
  canManageKisi: Scalars['Boolean']['output'];
  canUpload: Scalars['Boolean']['output'];
};

export type GetDeskAvailabilityByLevelIdFiltersInput = {
  deskAmenityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GetDeskAvailabilityByLevelIdScopeInput = {
  levelId: Scalars['ID']['input'];
};

export type GetDeskAvailabilityByLevelIdViewOptionsInput = {
  endTime: Scalars['ISODateTime']['input'];
  startTime: Scalars['ISODateTime']['input'];
};

export type GetEventsWithCheckInStatusForProxiedEventIdReponse = {
  __typename?: 'GetEventsWithCheckInStatusForProxiedEventIdReponse';
  events: Array<EventWithCheckInStatus>;
};

export type GetGeneaAccountForCurrentTenant = {
  __typename?: 'GetGeneaAccountForCurrentTenant';
  integrationUuid?: Maybe<Scalars['ID']['output']>;
  isHealthy: Scalars['Boolean']['output'];
  unhealthyReasons: Array<Scalars['String']['output']>;
};

export type GetGeneaDoorsForCurrentTenant = {
  __typename?: 'GetGeneaDoorsForCurrentTenant';
  doors: Array<GetGeneaDoorsForCurrentTenantLock>;
};

export type GetGeneaDoorsForCurrentTenantLock = {
  __typename?: 'GetGeneaDoorsForCurrentTenantLock';
  remoteDoorId: Scalars['ID']['output'];
  remoteDoorName: Scalars['String']['output'];
  remoteLocationId: Scalars['String']['output'];
  remoteLocationName: Scalars['String']['output'];
  robinLevelId?: Maybe<Scalars['Int']['output']>;
  robinLocationId?: Maybe<Scalars['Int']['output']>;
};

export type GetKisiAccountStatusForCurrentTenant = {
  __typename?: 'GetKisiAccountStatusForCurrentTenant';
  isHealthy: Scalars['Boolean']['output'];
  unhealthyReasons: Array<Scalars['String']['output']>;
};

export type GetKisiLocksForCurrentTenant = {
  __typename?: 'GetKisiLocksForCurrentTenant';
  sites: Array<GetKisiLocksForCurrentTenantLock>;
};

export type GetKisiLocksForCurrentTenantLock = {
  __typename?: 'GetKisiLocksForCurrentTenantLock';
  isConfigured: Scalars['Boolean']['output'];
  isOnline: Scalars['Boolean']['output'];
  remoteLockId: Scalars['String']['output'];
  remoteLockName: Scalars['String']['output'];
  remotePlaceId: Scalars['String']['output'];
  remotePlaceName: Scalars['String']['output'];
  robinLevelId?: Maybe<Scalars['Int']['output']>;
  robinLocationId?: Maybe<Scalars['Int']['output']>;
};

export type GetMeetingServiceByIdResponse = {
  __typename?: 'GetMeetingServiceByIdResponse';
  /** null if meeting service with ID cannot be found */
  meetingService?: Maybe<MeetingService>;
};

export type GetMeetingServiceRequestByIdResponse = {
  __typename?: 'GetMeetingServiceRequestByIdResponse';
  meetingServiceRequest?: Maybe<MeetingServiceRequest>;
};

export type GetMeetingServiceRequestByIdentifierResponse = {
  __typename?: 'GetMeetingServiceRequestByIdentifierResponse';
  meetingServiceRequest?: Maybe<MeetingServiceRequest>;
};

export type GetMyWaitlistResponse = {
  __typename?: 'GetMyWaitlistResponse';
  /** The active URL for actor to open up. It will change based on the current state of the waitlist and potentially will return different URLs based on actor's status in the queue. */
  activeUrl?: Maybe<Scalars['String']['output']>;
  /** Same as status == NOT_ENABLED, but simpler for front-ends to filter by this. */
  isEnabled: Scalars['Boolean']['output'];
  status: GetMyWaitlistStatus;
};

export enum GetMyWaitlistStatus {
  /** A waitlist is available and can be opened. */
  Available = 'AVAILABLE',
  /** Waitlist not enabled. */
  NotEnabled = 'NOT_ENABLED',
  /** Currently queued in a waitlist. */
  Scheduled = 'SCHEDULED'
}

export type GetMyWaitlistsResponse = {
  __typename?: 'GetMyWaitlistsResponse';
  /** Global flag indicating if waitlists are enabled for this context. */
  isEnabled: Scalars['Boolean']['output'];
  waitlists: Array<GetMyWaitlistsResponseWaitlists>;
};

export type GetMyWaitlistsResponseWaitlists = {
  __typename?: 'GetMyWaitlistsResponseWaitlists';
  /** The active URL for actor to open up. It will change based on the current state of the waitlist and potentially will return different URLs based on actor's status in the queue. */
  activeUrl?: Maybe<Scalars['String']['output']>;
  /** Waitlist name, usually shown in the UI. */
  name?: Maybe<Scalars['String']['output']>;
  /** Current status of the waitlist. */
  status: GetMyWaitlistsStatus;
  /** Status byline shown in the UI. Can show estimated waiting time etc. */
  statusByline?: Maybe<Scalars['String']['output']>;
};

export enum GetMyWaitlistsStatus {
  /** A waitlist is available and can be opened. */
  Available = 'AVAILABLE',
  /** Waitlist not enabled. */
  NotEnabled = 'NOT_ENABLED',
  /** Currently queued in a waitlist. */
  Scheduled = 'SCHEDULED'
}

export type GetOpenpathAccountStatusForCurrentTenant = {
  __typename?: 'GetOpenpathAccountStatusForCurrentTenant';
  credentialProvisioningEnabled: Scalars['Boolean']['output'];
  integrationUuid?: Maybe<Scalars['ID']['output']>;
  isHealthy: Scalars['Boolean']['output'];
  presenceReportingEnabled: Scalars['Boolean']['output'];
  unhealthyReasons: Array<Scalars['String']['output']>;
};

export type GetOpenpathLocksForCurrentTenant = {
  __typename?: 'GetOpenpathLocksForCurrentTenant';
  sites: Array<GetOpenpathLocksForCurrentTenantLock>;
};

export type GetOpenpathLocksForCurrentTenantLock = {
  __typename?: 'GetOpenpathLocksForCurrentTenantLock';
  remoteLockId: Scalars['String']['output'];
  remoteLockName: Scalars['String']['output'];
  remotePlaceId: Scalars['String']['output'];
  remotePlaceName: Scalars['String']['output'];
  robinLevelId?: Maybe<Scalars['Int']['output']>;
  robinLocationId?: Maybe<Scalars['Int']['output']>;
};

export type GetRipplingAccountStateResponse = {
  __typename?: 'GetRipplingAccountStateResponse';
  lastSync: Scalars['String']['output'];
  state: SyncState;
  syncedUsers: Scalars['Int']['output'];
};

export type GetSpaceSuggestableEventsForUserResponse = {
  __typename?: 'GetSpaceSuggestableEventsForUserResponse';
  events: Array<Event>;
};

export type GetSuggestedSpaceForEventsEventInput = {
  endTime: Scalars['ISODateTime']['input'];
  id: Scalars['ID']['input'];
  /** Represents the number of attendees that are in the office for the filtered location and also accepted the event */
  preferredSpaceCapacity?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['ISODateTime']['input'];
};

/** How to decide which spaces to suggest - for A/B testing */
export enum GetSuggestedSpacesForEventsAlgorithm {
  FrecencyV1 = 'FRECENCY_V1',
  FrecencyV2 = 'FRECENCY_V2'
}

export type GetSuggestedSpacesForEventsInput = {
  algorithm?: InputMaybe<GetSuggestedSpacesForEventsAlgorithm>;
  events: Array<GetSuggestedSpaceForEventsEventInput>;
  spaceFilters: GetSuggestedSpacesForEventsSpaceFiltersInput;
  /** Loose preferences for spaces - attempt to fit criteria but no guarantees */
  spacePreferences?: InputMaybe<GetSuggestedSpacesForEventsSpacePreferencesInput>;
};

export type GetSuggestedSpacesForEventsResponse = {
  __typename?: 'GetSuggestedSpacesForEventsResponse';
  /** Nullable to force resilience in the face of changing enum values */
  algorithm?: Maybe<GetSuggestedSpacesForEventsAlgorithm>;
  suggestedSpaces: Array<SuggestedSpaceForEvent>;
};

export type GetSuggestedSpacesForEventsSpaceFiltersInput = {
  locationIds: Array<Scalars['ID']['input']>;
};

export type GetSuggestedSpacesForEventsSpacePreferencesInput = {
  levelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** How to decide which spaces to suggest - for A/B testing */
export enum GetSuggestedSpacesForTimeFrameAlgorithm {
  FrecencyV1 = 'FRECENCY_V1',
  FrecencyV2 = 'FRECENCY_V2'
}

export type GetSuggestedSpacesForTimeFrameFiltersInput = {
  locationIds: Array<Scalars['ID']['input']>;
};

export type GetSuggestedSpacesForTimeFrameInput = {
  algorithm?: InputMaybe<GetSuggestedSpacesForEventsAlgorithm>;
  endTime: Scalars['ISODateTime']['input'];
  filters: GetSuggestedSpacesForTimeFrameFiltersInput;
  /** Loose preferences for spaces - attempt to fit criteria but no guarantees */
  spacePreferences?: InputMaybe<GetSuggestedSpacesForTimeFramePreferencesInput>;
  startTime: Scalars['ISODateTime']['input'];
};

export type GetSuggestedSpacesForTimeFramePreferencesInput = {
  levelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  preferredSpaceCapacity?: InputMaybe<Scalars['Int']['input']>;
};

export type GetSuggestedSpacesForTimeFrameResponse = {
  __typename?: 'GetSuggestedSpacesForTimeFrameResponse';
  /** Nullable to force resilience in the face of changing enum values */
  algorithm?: Maybe<GetSuggestedSpacesForTimeFrameAlgorithm>;
  suggestedSpaces: Array<Space>;
};

/** Filters for getPaginatedSurveyResponses */
export type GetSurveyResponsesFilters = {
  /** FIlter results by one or more building(s) */
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** End of search window */
  inOfficeAtEnd: Scalars['ISODateTime']['input'];
  /** Beginning of search window */
  inOfficeAtStart: Scalars['ISODateTime']['input'];
  /** Include pending, expired, and dismissed survey responses */
  includeIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter results by one or more rating(s) */
  ratings?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Filter resuts by a user ID */
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** Input for sorting survey responses by different keys */
export type GetSurveyResponsesSort = {
  /** The key to sort responses by */
  key: SurveyResponseSortKey;
  /** The order to sort that key */
  order: SortOrder;
};

export type GetTimeZonesForRipplingAddressesInput = {
  addresses: Array<Scalars['String']['input']>;
};

export type GetTimeZonesForRipplingAddressesResponse = {
  __typename?: 'GetTimeZonesForRipplingAddressesResponse';
  timezones: Array<GetTimeZonesForRipplingAddressesResponseTimeZone>;
};

export type GetTimeZonesForRipplingAddressesResponseTimeZone = {
  __typename?: 'GetTimeZonesForRipplingAddressesResponseTimeZone';
  address: Scalars['String']['output'];
  zone?: Maybe<Scalars['String']['output']>;
};

export type GetUpdatePermissionsForEventsResponse = {
  __typename?: 'GetUpdatePermissionsForEventsResponse';
  events: Array<Maybe<EventUpdatePermission>>;
};

export type GetVerkadaAccountForCurrentTenant = {
  __typename?: 'GetVerkadaAccountForCurrentTenant';
  integrationUuid?: Maybe<Scalars['ID']['output']>;
  isHealthy: Scalars['Boolean']['output'];
  unhealthyReasons: Array<Scalars['String']['output']>;
};

export type GetVerkadaDoorsForCurrentTenant = {
  __typename?: 'GetVerkadaDoorsForCurrentTenant';
  doors: Array<GetVerkadaDoorsForCurrentTenantLock>;
};

export type GetVerkadaDoorsForCurrentTenantLock = {
  __typename?: 'GetVerkadaDoorsForCurrentTenantLock';
  remoteDoorId: Scalars['ID']['output'];
  remoteDoorName: Scalars['String']['output'];
  remotePlaceId: Scalars['String']['output'];
  remotePlaceName: Scalars['String']['output'];
  robinLevelId?: Maybe<Scalars['Int']['output']>;
  robinLocationId?: Maybe<Scalars['Int']['output']>;
};

/** Useful context to show appropriate waitlist url for an actor. */
export type GetWaitlistForMeContext = {
  /** A flag indicating if current platform is a mobile app */
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  /** Location ID of the current context. */
  locationId?: InputMaybe<Scalars['String']['input']>;
};

/** Useful context to show appropriate waitlist url for an actor. */
export type GetWaitlistsForMeContext = {
  /** A flag indicating if current platform is a mobile app */
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  /** Location ID of the current context. */
  locationId?: InputMaybe<Scalars['String']['input']>;
};

/** Response wrapper for location-based onsite data queries. */
export type GetWhoIsOnsiteByLocationResponse = {
  __typename?: 'GetWhoIsOnsiteByLocationResponse';
  /** Retrieves data for a specific day */
  day: GetWhoIsOnsiteByLocationResponseDay;
  id: Scalars['ID']['output'];
  location: Location;
};


/** Response wrapper for location-based onsite data queries. */
export type GetWhoIsOnsiteByLocationResponseDayArgs = {
  date: Scalars['LocalDate']['input'];
};

/** Contains all onsite data for a specific day. */
export type GetWhoIsOnsiteByLocationResponseDay = {
  __typename?: 'GetWhoIsOnsiteByLocationResponseDay';
  /** The date in ISO 8601 format (YYYY-MM-DD) in location's time zone. */
  date: Scalars['String']['output'];
  /** The day of the week (e.g., "MONDAY", "TUESDAY") in location's timezone. */
  dayOfWeek?: Maybe<DayOfWeek>;
  /** The ID of the day's response. */
  id: Scalars['ID']['output'];
  /** List of onsite entries for this day. Can be ordered and limited based on input parameters. */
  onsites: Array<GetWhoIsOnsiteByLocationResponseDayOnsites>;
  /** Total count of onsite entries for this day excluding the returned onsites */
  remainingOnsitesWithoutResolvedOnsites: Scalars['Int']['output'];
  /** Total count of onsite entries for this day */
  totalOnsites: Scalars['Int']['output'];
  /** Total count of onsite entries for this day excluding the current user */
  totalOnsitesWithoutCurrentUser: Scalars['Int']['output'];
};


/** Contains all onsite data for a specific day. */
export type GetWhoIsOnsiteByLocationResponseDayOnsitesArgs = {
  limit?: InputMaybe<OnsitesLimit>;
  limitCeil?: InputMaybe<Scalars['Int']['input']>;
  limitFloor?: InputMaybe<Scalars['Int']['input']>;
  onsiteTypes?: InputMaybe<Array<OnsiteType>>;
  orderBy?: InputMaybe<Array<GetWhoIsOnsiteByLocationResponseDayOnsitesOrderBy>>;
};

/** Represents a single onsite entry containing user information. */
export type GetWhoIsOnsiteByLocationResponseDayOnsites = {
  __typename?: 'GetWhoIsOnsiteByLocationResponseDayOnsites';
  /** The ID of the day's response. */
  id: Scalars['ID']['output'];
  /** The type of onsite entry */
  onsiteType: OnsiteType;
  /** The user account information for this onsite entry */
  user: AccountUser;
};

/** Input type for specifying ordering criteria of onsite entries. */
export type GetWhoIsOnsiteByLocationResponseDayOnsitesOrderBy = {
  /** The direction of ordering */
  direction: OrderDirection;
  /** The field to order by */
  field: GetWhoIsOnsiteByLocationResponseDayOnsitesOrderField;
};

/** Available fields for ordering onsite entries. */
export enum GetWhoIsOnsiteByLocationResponseDayOnsitesOrderField {
  /** Order by current user */
  CurrentUser = 'CURRENT_USER',
  /** Order by whether the user is marked as favorite by the current user */
  IsMyFavorite = 'IS_MY_FAVORITE'
}

export type GoogleBuilding = {
  __typename?: 'GoogleBuilding';
  buildingId?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
};

export type GoogleToRobinInput = {
  locationId: Scalars['String']['input'];
  remoteGoogleId?: InputMaybe<Scalars['String']['input']>;
  remoteGoogleLabel?: InputMaybe<Scalars['String']['input']>;
};

export enum GoogleWorkingLocationSourceOfTruth {
  Google = 'GOOGLE',
  Robin = 'ROBIN'
}

export type GoogleWorkingLocationSyncSetting = {
  __typename?: 'GoogleWorkingLocationSyncSetting';
  organizationId: Scalars['ID']['output'];
  sourceOfTruth?: Maybe<Scalars['String']['output']>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID']['output'];
  members: Array<User>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};


export type GroupMembersArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupedResourceSaveUpdateInput = {
  bookingType: WeeklyBookingCommand;
  customResourceInstanceId: Scalars['ID']['input'];
  floorId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  originatingCustomResourceTemplateId: Scalars['ID']['input'];
  resourceGroupId: Scalars['ID']['input'];
};

export type GroupedUnhealthyCalendarLocation = {
  __typename?: 'GroupedUnhealthyCalendarLocation';
  hasMoreUnhealthyCalendars: Scalars['Boolean']['output'];
  locationId: Scalars['ID']['output'];
  locationName: Scalars['String']['output'];
  unhealthyCalendars: Array<UnhealthyCalendar>;
};

export type GroupedUnhealthyCalendarOrg = {
  __typename?: 'GroupedUnhealthyCalendarOrg';
  locations: Array<GroupedUnhealthyCalendarLocation>;
  orgId: Scalars['Int']['output'];
  orgName: Scalars['String']['output'];
};

/** A Guest is someone who was invited to a Visit. A single guest may be invited to multiple Visits, via a GuestInvite that's unique for each visit. */
export type Guest = {
  __typename?: 'Guest';
  /** Email address of the guest */
  guestEmail: Scalars['String']['output'];
  /** Name of the guest, ex Jeff Jefferson */
  guestName: Scalars['String']['output'];
  /** Guest unique identifier */
  id: Scalars['ID']['output'];
  /** URL of the guest's image */
  imageURL?: Maybe<Scalars['String']['output']>;
  /** Guest personal identity information has been obfuscated */
  isObfuscated: Scalars['Boolean']['output'];
  /** If this guest has been PII obfuscated, it occurred on this date and time */
  obfuscatedOn?: Maybe<Scalars['ISODateTime']['output']>;
};

export type GuestInputType = {
  /** Custom fields for the guest */
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  /** Email address of the guest */
  guestEmail: Scalars['String']['input'];
  /** An optional, client-generated ID for this guest's invite record */
  guestInviteId?: InputMaybe<Scalars['ID']['input']>;
  /** Name of the guest, ex Jeff Jefferson */
  guestName: Scalars['String']['input'];
};

/** A GuestInvite is the pairing of a Guest with a particular visit. It contains details about that guest's visit. GuestInvite is unique to the Visit, but a Guest can be associated with multiple Visits as a part of different GuestInvites. */
export type GuestInvite = {
  __typename?: 'GuestInvite';
  /** The access credentials for the guest invite */
  accessCredentials?: Maybe<GuestInviteAccessCredentials>;
  /** Visit canceled by robin user */
  canceledBy?: Maybe<UserWithDefault>;
  /** Date and time of cancelation */
  canceledOn?: Maybe<Scalars['ISODateTime']['output']>;
  /** Why the visit was canceled, if specified */
  canceledReason?: Maybe<GuestInviteCanceledReason>;
  /** Date and time of the check-in, null if never checked-in */
  checkinOn?: Maybe<Scalars['String']['output']>;
  /** Date and time of the checkout, null if never checked-out */
  checkoutOn?: Maybe<Scalars['String']['output']>;
  /** The custom fields for the guest invite */
  customFields?: Maybe<Array<CustomField>>;
  /** Invited Guest */
  guest: Guest;
  /** Indicates if the guest has access credentials */
  hasAccessCredentials?: Maybe<Scalars['Boolean']['output']>;
  /** The health checkpoint survey response associated with the visit */
  healthCheckpointSurveyResponse?: Maybe<GenericHealthCheckpointResponse>;
  /** Guest Invite unique identifier */
  id: Scalars['ID']['output'];
  /** The guest's magic link */
  magicLink: Scalars['String']['output'];
  /** Status of guest invitation */
  status: GuestInviteStatus;
  /** The visit the guest invite is for */
  visit?: Maybe<Visit>;
};

export type GuestInviteAccessCredentials = {
  __typename?: 'GuestInviteAccessCredentials';
  uuid: Scalars['ID']['output'];
  webCredentialsLink: Scalars['String']['output'];
};

export enum GuestInviteCanceledReason {
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  FailedHealthCheckpoint = 'FAILED_HEALTH_CHECKPOINT'
}

export enum GuestInviteStatus {
  Canceled = 'CANCELED',
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
  CompletedRequirements = 'COMPLETED_REQUIREMENTS',
  Expected = 'EXPECTED',
  Invited = 'INVITED',
  PendingInvite = 'PENDING_INVITE'
}

export type HealthCheckpoint = {
  __typename?: 'HealthCheckpoint';
  customizations?: Maybe<Customizations>;
  status: HealthCheckpointStatus;
  surveyId?: Maybe<Scalars['String']['output']>;
  surveyUrl?: Maybe<Scalars['String']['output']>;
};

export enum HealthCheckpointStatus {
  Complete = 'COMPLETE',
  Disabled = 'DISABLED',
  Exempt = 'EXEMPT',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  NotAvailable = 'NOT_AVAILABLE',
  Passed = 'PASSED'
}

export type HierarchyPermissionCommand = {
  /** Role IDs to which this permission applies (users) */
  applyPermissionToRoleIds: Array<Scalars['ID']['input']>;
  /** The actions permitted or denied by this permission */
  permissionActions: Array<PermissionAction>;
  /** The hierarchical grouping of resources to which this permission applies */
  permissionHierarchyTarget: PermissionHierarchyTarget;
  /** The ID of the hierarchical element that this permission applies */
  permissionHierarchyTargetId: Scalars['ID']['input'];
  /** True: permit this action on this hierarchy, False: Deny this action on this hierarchy */
  permit: Scalars['Boolean']['input'];
  /** ID of the resource category affected by this permission setting */
  resourceCategoryId: Scalars['ID']['input'];
};

export enum HomepageType {
  Office = 'OFFICE',
  Schedule = 'SCHEDULE'
}

export type IcalInstanceReference = {
  /** e.g. '20240510T014500Z' */
  recurrenceId?: InputMaybe<Scalars['String']['input']>;
  /** Likely a UUIDv4 but not necessarily */
  uid: Scalars['String']['input'];
};

export enum IconType {
  Hammer_1 = 'HAMMER_1',
  Projector_1 = 'PROJECTOR_1',
  RoundedSquare_1 = 'ROUNDED_SQUARE_1',
  Square_1 = 'SQUARE_1',
  Tablet_1 = 'TABLET_1',
  Triangle_1 = 'TRIANGLE_1'
}

export type InOfficeData = {
  __typename?: 'InOfficeData';
  peopleInTheOffice: Array<User>;
  totalNumberOfPeopleInTheOffice: Scalars['Int']['output'];
};


export type InOfficeDataPeopleInTheOfficeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type InOfficeDataByDay = {
  __typename?: 'InOfficeDataByDay';
  date: Scalars['ISODateTime']['output'];
  inOfficeData: InOfficeData;
};

export type InitiateBrivoAccountConnectionForCurrentTenantInput = {
  apiKey: Scalars['String']['input'];
  tenancy: BrivoTenancy;
};

export type InitiateBrivoAccountConnectionForCurrentTenantResponse = {
  __typename?: 'InitiateBrivoAccountConnectionForCurrentTenantResponse';
  url: Scalars['String']['output'];
};

export type InitiateFullRipplingUserSyncInput = {
  organizationId: Scalars['ID']['input'];
};

export type InitiateFullRipplingUserSyncResponse = {
  __typename?: 'InitiateFullRipplingUserSyncResponse';
  id: Scalars['ID']['output'];
};

export type InitiateRipplingBuildingSyncInput = {
  id: Scalars['ID']['input'];
};

export type InitiateRipplingBuildingSyncResponse = {
  __typename?: 'InitiateRipplingBuildingSyncResponse';
  id: Scalars['ID']['output'];
};

export type InitiateRipplingConnectionAndSyncInput = {
  agreeToTosForCurrentActor?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type InitiateRipplingConnectionAndSyncResponse = {
  __typename?: 'InitiateRipplingConnectionAndSyncResponse';
  buildingSyncId: Scalars['ID']['output'];
};

export type InitiateRipplingUserSyncInput = {
  organizationId: Scalars['ID']['input'];
};

export type InitiateRipplingUserSyncResponse = {
  __typename?: 'InitiateRipplingUserSyncResponse';
  syncId: Scalars['ID']['output'];
};

export type InsightsAssistantConversationV1 = {
  __typename?: 'InsightsAssistantConversationV1';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  messages?: Maybe<Array<InsightsAssistantMessageV1>>;
  organizationId: Scalars['ID']['output'];
  organizationSlug?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userEmail?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type InsightsAssistantFeedbackInputV1 = {
  category?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: FeedbackRating;
};

export type InsightsAssistantFeedbackV1 = {
  __typename?: 'InsightsAssistantFeedbackV1';
  category?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  conversationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  messageId: Scalars['ID']['output'];
  rating: FeedbackRating;
  updatedAt: Scalars['DateTime']['output'];
};

export type InsightsAssistantMessageV1 = {
  __typename?: 'InsightsAssistantMessageV1';
  conversationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  feedback?: Maybe<InsightsAssistantFeedbackV1>;
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InstantAvailability = {
  __typename?: 'InstantAvailability';
  availability: Availability;
  reservation?: Maybe<Reservation>;
};

export type InternalCategoryPermission = {
  __typename?: 'InternalCategoryPermission';
  action: PermissionAction;
  categoryId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  permitted: Scalars['Boolean']['output'];
};

export type InternalPermissionUserOrgActions = {
  __typename?: 'InternalPermissionUserOrgActions';
  actions: Array<PermissionAction>;
  campus: Array<InternalCategoryPermission>;
  group: Array<InternalCategoryPermission>;
  id: Scalars['ID']['output'];
  level: Array<InternalCategoryPermission>;
  location: Array<InternalCategoryPermission>;
  org: Array<InternalCategoryPermission>;
  organizationId: Scalars['ID']['output'];
  resource: Array<InternalResourcePermission>;
  userId: Scalars['ID']['output'];
};

export type InternalResource = {
  __typename?: 'InternalResource';
  campusId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  levelId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  resourceCategoryID: Scalars['ID']['output'];
  resourceGroupId?: Maybe<Scalars['ID']['output']>;
};

export type InternalResourcePermission = {
  __typename?: 'InternalResourcePermission';
  action: PermissionAction;
  id: Scalars['ID']['output'];
  permitted: Scalars['Boolean']['output'];
};

export type InvalidCidrError = {
  __typename?: 'InvalidCidrError';
  cidr: Scalars['IPAddressCIDR']['output'];
  message: Scalars['String']['output'];
};

export type InviteeEvent = {
  __typename?: 'InviteeEvent';
  confirmation?: Maybe<EventConfirmation>;
  id: Scalars['ID']['output'];
};

export type IsBrivoIntegrationEnabledForCurrentTenantReponse = {
  __typename?: 'IsBrivoIntegrationEnabledForCurrentTenantReponse';
  isEnabled: Scalars['Boolean']['output'];
};

export type IsCsvUploadEnabledForCurrentTenantReponse = {
  __typename?: 'IsCSVUploadEnabledForCurrentTenantReponse';
  isEnabled: Scalars['Boolean']['output'];
};

export type IsGeneaIntegrationEnabledForCurrentTenantReponse = {
  __typename?: 'IsGeneaIntegrationEnabledForCurrentTenantReponse';
  isEnabled: Scalars['Boolean']['output'];
};

export type IsKisiIntegrationEnabledForCurrentTenantReponse = {
  __typename?: 'IsKisiIntegrationEnabledForCurrentTenantReponse';
  isEnabled: Scalars['Boolean']['output'];
};

export type IsOpenpathIntegrationEnabledForCurrentTenantReponse = {
  __typename?: 'IsOpenpathIntegrationEnabledForCurrentTenantReponse';
  isEnabled: Scalars['Boolean']['output'];
};

export type IsOpenpathLockProvisioningEnabledForCurrentTenantResponse = {
  __typename?: 'IsOpenpathLockProvisioningEnabledForCurrentTenantResponse';
  isEnabled: Scalars['Boolean']['output'];
};

export type IsPassiveCheckInFeatureEnabledForBuildingInput = {
  buildingId: Scalars['ID']['input'];
};

export type IsVerkadaIntegrationEnabledForCurrentTenantReponse = {
  __typename?: 'IsVerkadaIntegrationEnabledForCurrentTenantReponse';
  isEnabled: Scalars['Boolean']['output'];
};

export type LayoutChangeEntity = {
  __typename?: 'LayoutChangeEntity';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  operation: LayoutParcelOperation;
  parcel?: Maybe<LayoutParcel>;
};

export type LayoutDraftInput = {
  levelId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type LayoutParcel = {
  __typename?: 'LayoutParcel';
  geometry?: Maybe<Geometry>;
  properties?: Maybe<CustomResourceInstance>;
};

export type LayoutParcelInput = {
  geometry: GeometryInput;
  properties?: InputMaybe<CustomResourceInstanceInput>;
};

export enum LayoutParcelOperation {
  DeleteResource = 'delete_resource',
  UpsertResource = 'upsert_resource',
  UpsertResourceGroup = 'upsert_resource_group'
}

export type LayoutResourceGroupEntity = {
  __typename?: 'LayoutResourceGroupEntity';
  amenityIds?: Maybe<Array<Scalars['ID']['output']>>;
  capacity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  operation: LayoutParcelOperation;
  policies?: Maybe<ResourcePolicy>;
  subCategory?: Maybe<Scalars['String']['output']>;
};

export type LayoutResourcePolicyDurationInput = {
  type?: InputMaybe<LayoutResourcePolicyDurationType>;
  value: Scalars['Int']['input'];
};

export enum LayoutResourcePolicyDurationType {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Months = 'months',
  Weeks = 'weeks',
  Years = 'years'
}

export type LayoutResourcePolicyEnabledWithDurationInput = {
  duration?: InputMaybe<LayoutResourcePolicyDurationInput>;
  enabled: Scalars['Boolean']['input'];
};

export type LayoutResourcePolicyInput = {
  advancedBookingThreshold?: InputMaybe<LayoutResourcePolicyEnabledWithDurationInput>;
  allowOverlappingBookings?: InputMaybe<Scalars['Boolean']['input']>;
  maxBookingLength?: InputMaybe<LayoutResourcePolicyEnabledWithDurationInput>;
  resourceSharing?: InputMaybe<Scalars['Boolean']['input']>;
  restrictBookingToWorkingHours?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LegacyIntegration = {
  __typename?: 'LegacyIntegration';
  configuration: Scalars['Json']['output'];
  createdAt: Scalars['String']['output'];
  externalAccountId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['Int']['output'];
  resourceType: LegacyIntegrationResourceType;
  state: Scalars['String']['output'];
  type: LegacyIntegrationType;
  updatedAt: Scalars['String']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export enum LegacyIntegrationResourceType {
  Calendar = 'CALENDAR',
  Event = 'EVENT',
  Notification = 'NOTIFICATION'
}

export enum LegacyIntegrationType {
  Bluejeans = 'BLUEJEANS',
  BluejeansRelay = 'BLUEJEANS_RELAY',
  Slack = 'SLACK',
  Teams = 'TEAMS',
  Zoom = 'ZOOM'
}

export type Level = {
  __typename?: 'Level';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount: Scalars['Int']['output'];
  availableDeskAmenities: Array<MinimizedAmenityViewModel>;
  availableSpaceAmenities: Array<MinimizedAmenityViewModel>;
  campusId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Total desks on the floor - including inactive. */
  deskCount: Scalars['Int']['output'];
  /** Total desks within the level that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  desks: Array<Desk>;
  filteredDesksByAmenityIds: Array<Desk>;
  filteredSpacesByAmenityIds: Array<Space>;
  id: Scalars['ID']['output'];
  levelLocation?: Maybe<LevelLocation>;
  levelNumber?: Maybe<Scalars['Int']['output']>;
  locationId: Scalars['ID']['output'];
  /** check whether the level has a map or not */
  mapIsAvailable?: Maybe<Scalars['Boolean']['output']>;
  mapUploadStatus?: Maybe<MapUploadStatus>;
  maxCapacity: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  spaces: Array<Space>;
  state: LevelState;
  timezone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int']['output'];
  /** total spaces will be contextual to the filter */
  totalSpaces: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  workAreas: Array<WorkArea>;
  /** Total desks within the location where booked counts are bound to the current workday and the configured hours of operation for the location */
  workplaceDeskSchedulingAggregates: BookedSchedulingAggregateOfDesks;
  zones: Array<Zone>;
};


export type LevelDesksArgs = {
  filters?: InputMaybe<Array<DeskFilters>>;
};


export type LevelFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type LevelFilteredSpacesByAmenityIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type LevelSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};


export type LevelStateArgs = {
  endTime: Scalars['Date']['input'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
};


export type LevelTotalSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};

export type LevelDeskReservation = {
  __typename?: 'LevelDeskReservation';
  levelId: Scalars['ID']['output'];
  reservations: Array<Reservation>;
};

export type LevelLocation = {
  __typename?: 'LevelLocation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LevelState = {
  __typename?: 'LevelState';
  availability: PlaceAvailability;
  freeCapacity: Scalars['Int']['output'];
};

export enum LimitStrategy {
  Adaptive = 'ADAPTIVE',
  Fixed = 'FIXED'
}

export type LinkKisiLockToRobinLocationInput = {
  remoteLockId: Scalars['String']['input'];
  robinLocationId: Scalars['Int']['input'];
};

export type LinkKisiLockToRobinLocationResponse = {
  __typename?: 'LinkKisiLockToRobinLocationResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type ListBuildingCidrsInput = {
  buildingId: Scalars['ID']['input'];
};

export type ListBuildingCidrsResponse = {
  __typename?: 'ListBuildingCidrsResponse';
  cidrs: Array<Scalars['IPAddressCIDR']['output']>;
};

export type ListBuildingsWhereUserCanManageMeetingServicesResponse = {
  __typename?: 'ListBuildingsWhereUserCanManageMeetingServicesResponse';
  buildings: Array<Maybe<Location>>;
};

export type ListMeetingServiceRequestsFiltersInput = {
  eventStartTimeBetween?: InputMaybe<TimeRangeInput>;
  /** userIds match filter transitively through group membership */
  hasAnyApprover?: InputMaybe<UsersAndGroupsInput>;
  /** userIds match filter transitively through group membership */
  hasAnyAssignee?: InputMaybe<UsersAndGroupsInput>;
  hasAnyRequesterUserId?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasAnyStatus?: InputMaybe<Array<MeetingServiceRequestStatus>>;
  /** 'CAT' matches 'CAT-123', 'CAT-456', but not 'CATS-123'. 'CAT-123' matches 'CAT-123' but not 'CAT-1234'. '123' matches 'CAT-123', 'DOG-123', but not 'CAT-1234'. */
  keyMatches?: InputMaybe<Scalars['String']['input']>;
  regardingAnyBuildingIdsOrTheirDescendants?: InputMaybe<Array<Scalars['ID']['input']>>;
  regardingAnySpaceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceBelongsToAnyCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceHasAnyId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ListMeetingServiceRequestsInput = {
  /** If after is provided, we only return results that come after the previous result set */
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ListMeetingServiceRequestsFiltersInput>;
  /** Return only the first N results */
  first: Scalars['Int']['input'];
  /** Skip N results, e.g. if you need to jump more than one page ahead. This stacks on top of `after` if provided. Avoid use if possible - prefer using `after` alone. */
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: ListMeetingServiceRequestsSortByInput;
};

export type ListMeetingServiceRequestsResponse = {
  __typename?: 'ListMeetingServiceRequestsResponse';
  forwardPageInfo: ForwardPageInfo;
  meetingServiceRequests: Array<MeetingServiceRequest>;
};

export enum ListMeetingServiceRequestsSortByCriteria {
  CreatedAt = 'CREATED_AT',
  EventStartTime = 'EVENT_START_TIME',
  Key = 'KEY',
  ServiceCategoryName = 'SERVICE_CATEGORY_NAME',
  ServiceName = 'SERVICE_NAME',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT'
}

export type ListMeetingServiceRequestsSortByInput = {
  criteria: ListMeetingServiceRequestsSortByCriteria;
  order: SortOrder;
};

export type ListMeetingServicesAvailableInSpaceFiltersInput = {
  spaceId: Scalars['ID']['input'];
};

export type ListMeetingServicesAvailableInSpaceInput = {
  /** If after is provided, we only return results that come after the previous result set */
  after?: InputMaybe<Scalars['String']['input']>;
  filters: ListMeetingServicesAvailableInSpaceFiltersInput;
  /** Return only the first N results */
  first: Scalars['Int']['input'];
};

export type ListMeetingServicesAvailableInSpaceResponse = {
  __typename?: 'ListMeetingServicesAvailableInSpaceResponse';
  forwardPageInfo: ForwardPageInfo;
  meetingServices: Array<MeetingService>;
};

export type ListMeetingServicesCategoriesFiltersInput = {
  hasServicesAvailableInBuildingIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  nameContainsString?: InputMaybe<Scalars['String']['input']>;
};

export type ListMeetingServicesCategoriesInput = {
  /** If after is provided, we only return results that come after the previous result set */
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ListMeetingServicesCategoriesFiltersInput>;
  /** Return only the first N results */
  first: Scalars['Int']['input'];
  /** Skip N results, e.g. if you need to jump more than one page ahead. This stacks on top of `after` if provided. Avoid use if possible - prefer using `after` alone. */
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: ListMeetingServicesCategoriesSortByInput;
};

export type ListMeetingServicesCategoriesResponse = {
  __typename?: 'ListMeetingServicesCategoriesResponse';
  forwardPageInfo: ForwardPageInfo;
  meetingServicesCategories: Array<MeetingServicesCategory>;
};

export enum ListMeetingServicesCategoriesSortByCriteria {
  Key = 'KEY',
  Name = 'NAME'
}

export type ListMeetingServicesCategoriesSortByInput = {
  criteria: ListMeetingServicesCategoriesSortByCriteria;
  order: SortOrder;
};

export type ListMeetingServicesFiltersInput = {
  /** userIds match filter transitively through group membership */
  assignedToAny?: InputMaybe<UsersAndGroupsInput>;
  availableInAnyBuildingIdsOrTheirDescendants?: InputMaybe<Array<Scalars['ID']['input']>>;
  belongsToAnyCategoryId?: InputMaybe<Array<Scalars['ID']['input']>>;
  nameContainsString?: InputMaybe<Scalars['String']['input']>;
};

export type ListMeetingServicesInput = {
  /** If after is provided, we only return results that come after the previous result set */
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ListMeetingServicesFiltersInput>;
  /** Return only the first N results */
  first: Scalars['Int']['input'];
  /** Skip N results, e.g. if you need to jump more than one page ahead. This stacks on top of `after` if provided. Avoid use if possible - prefer using `after` alone. */
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: ListMeetingServicesSortByInput;
};

export type ListMeetingServicesResponse = {
  __typename?: 'ListMeetingServicesResponse';
  forwardPageInfo: ForwardPageInfo;
  meetingServices: Array<MeetingService>;
};

export enum ListMeetingServicesSortByCriteria {
  CategoryName = 'CATEGORY_NAME',
  ServiceName = 'SERVICE_NAME',
  UpdatedAt = 'UPDATED_AT'
}

export type ListMeetingServicesSortByInput = {
  criteria: ListMeetingServicesSortByCriteria;
  order: SortOrder;
};

export type ListUniqueAssigneesForMeetingServicesFiltersInput = {
  serviceAvailableInAnyBuildingIdsOrTheirDescendants?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ListUniqueAssigneesForMeetingServicesInput = {
  /** If after is provided, we only return results that come after the previous result set */
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ListUniqueAssigneesForMeetingServicesFiltersInput>;
  /** Return only the first N results */
  first: Scalars['Int']['input'];
};

export type ListUniqueAssigneesForMeetingServicesResponse = {
  __typename?: 'ListUniqueAssigneesForMeetingServicesResponse';
  forwardPageInfo: ForwardPageInfo;
  uniqueAssignees: Array<Maybe<UserOrGroupUnion>>;
};

export type ListUniqueMeetingServiceRequestValuesFiltersInput = {
  eventStartTimeBetween?: InputMaybe<TimeRangeInput>;
  regardingAnyBuildingIdsOrTheirDescendants?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ListUniqueMeetingServiceRequestValuesInput = {
  filters?: InputMaybe<ListUniqueMeetingServiceRequestValuesFiltersInput>;
  /** Return only the first N results for each value */
  first: Scalars['Int']['input'];
};

export type ListUniqueMeetingServiceRequestValuesResponse = {
  __typename?: 'ListUniqueMeetingServiceRequestValuesResponse';
  uniqueValues: UniqueMeetingServiceRequestValues;
};

export type LocaleDateTimePair = {
  localDate: Scalars['IsoDateOnlyString']['input'];
  localTime: Scalars['IsoLocalTimeString']['input'];
};

export type Location = {
  __typename?: 'Location';
  /** Total desks in the building - excluding inactive. */
  activeDeskCount?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  allowsEmployeeCredentialProvisioning: Scalars['Boolean']['output'];
  campus?: Maybe<Campus>;
  campusId?: Maybe<Scalars['ID']['output']>;
  /** Excluding request-only and on-demand spaces. See SpaceScheduleConfiguration.SCHEDULED. */
  containsAtLeastOneScheduledSpace: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** customWorkingHours are limited to 100 entries and will only grab custom working hours from the current date of the location and upcoming custom working hours It paginates via optional `startFromDateTime` parameter This will grab custom working hours that has a date time greater than `startFromDateTime` provided Format expected is `YYYY-MM-DD HH:mm` */
  customWorkingHours: Array<CustomWorkingHours>;
  description?: Maybe<Scalars['String']['output']>;
  /** Total desks in the building - including inactive. */
  deskCount: Scalars['Int']['output'];
  /** Total desks within the location that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  employeeCredentialProvisioningProviders: Array<EmployeeCredentialProvisioningProvider>;
  /** Expects a `startDate` and `endDate` formated as YYYY-MM-DD `startDate` and `endDate` are inclusive */
  hoursForTimeRange: Array<DateSpecificWorkingHours>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isHealthCheckpointRequired: Scalars['Boolean']['output'];
  isVisitorManagementEnabled: Scalars['Boolean']['output'];
  isWorkplaceServicesEnabled: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  levels: Array<Level>;
  longitude?: Maybe<Scalars['Float']['output']>;
  maxCapacity: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  officeAccess: Array<OfficeAccess>;
  organizationId: Scalars['ID']['output'];
  preferredLanguageTag?: Maybe<Scalars['String']['output']>;
  remoteGoogleId?: Maybe<Scalars['ID']['output']>;
  remoteGoogleLabel?: Maybe<Scalars['String']['output']>;
  spaces: Array<Space>;
  state: LocationState;
  stateByDay: Array<LocationStateByDay>;
  timezone?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int']['output'];
  totalLevels: Scalars['Int']['output'];
  /** total spaces will be contextual to the filter */
  totalSpaces: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  workingHours: Array<WorkingHours>;
  /** Total desks within the location where booked counts are bound to the current workday and the configured hours of operation for the location */
  workplaceDeskSchedulingAggregates: BookedSchedulingAggregateOfDesks;
};


export type LocationCustomWorkingHoursArgs = {
  startFromDateTime?: InputMaybe<Scalars['Date']['input']>;
};


export type LocationHoursForTimeRangeArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};


export type LocationOfficeAccessArgs = {
  userId: Scalars['ID']['input'];
};


export type LocationSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};


export type LocationStateArgs = {
  endTime: Scalars['Date']['input'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
  userId: Scalars['ID']['input'];
};


export type LocationStateByDayArgs = {
  endTime: Scalars['Date']['input'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
};


export type LocationTotalSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};

export type LocationDeskReservation = {
  __typename?: 'LocationDeskReservation';
  locationId: Scalars['ID']['output'];
  reservations: Array<Reservation>;
};

export type LocationState = {
  __typename?: 'LocationState';
  availability: PlaceAvailability;
  freeCapacity: Scalars['Int']['output'];
  isOpen?: Maybe<Scalars['Boolean']['output']>;
};

export type LocationStateByDay = {
  __typename?: 'LocationStateByDay';
  availability: PlaceAvailability;
  day: Scalars['Date']['output'];
};

export type LogSuggestedSpaceBookedInput = {
  suggestedSpaceRecordId: Scalars['ID']['input'];
};

export type LogSuggestedSpaceDismissedInput = {
  suggestedSpaceRecordId: Scalars['ID']['input'];
};

export type LogSuggestedSpaceViewedInput = {
  suggestedSpaceRecordId: Scalars['ID']['input'];
};

export type MapBuildingToRipplingWorkLocationInput = {
  buildingId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  ripplingWorkLocationId: Scalars['ID']['input'];
};

export type MapBuildingToRipplingWorkLocationResponse = {
  __typename?: 'MapBuildingToRipplingWorkLocationResponse';
  id: Scalars['ID']['output'];
};

export type MapBuildingsToRipplingWorkLocationsInput = {
  mappings: Array<MapBuildingsToRipplingWorkLocationsMappingsInput>;
  organizationId: Scalars['ID']['input'];
};

export type MapBuildingsToRipplingWorkLocationsMappingsInput = {
  buildingId: Scalars['ID']['input'];
  ripplingWorkLocationId: Scalars['ID']['input'];
};

export type MapBuildingsToRipplingWorkLocationsResponse = {
  __typename?: 'MapBuildingsToRipplingWorkLocationsResponse';
  id: Scalars['ID']['output'];
};

export type MapKisiLocksToRobinLocations = {
  mappings: Array<MapKisiLocksToRobinLocationsMapping>;
};

export type MapKisiLocksToRobinLocationsMapping = {
  isConfigured: Scalars['Boolean']['input'];
  isOnline: Scalars['Boolean']['input'];
  remoteLockId: Scalars['String']['input'];
  remotePlaceId: Scalars['String']['input'];
  remotePlaceName: Scalars['String']['input'];
  robinFloorId?: InputMaybe<Scalars['Int']['input']>;
  robinLocationId: Scalars['Int']['input'];
};

export type MapKisiLocksToRobinLocationsResponse = {
  __typename?: 'MapKisiLocksToRobinLocationsResponse';
  uuid: Scalars['ID']['output'];
};

export enum MapUploadStatus {
  AwaitingUpload = 'AWAITING_UPLOAD',
  Unknown = 'UNKNOWN',
  Uploaded = 'UPLOADED'
}

export type MeOrganization = {
  __typename?: 'MeOrganization';
  authMethods?: Maybe<Array<AuthMethod>>;
  avatar: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type MeetingService = {
  __typename?: 'MeetingService';
  /** null if approval is not required */
  approvers?: Maybe<UserOrGroup>;
  /** Assignees are required when service is created, but could become null/empty due to user/group deletion */
  assignees?: Maybe<UsersAndGroups>;
  /** A building-level summary of availability of this service. Use availabilityInSpacesOfBuilding for the availability of a specific building. */
  availabilityInBuildings: MeetingServiceAvailabilityInBuildings;
  /** Use availabilityInBuildings first to get the buildings where this service is available */
  availabilityInSpacesOfBuilding: MeetingServiceAvailabilityInSpacesOfBuilding;
  category: MeetingServicesCategory;
  /** The currency for all monetary amounts in this service, like on menu item unitPrice. New meeting services require this field, but old ones may be missing. */
  currencyCode?: Maybe<Scalars['CurrencyCodeISO4217']['output']>;
  description?: Maybe<Scalars['Markdown']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Not yet implemented as of 2024-11-05 */
  permissions?: Maybe<MeetingServicePermissions>;
  questions: Array<TicketQuestion>;
  /** If true, approver must approve creation/changes on requests before assignees are emailed */
  requestsNeedApproval: Scalars['Boolean']['output'];
  /**
   * If true, approver must approve creation/changes on requests before assignees are emailed
   * @deprecated Use requestsNeedApproval instead
   */
  requireAdminApproval: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISODateTime']['output'];
  versionId: Scalars['ID']['output'];
  versionNumber: Scalars['Int']['output'];
};


export type MeetingServiceAvailabilityInSpacesOfBuildingArgs = {
  buildingId: Scalars['ID']['input'];
};

export type MeetingServiceAvailabilityInBuilding = {
  __typename?: 'MeetingServiceAvailabilityInBuilding';
  building?: Maybe<Location>;
  buildingId: Scalars['ID']['output'];
  /** The number of spaces within this building where this service is available */
  spaceCount: Scalars['Int']['output'];
};

export type MeetingServiceAvailabilityInBuildings = {
  __typename?: 'MeetingServiceAvailabilityInBuildings';
  /** The buildings containing at least one space where this service is available */
  buildingAvailability: Array<MeetingServiceAvailabilityInBuilding>;
  /** The number of buildings containing at least one space where this service is available */
  buildingCount: Scalars['Int']['output'];
  /** The number of spaces across all buildings where this service is available */
  spaceCount: Scalars['Int']['output'];
};

export type MeetingServiceAvailabilityInSpacesOfBuilding = {
  __typename?: 'MeetingServiceAvailabilityInSpacesOfBuilding';
  availableInSpaceIds: Array<Scalars['ID']['output']>;
  availableInSpaces: Array<Maybe<Space>>;
  building?: Maybe<Location>;
};

export type MeetingServiceAvailableInSpacesByBuildingInput = {
  /** The ID of the building that contains all the spaceIds */
  buildingId: Scalars['ID']['input'];
  spaceIds: Array<Scalars['ID']['input']>;
};

export type MeetingServicePermissions = {
  __typename?: 'MeetingServicePermissions';
  /** Must have workplace_services:manage permssion in ALL of the service's buildings. If the service is not available in any building, anyone with workplace_services:manage permission in at least one building can delete. */
  canDelete: Scalars['Boolean']['output'];
  /** Must have workplace_services:manage permission on AT LEAST ONE of the service's buildings. If the service is not available in any building, anyone with workplace_services:manage permission in at least one building can update. */
  canUpdate: Scalars['Boolean']['output'];
};

export type MeetingServiceRequest = Ticket & {
  __typename?: 'MeetingServiceRequest';
  approvers?: Maybe<UserOrGroup>;
  assignees?: Maybe<UsersAndGroups>;
  completedForm?: Maybe<CompletedTicketForm>;
  createdAt: Scalars['ISODateTime']['output'];
  /** This may differ from the currencyCode on the meetingService if the meeting service was changed after this request was made. */
  currencyCode?: Maybe<Scalars['CurrencyCodeISO4217']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  /** The ID of the latest change to this service request. Every time the service request is changed, this ID will change. Used for concurrency control on mutations like approveMeetingServiceRequest. */
  latestChangeId: Scalars['ID']['output'];
  /** If you are looking for form questions, consult completedForm which shows questions as they were at the time of ticket creation. meetingService.questions are presented for *new* service requests. */
  meetingService: MeetingService;
  meetingServiceRequestStatus: MeetingServiceRequestStatus;
  permissions?: Maybe<MeetingServiceRequestPermissions>;
  regardsEventAtSpace: TicketRegardsEventAtSpace;
  /** If the service request has status REJECTED, this will contain the comment related to the rejection. Such a comment is optional, so this may be null even if the service request is rejected. */
  rejectionComment?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<User>;
  title: Scalars['String']['output'];
  /** The total price of this service request (see menu choices), after any surcharges/taxes. */
  totalPrice?: Maybe<Money>;
  updatedAt: Scalars['ISODateTime']['output'];
};

export type MeetingServiceRequestIdentifierInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingServiceRequestPermissions = {
  __typename?: 'MeetingServiceRequestPermissions';
  canApproveOrReject: Scalars['Boolean']['output'];
  canChangeAnswers: Scalars['Boolean']['output'];
  canDeleteOrCancel: Scalars['Boolean']['output'];
  canUpdateStatus: Scalars['Boolean']['output'];
};

export enum MeetingServiceRequestSpaceStatus {
  /** The space has explicitly accepted the invitation. Some space calendars will never explicitly accept, and merely acquiesce. */
  Accepted = 'ACCEPTED',
  /** The space has not explicitly accepted the invitation, but it also hasn't explicitly declined the invitation, nor are we awaiting any sort of approval. Robin considers this enough for the space to be successfully booked. */
  Acquiesced = 'ACQUIESCED',
  /** The space declined the invitation from the organizer. The most likely reason is that there was a conflict with another event. Can also happen due to disallowed cross-domain invitations or other policy violations. Or, this could be a request-only space and the request was denied by the admin. */
  Declined = 'DECLINED',
  /** We have seen the event (e.g. through other space calendars), but the space that this service request was intended for is missing. The service request may have been created for an event that was not saved yet, and the creator removed the space before saving the event. Could also happen if someone was editing an event, added a space, moved a service request to that space, but then removed (or failed to add) the space. */
  Missing = 'MISSING',
  /** We have not seen anything related to this event. The service request may have been created for an event that was not saved yet, and the creator either never saved the event or removed all spaces before saving. */
  NotSynced = 'NOT_SYNCED',
  /** Either the event was cancelled by the organizer, or the space was disinvited from the event. It is difficult to tell the difference between the two situations. */
  RemovedOrEventCancelled = 'REMOVED_OR_EVENT_CANCELLED',
  /** The space has tentatively accepted the invitation, but we're still waiting for an admin to approve the request. */
  Requested = 'REQUESTED'
}

export enum MeetingServiceRequestStatus {
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  NeedsApproval = 'NEEDS_APPROVAL',
  Rejected = 'REJECTED',
  Todo = 'TODO'
}

export type MeetingServicesCategory = {
  __typename?: 'MeetingServicesCategory';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MeteredFeature = {
  __typename?: 'MeteredFeature';
  billableTotal?: Maybe<Scalars['Int']['output']>;
  feature: FeatureType;
  limit?: Maybe<Scalars['Int']['output']>;
  overProvisioningLimit?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MigrationProgress = {
  __typename?: 'MigrationProgress';
  migratedSpaceCount: Scalars['Int']['output'];
  spaceCount: Scalars['Int']['output'];
};

export type MigrationReportTotals = {
  __typename?: 'MigrationReportTotals';
  error: Scalars['Int']['output'];
  rolledBack: Scalars['Int']['output'];
  skipped: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
};

export type MigrationReportWithTotals = {
  __typename?: 'MigrationReportWithTotals';
  reports: Array<MigrationSpaceReport>;
  totals: MigrationReportTotals;
};

export type MigrationSpaceReport = {
  __typename?: 'MigrationSpaceReport';
  calendarId: Scalars['String']['output'];
  migratedAt: Scalars['String']['output'];
  spaceId: Scalars['String']['output'];
  spaceName: Scalars['String']['output'];
  spaceResourceEmail: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type MinimizedAmenityViewModel = {
  __typename?: 'MinimizedAmenityViewModel';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Money = {
  __typename?: 'Money';
  /** The number of major currency units in the amount. e.g. `"1.54"` in USD is one dollar and 54 cents. `"100"` in JPY is one hundred yen. Always serialized in fixed notation (i.e. not 1.24e4 exponential notation). */
  amountMajor: Scalars['Decimal']['output'];
  currencyCode: Scalars['CurrencyCodeISO4217']['output'];
};

export type MoneyInput = {
  /** The number of major currency units in the amount. e.g. `"1.54"` in USD is one dollar and 54 cents. `"100"` in JPY is one hundred yen. */
  amountMajor: Scalars['Decimal']['input'];
  currencyCode: Scalars['CurrencyCodeISO4217']['input'];
};

export type MultiPlacePassInput = {
  groups: Array<Scalars['ID']['input']>;
  healthCheckpointRequired?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  places: Array<PassPlaceInput>;
  schedule: Array<Scalars['String']['input']>;
  userChanges?: InputMaybe<PassUsersInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Requesting user acknowledges a given announcement for a given location */
  acknowledgeAnnouncement?: Maybe<Scalars['Void']['output']>;
  acknowledgeAnnouncementForMultipleLocations?: Maybe<Scalars['Void']['output']>;
  /** Acknowledge the handling of visit events to prevent duplication of event driven side-effects */
  acknowledgeVisitEventHandled?: Maybe<Scalars['Void']['output']>;
  /** Adds a new custom resource template to the collection of templates for an organization */
  addCustomResourceTemplate?: Maybe<Scalars['Void']['output']>;
  addCustomWorkingHours?: Maybe<Scalars['Void']['output']>;
  addGuestBadgeImage?: Maybe<Scalars['Void']['output']>;
  /** Adds a message to a conversation. This is called by the UI when a user sends a message. (The source of the resulting message will be "user") */
  addInsightsAssistantInboundMessageToConversation: InsightsAssistantMessageV1;
  /** Adds a message to a conversation. This is called by the insights-nlq-chaperone when the LLM generates a response. (The source of the resulting message will be "model") */
  addInsightsAssistantOutboundMessageToConversation?: Maybe<Scalars['Void']['output']>;
  addLevelToLocation?: Maybe<AddLevelToLocationResponse>;
  addLevelsToLocation?: Maybe<Array<Maybe<AddLevelsToLocationResponse>>>;
  addLevelsWithDetailsToLocation: Array<AddLevelsWithDetailsToLocationResponse>;
  addLocationToOrganization?: Maybe<AddLocationToOrganizationResponse>;
  /** Add a meeting service, e.g. 'Breakfast' */
  addMeetingService: AddMeetingServiceResponse;
  /** Add a category for meeting services, e.g. 'Catering'. Idempotent - if category already exists with same attributes, return old category. */
  addMeetingServicesCategory: AddMeetingServicesCategoryResponse;
  addOfficeAdmin?: Maybe<Scalars['Void']['output']>;
  addOrganization?: Maybe<AddOrganizationResponse>;
  addPinnedUserSpaces?: Maybe<Scalars['Void']['output']>;
  addSpaceToEvent: UpdateEventResponse;
  addUserToAssignmentsQueue?: Maybe<Scalars['Void']['output']>;
  addUserToFavorites?: Maybe<Scalars['Void']['output']>;
  addUserToRole?: Maybe<Scalars['Void']['output']>;
  /** Created a org_visit_type entry matching the given visitTypeName. If visit_type exists, simply enable it for given org. */
  addVisitTypeForOrg?: Maybe<Scalars['Void']['output']>;
  /** Allows a robin administrator to register a guest for a visit at some location */
  administratorRegisterGuest?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Use the `autoAllocateStickersToOrg` operation instead */
  allocateStickersToOrg?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Use the `autoAllocateStickersToOrg` operation instead */
  allocateStickersToOrgByDisplayCode?: Maybe<Scalars['Void']['output']>;
  /** Approve a meeting service request that has status "NEEDS_APPROVAL". Once approved, the status will change to "TODO". If the service request has changed since latestChangeId, this fails with "CONFLICT". Only permitted if MeetingServiceRequest.permissions.canApproveOrReject is true. */
  approveMeetingServiceRequest: ApproveMeetingServiceRequestResponse;
  /** Archive an announcement by ID. Changes the `status` to ARCHIVED. */
  archiveAnnouncement?: Maybe<Scalars['Void']['output']>;
  /** Archive a SLACK announcement by ID. Changes the `status` to ARCHIVED. */
  archiveAnnouncementForSlack?: Maybe<Scalars['Void']['output']>;
  /** Archives an integration by id */
  archiveIntegrationById?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Not yet implemented as of 2024-11-05 */
  archiveMeetingServicesCategory?: Maybe<Scalars['TODO']['output']>;
  /** Recover your arrival display using a client signed certificate of authority */
  arrivalDisplayCertRecovery: ArrivalDisplayRescueResponse;
  /** Provided the expressed name and email, either find or create a guest record. Returns guest ID in all cases. */
  assertGuestIdentity: Scalars['ID']['output'];
  /** Creates a new assigned desk reservation for a given user on your behalf. */
  assignDelegatedDesk: Reservation;
  /** Creates a new assigned desk reservation for the current user. */
  assignDeskToMe: Reservation;
  assignStickerToDeskByShortCode?: Maybe<AssignStickerToDeskByShortCodeResponse>;
  assignStickers: AssignStickerResponse;
  /** Associate a health checkpoint response record to a guest invite */
  associateHealthCheckpointWithVisit?: Maybe<Scalars['Void']['output']>;
  /** Generate an authentication bearer token in exchange for a magic link token */
  authenticateVisitorWithMagicLink: VisitMagicLinkAuthResponse;
  autoAllocateStickersToMultipleOrgs?: Maybe<Scalars['Void']['output']>;
  autoAllocateStickersToOrg?: Maybe<Scalars['Void']['output']>;
  /** Batch report for issues with booking users in auto-desk-booking */
  autoDeskBookingBatchReportFailures?: Maybe<Scalars['Void']['output']>;
  /** report for process failures in auto-desk-booking */
  autoDeskBookingReportProcessFailure?: Maybe<Scalars['Void']['output']>;
  /** This mutation is used to unlink a Kisi locks from a Robin levels. If the locks are linked to a Robin location, they will not be unlinked from their locations. */
  batchUnlinkKisiLocksFromRobinLevels?: Maybe<BatchUnlinkKisiLocksFromRobinLevelsResponse>;
  batchUnlinkKisiLocksFromRobinLocations?: Maybe<BatchUnlinkKisiLocksFromRobinLocationsResponse>;
  /** Archive multiple announcements by IDs, modifying it's status to ARCHIVED. If one announcement is unable to be processed, it fails the entire operation */
  bulkArchiveAnnouncements?: Maybe<Scalars['Void']['output']>;
  /** Bulk updates custom working hours for a single location by overriding all the custom working hours to the custom working hour objects provided in the payload. There are three scenarios: If a custom working hour object in the payload has an ID, it updates that object in the db with the corresponding ID If no ID is provided with a custom working hour object, a new object is created in the db If there are objects in the db that is not defined in the payload, they are deleted If there is a failure for one of the objects, the entire operation fails and reverts to custom working hours before the request was made. */
  bulkUpdateCustomWorkingHours?: Maybe<Scalars['Void']['output']>;
  cancelActivity?: Maybe<Scalars['Void']['output']>;
  /** Cancel an existing employee visit */
  cancelEmployeeVisit?: Maybe<Scalars['Void']['output']>;
  cancelEmployeeVisitsAtLocationForEmployee?: Maybe<Scalars['Void']['output']>;
  cancelEmployeeVisitsForEmployee?: Maybe<Scalars['Void']['output']>;
  /** Cancel event by event ID */
  cancelEvent?: Maybe<Scalars['Void']['output']>;
  cancelGuestInvite?: Maybe<Scalars['Void']['output']>;
  cancelVisit?: Maybe<Scalars['Void']['output']>;
  /** Change the answers to the questions on a meeting service request. The current CompletedTicketForm.id must be passed in to ensure that the client is working from the latest version. If someone else changes the answers in the meantime, the update will fail with "CONFLICT". */
  changeAnswersOnMeetingServiceRequest: ChangeAnswersOnMeetingServiceRequestResponse;
  /** Changes the Brivo lock links. Can add, change, or remove links. */
  changeBrivoLockLinks: ChangeBrivoLockLinksResponse;
  /** Changes the Genea lock links. Can add, change, or remove links. */
  changeGeneaDoorLinks: ChangeGeneaLockLinksResponse;
  /** This mutation is used to change the Robin level a Kisi lock is linked to. It can also be used to create a new link between a Kisi lock and a Robin level. */
  changeKisiLockLinkToRobinLevel?: Maybe<ChangeKisiLockLinkToRobinLevelResponse>;
  changeKisiLockLinkToRobinLocation?: Maybe<ChangeKisiLockLinkToRobinLocationResponse>;
  /** Changes the Openpath lock links. Can add, change, or remove links. */
  changeOpenpathLockLinks: ChangeOpenpathLockLinksResponse;
  /** Changes the Verkada door links. Can add, change, or remove links. */
  changeVerkadaDoorLinks: ChangeVerkadaLockLinksResponse;
  /** check into a visit that was self-registered from the arrival display */
  checkIntoSelfRegisteredVisitFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /**
   * check into a visit using a guest's magic link
   * @deprecated checkIntoVisit is deprecated. Use checkIntoVisitWithMagicLink instead.
   */
  checkIntoVisit?: Maybe<Scalars['Void']['output']>;
  /** check into a visit for a location that does not require HCP from the arrival display */
  checkIntoVisitFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  checkIntoVisitFromDashboard?: Maybe<Scalars['Void']['output']>;
  /**
   * check into a visit for a location that requires HCP from the arrival display
   * @deprecated Use checkIntoSelfRegisteredVisitFromArrivalDisplay instead
   */
  checkIntoVisitRequiringHCPFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /** check into a visit using a guest's magic link */
  checkIntoVisitWithMagicLink?: Maybe<Scalars['Void']['output']>;
  /** check out a guest from the arrival display */
  checkOutGuestFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /** check out a guest from the visit admin dashboard */
  checkOutGuestFromDashboard?: Maybe<Scalars['Void']['output']>;
  /** Complete a a survey response */
  completeSurveyResponse?: Maybe<SurveyResponse>;
  confirmDeskReservation?: Maybe<Scalars['Void']['output']>;
  /** Confirm the desk reservation for the given user. Requires a token with elevated scopes. */
  confirmDeskReservationDelegated?: Maybe<Scalars['Void']['output']>;
  /** Confirm event by event ID */
  confirmEvent?: Maybe<Scalars['Void']['output']>;
  /** Connects Genea account. */
  connectGeneaAccountForCurrentTenant: ConnectGeneaAccountForCurrentTenantResponse;
  connectGenericConnectorAccount: ConnectGenericConnectorAccountResponse;
  /** Accepts Kisi admin created API and creates necessary event webhooks. */
  connectKisiAccount: ConnectKisiAccountResponse;
  /** Accepts OpenPath credentials and sets up the account. */
  connectOpenpathAccount: ConnectOpenpathAccountResponse;
  /** Connects Verkada account. */
  connectVerkadaAccountForCurrentTenant: ConnectVerkadaAccountForCurrentTenantResponse;
  /** Converts an existing API token to a JWT claims token and refresh token pair */
  convertApiTokenToJwtAuth: AuthorizationResponse;
  convertGroupToExternalRipplingGroup: ConvertGroupToExternalRipplingGroupResponse;
  createActivity: CreateActivityReturnType;
  /** Create a new amenity (applies to neither spaces or desks if no configuration is provided) mutation returns the ID of the created amenity */
  createAmenity: Scalars['ID']['output'];
  createAndMapBuildingsFromRippling: CreateAndMapBuildingsFromRipplingResponse;
  /** Create a new announcement for given locations. `status` is ACTIVE by default. - Note: only user actors are allowed to create announcements */
  createAnnouncement?: Maybe<Scalars['Void']['output']>;
  /** Create a new announcement to be consumed by SLACK. `status` is ACTIVE by default. */
  createAnnouncementForSlack?: Maybe<Scalars['Void']['output']>;
  /** From dashboard on creation of the new arrival display. Creates new device for the given tenant. In event land: Successful create causes event handler to generate new device authentication which is registered against this entity. */
  createArrivalDisplay: Scalars['ArrivalDisplayRegistrationKey']['output'];
  /** Creates a badge CSV upload link, associated with given inputs. */
  createBadgeCSVUploadLink: CreateBadgeCsvUploadLinkResponse;
  /** From dashboard on creation of the new badge printer. Creates new device for the given tenant. */
  createBadgePrinter?: Maybe<Scalars['Void']['output']>;
  createBookingWindowPolicyForLocation?: Maybe<Scalars['Void']['output']>;
  /** Creates a new custom form from the input */
  createCustomVisitForm?: Maybe<CustomVisitForm>;
  createDefaultDraft: CreateDraftResponse;
  createDelivery?: Maybe<Delivery>;
  createDeliveryZone?: Maybe<DeliveryZone>;
  createDeskExclusions?: Maybe<Array<Scalars['ID']['output']>>;
  createDraft: CreateDraftResponse;
  /** Creates a new email configuration associated with the given location */
  createEmailConfiguration?: Maybe<EmailConfiguration>;
  createGenericHealthCheckpoint: CreateGenericHealthCheckpointResponse;
  createGroupAndMakeItExternalRipplingGroup: CreateGroupAndMakeItExternalRipplingGroupResponse;
  /** Creates a new integration */
  createIntegration?: Maybe<ExternalIntegration>;
  createLayoutDraft: CreateDraftResponse;
  createLegacyIntegration?: Maybe<LegacyIntegration>;
  createOfficeAdminRole?: Maybe<Scalars['Void']['output']>;
  /** @deprecated createPass is deprecated. Use createPassForPlaces instead. */
  createPass?: Maybe<Scalars['Void']['output']>;
  /** Creates a pass for one or more places */
  createPassForPlaces?: Maybe<Scalars['Void']['output']>;
  createRobinOrganizationFromRipplingOAuthData: CreateRobinOrganizationFromRipplingOAuthDataResponse;
  createRole?: Maybe<Scalars['Void']['output']>;
  /** Creates a Sigma user if it doesn't exist */
  createSigmaMember?: Maybe<Scalars['ID']['output']>;
  /** Creates a new Sigma workbook in My Documents or Shared */
  createSigmaWorkbook?: Maybe<SigmaWorkbook>;
  /**
   * Internal method to create a new visit with zero side effects.
   * @deprecated Use createVisitWithCustomVisitType
   */
  createVisit?: Maybe<Scalars['Void']['output']>;
  /** Internal method to create a new visit with zero side effects using a string visit type. */
  createVisitWithCustomVisitType?: Maybe<Scalars['Void']['output']>;
  createWorkPolicy?: Maybe<Scalars['Void']['output']>;
  customResourceInstancePrePublishDelete?: Maybe<Scalars['Void']['output']>;
  /** Save new, or update existing custom resource instances in a pre-publish state. This method is intended to support progressive updates to an in-progress mapping of resources. Changes will be published after save if publishChangesAfterSave is set to true, otherwise the session will remain open and accept additional updates. */
  customResourceInstancePrePublishSave?: Maybe<Scalars['Void']['output']>;
  /** Cancel a resource reservation occurrence */
  customResourceReservationOccurrenceCancel?: Maybe<Scalars['Void']['output']>;
  /** Request to reschedule a reservation occurrence for a resource by ID */
  customResourceReservationOccurrenceReschedule: ScheduleSingleDayReservationResponseType;
  /** Request to schedule a single day reservation of a resource by ID for another user */
  customResourceScheduleSingleDayDelegateReservation: ScheduleSingleDayReservationResponseType;
  /** Disable a org_visit_type entry matching the given visitTypeName. */
  deactivateVisitTypeForOrg?: Maybe<Scalars['Void']['output']>;
  /**
   * Set a decision for a document for a visiting guest for one document within a specified workflow.
   * @deprecated Use decideOutcomeForVisitDocumentFromArrivalDisplay instead
   */
  decideOutcomeForVisitDocument?: Maybe<Scalars['Void']['output']>;
  /** Set a decision from the Arrival Display for a document for a visiting guest for one document within a specified workflow. */
  decideOutcomeForVisitDocumentFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /** Set a decision from the GuestUI for a document for a visiting guest for one document within a specified workflow. */
  decideOutcomeForVisitDocumentFromGuestUI?: Maybe<Scalars['Void']['output']>;
  /** Ends or cancels a reservation and updates the user's participation status. Should be used to cancel actor's own reservation. */
  declineAndEndReservation: EndOrCancelReservationResponse;
  /** Deletes a custom form with the given ID */
  deleteCustomVisitForm?: Maybe<Scalars['Void']['output']>;
  deleteDeliveries?: Maybe<Scalars['Void']['output']>;
  /** @deprecated use deleteDeliveries instead */
  deleteDelivery?: Maybe<Scalars['Void']['output']>;
  deleteDeliveryZone?: Maybe<Scalars['Void']['output']>;
  deleteDeskExclusions?: Maybe<Scalars['Void']['output']>;
  deleteDraft?: Maybe<Scalars['Void']['output']>;
  /** Deletes the email configuration associated with the given location */
  deleteEmailConfiguration?: Maybe<Scalars['Void']['output']>;
  deleteExternalRipplingGroup: DeleteExternalRipplingGroupResponse;
  /** Deletes a legacy integration exists for the user by the given type and resource-type Tenant ID is also taken into account Returns the id of the deleted integration */
  deleteLegacyIntegrationByType?: Maybe<Scalars['Int']['output']>;
  /** Soft deletes meeting services by ids. Idempotent. It does not cancel any meeting requests already created under those services. */
  deleteMeetingServices?: Maybe<Scalars['Void']['output']>;
  /** Deletes a meeting service request by ID. Idempotent. */
  deleteOrCancelMeetingServiceRequest?: Maybe<Scalars['Void']['output']>;
  deletePass?: Maybe<Scalars['Void']['output']>;
  deleteWorkAreaDesks?: Maybe<Scalars['Void']['output']>;
  disableAnonymityModeForOrg?: Maybe<Scalars['Void']['output']>;
  disableOrganizationUser?: Maybe<Scalars['Void']['output']>;
  disablePassiveCheckInFeatureForBuilding?: Maybe<Scalars['Void']['output']>;
  disableSpaces: DisableSpacesResults;
  discardDefaultDraft?: Maybe<Scalars['Void']['output']>;
  /** Disables Brivo integration for the current tenant. */
  disconnectBrivoAccountForCurrentTenant: DisconnectBrivoAccountResponse;
  /** Disables Genea integration by given UUID. */
  disconnectGeneaAccountByIntegrationUuid: DisconnectGeneaAccountByIntegrationUuidResponse;
  /** Disables Kisi integration for the current tenant. */
  disconnectKisiAccount: DisconnnectKisiAccountResponse;
  /** Disables OpenPath integration for the current tenant. */
  disconnectOpenpathAccount: DisconnectOpenpathAccountResponse;
  /** Disables Verkada integration by given UUID. */
  disconnectVerkadaAccountByIntegrationUuid: DisconnectVerkadaAccountByIntegrationUuidResponse;
  /** Dismiss a survey response */
  dismissSurveyResponse?: Maybe<SurveyResponse>;
  employeeRegisteredForWaitlistVisit?: Maybe<Scalars['Void']['output']>;
  employeeVisit: EmployeeVisitMutations;
  enableAnonymityModeForOrg?: Maybe<Scalars['Void']['output']>;
  enableOrganizationUser?: Maybe<Scalars['Void']['output']>;
  enablePassiveCheckInFeatureForBuilding?: Maybe<Scalars['Void']['output']>;
  /** Terminates a conversation setting active to false. */
  endInsightsAssistantConversation?: Maybe<Scalars['Void']['output']>;
  /** Ends or cancels a reservation via delegation */
  endOrCancelDelegatedReservation: EndOrCancelReservationResponse;
  /** Ends or cancels a reservation. Should be used to cancel actor's own reservation. */
  endOrCancelReservation: EndOrCancelReservationResponse;
  endReservationOrVisitsInRange?: Maybe<EndReservationOrVisitInRangeResponse>;
  /** Accepts a list of records and force resolves them to a success state */
  forceResolveCSVUploadRecords: ForceResolveCsvUploadRecordsResponse;
  generateStickerBatch: GenerateStickerBatchResponse;
  /** A guest invite failed their health checkpoint */
  guestHealthCheckpointFailed?: Maybe<Scalars['Void']['output']>;
  /** Called when a guest's invitation email has been sent */
  guestInvited?: Maybe<Scalars['Void']['output']>;
  /** Initiates Brivo account connection. Will return an OAuth URL client should use. */
  initiateBrivoAccountConnectionForCurrentTenant: InitiateBrivoAccountConnectionForCurrentTenantResponse;
  initiateRipplingConnectionAndSync: InitiateRipplingConnectionAndSyncResponse;
  initiateRipplingUserSync: InitiateRipplingUserSyncResponse;
  inviteOfficeAdminUsers?: Maybe<Scalars['Void']['output']>;
  inviteUsersToDraftAsCollaborators?: Maybe<Scalars['Void']['output']>;
  joinActivity?: Maybe<Scalars['Void']['output']>;
  /** Perform whichever action makes sense to allow a user to "Join" the office. If there is a desk reservation reservation, confirm it if allowed. Otherwise, schedule an employee visit. */
  joinOffice?: Maybe<Scalars['Void']['output']>;
  linkArrivalDisplayAndBadgePrinter?: Maybe<Scalars['Void']['output']>;
  linkKisiLockToRobinLocation?: Maybe<LinkKisiLockToRobinLocationResponse>;
  logSpaceSuggestionBooked?: Maybe<Scalars['Void']['output']>;
  logSpaceSuggestionDismissed?: Maybe<Scalars['Void']['output']>;
  logSpaceSuggestionViewed?: Maybe<Scalars['Void']['output']>;
  mapBuildingToRipplingWorkLocation: MapBuildingToRipplingWorkLocationResponse;
  mapBuildingsToRipplingWorkLocations: Array<MapBuildingsToRipplingWorkLocationsResponse>;
  /** Accepts a list of Kisi locks and maps it to Robin locations. */
  mapKisiLocksToRobinLocations?: Maybe<MapKisiLocksToRobinLocationsResponse>;
  publishDraft?: Maybe<Scalars['Void']['output']>;
  publishSharedScheduleForDesk?: Maybe<Scalars['Void']['output']>;
  /** Accepts OpenPath credentials and updates the account connection. */
  reconnectOpenpathAccount: ReconnectOpenpathAccountResponse;
  refreshDeviceAuthorization: DeviceAuthorizationResponse;
  /** Refreshes an existing JWT claims token and refresh token pair */
  refreshJwtAuthToken: AuthorizationResponse;
  /** Register arrival display for service. Each key only works one time. */
  registerArrivalDisplay: ArrivalDisplayRegistrationResponse;
  /**
   * register a guest in a location that doesn't require HCP from the arrival display - automatically checks the visit in
   * @deprecated Use registerGuestWithoutHCPFromArrivalDisplay
   */
  registerGuestFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /**
   * create a new visit from dashboard. guests listed will be upserted into the set of known guests, matched by email.
   * @deprecated This creation method will be replaced with a more open usage of visit type. Replaced by administratorRegisterGuest.
   */
  registerGuestFromDashboard?: Maybe<Scalars['Void']['output']>;
  /**
   * register a guest in a location that requires a HCP from the arrival display
   * @deprecated Use registerGuestWithHCPFromArrivalDisplayWithCustomVisitType
   */
  registerGuestWithHCPFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /** register a guest in a location that requires a HCP from the arrival display using a string visit type */
  registerGuestWithHCPFromArrivalDisplayWithCustomVisitType?: Maybe<Scalars['Void']['output']>;
  /**
   * register a guest in a location that doesn't require HCP from the arrival display
   * @deprecated Use registerGuestWithoutHCPFromArrivalDisplayWithCustomVisitType
   */
  registerGuestWithoutHCPFromArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /** register a guest in a location that doesn't require HCP from the arrival display using a string visit type */
  registerGuestWithoutHCPFromArrivalDisplayWithCustomVisitType?: Maybe<Scalars['Void']['output']>;
  /** Reject a meeting service request that has status "NEEDS_APPROVAL". Once rejected, the status will change to "REJECTED", similar to "CANCELLED". If the service request has changed since latestChangeId, this fails with "CONFLICT". Only permitted if MeetingServiceRequest.permissions.canApproveOrReject is true. */
  rejectMeetingServiceRequest: RejectMeetingServiceRequestResponse;
  /** Unregister and de-authenticate all devices for location. */
  removeAllDevicesForLocation?: Maybe<Scalars['Void']['output']>;
  /** Unregister and de-authenticate an arrival display. */
  removeArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /** Unregister a badge printer. */
  removeBadgePrinter?: Maybe<Scalars['Void']['output']>;
  removeBookingWindowPolicyForLocation?: Maybe<Scalars['Void']['output']>;
  /** Removes messages by ID from a chat on activity */
  removeChatMessagesFromActivity?: Maybe<Scalars['Void']['output']>;
  removeCustomWorkingHours?: Maybe<Scalars['Void']['output']>;
  removeOfficeAdmin?: Maybe<Scalars['Void']['output']>;
  removePinnedUserSpaces?: Maybe<Scalars['Void']['output']>;
  removeSpaceFromEvent: RemoveSpaceFromEventResponse;
  removeUserFromFavorites?: Maybe<Scalars['Void']['output']>;
  removeUserFromRole?: Maybe<Scalars['Void']['output']>;
  removeUsersFromAssignmentsQueue?: Maybe<Scalars['Void']['output']>;
  removeUsersFromDraftCollaborators?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Not yet implemented as of 2024-11-05 */
  renameMeetingServicesCategory?: Maybe<Scalars['TODO']['output']>;
  /** Extends the expiration period on an expired, unused arrival display registration. */
  renewArrivalDisplayRegistration?: Maybe<Scalars['Void']['output']>;
  /** Used by arrival displays to report their status and to report the status of their connected badge printer. */
  reportDeviceStatus?: Maybe<Scalars['Void']['output']>;
  /** Report an issue with a desk - chair is broken, monitor is busted, etc */
  reportIssueWithDesk: ReportIssueResponse;
  /** Report an issue with a space - TV isn't working, table is wobbly, etc */
  reportIssueWithSpace: ReportIssueResponse;
  /** Tells us the device's current IP address. From this, we can infer that the user is present with their device at a building associated with that IP address, and perform side effects like checking them in to their reservations at that building. Don't bother calling this if device is on a cellular data connection or a VPN. */
  reportUserPresenceAtIPAddress: ReportUserPresenceAtIpAddressResponse;
  /** Reports presence from a web client. Similar to reportUserPresenceAtIPAddress, but for web clients, which don't have a direct way to report their IP address. We will be conservative in trusting the remote IP. */
  reportUserPresenceFromWebClient: ReportUserPresenceFromWebClientResponse;
  /** Tells us that the user is present in a particular building at a particular time */
  reportUserPresenceInBuildingAtTime: ReportUserPresenceInBuildingAtTimeResponse;
  /** Creates a meeting service request for a draft event at a space. A "draft event" is one that has not yet been created, so neither Robin nor Google/MS know about it. Service requests created with this mutation are not "real" until we see the event sync back to Robin. Nobody will be emailed until that sync happens. If the draft Event is never saved, then we can have dangling service requests that should be cleaned up later. If the event has already been created, use requestMeetingServiceForExistingEventAtSpace instead. Requires permission to request services at the given spaceId (workplace_services:request). */
  requestMeetingServiceForDraftEventAtSpace: RequestMeetingServiceForDraftEventAtSpaceResponse;
  /** Creates a meeting service request for an existing event at a space. If the event has not been created yet, use requestMeetingServiceForDraftEventAtSpace instead. Requires permission to request services at the given spaceId (workplace_services:request). */
  requestMeetingServiceForExistingEventAtSpace: RequestMeetingServiceForExistingEventAtSpaceResponse;
  resendCollaboratorInvite?: Maybe<Scalars['Void']['output']>;
  /** Creates a new hot/hoteled desk reservationfor a given user on your behalf. */
  reserveDelegatedDesk: Reservation;
  /** Creates a new hot/hoteled desk reservation for the current user. */
  reserveDeskForMe: Reservation;
  /** Reserve a space */
  reserveSpace: ReserveSpaceResponse;
  /** Request to schedule a recurring reservation for current user by resource ID */
  resourceRecurringReservationSchedule: ScheduleRecurringReservationResponseType;
  rollbackMicrosoftMigration?: Maybe<Scalars['Void']['output']>;
  saveMappedGoogleLocations?: Maybe<Scalars['Void']['output']>;
  /** Save a new survey configuration */
  saveSurveyConfiguration?: Maybe<Survey>;
  scaffoldDemoFloorPlan?: Maybe<Scalars['Void']['output']>;
  /** Create a new employee visit */
  scheduleEmployeeVisitAtLocation?: Maybe<Scalars['Void']['output']>;
  /** Request to schedule a single day reservation for current user of a resource by ID */
  scheduleSingleDayReservation: ScheduleSingleDayReservationResponseType;
  /**
   * Add a chat message to an activity
   * @deprecated Deprecated in favor of `sendChatOnActivityWithId`
   */
  sendChatOnActivity?: Maybe<Scalars['Void']['output']>;
  /** Add a chat message to an activity with client specified UUID identity */
  sendChatOnActivityWithId?: Maybe<Scalars['Void']['output']>;
  /**
   * This mutation allows admins to retrigger a notification to a recipient.
   * @deprecated use sendDeliveryNotificationsToRecipients instead
   */
  sendDeliveryNotificationToRecipient?: Maybe<Scalars['Void']['output']>;
  /** This mutation allows admins to retrigger notifications to many recipients. */
  sendDeliveryNotificationsToRecipients?: Maybe<Scalars['Void']['output']>;
  /** Sets the auto desk booking setting for current (user) actor */
  setAutoDeskBooking?: Maybe<Scalars['Void']['output']>;
  setAutoDeskBookingForOrg?: Maybe<Scalars['Void']['output']>;
  /** Requires places:manage permission for the given building. Sets public CIDRs associated with the given building, for use with passive check-in. Idempotent. Number of CIDRs, size of subnet are both limited. Rejects CIDRs that might technically be valid but are likely user error: "1", "1.2", etc. Tries to reject bogons and martians: https://en.wikipedia.org/wiki/Bogon_filtering */
  setBuildingCidrs: SetBuildingCidrsResponse;
  /** Sets a channel ID for a user's personal calendar integration. */
  setChannelAndResourceIdsForUserCalendar?: Maybe<Scalars['Void']['output']>;
  setDefaultLocationForUser?: Maybe<Scalars['Void']['output']>;
  /** Sets sentAt field for distribution and resolves sendResult to SUCCESS for all provided recipient ids. An error will be thrown if client tries to set send data of a distribution or recipient that has already been set. */
  setDistributionSendData?: Maybe<Scalars['Void']['output']>;
  setEmployeeVisitsEnabledForOrg?: Maybe<Scalars['Void']['output']>;
  setGoogleWorkingLocationSyncSettingForOrg?: Maybe<Scalars['Void']['output']>;
  /** Sets the sync google working locations setting user. 0 for off, org ID for on. */
  setGoogleWorkingLocationSyncSettingForUser?: Maybe<Scalars['Void']['output']>;
  /** Sets the homepage preference for current (user) actor. */
  setMyHomepage?: Maybe<Scalars['Void']['output']>;
  /** Sets a languge tag for current (user) actor */
  setMyLanguageTag?: Maybe<Scalars['Void']['output']>;
  /** Update the given notification preference for the current user */
  setNotificationPreferenceForCurrentUser?: Maybe<Scalars['Void']['output']>;
  setOpenpathCredentialProvisioningEnabledForIntegration: SetOpenpathCredentialProvisioningEnabledForIntegrationResponse;
  setOpenpathPresenceReportingEnabledForIntegration: SetOpenpathPresenceReportingEnabledForIntegrationResponse;
  setOrganization14Day100UsersTrial?: Maybe<Scalars['Void']['output']>;
  /** Set resource permissions within hierarchy */
  setPermissionsOnHierarchy?: Maybe<Scalars['Void']['output']>;
  /** Updates startAt date for recurring distribution announcements and keeps the sentAt field set to null. Sent the startAt date as a ISODateTime */
  setRecurringAnnouncementData?: Maybe<Scalars['Void']['output']>;
  /** Sets the show weekends setting for current (user) actor */
  setShowWeekendsForMe?: Maybe<Scalars['Void']['output']>;
  setSuggestedSpacesEnabledForOrg?: Maybe<Scalars['Void']['output']>;
  setTeamMembershipByName?: Maybe<Scalars['Void']['output']>;
  /** Sets a user notification setting. */
  setUserNotificationSetting?: Maybe<Scalars['Void']['output']>;
  /** Begins a new empty active conversation. */
  startInsightsAssistantConversation: InsightsAssistantConversationV1;
  /** Temporary migration query which generates and returns the client secret for existing arrival displays */
  tempArrivalDisplayAuthMigration: ArrivalDisplayRegistrationResponse;
  triggerManualSyncOfSpaceCalendar?: Maybe<Scalars['Void']['output']>;
  unassignSticker?: Maybe<Scalars['Void']['output']>;
  unjoinActivity?: Maybe<Scalars['Void']['output']>;
  unlinkArrivalDisplayAndBadgePrinter?: Maybe<Scalars['Void']['output']>;
  /** This mutation is used to unlink a Kisi lock from a Robin level. If the lock is linked to a Robin location, it will not be unlinked from the location. */
  unlinkKisiLockFromRobinLevel?: Maybe<UnlinkKisiLockFromRobinLevelResponse>;
  unlinkKisiLockFromRobinLocation?: Maybe<UnlinkKisiLockFromRobinLocationResponse>;
  /** To be used when a location is deleted */
  unlinkLocationFromCustomForms?: Maybe<Scalars['Void']['output']>;
  /** This mutation understands the input as the state of the activity, therefore it will update every field according to the input */
  updateActivity?: Maybe<Scalars['Void']['output']>;
  /** Update a single (ACTIVE or SCHEDULED) announcement. Author cannot change. */
  updateAnnouncement?: Maybe<Scalars['Void']['output']>;
  /** From the Dashboard, users can edit arrival displays they've already created. */
  updateArrivalDisplay?: Maybe<Scalars['Void']['output']>;
  /** From the Dashboard, users can edit badge printers they've already created. */
  updateBadgePrinter?: Maybe<Scalars['Void']['output']>;
  updateBookingWindowPolicyForLocation?: Maybe<Scalars['Void']['output']>;
  /** Updates an existing custom resource template's associated amenities */
  updateCustomResourceTemplateAssociatedAmenities?: Maybe<Scalars['Void']['output']>;
  /** Updates a given custom form with the provided updates */
  updateCustomVisitForm?: Maybe<CustomVisitForm>;
  updateCustomWorkingHours?: Maybe<Scalars['Void']['output']>;
  /** Updates the default pass setting */
  updateDefaultPass?: Maybe<Scalars['Void']['output']>;
  updateDelivery?: Maybe<Delivery>;
  /** This route allows user's with magic links to update delivery status. */
  updateDeliveryStatusByMagicLink: Delivery;
  updateDeliveryZone: DeliveryZone;
  updateDistributionsByAnnouncementId?: Maybe<Scalars['Void']['output']>;
  updateDraft: UpdateDraftResponse;
  updateDraftConflicts: Array<DraftConflict>;
  updateDraftData: UpdateDraftDataResponse;
  updateDraftMetadata?: Maybe<Scalars['Void']['output']>;
  /** Updates the email configuration associated with the given location */
  updateEmailConfiguration?: Maybe<EmailConfiguration>;
  /** Adds feedback for a given message. */
  updateInsightsAssistantFeedbackForMessage?: Maybe<Scalars['Void']['output']>;
  updateLayoutDraft: UpdateLayoutDraftResponse;
  /** Change a meeting service, keeping the old versions of questions around for existing requests. Clients must have an accurate picture of previous state, so conflicting changes are rejected. Future iterations of this mutation might be diff-based (e.g. adding spaces instead of passing the whole list back and forth). Partial updates are supported by omitting fields, rather than explicitly setting them to null. */
  updateMeetingService: UpdateMeetingServiceResponse;
  /** Updates a ticket status based on current status. Idempotent. */
  updateMeetingServiceRequestStatus: UpdateMeetingServiceRequestStatusResponse;
  /** Updates the desk, start time, and end time for the current user's non-recurring desk reservation. */
  updateMyReservationDeskAndTimeFrame: UpdateDeskReservationResponse;
  /** @deprecated updatePass is deprecated. Use updatePassForPlaces instead. */
  updatePass?: Maybe<Scalars['Void']['output']>;
  /** Updates a pass for one or more places */
  updatePassForPlaces?: Maybe<Scalars['Void']['output']>;
  /** Updates the visibility of a desk reservation. */
  updateReservationVisibility?: Maybe<Scalars['Void']['output']>;
  updateStandardWorkingHoursForLocation?: Maybe<Scalars['Void']['output']>;
  /** Update an existing survey configuration */
  updateSurveyConfiguration?: Maybe<Survey>;
  updateWorkAreas?: Maybe<Scalars['TODO']['output']>;
  updateWorkPolicy?: Maybe<Scalars['Void']['output']>;
  /** Store data that user has viewed an announcement at the given location. */
  viewAnnouncement?: Maybe<Scalars['Void']['output']>;
  viewAnnouncementForMultipleLocations?: Maybe<Scalars['Void']['output']>;
  /** Add a new document task to visit workflows for the specified visit types and locations */
  visitAddDocumentTask?: Maybe<Scalars['Void']['output']>;
  /** Archive an existing document. The specified document is removed from all live workflows. */
  visitArchiveDocument?: Maybe<Scalars['Void']['output']>;
  /** Update the status of an async visit request. This mutation is only accessible using an internal service auth token. */
  visitAsyncRequestStatusReport?: Maybe<Scalars['Void']['output']>;
  /**
   * Update a document task to apply to a new visit workflow for the specified visit types and locations
   * @deprecated Use visitChangeDocumentTaskAssociationsWithCustomVisitType instead
   */
  visitChangeDocumentTaskAssociations?: Maybe<Scalars['Void']['output']>;
  /** Update a document task to apply to a new visit workflow for the specified visit types and locations */
  visitChangeDocumentTaskAssociationsWithCustomVisitType?: Maybe<Scalars['Void']['output']>;
};


export type MutationAcknowledgeAnnouncementArgs = {
  announcementId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};


export type MutationAcknowledgeAnnouncementForMultipleLocationsArgs = {
  announcementId: Scalars['ID']['input'];
  locationIds: Array<Scalars['ID']['input']>;
};


export type MutationAcknowledgeVisitEventHandledArgs = {
  eventType: VisitEventAcknowledgement;
  visitId: Scalars['ID']['input'];
};


export type MutationAddCustomResourceTemplateArgs = {
  associatedAmenityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<IconType>;
  organizationID: Scalars['ID']['input'];
  policyConfiguration?: InputMaybe<CustomResourcePolicyDocumentCommand>;
  resourceCategory: Scalars['String']['input'];
  subCategories?: InputMaybe<Array<Scalars['CustomResourceSubCategoryType']['input']>>;
  templateId: Scalars['ID']['input'];
};


export type MutationAddCustomWorkingHoursArgs = {
  customWorkingHours: CustomWorkingHoursInput;
};


export type MutationAddGuestBadgeImageArgs = {
  base64Image: Scalars['String']['input'];
  guestInviteId: Scalars['ID']['input'];
};


export type MutationAddInsightsAssistantInboundMessageToConversationArgs = {
  conversationId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


export type MutationAddInsightsAssistantOutboundMessageToConversationArgs = {
  conversationId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


export type MutationAddLevelToLocationArgs = {
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationAddLevelsToLocationArgs = {
  locationId: Scalars['ID']['input'];
  names: Array<Scalars['String']['input']>;
};


export type MutationAddLevelsWithDetailsToLocationArgs = {
  levels: Array<CreateLevelInput>;
  locationId: Scalars['ID']['input'];
};


export type MutationAddLocationToOrganizationArgs = {
  address: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};


export type MutationAddMeetingServiceArgs = {
  input: AddMeetingServiceInput;
};


export type MutationAddMeetingServicesCategoryArgs = {
  input: AddMeetingServicesCategoryInput;
};


export type MutationAddOfficeAdminArgs = {
  locationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddOrganizationArgs = {
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type MutationAddPinnedUserSpacesArgs = {
  locationId: Scalars['String']['input'];
  spaceIds: Array<Scalars['String']['input']>;
};


export type MutationAddSpaceToEventArgs = {
  request?: InputMaybe<AddSpaceToEventRequest>;
};


export type MutationAddUserToAssignmentsQueueArgs = {
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddUserToFavoritesArgs = {
  userId: Scalars['String']['input'];
};


export type MutationAddUserToRoleArgs = {
  roleSlug: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddVisitTypeForOrgArgs = {
  visitTypeName: Scalars['String']['input'];
};


export type MutationAdministratorRegisterGuestArgs = {
  arrivalInstructions?: InputMaybe<Scalars['String']['input']>;
  arrivalLocationId: Scalars['ID']['input'];
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  guests: Array<GuestInputType>;
  hostUserId: Scalars['ID']['input'];
  provisionCredentials?: InputMaybe<Scalars['Boolean']['input']>;
  sendGuestEmailInvite?: InputMaybe<Scalars['Boolean']['input']>;
  startTime: Scalars['ISODateTime']['input'];
  visitType: Scalars['String']['input'];
};


export type MutationAllocateStickersToOrgArgs = {
  input: AllocateStickersToOrgRequest;
};


export type MutationAllocateStickersToOrgByDisplayCodeArgs = {
  input: AllocateStickersToOrgByDisplayCodeRequest;
};


export type MutationApproveMeetingServiceRequestArgs = {
  input: ApproveMeetingServiceRequestInput;
};


export type MutationArchiveAnnouncementArgs = {
  announcementId: Scalars['ID']['input'];
};


export type MutationArchiveAnnouncementForSlackArgs = {
  announcementId: Scalars['ID']['input'];
};


export type MutationArchiveIntegrationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveMeetingServicesCategoryArgs = {
  input?: InputMaybe<Scalars['TODO']['input']>;
};


export type MutationAssertGuestIdentityArgs = {
  guestEmail: Scalars['String']['input'];
  guestName: Scalars['String']['input'];
};


export type MutationAssignDelegatedDeskArgs = {
  email: Scalars['String']['input'];
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  seatId: Scalars['Int']['input'];
  start: DateWithTimezone;
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationAssignDeskToMeArgs = {
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  seatId: Scalars['Int']['input'];
  start: DateWithTimezone;
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationAssignStickerToDeskByShortCodeArgs = {
  input: AssignStickerToDeskByShortCodeRequest;
};


export type MutationAssignStickersArgs = {
  input: AssignStickerRequest;
};


export type MutationAssociateHealthCheckpointWithVisitArgs = {
  guestInviteId: Scalars['ID']['input'];
  healthCheckpointId: Scalars['ID']['input'];
};


export type MutationAuthenticateVisitorWithMagicLinkArgs = {
  magicLink: Scalars['String']['input'];
};


export type MutationAutoAllocateStickersToMultipleOrgsArgs = {
  input: Array<AutoAllocateStickersToOrgRequest>;
};


export type MutationAutoAllocateStickersToOrgArgs = {
  input: AutoAllocateStickersToOrgRequest;
};


export type MutationAutoDeskBookingBatchReportFailuresArgs = {
  reports: Array<AutoDeskBookingUserBatchFailureReportCommand>;
};


export type MutationAutoDeskBookingReportProcessFailureArgs = {
  report?: InputMaybe<AutoDeskBookingProcessFailureReportCommand>;
};


export type MutationBatchUnlinkKisiLocksFromRobinLevelsArgs = {
  input: BatchUnlinkKisiLocksFromRobinLevelsInput;
};


export type MutationBatchUnlinkKisiLocksFromRobinLocationsArgs = {
  input: BatchUnlinkKisiLocksFromRobinLocationsInput;
};


export type MutationBulkArchiveAnnouncementsArgs = {
  announcementIds: Array<Scalars['ID']['input']>;
};


export type MutationBulkUpdateCustomWorkingHoursArgs = {
  customWorkingHoursUpdates: Array<CustomWorkingHoursBulkUpdateInput>;
  locationId: Scalars['ID']['input'];
};


export type MutationCancelActivityArgs = {
  activityId: Scalars['ID']['input'];
};


export type MutationCancelEmployeeVisitArgs = {
  cancelReservations?: InputMaybe<Scalars['Boolean']['input']>;
  employeeVisitId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelEmployeeVisitsAtLocationForEmployeeArgs = {
  cancelReservations?: InputMaybe<Scalars['Boolean']['input']>;
  employeeId: Scalars['ID']['input'];
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['ISODateTime']['input'];
};


export type MutationCancelEmployeeVisitsForEmployeeArgs = {
  cancelReservations?: InputMaybe<Scalars['Boolean']['input']>;
  date: Scalars['LocalDate']['input'];
  employeeId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelGuestInviteArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<GuestInviteCanceledReason>;
};


export type MutationCancelVisitArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<GuestInviteCanceledReason>;
};


export type MutationChangeAnswersOnMeetingServiceRequestArgs = {
  input: ChangeAnswersOnMeetingServiceRequestInput;
};


export type MutationChangeBrivoLockLinksArgs = {
  input: ChangeBrivoLockLinksInput;
};


export type MutationChangeGeneaDoorLinksArgs = {
  input: ChangeGeneaDoorLinksInput;
};


export type MutationChangeKisiLockLinkToRobinLevelArgs = {
  input: ChangeKisiLockLinkToRobinLevelInput;
};


export type MutationChangeKisiLockLinkToRobinLocationArgs = {
  input: ChangeKisiLockLinkToRobinLocationInput;
};


export type MutationChangeOpenpathLockLinksArgs = {
  input: ChangeOpenpathLockLinksInput;
};


export type MutationChangeVerkadaDoorLinksArgs = {
  input: ChangeVerkadaDoorLinksInput;
};


export type MutationCheckIntoSelfRegisteredVisitFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID']['input'];
};


export type MutationCheckIntoVisitArgs = {
  magicLink: Scalars['String']['input'];
};


export type MutationCheckIntoVisitFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID']['input'];
};


export type MutationCheckIntoVisitFromDashboardArgs = {
  guestInviteId: Scalars['ID']['input'];
};


export type MutationCheckIntoVisitRequiringHcpFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID']['input'];
};


export type MutationCheckIntoVisitWithMagicLinkArgs = {
  magicLink: Scalars['String']['input'];
};


export type MutationCheckOutGuestFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID']['input'];
};


export type MutationCheckOutGuestFromDashboardArgs = {
  guestInviteId: Scalars['ID']['input'];
};


export type MutationCompleteSurveyResponseArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  isAnonymous: Scalars['Boolean']['input'];
  rating: Scalars['Int']['input'];
  reservationId?: InputMaybe<Scalars['String']['input']>;
  surveyResponseId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationConfirmDeskReservationArgs = {
  reservationId: Scalars['ID']['input'];
  type: DeskConfirmationType;
};


export type MutationConfirmDeskReservationDelegatedArgs = {
  confirmedAt?: InputMaybe<Scalars['ISODateTime']['input']>;
  reservationId: Scalars['ID']['input'];
  type: DeskConfirmationType;
  userId: Scalars['ID']['input'];
};


export type MutationConfirmEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationConnectGeneaAccountForCurrentTenantArgs = {
  input: ConnectGeneaAccountForCurrentTenantInput;
};


export type MutationConnectGenericConnectorAccountArgs = {
  input: ConnectGenericConnectorAccountInput;
};


export type MutationConnectKisiAccountArgs = {
  input: ConnectKisiAccountInput;
};


export type MutationConnectOpenpathAccountArgs = {
  input: ConnectOpenpathAccountInput;
};


export type MutationConnectVerkadaAccountForCurrentTenantArgs = {
  input: ConnectVerkadaAccountForCurrentTenantInput;
};


export type MutationConvertGroupToExternalRipplingGroupArgs = {
  input: ConvertGroupToExternalRipplingGroupInput;
};


export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};


export type MutationCreateAmenityArgs = {
  amenityName: Scalars['String']['input'];
  configuration?: InputMaybe<AmenitySelectionConfiguration>;
  organizationId: Scalars['ID']['input'];
};


export type MutationCreateAndMapBuildingsFromRipplingArgs = {
  input: CreateAndMapBuildingsFromRipplingInput;
};


export type MutationCreateAnnouncementArgs = {
  announcement: AnnouncementCreateInput;
};


export type MutationCreateAnnouncementForSlackArgs = {
  announcement: AnnouncementCreateInput;
};


export type MutationCreateArrivalDisplayArgs = {
  badgePrinterIPAddress?: InputMaybe<Scalars['String']['input']>;
  badgePrinterId?: InputMaybe<Scalars['String']['input']>;
  guestImageCaptureEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  selfRegistrationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateBadgeCsvUploadLinkArgs = {
  input: CreateBadgeCsvUploadLinkInput;
};


export type MutationCreateBadgePrinterArgs = {
  arrivalDisplayIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  badgePrinterIPAddress: Scalars['String']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateBookingWindowPolicyForLocationArgs = {
  input: CreateBookingWindowPolicyInput;
};


export type MutationCreateCustomVisitFormArgs = {
  form?: InputMaybe<CustomVisitFormInput>;
};


export type MutationCreateDefaultDraftArgs = {
  levelId: Scalars['ID']['input'];
};


export type MutationCreateDeliveryArgs = {
  input: CreateDeliveryInput;
};


export type MutationCreateDeliveryZoneArgs = {
  input: CreateDeliveryZoneInput;
};


export type MutationCreateDeskExclusionsArgs = {
  dates: Array<InputMaybe<DateRangeWithTimeZone>>;
  seriesId: Scalars['ID']['input'];
};


export type MutationCreateDraftArgs = {
  draft: DraftInput;
};


export type MutationCreateEmailConfigurationArgs = {
  input: EmailConfigurationInput;
  locationId: Scalars['ID']['input'];
};


export type MutationCreateGenericHealthCheckpointArgs = {
  entityId: Scalars['ID']['input'];
  entityType: GenericHealthCheckpointEntityType;
  id?: InputMaybe<Scalars['ID']['input']>;
  locationId: Scalars['ID']['input'];
};


export type MutationCreateGroupAndMakeItExternalRipplingGroupArgs = {
  input: CreateGroupAndMakeItExternalRipplingGroupInput;
};


export type MutationCreateIntegrationArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  externalId: Scalars['String']['input'];
  integrationJson?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
};


export type MutationCreateLayoutDraftArgs = {
  draft: LayoutDraftInput;
};


export type MutationCreateLegacyIntegrationArgs = {
  configuration: Scalars['Json']['input'];
  resourceType: Scalars['String']['input'];
  state: Scalars['String']['input'];
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCreateOfficeAdminRoleArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationCreatePassArgs = {
  input?: InputMaybe<PassInput>;
};


export type MutationCreatePassForPlacesArgs = {
  input?: InputMaybe<MultiPlacePassInput>;
};


export type MutationCreateRobinOrganizationFromRipplingOAuthDataArgs = {
  input: CreateRobinOrganizationFromRipplingOAuthDataInput;
};


export type MutationCreateRoleArgs = {
  input: RoleCreationInput;
};


export type MutationCreateSigmaWorkbookArgs = {
  location: SigmaLocationType;
  name: Scalars['String']['input'];
};


export type MutationCreateVisitArgs = {
  guests: Array<GuestInputType>;
  organizerId: Scalars['ID']['input'];
  organizerType: VisitOrganizerType;
  visitDetails: VisitWithIdInputType;
};


export type MutationCreateVisitWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID']['input'];
  guests: Array<GuestInputType>;
  hostUserId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  organizerId: Scalars['ID']['input'];
  organizerType: VisitOrganizerType;
  startTime: Scalars['ISODateTime']['input'];
  visitType: Scalars['String']['input'];
};


export type MutationCreateWorkPolicyArgs = {
  workPolicy: WorkPolicyInput;
};


export type MutationCustomResourceInstancePrePublishDeleteArgs = {
  customResourceInstanceIds: Array<Scalars['ID']['input']>;
  mapId: Scalars['ID']['input'];
  organizationID: Scalars['ID']['input'];
  publicationSessionId: Scalars['ID']['input'];
  publishChangesAfterSave?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCustomResourceInstancePrePublishSaveArgs = {
  deletedCustomResourceInstanceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupedResourceInstances?: InputMaybe<Array<GroupedResourceSaveUpdateInput>>;
  mapId: Scalars['ID']['input'];
  organizationID: Scalars['ID']['input'];
  publicationSessionId: Scalars['ID']['input'];
  publishChangesAfterSave?: InputMaybe<Scalars['Boolean']['input']>;
  resourceGroups?: InputMaybe<Array<ResourceGroupSaveUpdateInput>>;
  ungroupedResourceInstances?: InputMaybe<Array<UngroupedResourceSaveUpdateInput>>;
};


export type MutationCustomResourceReservationOccurrenceCancelArgs = {
  organizationId: Scalars['ID']['input'];
  reservationId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type MutationCustomResourceReservationOccurrenceRescheduleArgs = {
  clientType: ClientType;
  date: Scalars['IsoDateOnlyString']['input'];
  duration: Scalars['ISO8601_Duration']['input'];
  organizationId: Scalars['ID']['input'];
  reservationId: Scalars['ID']['input'];
  resourceInstanceId: Scalars['ID']['input'];
  startTime: Scalars['IsoLocalTimeString']['input'];
};


export type MutationCustomResourceScheduleSingleDayDelegateReservationArgs = {
  clientType: ClientType;
  date: Scalars['IsoDateOnlyString']['input'];
  duration: Scalars['ISO8601_Duration']['input'];
  organizationId: Scalars['ID']['input'];
  reservee: Scalars['ID']['input'];
  resourceInstanceId: Scalars['ID']['input'];
  startTime: Scalars['IsoLocalTimeString']['input'];
};


export type MutationDeactivateVisitTypeForOrgArgs = {
  visitTypeName: Scalars['String']['input'];
};


export type MutationDecideOutcomeForVisitDocumentArgs = {
  guestId: Scalars['ID']['input'];
  outcomeId: Scalars['ID']['input'];
  visitId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
  workflowId: Scalars['ID']['input'];
};


export type MutationDecideOutcomeForVisitDocumentFromArrivalDisplayArgs = {
  guestId: Scalars['ID']['input'];
  outcomeId: Scalars['ID']['input'];
  sendCopyToGuest?: InputMaybe<Scalars['Boolean']['input']>;
  visitId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
  workflowId: Scalars['ID']['input'];
};


export type MutationDecideOutcomeForVisitDocumentFromGuestUiArgs = {
  guestId: Scalars['ID']['input'];
  outcomeId: Scalars['ID']['input'];
  sendCopyToGuest?: InputMaybe<Scalars['Boolean']['input']>;
  visitId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
  workflowId: Scalars['ID']['input'];
};


export type MutationDeclineAndEndReservationArgs = {
  reservationId: Scalars['ID']['input'];
};


export type MutationDeleteCustomVisitFormArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteDeliveriesArgs = {
  clientOptions?: InputMaybe<ClientOptionsInput>;
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteDeliveryArgs = {
  clientOptions?: InputMaybe<ClientOptionsInput>;
  id: Scalars['ID']['input'];
};


export type MutationDeleteDeliveryZoneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDeskExclusionsArgs = {
  exclusionIds: Array<Scalars['ID']['input']>;
  seriesId: Scalars['ID']['input'];
};


export type MutationDeleteDraftArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEmailConfigurationArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationDeleteExternalRipplingGroupArgs = {
  input: DeleteExternalRipplingGroupInput;
};


export type MutationDeleteLegacyIntegrationByTypeArgs = {
  resourceType: LegacyIntegrationResourceType;
  type: LegacyIntegrationType;
};


export type MutationDeleteMeetingServicesArgs = {
  meetingServiceIds: Array<Scalars['ID']['input']>;
};


export type MutationDeleteOrCancelMeetingServiceRequestArgs = {
  meetingServiceRequestId: Scalars['ID']['input'];
};


export type MutationDeletePassArgs = {
  passId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteWorkAreaDesksArgs = {
  deskIds: Array<Scalars['ID']['input']>;
};


export type MutationDisableAnonymityModeForOrgArgs = {
  input: AnonymityModeToggleInput;
};


export type MutationDisableOrganizationUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDisablePassiveCheckInFeatureForBuildingArgs = {
  input: SetPassiveCheckInFeatureForBuildingInput;
};


export type MutationDisableSpacesArgs = {
  spaceIds: Array<Scalars['ID']['input']>;
};


export type MutationDiscardDefaultDraftArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisconnectGeneaAccountByIntegrationUuidArgs = {
  input: DisconnectGeneaAccountByIntegrationUuidInput;
};


export type MutationDisconnectVerkadaAccountByIntegrationUuidArgs = {
  input: DisconnectVerkadaAccountByIntegrationUuidInput;
};


export type MutationDismissSurveyResponseArgs = {
  reservationId?: InputMaybe<Scalars['String']['input']>;
  surveyResponseId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationEmployeeRegisteredForWaitlistVisitArgs = {
  input: EmployeeRegisteredForWaitlistVisitInput;
};


export type MutationEnableAnonymityModeForOrgArgs = {
  input: AnonymityModeToggleInput;
};


export type MutationEnableOrganizationUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationEnablePassiveCheckInFeatureForBuildingArgs = {
  input: SetPassiveCheckInFeatureForBuildingInput;
};


export type MutationEndInsightsAssistantConversationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEndOrCancelDelegatedReservationArgs = {
  notifyAssignee?: InputMaybe<Scalars['Boolean']['input']>;
  reservationId: Scalars['ID']['input'];
};


export type MutationEndOrCancelReservationArgs = {
  reservationId: Scalars['ID']['input'];
};


export type MutationEndReservationOrVisitsInRangeArgs = {
  cancelFutureVisits?: InputMaybe<Scalars['Boolean']['input']>;
  creationType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  end: Scalars['Date']['input'];
  orgId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationForceResolveCsvUploadRecordsArgs = {
  input: UploadCsvForcePushRecords;
};


export type MutationGenerateStickerBatchArgs = {
  input: GenerateStickerBatchRequest;
};


export type MutationGuestHealthCheckpointFailedArgs = {
  healthCheckpointResponseId: Scalars['ID']['input'];
};


export type MutationGuestInvitedArgs = {
  magicLink: Scalars['String']['input'];
};


export type MutationInitiateBrivoAccountConnectionForCurrentTenantArgs = {
  input: InitiateBrivoAccountConnectionForCurrentTenantInput;
};


export type MutationInitiateRipplingConnectionAndSyncArgs = {
  input: InitiateRipplingConnectionAndSyncInput;
};


export type MutationInitiateRipplingUserSyncArgs = {
  input: InitiateRipplingUserSyncInput;
};


export type MutationInviteOfficeAdminUsersArgs = {
  emailAddresses: Array<Scalars['String']['input']>;
};


export type MutationInviteUsersToDraftAsCollaboratorsArgs = {
  draftId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationJoinActivityArgs = {
  activityId: Scalars['ID']['input'];
};


export type MutationJoinOfficeArgs = {
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  type: DeskConfirmationType;
  userId: Scalars['ID']['input'];
};


export type MutationLinkArrivalDisplayAndBadgePrinterArgs = {
  arrivalDisplayId: Scalars['ID']['input'];
  badgePrinterId: Scalars['ID']['input'];
};


export type MutationLinkKisiLockToRobinLocationArgs = {
  input: LinkKisiLockToRobinLocationInput;
};


export type MutationLogSpaceSuggestionBookedArgs = {
  input: LogSuggestedSpaceBookedInput;
};


export type MutationLogSpaceSuggestionDismissedArgs = {
  input: LogSuggestedSpaceDismissedInput;
};


export type MutationLogSpaceSuggestionViewedArgs = {
  input: LogSuggestedSpaceViewedInput;
};


export type MutationMapBuildingToRipplingWorkLocationArgs = {
  input: MapBuildingToRipplingWorkLocationInput;
};


export type MutationMapBuildingsToRipplingWorkLocationsArgs = {
  input: MapBuildingsToRipplingWorkLocationsInput;
};


export type MutationMapKisiLocksToRobinLocationsArgs = {
  input: MapKisiLocksToRobinLocations;
};


export type MutationPublishDraftArgs = {
  id: Scalars['ID']['input'];
  sendNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  version: Scalars['Int']['input'];
};


export type MutationPublishSharedScheduleForDeskArgs = {
  input: SharedDeskSchedulesInput;
};


export type MutationReconnectOpenpathAccountArgs = {
  input: ReconnectOpenpathAccountInput;
};


export type MutationRefreshDeviceAuthorizationArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRefreshJwtAuthTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRegisterArrivalDisplayArgs = {
  key: Scalars['ArrivalDisplayRegistrationKey']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationRegisterGuestFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID']['input'];
  guestEmail: Scalars['String']['input'];
  guestName: Scalars['String']['input'];
  hostUserId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  visitId?: InputMaybe<Scalars['ID']['input']>;
  visitInviteId?: InputMaybe<Scalars['ID']['input']>;
  visitType: VisitType;
};


export type MutationRegisterGuestFromDashboardArgs = {
  guests: Array<GuestInputType>;
  visitDetails: VisitInputType;
};


export type MutationRegisterGuestWithHcpFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID']['input'];
  guestEmail: Scalars['String']['input'];
  guestInviteId: Scalars['ID']['input'];
  guestName: Scalars['String']['input'];
  healthCheckpointId: Scalars['ID']['input'];
  hostUserId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  visitId: Scalars['ID']['input'];
  visitType: VisitType;
};


export type MutationRegisterGuestWithHcpFromArrivalDisplayWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID']['input'];
  guestEmail: Scalars['String']['input'];
  guestInviteId: Scalars['ID']['input'];
  guestName: Scalars['String']['input'];
  healthCheckpointId: Scalars['ID']['input'];
  hostUserId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  visitId: Scalars['ID']['input'];
  visitType: Scalars['String']['input'];
};


export type MutationRegisterGuestWithoutHcpFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID']['input'];
  guestEmail: Scalars['String']['input'];
  guestName: Scalars['String']['input'];
  hostUserId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  visitId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
  visitType: VisitType;
};


export type MutationRegisterGuestWithoutHcpFromArrivalDisplayWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID']['input'];
  guestEmail: Scalars['String']['input'];
  guestName: Scalars['String']['input'];
  hostUserId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  visitId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
  visitType: Scalars['String']['input'];
};


export type MutationRejectMeetingServiceRequestArgs = {
  input: RejectMeetingServiceRequestInput;
};


export type MutationRemoveAllDevicesForLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationRemoveArrivalDisplayArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveBadgePrinterArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveBookingWindowPolicyForLocationArgs = {
  input: RemoveBookingWindowPolicyInput;
};


export type MutationRemoveChatMessagesFromActivityArgs = {
  chatMessageIdentifiers: Array<Scalars['ID']['input']>;
};


export type MutationRemoveCustomWorkingHoursArgs = {
  customWorkingHoursId: Scalars['ID']['input'];
};


export type MutationRemoveOfficeAdminArgs = {
  locationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemovePinnedUserSpacesArgs = {
  spaceIds: Array<Scalars['String']['input']>;
};


export type MutationRemoveSpaceFromEventArgs = {
  input: RemoveSpaceFromEventInput;
};


export type MutationRemoveUserFromFavoritesArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserFromRoleArgs = {
  roleSlug: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveUsersFromAssignmentsQueueArgs = {
  id: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationRemoveUsersFromDraftCollaboratorsArgs = {
  draftId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationRenameMeetingServicesCategoryArgs = {
  input?: InputMaybe<Scalars['TODO']['input']>;
};


export type MutationRenewArrivalDisplayRegistrationArgs = {
  key: Scalars['ArrivalDisplayRegistrationKey']['input'];
};


export type MutationReportDeviceStatusArgs = {
  arrivalDisplayStatus?: InputMaybe<DeviceStatusInput>;
  arrivalDisplayStatusMessage?: InputMaybe<Scalars['String']['input']>;
  badgePrinterStatus?: InputMaybe<DeviceStatusInput>;
  badgePrinterStatusMessage?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReportIssueWithDeskArgs = {
  input: ReportIssueWithDeskInput;
};


export type MutationReportIssueWithSpaceArgs = {
  input: ReportIssueWithSpaceInput;
};


export type MutationReportUserPresenceAtIpAddressArgs = {
  input: ReportUserPresenceAtIpAddressInput;
};


export type MutationReportUserPresenceFromWebClientArgs = {
  input: ReportUserPresenceFromWebClientInput;
};


export type MutationReportUserPresenceInBuildingAtTimeArgs = {
  input: ReportUserPresenceInBuildingAtTimeInput;
};


export type MutationRequestMeetingServiceForDraftEventAtSpaceArgs = {
  input: RequestMeetingServiceForDraftEventAtSpaceInput;
};


export type MutationRequestMeetingServiceForExistingEventAtSpaceArgs = {
  input: RequestMeetingServiceForExistingEventAtSpaceInput;
};


export type MutationResendCollaboratorInviteArgs = {
  draftId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationReserveDelegatedDeskArgs = {
  email: Scalars['String']['input'];
  end: DateWithTimezone;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  recurrence?: InputMaybe<Array<Scalars['String']['input']>>;
  seatId: Scalars['Int']['input'];
  start: DateWithTimezone;
  type: Scalars['String']['input'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationReserveDeskForMeArgs = {
  end: DateWithTimezone;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  recurrence?: InputMaybe<Array<Scalars['String']['input']>>;
  seatId: Scalars['Int']['input'];
  start: DateWithTimezone;
  type: Scalars['String']['input'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationReserveSpaceArgs = {
  request: ReserveSpaceRequest;
};


export type MutationResourceRecurringReservationScheduleArgs = {
  clientType: ClientType;
  duration: Scalars['ISO8601_Duration']['input'];
  organizationId: Scalars['ID']['input'];
  recurringReservationRequestExclusions?: InputMaybe<Array<RecurringReservationRequest>>;
  recurringReservationRequests: Array<RecurringReservationRequest>;
  requestReservationDates?: InputMaybe<Array<LocaleDateTimePair>>;
  reservationRequestDateExclusions?: InputMaybe<Array<LocaleDateTimePair>>;
  resourceInstanceId: Scalars['ID']['input'];
};


export type MutationSaveMappedGoogleLocationsArgs = {
  input?: InputMaybe<Array<InputMaybe<GoogleToRobinInput>>>;
};


export type MutationSaveSurveyConfigurationArgs = {
  allowAnonymous: Scalars['Boolean']['input'];
  buildingIds: Array<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};


export type MutationScaffoldDemoFloorPlanArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationScheduleEmployeeVisitAtLocationArgs = {
  employeeId: Scalars['ID']['input'];
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['ISODateTime']['input'];
};


export type MutationScheduleSingleDayReservationArgs = {
  clientType: ClientType;
  date: Scalars['IsoDateOnlyString']['input'];
  duration: Scalars['ISO8601_Duration']['input'];
  organizationId: Scalars['ID']['input'];
  resourceInstanceId: Scalars['ID']['input'];
  startTime: Scalars['IsoLocalTimeString']['input'];
};


export type MutationSendChatOnActivityArgs = {
  activityID: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};


export type MutationSendChatOnActivityWithIdArgs = {
  activityID: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  messageID: Scalars['ID']['input'];
};


export type MutationSendDeliveryNotificationToRecipientArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSendDeliveryNotificationsToRecipientsArgs = {
  deliveryIds: Array<Scalars['ID']['input']>;
};


export type MutationSetAutoDeskBookingArgs = {
  input: SetAutoDeskBookingInput;
};


export type MutationSetAutoDeskBookingForOrgArgs = {
  input: AutoDeskBookingOrgInput;
};


export type MutationSetBuildingCidrsArgs = {
  input: SetBuildingCidrsInput;
};


export type MutationSetChannelAndResourceIdsForUserCalendarArgs = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  expiration?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationSetDefaultLocationForUserArgs = {
  levelId?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  organizationSlug?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetDistributionSendDataArgs = {
  distributionId: Scalars['ID']['input'];
  recipientIds: Array<Scalars['ID']['input']>;
};


export type MutationSetEmployeeVisitsEnabledForOrgArgs = {
  organizationId: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetGoogleWorkingLocationSyncSettingForOrgArgs = {
  organizationId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationSetGoogleWorkingLocationSyncSettingForUserArgs = {
  organizationId: Scalars['String']['input'];
  sourceOfTruth?: InputMaybe<GoogleWorkingLocationSourceOfTruth>;
  userId: Scalars['ID']['input'];
};


export type MutationSetMyHomepageArgs = {
  input: SetMyHomepageInput;
};


export type MutationSetMyLanguageTagArgs = {
  input: SetMyLanguageTagInput;
};


export type MutationSetNotificationPreferenceForCurrentUserArgs = {
  isEnabled: Scalars['Boolean']['input'];
  preferenceKey?: InputMaybe<NotificationPreferenceKey>;
};


export type MutationSetOpenpathCredentialProvisioningEnabledForIntegrationArgs = {
  input: SetOpenpathCredentialProvisioningEnabledForIntegrationInput;
};


export type MutationSetOpenpathPresenceReportingEnabledForIntegrationArgs = {
  input: SetOpenpathPresenceReportingEnabledForIntegrationInput;
};


export type MutationSetPermissionsOnHierarchyArgs = {
  organizationId: Scalars['ID']['input'];
  permissionCommands: Array<HierarchyPermissionCommand>;
};


export type MutationSetRecurringAnnouncementDataArgs = {
  announcementId: Scalars['ID']['input'];
  startAt: Scalars['String']['input'];
};


export type MutationSetShowWeekendsForMeArgs = {
  input: SetShowWeekendsForMeInput;
};


export type MutationSetSuggestedSpacesEnabledForOrgArgs = {
  organizationId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationSetTeamMembershipByNameArgs = {
  teamMemberUserIds: Array<Scalars['String']['input']>;
  teamName: Scalars['String']['input'];
};


export type MutationSetUserNotificationSettingArgs = {
  input: SetUserNotificationSettingInput;
};


export type MutationTriggerManualSyncOfSpaceCalendarArgs = {
  calendarId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};


export type MutationUnassignStickerArgs = {
  input: UnassignStickerRequest;
};


export type MutationUnjoinActivityArgs = {
  activityId: Scalars['ID']['input'];
};


export type MutationUnlinkArrivalDisplayAndBadgePrinterArgs = {
  arrivalDisplayId: Scalars['ID']['input'];
  badgePrinterId: Scalars['ID']['input'];
};


export type MutationUnlinkKisiLockFromRobinLevelArgs = {
  input: UnlinkKisiLockFromRobinLevelInput;
};


export type MutationUnlinkKisiLockFromRobinLocationArgs = {
  input: UnlinkKisiLockFromRobinLocationInput;
};


export type MutationUnlinkLocationFromCustomFormsArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationUpdateActivityArgs = {
  activityId: Scalars['ID']['input'];
  input: UpdateActivityInput;
};


export type MutationUpdateAnnouncementArgs = {
  announcementId: Scalars['ID']['input'];
  content: AnnouncementUpdateInput;
};


export type MutationUpdateArrivalDisplayArgs = {
  badgePrinterId?: InputMaybe<Scalars['String']['input']>;
  guestImageCaptureEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  selfRegistrationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateBadgePrinterArgs = {
  arrivalDisplayIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  badgePrinterIPAddress?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateBookingWindowPolicyForLocationArgs = {
  input: UpdateBookingWindowPolicyInput;
};


export type MutationUpdateCustomResourceTemplateAssociatedAmenitiesArgs = {
  associatedAmenityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationID: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};


export type MutationUpdateCustomVisitFormArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  update?: InputMaybe<CustomVisitFormInput>;
};


export type MutationUpdateCustomWorkingHoursArgs = {
  customWorkingHoursId: Scalars['ID']['input'];
  customWorkingHoursUpdate: CustomWorkingHoursUpdateInput;
};


export type MutationUpdateDefaultPassArgs = {
  input: UpdateDefaultPassInput;
};


export type MutationUpdateDeliveryArgs = {
  clientOptions?: InputMaybe<ClientOptionsInput>;
  id: Scalars['ID']['input'];
  input: UpdateDeliveryInput;
};


export type MutationUpdateDeliveryStatusByMagicLinkArgs = {
  magicLink: Scalars['String']['input'];
  status: DeliveryStatus;
};


export type MutationUpdateDeliveryZoneArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDeliveryZoneInput;
};


export type MutationUpdateDistributionsByAnnouncementIdArgs = {
  announcementId: Scalars['ID']['input'];
  distributions: Array<DistributionInput>;
};


export type MutationUpdateDraftArgs = {
  changes: Array<DeskChangeInput>;
  id: Scalars['ID']['input'];
  resolvingConflict?: InputMaybe<Scalars['Boolean']['input']>;
  version: Scalars['Int']['input'];
};


export type MutationUpdateDraftConflictsArgs = {
  draftId: Scalars['ID']['input'];
};


export type MutationUpdateDraftDataArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateDraftMetadataArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateEmailConfigurationArgs = {
  input: EmailConfigurationInput;
  locationId: Scalars['ID']['input'];
};


export type MutationUpdateInsightsAssistantFeedbackForMessageArgs = {
  conversationId: Scalars['ID']['input'];
  input: InsightsAssistantFeedbackInputV1;
  messageId: Scalars['ID']['input'];
};


export type MutationUpdateLayoutDraftArgs = {
  changes: DraftLayoutInputEntity;
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


export type MutationUpdateMeetingServiceArgs = {
  input: UpdateMeetingServiceInput;
};


export type MutationUpdateMeetingServiceRequestStatusArgs = {
  input: UpdateMeetingServiceRequestStatusInput;
};


export type MutationUpdateMyReservationDeskAndTimeFrameArgs = {
  input: UpdateMyReservationDeskAndTimeFrameInput;
};


export type MutationUpdatePassArgs = {
  input?: InputMaybe<PassInput>;
};


export type MutationUpdatePassForPlacesArgs = {
  input?: InputMaybe<MultiPlacePassInput>;
};


export type MutationUpdateReservationVisibilityArgs = {
  reservationId: Scalars['ID']['input'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationUpdateStandardWorkingHoursForLocationArgs = {
  locationId: Scalars['ID']['input'];
  workingHours: Array<WorkingHoursInput>;
};


export type MutationUpdateSurveyConfigurationArgs = {
  allowAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  surveyId: Scalars['ID']['input'];
};


export type MutationUpdateWorkAreasArgs = {
  input: UpdateWorkAreasInput;
  levelId: Scalars['ID']['input'];
};


export type MutationUpdateWorkPolicyArgs = {
  workPolicy: WorkPolicyInput;
  workPolicyId: Scalars['String']['input'];
};


export type MutationViewAnnouncementArgs = {
  announcementId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};


export type MutationViewAnnouncementForMultipleLocationsArgs = {
  announcementId: Scalars['ID']['input'];
  locationIds: Array<Scalars['ID']['input']>;
};


export type MutationVisitAddDocumentTaskArgs = {
  documentBody: Scalars['DocumentBody']['input'];
  documentName: Scalars['String']['input'];
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  notificationEmails?: InputMaybe<Array<Scalars['WorkflowEmail']['input']>>;
  visitTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationVisitArchiveDocumentArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationVisitAsyncRequestStatusReportArgs = {
  errorText?: InputMaybe<Array<Scalars['String']['input']>>;
  status: VisitAsyncRequestReport;
  visitId: Scalars['ID']['input'];
};


export type MutationVisitChangeDocumentTaskAssociationsArgs = {
  documentId: Scalars['ID']['input'];
  locationIds: Array<Scalars['ID']['input']>;
  notificationEmails: Array<Scalars['WorkflowEmail']['input']>;
  visitTypes: Array<Scalars['String']['input']>;
};


export type MutationVisitChangeDocumentTaskAssociationsWithCustomVisitTypeArgs = {
  documentId: Scalars['ID']['input'];
  locationIds: Array<Scalars['ID']['input']>;
  notificationEmails: Array<Scalars['WorkflowEmail']['input']>;
  visitTypes: Array<Scalars['String']['input']>;
};

export type MyAutoDeskBookingResponse = {
  __typename?: 'MyAutoDeskBookingResponse';
  autoDeskBooking: Scalars['Boolean']['output'];
};

export type MyHomepageResponse = {
  __typename?: 'MyHomepageResponse';
  homepage: HomepageType;
};

export type MyLanguageTagResponse = {
  __typename?: 'MyLanguageTagResponse';
  languageTag?: Maybe<Scalars['String']['output']>;
};

export type MyShowWeekendsResponse = {
  __typename?: 'MyShowWeekendsResponse';
  showWeekends: Scalars['Boolean']['output'];
};

export type NextAvailableMeta = {
  __typename?: 'NextAvailableMeta';
  end?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export enum NotificationPreferenceKey {
  WhosInDigestEmail = 'WHOS_IN_DIGEST_EMAIL'
}

export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  isSubscribedToWhosInDigestEmail: Scalars['Boolean']['output'];
};

export enum NotificationSettingType {
  Email = 'EMAIL',
  Push = 'PUSH',
  Slack = 'SLACK',
  Teams = 'TEAMS'
}

export type OfficeAccess = {
  __typename?: 'OfficeAccess';
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  campus?: Maybe<Campus>;
  groups: Array<Group>;
  healthCheckpointRequired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  location?: Maybe<Location>;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  organization?: Maybe<Organization>;
  permissions: OfficeAccessPermissions;
  /** @deprecated Deprecated in favor of places */
  place?: Maybe<AccessPlace>;
  places?: Maybe<Array<Maybe<AccessPlace>>>;
  resolvedPlaces?: Maybe<Array<Maybe<AccessPassResolvedPlaces>>>;
  schedule: Array<Scalars['String']['output']>;
  /** @deprecated Deprecated in favor of id */
  slug?: Maybe<Scalars['String']['output']>;
  theme: Theme;
  users: Array<User>;
};


export type OfficeAccessGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OfficeAccessPermissions = {
  __typename?: 'OfficeAccessPermissions';
  canManage: Scalars['Boolean']['output'];
  canView: Scalars['Boolean']['output'];
};

export enum OnsiteType {
  OnSiteConfirmation = 'ON_SITE_CONFIRMATION',
  RemoteConfirmation = 'REMOTE_CONFIRMATION',
  Scheduled = 'SCHEDULED'
}

export type OnsitesLimit = {
  ceiling?: InputMaybe<Scalars['Int']['input']>;
  floor?: InputMaybe<Scalars['Int']['input']>;
  strategy: LimitStrategy;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export enum OperatingSystem {
  Android = 'ANDROID',
  Ios = 'IOS'
}

/** Specifies the direction of ordering for query results. */
export enum OrderDirection {
  /** Ascending order (A-Z, 0-9, false-true) */
  Asc = 'ASC',
  /** Descending order (Z-A, 9-0, true-false) */
  Desc = 'DESC'
}

export enum Ordinality {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organization = {
  __typename?: 'Organization';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  campuses: Array<Campus>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  /**
   * Total number of booked desks within the organization
   * @deprecated Deprecated in favor of deskSchedulingAggregates
   */
  deskBookedTotal: Scalars['Int']['output'];
  /** Total desks within the organization that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  /**
   * Total number of unavailable desks within the organization
   * @deprecated Deprecated in favor of deskSchedulingAggregates
   */
  deskUnavailableTotal: Scalars['Int']['output'];
  features?: Maybe<OrganizationFeatures>;
  /** Indicates if organization has active locations (meaning any locations added to an org). */
  hasActiveLocations: Scalars['Boolean']['output'];
  /** Indicates if organization has at least one active map (floorplan). */
  hasActiveMaps: Scalars['Boolean']['output'];
  /** Indicates if organization has uploaded/submitted at least a single map (floorplan). */
  hasUploadedMaps: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  locations: Array<Location>;
  logo?: Maybe<Scalars['String']['output']>;
  mapOnboardingStatus: OrganizationMapOnboardingStatus;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};


export type OrganizationLocationsArgs = {
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationExternalAccount = {
  __typename?: 'OrganizationExternalAccount';
  accessToken?: Maybe<Scalars['String']['output']>;
  accountId: Scalars['ID']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type OrganizationFeatureLimits = {
  __typename?: 'OrganizationFeatureLimits';
  activatedUsers: MeteredFeature;
  activeUsers: MeteredFeature;
  bookableSeats: MeteredFeature;
  bookableSpaces: MeteredFeature;
  campuses: MeteredFeature;
  exportableDataDays: MeteredFeature;
  invitedUsers: MeteredFeature;
  levels: MeteredFeature;
  locations: MeteredFeature;
  ripplingUsers: MeteredFeature;
  viewableDataDays: MeteredFeature;
  visitorManagementLocations: MeteredFeature;
  workplaceServicesLocations: MeteredFeature;
};

export type OrganizationFeatures = {
  __typename?: 'OrganizationFeatures';
  adminOnlyBooking: Scalars['Boolean']['output'];
  amenities: Scalars['Boolean']['output'];
  announcements: Scalars['Boolean']['output'];
  billingUIV1: Scalars['Boolean']['output'];
  /** @deprecated Use `limits` instead */
  bookableSeatLimit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `limits` instead */
  bookableSpaceLimit?: Maybe<Scalars['Int']['output']>;
  campuses: Scalars['Boolean']['output'];
  customLogo: Scalars['Boolean']['output'];
  customRoles: Scalars['Boolean']['output'];
  customThemes: Scalars['Boolean']['output'];
  documentAgreements: Scalars['Boolean']['output'];
  /** @deprecated Use `limits` instead */
  exportableDataLimitDays?: Maybe<Scalars['Int']['output']>;
  hotSeatReservations: Scalars['Boolean']['output'];
  hoteledSeatReservations: Scalars['Boolean']['output'];
  insights: Scalars['Boolean']['output'];
  insightsExportsApi: Scalars['Boolean']['output'];
  issueReporting: Scalars['Boolean']['output'];
  levels: Scalars['Boolean']['output'];
  limits: OrganizationFeatureLimits;
  maps: Scalars['Boolean']['output'];
  meetingApprovals: Scalars['Boolean']['output'];
  meetingServices: Scalars['Boolean']['output'];
  meridianMapping: Scalars['Boolean']['output'];
  noPersonalCalendars: Scalars['Boolean']['output'];
  overProvisioning: Scalars['Boolean']['output'];
  peopleDirectory: Scalars['Boolean']['output'];
  /** @deprecated Use `prioritySupport` instead. */
  premierSupport: Scalars['Boolean']['output'];
  prioritySupport: Scalars['Boolean']['output'];
  reverseHoteling: Scalars['Boolean']['output'];
  ripplingIntegration: Scalars['Boolean']['output'];
  samlAuthentication: Scalars['Boolean']['output'];
  scim: Scalars['Boolean']['output'];
  statusBoards: Scalars['Boolean']['output'];
  /** @deprecated Use `limits` instead */
  userLimit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `limits` instead */
  viewableDataLimitDays?: Maybe<Scalars['Int']['output']>;
  visitorManagement: Scalars['Boolean']['output'];
  workweek: Scalars['Boolean']['output'];
};

export type OrganizationIdHashResponse = {
  __typename?: 'OrganizationIdHashResponse';
  hash?: Maybe<Scalars['String']['output']>;
};

export type OrganizationLocation = {
  __typename?: 'OrganizationLocation';
  locationId?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
};

/** Indicates the status for map upload onboarding. It also provides related entities which help drive the UIs. */
export type OrganizationMapOnboardingStatus = {
  __typename?: 'OrganizationMapOnboardingStatus';
  level?: Maybe<Level>;
  /** This location will be the one that has a level, if organization has a level at all. */
  location?: Maybe<Location>;
  shouldShowSetup: Scalars['Boolean']['output'];
};

export enum OwnerType {
  Seat = 'SEAT',
  Space = 'SPACE',
  Unassigned = 'UNASSIGNED'
}

export type PagedApiUserResult = {
  __typename?: 'PagedApiUserResult';
  hasNextPage: Scalars['Boolean']['output'];
  nextPageOffset?: Maybe<Scalars['Int']['output']>;
  users: Array<User>;
  usersTotal: Scalars['Int']['output'];
};

export type PagedPaginationResponse = {
  __typename?: 'PagedPaginationResponse';
  hasMore: Scalars['Boolean']['output'];
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type PagedUserFilterInput = {
  favoriteFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hasActivity?: InputMaybe<Scalars['Boolean']['input']>;
  preferredLocationFilter?: InputMaybe<Scalars['ID']['input']>;
  userNameFilter?: InputMaybe<Scalars['String']['input']>;
};

export type PagedUserResult = {
  __typename?: 'PagedUserResult';
  accountUsers: Array<AccountUser>;
  accountUsersTotal: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  nextPageOffset: Scalars['Int']['output'];
  users: Array<User>;
};

export type PagedUserResultV2 = {
  __typename?: 'PagedUserResultV2';
  accountUsers: Array<AccountUser>;
  accountUsersTotal: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  nextPageOffset: Scalars['Int']['output'];
};

export type PaginatedUnhealthyCalendarsForNotificationsResponse = {
  __typename?: 'PaginatedUnhealthyCalendarsForNotificationsResponse';
  cursor?: Maybe<Scalars['ID']['output']>;
  orgCalendars: Array<GroupedUnhealthyCalendarOrg>;
};

export type ParsedDeliveryText = {
  __typename?: 'ParsedDeliveryText';
  matchedUsers: Array<User>;
  parsedRecipients: Array<Scalars['String']['output']>;
};

export enum ParticipationStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  NotResponded = 'NOT_RESPONDED',
  Tentative = 'TENTATIVE',
  Unknown = 'UNKNOWN'
}

export type PassInput = {
  groups: Array<Scalars['ID']['input']>;
  healthCheckpointRequired?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  place: PassPlaceInput;
  schedule: Array<Scalars['String']['input']>;
  userChanges?: InputMaybe<PassUsersInput>;
};

export type PassPlaceInput = {
  id: Scalars['ID']['input'];
  type: AccessPlaceType;
};

export type PassUsersInput = {
  added?: InputMaybe<Array<Scalars['ID']['input']>>;
  removed?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Permission = {
  __typename?: 'Permission';
  name: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export enum PermissionAction {
  Book = 'BOOK'
}

export enum PermissionHierarchyTarget {
  Campus = 'CAMPUS',
  Level = 'LEVEL',
  Location = 'LOCATION',
  Organization = 'ORGANIZATION',
  ResourceGroup = 'RESOURCE_GROUP',
  ResourceInstance = 'RESOURCE_INSTANCE'
}

export type PermissionScope = {
  __typename?: 'PermissionScope';
  allowedLocationIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** @deprecated No longer supported */
  allowedOrgIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type PinnedUserSpaceEntity = {
  __typename?: 'PinnedUserSpaceEntity';
  locationId: Scalars['String']['output'];
  spaceIds: Array<Scalars['String']['output']>;
};

export type PlaceAvailability = {
  __typename?: 'PlaceAvailability';
  status: PlaceAvailabilityType;
  unavailableReasons: Array<PlaceUnavailableReason>;
};

export enum PlaceAvailabilityType {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export enum PlaceType {
  Location = 'location'
}

export enum PlaceUnavailableReason {
  /** The location is closed according to its working hours */
  BuildingClosed = 'BUILDING_CLOSED',
  /** The maxiumum number of flexible desks have been booked in this location */
  MaxCapacityReached = 'MAX_CAPACITY_REACHED',
  /** The user doesn't have a pass */
  NoAccess = 'NO_ACCESS',
  /** The user has a pass but it doesn't grant access to the location */
  NoLocationAccess = 'NO_LOCATION_ACCESS',
  /** The user has a pass to the location, but it's not valid during the specified start and end times */
  ScheduleViolation = 'SCHEDULE_VIOLATION'
}

export type Policy = {
  __typename?: 'Policy';
  is_allowed: Scalars['Boolean']['output'];
  object_id: Scalars['String']['output'];
  object_type: Scalars['String']['output'];
  permission_operation: Scalars['String']['output'];
};

export type PolicyEnabledWithDurationCommand = {
  duration?: InputMaybe<Scalars['ISO8601_Duration']['input']>;
  enabled: Scalars['Boolean']['input'];
};

export type PolicyEnabledWithDurationType = {
  __typename?: 'PolicyEnabledWithDurationType';
  duration?: Maybe<Scalars['ISO8601_Duration']['output']>;
  enabled: Scalars['Boolean']['output'];
};

export enum PolicyType {
  AbandonedResourceProtection = 'ABANDONED_RESOURCE_PROTECTION',
  AdvancedBookingThreshold = 'ADVANCED_BOOKING_THRESHOLD',
  AdvanceCheckInWindow = 'ADVANCE_CHECK_IN_WINDOW',
  AllowOverlappingBookings = 'ALLOW_OVERLAPPING_BOOKINGS',
  BookingPrivacy = 'BOOKING_PRIVACY',
  LocalCheckInOnly = 'LOCAL_CHECK_IN_ONLY',
  MaxBookingLength = 'MAX_BOOKING_LENGTH',
  PreventBookingFromKiosk = 'PREVENT_BOOKING_FROM_KIOSK',
  Recurrence = 'RECURRENCE',
  RequireCheckIn = 'REQUIRE_CHECK_IN',
  ReservationType = 'RESERVATION_TYPE',
  ResourceSharing = 'RESOURCE_SHARING',
  RestrictBookingToWorkingHours = 'RESTRICT_BOOKING_TO_WORKING_HOURS'
}

export type PrebuiltSigmaWorkbooksMap = {
  __typename?: 'PrebuiltSigmaWorkbooksMap';
  collab_score_demo: Scalars['String']['output'];
  department_usage: Scalars['String']['output'];
  desk_bookings: Scalars['String']['output'];
  desk_bookings_advanced: Scalars['String']['output'];
  desk_cancellations: Scalars['String']['output'];
  desk_checkins: Scalars['String']['output'];
  executive_insights_aa: Scalars['String']['output'];
  executive_insights_general: Scalars['String']['output'];
  inventory: Scalars['String']['output'];
  landing_page: Scalars['String']['output'];
  office_density: Scalars['String']['output'];
  people_roster: Scalars['String']['output'];
  presence: Scalars['String']['output'];
  space_bookings: Scalars['String']['output'];
  space_bookings_advanced: Scalars['String']['output'];
  space_cancellations: Scalars['String']['output'];
  space_checkins: Scalars['String']['output'];
};

export type PrimaryEmail = {
  __typename?: 'PrimaryEmail';
  email?: Maybe<Scalars['String']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
};

export type PropertiesInput = {
  decorId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
  ownerType: Scalars['String']['input'];
  placedOnMap: Scalars['Boolean']['input'];
  updated: Scalars['Boolean']['input'];
  zoneId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ProxiedEventResponseStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Tentative = 'TENTATIVE',
  Unknown = 'UNKNOWN'
}

export type PublicArrivalDisplay = {
  __typename?: 'PublicArrivalDisplay';
  id: Scalars['ID']['output'];
  location: PublicLocationOrDefault;
  name: Scalars['String']['output'];
  organization: PublicOrganization;
};

export type PublicGuest = {
  __typename?: 'PublicGuest';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type PublicLocationOrDefault = {
  __typename?: 'PublicLocationOrDefault';
  address?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHealthCheckpointRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

export type PublicOrganization = {
  __typename?: 'PublicOrganization';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type PublicUserWithDefault = {
  __typename?: 'PublicUserWithDefault';
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PublicUserWithEmail = {
  __typename?: 'PublicUserWithEmail';
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** PublicVisitResult represents the visit for a single GuestInvite. The result is "public" because it is available to the public via Arrival Display kiosk devices. A PublicVisitResult is the guest (GuestInvite) along with information about their associated Visit. */
export type PublicVisitResult = {
  __typename?: 'PublicVisitResult';
  arrivalInstructions?: Maybe<Scalars['String']['output']>;
  arrivalLocation: PublicLocationOrDefault;
  arrivalLocationId: Scalars['String']['output'];
  checkinOn?: Maybe<Scalars['String']['output']>;
  checkoutOn?: Maybe<Scalars['String']['output']>;
  customVisitType: Scalars['String']['output'];
  guest: PublicGuest;
  healthCheckpointSurveyResponse?: Maybe<GenericHealthCheckpointResponse>;
  host: PublicUserWithEmail;
  /** The ID of the GuestInvite */
  id: Scalars['ID']['output'];
  inviteCustomFields?: Maybe<Array<CustomField>>;
  organization: PublicOrganization;
  startTime: Scalars['ISODateTime']['output'];
  status: GuestInviteStatus;
  visitCustomFields?: Maybe<Array<CustomField>>;
  /** The ID of the associated visit */
  visitId: Scalars['ID']['output'];
  /** @deprecated replaced by the more flexible customVisitType */
  visitType: VisitType;
};

export enum Query_Visit_Document_Sort_By {
  AddedOn = 'ADDED_ON',
  Name = 'NAME',
  Status = 'STATUS'
}

export enum Query_Workflow_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type Query = {
  __typename?: 'Query';
  /** Allows arrival displays to self-rescue. If you know, you know. Vague by design. */
  arrivalDisplaySelfRescue: Array<Scalars['String']['output']>;
  autoDeskBookingAttemptedForLocalDate: Array<AutoDeskBookingAttemptedResponseType>;
  /** Returns a array of AutoDeskBookingScheduleResponse based on the users schedule. It will search the full week starting from Sunday through Saturday and return the dates only in the users schedule and the auto desk booking status for that date, */
  autoDeskBookingAttemptsForUserSchedule: Array<AutoDeskBookingScheduleResponse>;
  /** Badge printer IP address */
  badgePrinterIPAddress: Scalars['String']['output'];
  canUserDelegateDesk: Scalars['Boolean']['output'];
  checkOrganizationNameAvailability?: Maybe<CheckOrganizationNameAvailabilityResponse>;
  checkUserEmails: EmailCheckResponse;
  /** Requires permission to manage services in at least one place. Can use with listMeetingServiceRequests to get total result count for pagination. */
  countMeetingServiceRequests: CountMeetingServiceRequestsResponse;
  /** Requires permission to manage services in at least one place. Can use with listMeetingServices to get total result count for pagination. */
  countMeetingServices: CountMeetingServicesResponse;
  countMeetingServicesCategories: CountMeetingServicesCategoriesResponse;
  /** Total count of filtered visit documents */
  countVisitFilteredDocuments: Scalars['Int']['output'];
  /** Provides the policy document configuration for a resource, or default if not configured */
  customResourcePolicies: CustomResourcePolicyDocument;
  /** Query for reservations for a set of custom resources over a range of dates */
  customResourceReservations: CustomResourceReservationsRangeResponseType;
  /** Provides the policy document configuration for many resource, or default if not configured */
  customResourcesPolicies: Array<CustomResourcePolicyDocument>;
  /** Provides the default policy configuration for all new robin custom resource templates */
  defaultRobinCustomResourcePolicies: CustomResourcePolicyDocument;
  /** Tells you if the current user is allowed to report issues on a desk */
  deskAllowsIssueReports: ResourceAllowsIssueReportsResponse;
  doIHaveACalendar?: Maybe<Scalars['Boolean']['output']>;
  employeeVisit: EmployeeVisitQueries;
  getAccountUsersByIds?: Maybe<Array<Maybe<AccountUser>>>;
  /** Get all active announcements for a given location, within the specific location context & for current user. (e.g. viewedAt - when has the current user first viewed this announcement at provided location) */
  getActiveAnnouncementsByLocationId: Array<AnnouncementForLocation>;
  /** Get all active announcements for given locations, within the specific location context & for current user. (e.g. viewedAt - when has the current user first viewed this announcement at provided location) */
  getActiveAnnouncementsByLocationIds: Array<AnnouncementForLocation>;
  getActiveDistributionsByAuthorId: Array<Maybe<AnnouncementDistributionForLocation>>;
  getActiveDistributionsByOrganizationId: Array<Maybe<AnnouncementDistributionForLocation>>;
  /** Get list of active visit types for org */
  getActiveVisitTypesForOrg: Array<Scalars['String']['output']>;
  getActivitiesSummaryForTimeRangeByLocationIds: Array<DailyActivitiesSummary>;
  /** Gets the latest messages for activity chat. Window from latest message ID. Retrieves messages newer than the referenced message. */
  getActivityChatNewerMessages: Array<ChatMessage>;
  /** Returns the set of message IDs in chat that have been removed after the specified timestamp. */
  getActivityChatRemovedMessagesSince: Array<Scalars['ID']['output']>;
  /** Gets the latest messages for activity chat. Optional parameter @messagesPriorTo is the identity of the earliest ChatMessage of which the client is aware. Providing @messagesPriorTo retrieves the previous page of messages. Default @pageSize is 5. */
  getActivityChatTail: Array<ChatMessage>;
  getActivityForCurrentUserById: ActivityForCurrentUser;
  getAllActivitiesForDateByLocationIds: Array<ActivitiesByLocation>;
  getAllJoinedUsersByActivityId: Array<Maybe<User>>;
  getAllOrganizationLocations: Array<Maybe<OrganizationLocation>>;
  /** Returns filtered view of all visit data for the organization specified in the tenant ID header */
  getAllVisits: Array<Visit>;
  getAmenitiesByIds: Array<Amenity>;
  getAmenityById?: Maybe<Amenity>;
  getAnalyticsAuthorizationToken: AnalyticsAuthorizationResponse;
  /** Get a single announcement by ID */
  getAnnouncementById?: Maybe<Announcement>;
  /** Get a single announcement as type AnnouncementForLocation by ID */
  getAnnouncementForLocationById?: Maybe<AnnouncementForLocation>;
  getAnnouncementViewAcknowledgeCount?: Maybe<AnnouncementViewAcknowledgeCount>;
  /** Get all the announcements for an organization, filterable by location id and status */
  getAnnouncements: Array<Maybe<Announcement>>;
  getAnyDeskById?: Maybe<Desk>;
  getAnyDesksByIds: Array<Desk>;
  /** Get a list of arrival displays registered to the organization of the requesting user */
  getArrivalDisplay?: Maybe<ArrivalDisplay>;
  /** Arrival display settings (badge printer IP address, guest image capture enabled, guest self-registration enabled) */
  getArrivalDisplaySettings: ArrivalDisplaySettings;
  /** Get a list of arrival displays registered to the organization of the requesting user */
  getArrivalDisplays: Array<ArrivalDisplay>;
  getAuthedTeamUsersByName: Array<User>;
  /** Gets the auto desk booking setting for current (user) actor */
  getAutoDeskBooking: MyAutoDeskBookingResponse;
  /** Gets the auto desk booking setting for user */
  getAutoDeskBookingByUserId: AutoDeskBookingResponse;
  getAutoDeskBookingEnabledConfiguration: Array<AutoDeskBookingInternalResponse>;
  getAutoDeskBookingForOrg: AutoDeskBookingOrgResponse;
  getAvailableFloors: Array<Level>;
  /** Gets the status of a given badge CSV upload. */
  getBadgeCSVUploadStatus: GetBadgeCsvUploadStatusResponse;
  /** Gets a list of CSV uploads for the current tenant. */
  getBadgeCSVUploadsForCurrentTenant: GetBadgeCsvUploadsForCurrentTenantResponse;
  /** Gets a single badge printer via its id */
  getBadgePrinter?: Maybe<BadgePrinter>;
  /** Get a list of badge printers registered to the organization of the requesting user */
  getBadgePrinters: Array<BadgePrinter>;
  /** Get a badge printers for organization by locationId */
  getBadgePrintersByLocationId: Array<BadgePrinter>;
  getBookingWindowPoliciesById: Array<Maybe<BookingWindowPolicy>>;
  getBookingWindowPoliciesForLocation: Array<BookingWindowPolicy>;
  getBookingWindowPolicyById?: Maybe<BookingWindowPolicy>;
  /** Checks the Brivo account for current tenant */
  getBrivoAccountStatusForCurrentTenant: GetBrivoAccountStatusForCurrentTenant;
  /** Gets a list of Brivo locks for current tenant. */
  getBrivoLocksForCurrentTenant: GetBrivoLocksForCurrentTenant;
  /** Get view count & acknowledge count for each given announcement ID */
  getBulkAnnouncementViewAcknowledgeCount: Array<AnnouncementViewAcknowledgeCountWithId>;
  getCampusById?: Maybe<Campus>;
  getCampusesByIds: Array<Campus>;
  /** Return a list of visits given a guest's partial email */
  getCheckedInVisitsByPartialEmail: Array<PublicVisitResult>;
  getCoverImagesForActivities: ActivityCoverImagesObject;
  /** Query to determine if current actor's permissions with regards to badge domain. */
  getCurrentActorBadgePermissionsForCurrentTenant: GetCurrentActorBadgePermissionsForCurrentTenantReponse;
  getCurrentUserAndOrgs?: Maybe<CurrentUserAndOrgs>;
  /** Query custom resource templates by organization ID */
  getCustomResourceTemplates: Array<CustomResourceTemplate>;
  /** Used in conjunction with getCustomVisitForms for pagination */
  getCustomVisitFormCount?: Maybe<Scalars['Int']['output']>;
  getCustomVisitFormLocations?: Maybe<CustomVisitFormLocationResponse>;
  getCustomVisitForms?: Maybe<Array<CustomVisitForm>>;
  getDeliveries: Array<Delivery>;
  getDeliveriesCount: Scalars['Int']['output'];
  getDeliveriesEnabledLocations: Array<DeliveryLocation>;
  /** Returns locations that the user is able to view and manage */
  getDeliveriesPermittedLocations: DeliveriesPermittedLocations;
  getDeliveryById?: Maybe<Delivery>;
  getDeliveryZoneCount: Scalars['Int']['output'];
  getDeliveryZones: Array<DeliveryZone>;
  getDeskAvailabilityByLevelIdForMe: DeskAvailabilityByLevelId;
  getDeskById?: Maybe<Desk>;
  getDeskExclusionById?: Maybe<Exclusion>;
  getDeskExclusionsByIds: Array<Exclusion>;
  getDeskReservationById?: Maybe<Reservation>;
  getDeskReservationInstancesById: Array<Reservation>;
  getDeskReservationsByIds: Array<Reservation>;
  getDeskReservationsByLevelId: LevelDeskReservation;
  getDeskReservationsByLocationId: LocationDeskReservation;
  getDeskReservationsBySeatId: Array<Reservation>;
  getDeskReservationsBySpaceIds: Array<ReservationsBySpacesForStatusBoard>;
  getDeskReservationsByUserId: UserDeskReservation;
  getDeskReservationsByUserIds: Array<UserDeskReservation>;
  getDeskReservationsByZoneId: ZoneDeskReservation;
  /** Query reservations for a set of users between two moments at a specific location. Results are grouped by date relative to the location ISO time offset. */
  getDeskReservationsForUsersAtLocationGroupedByDateAscending: DateGroupedUserReservations;
  /** Query reservations for a set of users between two moments. Results are grouped by date relative to the requesting users ISO time offset. */
  getDeskReservationsForUsersGroupedByDateAscending: DateGroupedUserReservations;
  getDesksByIds: Array<Desk>;
  getDesksWithAssigneesByLevelId: Array<DeskWithAssignees>;
  getDisabledDeskById?: Maybe<Desk>;
  getDisabledDesksByIds: Array<Desk>;
  getDistributionById: AnnouncementDistribution;
  getDistributionsByAnnouncementId: Array<AnnouncementDistribution>;
  getDraftCollaborators: Array<AccountUser>;
  getDraftConflicts: Array<DraftConflict>;
  getDraftStatus: DraftStatusResponse;
  getDraftsList: DraftPagingResult;
  /** Get all of an employee's visits during a specific time range at a specific location */
  getEmployeeVisitsAtLocationForEmployee: Array<EmployeeVisit>;
  /** Get all of the visits during a specific time range at a specific location for several employees */
  getEmployeeVisitsForUsersAtLocationGroupedByDateAscending: Array<EmployeeVisitsByDate>;
  getEventById?: Maybe<Event>;
  getEventsWithCheckInStatusForProxiedEventId: GetEventsWithCheckInStatusForProxiedEventIdReponse;
  getExternalEvents: Array<ExternalEvent>;
  /** Checks the Genea account for current tenant */
  getGeneaAccountForCurrentTenant: GetGeneaAccountForCurrentTenant;
  /** Gets a list of Genea doors for current tenant. */
  getGeneaDoorsForCurrentTenant: GetGeneaDoorsForCurrentTenant;
  /** Gets all Google buildings tied to the provided external account ID. */
  getGoogleBuildings?: Maybe<Array<Maybe<GoogleBuilding>>>;
  getGoogleWorkingLocationSyncSettingForOrg: Scalars['Boolean']['output'];
  /** Gets the sync google working locations setting user */
  getGoogleWorkingLocationSyncSettingForUser: GoogleWorkingLocationSyncSetting;
  getGroupsByKeyword: Array<Group>;
  getGuestInviteById?: Maybe<GuestInvite>;
  /** Returns a count of guest invites matching criteria */
  getGuestInviteCount: Scalars['Int']['output'];
  /** Returns filtered view of all guest invites */
  getGuestInvites: Array<GuestInvite>;
  /** Get a guest invite of a visit by its ID */
  getGuestVisitById?: Maybe<PublicVisitResult>;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByLocation: InOfficeData;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByLocationByDay: Array<InOfficeDataByDay>;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByUserIdsAndLocation: InOfficeData;
  getInitialWorkPolicyCreatedAt?: Maybe<Scalars['ISODateTime']['output']>;
  /** Gets a external integration by externalId */
  getIntegrationByExternalId?: Maybe<Array<Maybe<ExternalIntegration>>>;
  /** Gets a external integration by id */
  getIntegrationById?: Maybe<ExternalIntegration>;
  /** Gets a external integration by orgId */
  getIntegrationByOrgId?: Maybe<Array<Maybe<ExternalIntegration>>>;
  /** Checks the Kisi account for current tenant */
  getKisiAccountStatusForCurrentTenant: GetKisiAccountStatusForCurrentTenant;
  /** Gets a list of Kisi locks for current tenant. */
  getKisiLocksForCurrentTenant?: Maybe<GetKisiLocksForCurrentTenant>;
  /** Returns a legacy integration from ACL by its userId */
  getLegacyIntegrationByUserId?: Maybe<LegacyIntegration>;
  /** Returns if a legacy integration exists for the user by the given type and resource-type Tenant ID is also taken into account */
  getLegacyIntegrationExistsByType?: Maybe<Scalars['Boolean']['output']>;
  /** Returns a legacy integrations from ACL by it orgId */
  getLegacyIntegrationsByOrgId?: Maybe<Array<Maybe<LegacyIntegration>>>;
  getLevelById?: Maybe<Level>;
  getLevelsByIds: Array<Level>;
  getLocationById?: Maybe<Location>;
  getLocationByRemoteGoogleId?: Maybe<SimpleLocation>;
  getLocationsByIds: Array<Location>;
  /** Allows getting archived meeting services */
  getMeetingServiceById: GetMeetingServiceByIdResponse;
  getMeetingServiceRequestByIdentifier: GetMeetingServiceRequestByIdentifierResponse;
  getMicrosoftGraphEnabled: Scalars['Boolean']['output'];
  getMicrosoftGraphMigrationProgress?: Maybe<MigrationProgress>;
  getMicrosoftGraphMigrationReport?: Maybe<MigrationReportWithTotals>;
  getMyDefaultLocationOrFallback?: Maybe<Location>;
  getMyEventsInTheRange: Array<Event>;
  getMyEventsInTheRangeWithCheckInStatus: Array<Event>;
  getMyHomepage: MyHomepageResponse;
  /** Gets a language tag for current (user) actor */
  getMyLanguageTag: MyLanguageTagResponse;
  getMyTeamNames: Array<Scalars['String']['output']>;
  /** Returns the set of unacknowledged announcements for the requesting user for the given location. (Only PRIORITY announcements, as they can be acknowledged) */
  getMyUnacknowledgedActiveAnnouncementsByLocationId: Array<Announcement>;
  /** Returns the set of unacknowledged announcements for the requesting user for the given locations. (Only PRIORITY announcements, as they can be acknowledged) */
  getMyUnacknowledgedActiveAnnouncementsByLocationIds: Array<Announcement>;
  getMyWorkPolicyWithProgress?: Maybe<WorkPolicyWithProgress>;
  getOfficeAdmins: Array<User>;
  /** Checks the Openpath account for current tenant */
  getOpenpathAccountStatusForCurrentTenant: GetOpenpathAccountStatusForCurrentTenant;
  /** Gets a list of Openpath locks for current tenant. */
  getOpenpathLocksForCurrentTenant: GetOpenpathLocksForCurrentTenant;
  getOrSuggestMyDefaultLocationOrLevel?: Maybe<DefaultLocationAndLevel>;
  getOrganizationById?: Maybe<Organization>;
  /** Gets a legacy integration by externalId and type */
  getOrganizationByIntegration?: Maybe<ApiOrganizationExternalAccount>;
  getOrganizationExternalAccount?: Maybe<OrganizationExternalAccount>;
  /** Gets an encoded version of an organization ID */
  getOrganizationIdHash: OrganizationIdHashResponse;
  getOrganizationsByIds: Array<Organization>;
  getOrganizationsByUserEmail: Array<Maybe<MeOrganization>>;
  getPagedUsers: PagedUserResult;
  /** @deprecated Deprecated -- continue using getPagedUsers. This is being removed */
  getPagedUsersV2: PagedUserResult;
  /** Get all survey responses with pagination based on the filters By default returns only completed responses. */
  getPaginatedSurveyResponses?: Maybe<Array<Maybe<SurveyResponse>>>;
  getPaginatedSurveyResponsesTotalPageCount?: Maybe<Scalars['Int']['output']>;
  getPassesByOrganizationId: Array<OfficeAccess>;
  /** Get the most recent pending survey response for a user */
  getPendingSurveyResponseForUser?: Maybe<SurveyResponse>;
  getPinnedUserSpacesByLocationIds: Array<PinnedUserSpaceEntity>;
  /** Returns a name, id mapping of the prebuilt workbooks */
  getPrebuiltSigmaWorkbookIds?: Maybe<PrebuiltSigmaWorkbooksMap>;
  getRecipientById?: Maybe<Recipient>;
  getRecipients: Array<Recipient>;
  getReservationExclusions: Array<Exclusion>;
  getRipplingAccountState?: Maybe<GetRipplingAccountStateResponse>;
  getRipplingWorkLocationsAndExistingMappings?: Maybe<RipplingWorkLocationsWithMappings>;
  getRobinUserInfoBySlackId?: Maybe<RobinUserInfoForSlack>;
  getRole?: Maybe<Role>;
  getSeatIdByStickerShortCode?: Maybe<Scalars['ID']['output']>;
  /** @deprecated You should use getSharedDesksSchedules instead */
  getSharedDeskSchedule?: Maybe<SharedDeskSchedule>;
  getSharedDesksSchedules?: Maybe<Array<Maybe<SharedDeskSchedule>>>;
  /**
   * Returns the appropriate embed mode for the user
   * @deprecated Use userHasAdvancedAnalyticsManage instead
   */
  getSigmaEmbedType?: Maybe<SigmaEmbedType>;
  /** Constructs a Sigma embed URL */
  getSigmaEmbedUrl?: Maybe<SigmaEmbedUrl>;
  /** Lists all favorited Sigma workbooks for a user */
  getSigmaFavorites?: Maybe<Array<Maybe<SigmaFile>>>;
  /** Returns the appropriate embed mode for the user */
  getSigmaMemberId?: Maybe<Scalars['ID']['output']>;
  /** Get a Sigma workbook */
  getSigmaWorkbook?: Maybe<SigmaWorkbook>;
  /** Lists all Sigma workbooks accessable by a user */
  getSigmaWorkbooks?: Maybe<Array<Maybe<SigmaFile>>>;
  /** Get all of an employee's one-off visits during a specific time range within a specific org */
  getSingleDayEmployeeVisitsForEmployee: Array<EmployeeVisit>;
  getSpaceById?: Maybe<Space>;
  getSpaceCalendarsByOrgId: SpaceCalendarResponse;
  /** Returns user events which fall within the given time frame and which do not have a space associated with them. Also filters events which are not considered "meetings" and therefore do not warrent a space suggestion. Also makes sure the org has space suggestions enabled - if not, no events are returned. Also makes sure the specified building contains scheduled spaces. Finally, this also checks that the user has permission to modify the event, otherwise it will not be returned, as the use of this query is intended to inform the user that meetings are lacking spaces and to prompt them to add one. */
  getSpaceSuggestableEventsForUser: GetSpaceSuggestableEventsForUserResponse;
  getSpacesByIds: Array<Space>;
  getStickerBatchById?: Maybe<StickerBatch>;
  getStickerById?: Maybe<Sticker>;
  getStickerByShortCode?: Maybe<Sticker>;
  getStickersByOrgId: Array<Maybe<Sticker>>;
  getSuggestedDesksForUser: Array<SuggestedDesk>;
  getSuggestedSpacesEnabledForOrg: Scalars['Boolean']['output'];
  /** Given a set of event IDs and space filter criteria, return a suggested space matching that filter criteria for each event. All suggested spaces are simultaneously reservable by the current user. * All returned spaces are available for their event's timeframe. * If events overlap in time, we try our best to return distinct spaces per overlapping event, but can return null if impossible * No other policies (e.g. max duration) prevent reservation */
  getSuggestedSpacesForEvents: GetSuggestedSpacesForEventsResponse;
  /** This is a query which calls the same underlying suggested spaces algorithm as the above, but doesn't consider existing events when querying for suggested spaces. Every other condition is the same, and it uses the same datasource as the above query. Use this one if you don't have an event for which you are querying suggested spaces. */
  getSuggestedSpacesForTimeFrame: GetSuggestedSpacesForTimeFrameResponse;
  /** Get all survey configurations for an organization */
  getSurveyConfigurations?: Maybe<Array<Maybe<Survey>>>;
  /** Get summary of the ratings of all survey responses with the given filters */
  getSurveyResponseRatingSummary?: Maybe<SurveyResponseRatingSummary>;
  /** Get summary of the statuses of all survey responses with the given filters */
  getSurveyResponseStatusSummary?: Maybe<SurveyResponseStatusSummary>;
  /** Get all survey responses based on the criteria. By default returns only completed responses. */
  getSurveyResponses?: Maybe<Array<Maybe<SurveyResponse>>>;
  /** @deprecated Deprecated -- use getAuthedTeamUsersByName instead to avoid getting invalid favorites */
  getTeamUsersByName: Array<User>;
  /** Return associated tenant ID for a visit given a magic link */
  getTenantIdByMagicLink: Scalars['ID']['output'];
  getTimeZonesForRipplingAddresses: GetTimeZonesForRipplingAddressesResponse;
  /** Gets publicly available information about a arrival display iff that display has not yet been registered */
  getUnauthorizedArrivalDisplayDetails?: Maybe<PublicArrivalDisplay>;
  /** Fetches unhealthy calendars as a batch orgLimit is defaulted to 10 with a MAX of 10 */
  getUnhealthySpaceCalendarsForNotifications: PaginatedUnhealthyCalendarsForNotificationsResponse;
  /** Returns whether the current user has access to update the passed through events. This is currently used to see whether we should allow them to add a space to these events. All events should fall within the time window. */
  getUpdatePermissionsForEvents: GetUpdatePermissionsForEventsResponse;
  /** Get a static list of all supported access control vendors. */
  getUploadCSVVendorList: Array<Maybe<UploadCsvVendor>>;
  /** Check if a user has acknowledged an announcement in a given location */
  getUserAcknowledgementByAnnouncementId?: Maybe<AnnouncementAcknowledgement>;
  getUserAssignments?: Maybe<Array<Maybe<UserAssignment>>>;
  getUserById?: Maybe<User>;
  /** Returns the user's desk reservations and visits per day. Should not be used in conjunction with Who's In queries beacuse those will not count deactivate users in its queries but getUserInOfficeDataByDay() will return a result for a deactivated user Reason being is the reservations and visits is not removed on deactivation. */
  getUserInOfficeDataByDay: Array<UserInOfficeByDay>;
  /** Get the notification preferences for the given user */
  getUserNotificationPreferences?: Maybe<NotificationPreferences>;
  getUserNotificationSettings?: Maybe<Array<Maybe<UserNotificationsSetting>>>;
  getUserSetting?: Maybe<UserSetting>;
  getUserSyncStatus?: Maybe<UserSyncStatus>;
  getUsersByEmails: Array<User>;
  getUsersByIds: Array<User>;
  getUsersByKeyword: Array<User>;
  getUsersByRole: Array<User>;
  getUsersForTeams: Array<Maybe<TeamsUserSearchResult>>;
  getUsersWithPermissionOnHierarchyResource: PagedApiUserResult;
  /** Checks the Verkada account for current tenant */
  getVerkadaAccountForCurrentTenant: GetVerkadaAccountForCurrentTenant;
  /** Gets a list of Verkada locks for current tenant. */
  getVerkadaDoorsForCurrentTenant: GetVerkadaDoorsForCurrentTenant;
  /** Gets the state of an asynchronous visit request */
  getVisitAsyncRequestStatus: VisitAsyncRequestStatusResponse;
  /** Get a visit by its ID */
  getVisitById?: Maybe<Visit>;
  /** Return details for a visit given a magic link */
  getVisitByMagicLink?: Maybe<UnauthenticatedVisitResult>;
  /** Returns a count of all visits matching the specified search. */
  getVisitCount: Scalars['Int']['output'];
  /** Returns a user's visitor management permission scopes */
  getVisitorManagementPermissionScopes?: Maybe<VisitorManagementPermissionScopes>;
  /** Returns a user's visitor management permissions, contextualized to a location if an id is provided. */
  getVisitorManagementPermissions?: Maybe<VisitorManagementPermissions>;
  /** Return a list of visits given a guest's email */
  getVisitsByEmail: Array<PublicVisitResult>;
  /**
   * Get the waitlist and the status for current user.
   * @deprecated Use getWaitlistsForMe instead.
   */
  getWaitlistForMe: GetMyWaitlistResponse;
  /** Get the waitlists for current user, adjusted for given context. */
  getWaitlistsForMe: GetMyWaitlistsResponse;
  /** Retrieves onsite data for a specific location. Requires authentication and tenant ID header. */
  getWhoIsOnsiteByLocation: GetWhoIsOnsiteByLocationResponse;
  /** @deprecated Deprecated in favor of getWhosInDataByLocations */
  getWhosInDataByLocation: WhosInData;
  /** @deprecated Deprecated in favor of getWhosInDataByLocationsByDay */
  getWhosInDataByLocationByDay: Array<WhosInDataByDay>;
  getWhosInDataByLocationsByDay: Array<WhosInDataByDay>;
  getWhosInDataForLocations: WhosInData;
  getWorkAreaById?: Maybe<WorkArea>;
  getWorkAreasByLevel: Array<WorkArea>;
  getWorkPoliciesForOrganization: Array<WorkPolicy>;
  getWorkPolicyById?: Maybe<WorkPolicy>;
  /** Gets a single zone by its identifier */
  getZoneById?: Maybe<Zone>;
  /** Gets several zones by their identifiers */
  getZonesByIds: Array<Zone>;
  /** @deprecated Replaced with getInitialWorkPolicyCreatedAt for that check */
  hasVirtualWorkPolicy: Scalars['Boolean']['output'];
  /** Returns metadata of a conversation as well as the list of all external messages for display in the UI. This endpoint is intended to be periodically polled by the UI when a message is in the process of being fulfilled. */
  insightsAssistantConversationV1?: Maybe<InsightsAssistantConversationV1>;
  /** Returns a list of all conversations for a given organization. This is only available to support users or users in the Chimera team. */
  insightsAssistantConversationsForOrganizationV1: Array<InsightsAssistantConversationV1>;
  /** Returns a list of all conversations for the given user. Used to supply UI navigation components, similar to how we display Sigma workbooks. */
  insightsAssistantConversationsV1?: Maybe<Array<InsightsAssistantConversationV1>>;
  /** Returns feedback for a given message if it exists. */
  insightsAssistantFeedbackV1?: Maybe<InsightsAssistantFeedbackV1>;
  /** Returns a list of all messages for a given organization. This is only available to support users or users in the Chimera team. */
  insightsAssistantMessagesForOrganizationV1: Array<InsightsAssistantMessageV1>;
  /** Returns a list of all messages for a given conversation. */
  insightsAssistantMessagesV1: Array<InsightsAssistantMessageV1>;
  insightsPing: Scalars['String']['output'];
  /** get internal model for user org permissions for action */
  internalPermissionUserOrgAction?: Maybe<InternalPermissionUserOrgActions>;
  /** get internal model for resource identity. Null if not found. */
  internalResource?: Maybe<InternalResource>;
  /** Returns whether or not advanced analytics is enabled for the particular organization. Includes the EAP allow list to grant individual users access to Sigma. */
  isAdvancedAnalyticsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Returns if the organization is in trial mode. */
  isAdvancedAnalyticsTrial?: Maybe<Scalars['Boolean']['output']>;
  isAiEnabled: Scalars['Boolean']['output'];
  /** Is the arrival display paired? Returns false if the device has been removed. */
  isArrivalDisplayPaired: Scalars['Boolean']['output'];
  /** Checks if current tenant is integrated with Brivo. */
  isBrivoAccountConnectedForCurrentTenant: Scalars['Boolean']['output'];
  /** Feature flag check to determine if Brivo integration is enabled for current tenant. */
  isBrivoIntegrationEnabledForCurrentTenant: IsBrivoIntegrationEnabledForCurrentTenantReponse;
  /** Feature flag check to determine if CSV upload is enabled for current tenant. */
  isCSVUploadEnabledForCurrentTenant: IsCsvUploadEnabledForCurrentTenantReponse;
  /**
   * Returns whether or not custom analytics is enabled for the particular organization. Includes the EAP allow list to grant individual users access to Sigma.
   * @deprecated Use isAdvancedAnalyticsEnabled instead
   */
  isCustomAnalyticsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Returns if the organization is in trial mode.
   * @deprecated Use isAdvancedAnalyticsTrial instead
   */
  isCustomAnalyticsTrial?: Maybe<Scalars['Boolean']['output']>;
  isFavoritesEnabled: Scalars['Boolean']['output'];
  /** Is a feature available for a device's organization (guestUI, Arrival Display)? */
  isFeatureFlagEnabledForDeviceOrg: Scalars['Boolean']['output'];
  isFeatureFlagEnabledForOrgOrMe: Scalars['Boolean']['output'];
  /** Checks if current tenant is integrated with Genea. */
  isGeneaAccountConnectedForCurrentTenant: Scalars['Boolean']['output'];
  /** Feature flag check to determine if Genea integration is enabled for current tenant. */
  isGeneaIntegrationEnabledForCurrentTenant: IsGeneaIntegrationEnabledForCurrentTenantReponse;
  /** Is guest image capture enabled for a device? */
  isGuestImageCaptureEnabled: Scalars['Boolean']['output'];
  /** Is guest self-registration enabled for a device? */
  isGuestSelfRegistrationEnabled: Scalars['Boolean']['output'];
  /** Checks if current tenant is integrated with Kisi. */
  isKisiAccountConnectedForCurrentTenant: Scalars['Boolean']['output'];
  /** Feature flag check to determine if Kisi integration is enabled for current tenant. */
  isKisiIntegrationEnabledForCurrentTenant: IsKisiIntegrationEnabledForCurrentTenantReponse;
  /** Checks if current tenant is integrated with Openpath. */
  isOpenpathAccountConnectedForCurrentTenant: Scalars['Boolean']['output'];
  /** Feature flag check to determine if Openpath integration is enabled for current tenant. */
  isOpenpathIntegrationEnabledForCurrentTenant: IsOpenpathIntegrationEnabledForCurrentTenantReponse;
  /** Feature flag check to determine if Openpath integration supports lock provisioning. */
  isOpenpathLockProvisioningEnabledForCurrentTenant: IsOpenpathLockProvisioningEnabledForCurrentTenantResponse;
  isPassiveCheckInFeatureEnabledForBuilding: Scalars['Boolean']['output'];
  isRipplingAccountConnectedForOrganization: Scalars['Boolean']['output'];
  /** Gets the show weekend setting for current (user) actor */
  isShowWeekendsEnabledForMe: MyShowWeekendsResponse;
  /** Checks if current tenant is integrated with Verkada. */
  isVerkadaAccountConnectedForCurrentTenant: Scalars['Boolean']['output'];
  /** Feature flag check to determine if Verkada integration is enabled for current tenant. */
  isVerkadaIntegrationEnabledForCurrentTenant: IsVerkadaIntegrationEnabledForCurrentTenantReponse;
  /** Returns latest change date for all reservations indicating the latest reservation state change. If the returned timestamp changes in subsequent requests, it indicates that either a desk or space reservation has been created since last fetch. NULL response when no reservations exist for the specified level */
  levelChangeDetectionToken?: Maybe<Scalars['ISODateTime']['output']>;
  listBuildingCidrs: ListBuildingCidrsResponse;
  listBuildingsWhereUserCanManageMeetingServices: ListBuildingsWhereUserCanManageMeetingServicesResponse;
  /** Requires permission to manage services in at least one place */
  listMeetingServiceRequests: ListMeetingServiceRequestsResponse;
  /** Requires permission to manage services in at least one place */
  listMeetingServices: ListMeetingServicesResponse;
  /** For showing a user which meeting services can be requested for a certain space. */
  listMeetingServicesAvailableInSpace: ListMeetingServicesAvailableInSpaceResponse;
  /** Requires permission to manage services in at least one place */
  listMeetingServicesCategories: ListMeetingServicesCategoriesResponse;
  /** Requires permission to manage services in at least one place List out all assignees for meeting services that match the filter. Intended for use in a filter panel for meeting services, so you can see all possible assignees and select the ones with which to filter meeting services. */
  listUniqueAssigneesForMeetingServices: ListUniqueAssigneesForMeetingServicesResponse;
  /** Requires permission to manage services in at least one place. Intended for listing unique values for filters you can pass to listMeetingServiceRequests. */
  listUniqueMeetingServiceRequestValues: ListUniqueMeetingServiceRequestValuesResponse;
  parseDeliveryText: ParsedDeliveryText;
  /** Checks seats for multidate reservation availability. */
  reservationsMultiDayGroupedByDate: Array<DeskAvailabilityLocalizedDate>;
  /** Get resource details for individual resources by ID */
  resourceDetails: Array<CustomResource>;
  /** Get resource details for individual resources by floor ID */
  resourceDetailsByFloor: Array<CustomResource>;
  /** Request validation of a request for a single day reservation */
  resourceReservationSingleDayValidate: ResourceReservationValidationResponse;
  reviewDraft: ReviewDraftResponse;
  /** Tells you if the current user is allowed to report issues on a space */
  spaceAllowsIssueReports: ResourceAllowsIssueReportsResponse;
  /** Is the user part of an organization with AA enabled and does that user have analytics:manage permissions */
  userHasAdvancedAnalyticsManage?: Maybe<Scalars['Boolean']['output']>;
  /** Fetch visit document with agreement from user based on visit invite ID */
  visitDocumentAgreement: VisitDocumentAgreementResponse;
  /** Query for visit and document information associated with a decision ID (assumed to be document accept) */
  visitDocumentByDecisionId?: Maybe<VisitDocumentByDecisionResponse>;
  /** @deprecated Use visitDocumentByIdWithCustomVisitType instead */
  visitDocumentById?: Maybe<VisitDocumentResponse>;
  visitDocumentByIdWithCustomVisitType?: Maybe<VisitDocumentResponse>;
  /**
   * Query paged organizations documents available to workflows which can be applied to visits
   * @deprecated Use visitDocumentNameStatusAndTypesPageWithCustomVisitType instead
   */
  visitDocumentNameStatusAndTypesPage: Array<VisitDocumentNameStatusAndTypesQueryResponse>;
  /** Query paged organizations documents available to workflows which can be applied to visits */
  visitDocumentNameStatusAndTypesPageWithCustomVisitType: Array<VisitDocumentNameStatusAndTypesQueryResponse>;
  /**
   * Returns document agreement details for `documentID` or first document agreement if no `documentId` is specified. If response is NULL, no documents to agree with to for a visit.
   * @deprecated Use visitDocumentWithNextDocumentIdWithCustomVisitType instead
   */
  visitDocumentWithNextDocumentId?: Maybe<VisitDocumentAgreementWithNextDocumentResponse>;
  /** Returns document agreement details for `documentID` or first document agreement if no `documentId` is specified. If response is NULL, no documents to agree with to for a visit. */
  visitDocumentWithNextDocumentIdWithCustomVisitType?: Maybe<VisitDocumentAgreementWithNextDocumentResponse>;
  /**
   * Query for menu overview of documents required for a key pair of visit type and location ID.
   * @deprecated Use visitOverviewOfDocumentsWithCustomVisitType instead
   */
  visitOverviewOfDocuments?: Maybe<VisitWorkflowOverview>;
  /** Query for menu overview of documents required for a key pair of visit type and location ID. */
  visitOverviewOfDocumentsWithCustomVisitType?: Maybe<VisitWorkflowOverview>;
};


export type QueryAutoDeskBookingAttemptedForLocalDateArgs = {
  localStartDate: Scalars['LocalDate']['input'];
  locationId: Scalars['ID']['input'];
  numberOfDays: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryAutoDeskBookingAttemptsForUserScheduleArgs = {
  locationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryCanUserDelegateDeskArgs = {
  deskId: Scalars['ID']['input'];
  endTime: Scalars['ISODateTime']['input'];
  startTime: Scalars['ISODateTime']['input'];
};


export type QueryCheckOrganizationNameAvailabilityArgs = {
  name: Scalars['String']['input'];
};


export type QueryCheckUserEmailsArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type QueryCountMeetingServiceRequestsArgs = {
  input: CountMeetingServiceRequestsInput;
};


export type QueryCountMeetingServicesArgs = {
  input: CountMeetingServicesInput;
};


export type QueryCountMeetingServicesCategoriesArgs = {
  input: CountMeetingServicesCategoriesInput;
};


export type QueryCountVisitFilteredDocumentsArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
};


export type QueryCustomResourcePoliciesArgs = {
  organizationId: Scalars['ID']['input'];
  resourceId: Scalars['ID']['input'];
};


export type QueryCustomResourceReservationsArgs = {
  localRangeEndDate: Scalars['IsoDateOnlyString']['input'];
  localRangeStartDate: Scalars['IsoDateOnlyString']['input'];
  organizationId: Scalars['ID']['input'];
  resourceInstanceIds: Array<Scalars['ID']['input']>;
};


export type QueryCustomResourcesPoliciesArgs = {
  organizationId: Scalars['ID']['input'];
  resourceIds: Array<Scalars['ID']['input']>;
};


export type QueryDeskAllowsIssueReportsArgs = {
  deskId: Scalars['ID']['input'];
};


export type QueryGetAccountUsersByIdsArgs = {
  filterBy?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortByField?: InputMaybe<Array<InputMaybe<UserSortBy>>>;
  sortOrder?: InputMaybe<UserSortOrder>;
};


export type QueryGetActiveAnnouncementsByLocationIdArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryGetActiveAnnouncementsByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']['input']>;
};


export type QueryGetActiveDistributionsByAuthorIdArgs = {
  authorId: Scalars['ID']['input'];
  service?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetActiveDistributionsByOrganizationIdArgs = {
  organizationId: Scalars['ID']['input'];
  service?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetActiveVisitTypesForOrgArgs = {
  locationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetActivitiesSummaryForTimeRangeByLocationIdsArgs = {
  input: ActivitiesForTimeRangeByLocationIdsInput;
  pagingInput?: InputMaybe<ActivitiesForTimeRangeByLocationIdsPagingInput>;
};


export type QueryGetActivityChatNewerMessagesArgs = {
  activityID: Scalars['ID']['input'];
  messagesAfter: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetActivityChatRemovedMessagesSinceArgs = {
  activityID: Scalars['ID']['input'];
  since: Scalars['ISODateTime']['input'];
};


export type QueryGetActivityChatTailArgs = {
  activityID: Scalars['ID']['input'];
  messagesPriorTo?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetActivityForCurrentUserByIdArgs = {
  id: Scalars['ID']['input'];
  joinedLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllActivitiesForDateByLocationIdsArgs = {
  input?: InputMaybe<ActivitiesForDateByLocationIdsInput>;
};


export type QueryGetAllJoinedUsersByActivityIdArgs = {
  activityId: Scalars['ID']['input'];
};


export type QueryGetAllVisitsArgs = {
  dateFilter: DateFilterMandatoryInput;
  excludeCanceledVisits?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationIdFilter: Array<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAmenitiesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetAmenityByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAnalyticsAuthorizationTokenArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetAnnouncementByIdArgs = {
  announcementId: Scalars['ID']['input'];
};


export type QueryGetAnnouncementForLocationByIdArgs = {
  announcementId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};


export type QueryGetAnnouncementViewAcknowledgeCountArgs = {
  announcementId: Scalars['ID']['input'];
};


export type QueryGetAnnouncementsArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<AnnouncementStatus>;
};


export type QueryGetAnyDeskByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAnyDesksByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetArrivalDisplayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetArrivalDisplaysArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isLinkedWithBadgePrinter?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  seekKey?: InputMaybe<Scalars['ID']['input']>;
  sortKey?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAuthedTeamUsersByNameArgs = {
  teamName: Scalars['String']['input'];
};


export type QueryGetAutoDeskBookingByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetAvailableFloorsArgs = {
  buildingId: Scalars['ID']['input'];
};


export type QueryGetBadgeCsvUploadStatusArgs = {
  input: GetBadgeCsvUploadStatusInput;
};


export type QueryGetBadgeCsvUploadsForCurrentTenantArgs = {
  pagination?: InputMaybe<GetBadgeCsvUploadsForCurrentTenantPagination>;
};


export type QueryGetBadgePrinterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetBadgePrintersArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  seekKey?: InputMaybe<Scalars['ID']['input']>;
  sortKey?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetBadgePrintersByLocationIdArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryGetBookingWindowPoliciesByIdArgs = {
  locationId: Scalars['ID']['input'];
  policyIds: Array<Scalars['ID']['input']>;
};


export type QueryGetBookingWindowPoliciesForLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryGetBookingWindowPolicyByIdArgs = {
  locationId: Scalars['ID']['input'];
  policyId: Scalars['ID']['input'];
};


export type QueryGetBulkAnnouncementViewAcknowledgeCountArgs = {
  announcementIds: Array<Scalars['ID']['input']>;
};


export type QueryGetCampusByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCampusesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetCheckedInVisitsByPartialEmailArgs = {
  email: Scalars['String']['input'];
  includeCheckedOutVisits?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  minutesAfter?: InputMaybe<Scalars['Int']['input']>;
  minutesBefore?: InputMaybe<Scalars['Int']['input']>;
  resultLimiter?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCustomResourceTemplatesArgs = {
  organizationID: Scalars['ID']['input'];
};


export type QueryGetCustomVisitFormCountArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetCustomVisitFormsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomVisitFormOrderBy>;
};


export type QueryGetDeliveriesArgs = {
  filters?: InputMaybe<DeliveryFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DeliveriesOrderBy>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDeliveriesCountArgs = {
  filters?: InputMaybe<DeliveryFilterOptions>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetDeliveryByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetDeliveryZoneCountArgs = {
  filters?: InputMaybe<DeliveryZoneFilterOptions>;
};


export type QueryGetDeliveryZonesArgs = {
  filters?: InputMaybe<DeliveryZoneFilterOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetDeskAvailabilityByLevelIdForMeArgs = {
  filters?: InputMaybe<GetDeskAvailabilityByLevelIdFiltersInput>;
  scope: GetDeskAvailabilityByLevelIdScopeInput;
  viewOptions: GetDeskAvailabilityByLevelIdViewOptionsInput;
};


export type QueryGetDeskByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDeskExclusionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDeskExclusionsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetDeskReservationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDeskReservationInstancesByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDeskReservationsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetDeskReservationsByLevelIdArgs = {
  endTime: Scalars['Date']['input'];
  levelId: Scalars['ID']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
};


export type QueryGetDeskReservationsByLocationIdArgs = {
  endTime: Scalars['Date']['input'];
  locationId: Scalars['ID']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
};


export type QueryGetDeskReservationsBySeatIdArgs = {
  deskId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  startTime: Scalars['ISODateTime']['input'];
};


export type QueryGetDeskReservationsBySpaceIdsArgs = {
  endTime: Scalars['ISODateTime']['input'];
  spaceIds: Array<Scalars['ID']['input']>;
  startTime: Scalars['ISODateTime']['input'];
};


export type QueryGetDeskReservationsByUserIdArgs = {
  endTime: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  startTime: Scalars['Date']['input'];
};


export type QueryGetDeskReservationsByUserIdsArgs = {
  endTime: Scalars['Date']['input'];
  ids: Array<Scalars['ID']['input']>;
  startTime: Scalars['Date']['input'];
};


export type QueryGetDeskReservationsByZoneIdArgs = {
  endTime: Scalars['Date']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
  zoneId: Scalars['ID']['input'];
};


export type QueryGetDeskReservationsForUsersAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type QueryGetDeskReservationsForUsersGroupedByDateAscendingArgs = {
  endTime: Scalars['Date']['input'];
  ids: Array<Scalars['ID']['input']>;
  startTime: Scalars['Date']['input'];
};


export type QueryGetDesksByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetDesksWithAssigneesByLevelIdArgs = {
  levelId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetDisabledDeskByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDisabledDesksByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetDistributionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDistributionsByAnnouncementIdArgs = {
  announcementId: Scalars['ID']['input'];
};


export type QueryGetDraftCollaboratorsArgs = {
  draftId: Scalars['ID']['input'];
};


export type QueryGetDraftConflictsArgs = {
  draftId: Scalars['ID']['input'];
};


export type QueryGetDraftStatusArgs = {
  draftId: Scalars['ID']['input'];
};


export type QueryGetDraftsListArgs = {
  filters?: InputMaybe<DraftFiltersInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sortBy?: InputMaybe<DraftsSort>;
};


export type QueryGetEmployeeVisitsAtLocationForEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
  endTime: Scalars['ISODateTime']['input'];
  filterSetting?: InputMaybe<EmployeeVisitFilterSetting>;
  locationId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
};


export type QueryGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type QueryGetEventByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetEventsWithCheckInStatusForProxiedEventIdArgs = {
  externalAccountType: Scalars['String']['input'];
  proxiedEventId: Scalars['ID']['input'];
  timeContext: Scalars['String']['input'];
};


export type QueryGetExternalEventsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryGetGoogleBuildingsArgs = {
  externalAccountId: Scalars['String']['input'];
};


export type QueryGetGoogleWorkingLocationSyncSettingForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetGroupsByKeywordArgs = {
  keyword: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetGuestInviteByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetGuestInviteCountArgs = {
  dateFilter?: InputMaybe<DateFilterOptionalInput>;
  excludeCanceled?: InputMaybe<Scalars['Boolean']['input']>;
  locationIdFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<GuestInviteStatus>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetGuestInvitesArgs = {
  dateFilter?: InputMaybe<DateFilterOptionalInput>;
  excludeCanceled?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationIdFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<GuestInviteStatus>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetGuestVisitByIdArgs = {
  guestVisitId: Scalars['ID']['input'];
};


export type QueryGetInOfficeDataByLocationArgs = {
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
};


export type QueryGetInOfficeDataByLocationByDayArgs = {
  dateRanges: Array<DateRangeInput>;
  locationId: Scalars['ID']['input'];
};


export type QueryGetInOfficeDataByUserIdsAndLocationArgs = {
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type QueryGetIntegrationByExternalIdArgs = {
  externalId: Scalars['ID']['input'];
};


export type QueryGetIntegrationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetIntegrationByOrgIdArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryGetLegacyIntegrationByUserIdArgs = {
  resourceType: Scalars['String']['input'];
  type: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetLegacyIntegrationExistsByTypeArgs = {
  resourceType: LegacyIntegrationResourceType;
  type: LegacyIntegrationType;
};


export type QueryGetLegacyIntegrationsByOrgIdArgs = {
  orgId: Scalars['ID']['input'];
  resourceType: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryGetLevelByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLevelsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetLocationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLocationByRemoteGoogleIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLocationsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetMeetingServiceByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMeetingServiceRequestByIdentifierArgs = {
  identifier: MeetingServiceRequestIdentifierInput;
};


export type QueryGetMicrosoftGraphMigrationProgressArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetMicrosoftGraphMigrationReportArgs = {
  orderBy: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetMyEventsInTheRangeArgs = {
  endTime: Scalars['Date']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['Date']['input'];
};


export type QueryGetMyEventsInTheRangeWithCheckInStatusArgs = {
  endTime: Scalars['Date']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['Date']['input'];
  timeContext: Scalars['String']['input'];
};


export type QueryGetMyUnacknowledgedActiveAnnouncementsByLocationIdArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryGetMyUnacknowledgedActiveAnnouncementsByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']['input']>;
};


export type QueryGetMyWorkPolicyWithProgressArgs = {
  myTimezone: Scalars['String']['input'];
};


export type QueryGetOfficeAdminsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetOrganizationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetOrganizationByIntegrationArgs = {
  externalId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryGetOrganizationExternalAccountArgs = {
  externalId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrganizationIdHashArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetOrganizationsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetOrganizationsByUserEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetPagedUsersArgs = {
  filters?: InputMaybe<PagedUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryGetPagedUsersV2Args = {
  filters?: InputMaybe<PagedUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryGetPaginatedSurveyResponsesArgs = {
  filters: GetSurveyResponsesFilters;
  page: Scalars['Int']['input'];
  perPage: Scalars['Int']['input'];
  sortBy?: InputMaybe<GetSurveyResponsesSort>;
  surveyId: Scalars['ID']['input'];
};


export type QueryGetPaginatedSurveyResponsesTotalPageCountArgs = {
  filters: GetSurveyResponsesFilters;
  perPage: Scalars['Int']['input'];
  surveyId: Scalars['ID']['input'];
};


export type QueryGetPassesByOrganizationIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPendingSurveyResponseForUserArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPinnedUserSpacesByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']['input']>;
};


export type QueryGetRecipientByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetRecipientsArgs = {
  queryString?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetReservationExclusionsArgs = {
  endDate: Scalars['Date']['input'];
  reservationSeriesId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryGetRipplingAccountStateArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetRipplingWorkLocationsAndExistingMappingsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetRobinUserInfoBySlackIdArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryGetRoleArgs = {
  organizationId: Scalars['ID']['input'];
  roleSlug: Scalars['String']['input'];
};


export type QueryGetSeatIdByStickerShortCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetSharedDeskScheduleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSharedDesksSchedulesArgs = {
  deskIds: Array<Scalars['ID']['input']>;
};


export type QueryGetSigmaEmbedUrlArgs = {
  edit?: InputMaybe<Scalars['Boolean']['input']>;
  hideFolderNavigation?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  productionShadow?: InputMaybe<Scalars['Boolean']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
  workbookUrlId: Scalars['String']['input'];
};


export type QueryGetSigmaWorkbookArgs = {
  workbookId: Scalars['String']['input'];
};


export type QueryGetSingleDayEmployeeVisitsForEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
  endTime: Scalars['ISODateTime']['input'];
  startTime: Scalars['ISODateTime']['input'];
};


export type QueryGetSpaceByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSpaceCalendarsByOrgIdArgs = {
  filters?: InputMaybe<SpaceCalendarFiltersInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};


export type QueryGetSpaceSuggestableEventsForUserArgs = {
  buildingIdThatMustContainScheduledSpaces?: InputMaybe<Scalars['ID']['input']>;
  endTime: Scalars['ISODateTime']['input'];
  startTime: Scalars['ISODateTime']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetSpacesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryGetStickerBatchByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStickerByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetStickerByShortCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetStickersByOrgIdArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryGetSuggestedDesksForUserArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetSuggestedSpacesForEventsArgs = {
  input: GetSuggestedSpacesForEventsInput;
};


export type QueryGetSuggestedSpacesForTimeFrameArgs = {
  input: GetSuggestedSpacesForTimeFrameInput;
};


export type QueryGetSurveyResponseRatingSummaryArgs = {
  filters: GetSurveyResponsesFilters;
  surveyId: Scalars['ID']['input'];
};


export type QueryGetSurveyResponseStatusSummaryArgs = {
  filters: GetSurveyResponsesFilters;
  surveyId: Scalars['ID']['input'];
};


export type QueryGetSurveyResponsesArgs = {
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  inOfficeAtEnd: Scalars['ISODateTime']['input'];
  inOfficeAtStart: Scalars['ISODateTime']['input'];
  includeIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  ratings?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sortBy?: InputMaybe<GetSurveyResponsesSort>;
  surveyId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTeamUsersByNameArgs = {
  teamName: Scalars['String']['input'];
};


export type QueryGetTenantIdByMagicLinkArgs = {
  magicLink: Scalars['String']['input'];
};


export type QueryGetTimeZonesForRipplingAddressesArgs = {
  input: GetTimeZonesForRipplingAddressesInput;
};


export type QueryGetUnauthorizedArrivalDisplayDetailsArgs = {
  orgId: Scalars['ID']['input'];
  registrationCode: Scalars['ArrivalDisplayRegistrationKey']['input'];
};


export type QueryGetUnhealthySpaceCalendarsForNotificationsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  orgLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetUpdatePermissionsForEventsArgs = {
  endTime: Scalars['ISODateTime']['input'];
  eventIds: Array<Scalars['ID']['input']>;
  startTime: Scalars['ISODateTime']['input'];
};


export type QueryGetUserAcknowledgementByAnnouncementIdArgs = {
  announcementId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetUserAssignmentsArgs = {
  userIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUserInOfficeDataByDayArgs = {
  dateRanges: Array<DateRangeInput>;
  id: Scalars['ID']['input'];
};


export type QueryGetUserNotificationPreferencesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetUserNotificationSettingsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetUserSettingArgs = {
  settingSlug: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetUserSyncStatusArgs = {
  syncId: Scalars['ID']['input'];
};


export type QueryGetUsersByEmailsArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type QueryGetUsersByIdsArgs = {
  filterBy?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortByField?: InputMaybe<Array<InputMaybe<UserSortBy>>>;
  sortOrder?: InputMaybe<UserSortOrder>;
};


export type QueryGetUsersByKeywordArgs = {
  keyword: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetUsersByRoleArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId: Scalars['ID']['input'];
  roleQuery?: InputMaybe<Scalars['String']['input']>;
  roleSlug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUsersForTeamsArgs = {
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetUsersWithPermissionOnHierarchyResourceArgs = {
  hierarchyType: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  permission: Scalars['String']['input'];
  resourceId: Scalars['ID']['input'];
};


export type QueryGetVisitAsyncRequestStatusArgs = {
  visitId: Scalars['ID']['input'];
};


export type QueryGetVisitByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetVisitByMagicLinkArgs = {
  magicLink: Scalars['String']['input'];
};


export type QueryGetVisitCountArgs = {
  dateFilter: DateFilterMandatoryInput;
  excludeCanceledVisits?: InputMaybe<Scalars['Boolean']['input']>;
  locationIdFilter: Array<Scalars['ID']['input']>;
};


export type QueryGetVisitorManagementPermissionsArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetVisitsByEmailArgs = {
  email: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['String']['input']>;
  minutesAfter?: InputMaybe<Scalars['Int']['input']>;
  minutesBefore?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetWaitlistForMeArgs = {
  context?: InputMaybe<GetWaitlistForMeContext>;
};


export type QueryGetWaitlistsForMeArgs = {
  context?: InputMaybe<GetWaitlistsForMeContext>;
};


export type QueryGetWhoIsOnsiteByLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryGetWhosInDataByLocationArgs = {
  date?: InputMaybe<Scalars['LocalDate']['input']>;
  locationId: Scalars['ID']['input'];
};


export type QueryGetWhosInDataByLocationByDayArgs = {
  dates?: InputMaybe<Array<Scalars['LocalDate']['input']>>;
  locationId: Scalars['ID']['input'];
};


export type QueryGetWhosInDataByLocationsByDayArgs = {
  dates: Array<Scalars['LocalDate']['input']>;
  locationIds: Array<Scalars['ID']['input']>;
};


export type QueryGetWhosInDataForLocationsArgs = {
  date?: InputMaybe<Scalars['LocalDate']['input']>;
  locationIds: Array<Scalars['ID']['input']>;
};


export type QueryGetWorkAreaByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetWorkAreasByLevelArgs = {
  levelId: Scalars['ID']['input'];
};


export type QueryGetWorkPolicyByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetZoneByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetZonesByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryInsightsAssistantConversationV1Args = {
  id: Scalars['ID']['input'];
};


export type QueryInsightsAssistantConversationsForOrganizationV1Args = {
  organizationId: Scalars['ID']['input'];
};


export type QueryInsightsAssistantFeedbackV1Args = {
  conversationId: Scalars['ID']['input'];
  messageId: Scalars['ID']['input'];
};


export type QueryInsightsAssistantMessagesForOrganizationV1Args = {
  conversationId: Scalars['ID']['input'];
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryInsightsAssistantMessagesV1Args = {
  conversationId: Scalars['ID']['input'];
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryInternalPermissionUserOrgActionArgs = {
  actions: Array<PermissionAction>;
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryInternalResourceArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryIsFeatureFlagEnabledForDeviceOrgArgs = {
  flagType?: InputMaybe<DeviceFeatureFlagType>;
};


export type QueryIsFeatureFlagEnabledForOrgOrMeArgs = {
  flagType?: InputMaybe<FeatureFlagType>;
};


export type QueryIsPassiveCheckInFeatureEnabledForBuildingArgs = {
  input: IsPassiveCheckInFeatureEnabledForBuildingInput;
};


export type QueryIsRipplingAccountConnectedForOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryLevelChangeDetectionTokenArgs = {
  levelId: Scalars['ID']['input'];
};


export type QueryListBuildingCidrsArgs = {
  input: ListBuildingCidrsInput;
};


export type QueryListMeetingServiceRequestsArgs = {
  input: ListMeetingServiceRequestsInput;
};


export type QueryListMeetingServicesArgs = {
  input: ListMeetingServicesInput;
};


export type QueryListMeetingServicesAvailableInSpaceArgs = {
  input: ListMeetingServicesAvailableInSpaceInput;
};


export type QueryListMeetingServicesCategoriesArgs = {
  input: ListMeetingServicesCategoriesInput;
};


export type QueryListUniqueAssigneesForMeetingServicesArgs = {
  input: ListUniqueAssigneesForMeetingServicesInput;
};


export type QueryListUniqueMeetingServiceRequestValuesArgs = {
  input: ListUniqueMeetingServiceRequestValuesInput;
};


export type QueryParseDeliveryTextArgs = {
  text: Scalars['String']['input'];
  textType: DeliveryTextType;
};


export type QueryReservationsMultiDayGroupedByDateArgs = {
  dates: Array<Scalars['LocalDate']['input']>;
  deskIds: Array<Scalars['ID']['input']>;
  durationInMinutes: Scalars['Int']['input'];
  startTime: Scalars['LocalTime']['input'];
  timezone: Scalars['IANATimezone']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryResourceDetailsArgs = {
  organizationId: Scalars['ID']['input'];
  resourceIds: Array<Scalars['ID']['input']>;
};


export type QueryResourceDetailsByFloorArgs = {
  floorId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryResourceReservationSingleDayValidateArgs = {
  clientType: ClientType;
  date: Scalars['IsoDateOnlyString']['input'];
  duration: Scalars['ISO8601_Duration']['input'];
  organizationId: Scalars['ID']['input'];
  reservee: Scalars['ID']['input'];
  resourceInstanceId: Scalars['ID']['input'];
  startTime: Scalars['IsoLocalTimeString']['input'];
};


export type QueryReviewDraftArgs = {
  draftId: Scalars['ID']['input'];
};


export type QuerySpaceAllowsIssueReportsArgs = {
  spaceId: Scalars['ID']['input'];
};


export type QueryVisitDocumentAgreementArgs = {
  documentId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
};


export type QueryVisitDocumentByDecisionIdArgs = {
  decisionId: Scalars['ID']['input'];
};


export type QueryVisitDocumentByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitDocumentByIdWithCustomVisitTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitDocumentNameStatusAndTypesPageArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortByField?: InputMaybe<Query_Visit_Document_Sort_By>;
  sortOrder?: InputMaybe<Query_Workflow_Sort_Order>;
};


export type QueryVisitDocumentNameStatusAndTypesPageWithCustomVisitTypeArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortByField?: InputMaybe<Query_Visit_Document_Sort_By>;
  sortOrder?: InputMaybe<Query_Workflow_Sort_Order>;
};


export type QueryVisitDocumentWithNextDocumentIdArgs = {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  visitInviteId: Scalars['ID']['input'];
  visitLocationId: Scalars['ID']['input'];
  visitType: Scalars['String']['input'];
};


export type QueryVisitDocumentWithNextDocumentIdWithCustomVisitTypeArgs = {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  visitInviteId: Scalars['ID']['input'];
  visitLocationId: Scalars['ID']['input'];
  visitType: Scalars['String']['input'];
};


export type QueryVisitOverviewOfDocumentsArgs = {
  locationId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
  visitType: Scalars['String']['input'];
};


export type QueryVisitOverviewOfDocumentsWithCustomVisitTypeArgs = {
  locationId: Scalars['ID']['input'];
  visitInviteId: Scalars['ID']['input'];
  visitType: Scalars['String']['input'];
};

export type Recipient = {
  __typename?: 'Recipient';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type RecipientInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReconnectOpenpathAccountInput = {
  email: Scalars['String']['input'];
  otp?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type ReconnectOpenpathAccountResponse = {
  __typename?: 'ReconnectOpenpathAccountResponse';
  uuid: Scalars['ID']['output'];
};

export enum RecurringReservationDayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum RecurringReservationFrequency {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Minutely = 'MINUTELY',
  Monthly = 'MONTHLY',
  Secondly = 'SECONDLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum RecurringReservationMonth {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER'
}

export type RecurringReservationRequest = {
  /** Hours to which the recurrence is applied. Default: undefined */
  byHour?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Minutes to which the recurrence is applied. Default: undefined */
  byMinute?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Months to which the recurrence is applied. Default: undefined */
  byMonth?: InputMaybe<Array<RecurringReservationMonth>>;
  /** Month-days to which the recurrence is applied. Default: undefined */
  byMonthDay?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Seconds to which the recurrence is applied. Default: undefined */
  bySecond?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Each given integer will specify an occurrence number, corresponding to the nth occurrence of the rule inside the frequency period. For example, a bySetPosition of [-1] if combined with a MONTHLY frequency, and a byWeekDay of [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY], will result in the last work day of every month. */
  bySetPosition?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Weekdays to which the recurrence is applied. Default: undefined */
  byWeekDay?: InputMaybe<Array<ByWeekDayValue>>;
  /** Week numbers to which the recurrence is applied. Default: undefined. Range 1 - 53. */
  byWeekNumber?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Year-days to which the recurrence is applied. Default: undefined */
  byYearDay?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Number of occurrences. Default 1 */
  count?: InputMaybe<Scalars['Int']['input']>;
  endDateTime?: InputMaybe<LocaleDateTimePair>;
  frequency: RecurringReservationFrequency;
  /** Number of 'frequency' gaps between reservations. Default: 1 */
  interval?: InputMaybe<Scalars['Int']['input']>;
  /** Default: Locale today, now */
  startDateTime?: InputMaybe<LocaleDateTimePair>;
  /** Default: MONDAY */
  weekStartDay?: InputMaybe<RecurringReservationDayOfWeek>;
};

export enum RejectMeetingServiceRequestErrorReason {
  Conflict = 'CONFLICT'
}

export type RejectMeetingServiceRequestErrorResponse = {
  __typename?: 'RejectMeetingServiceRequestErrorResponse';
  reason: RejectMeetingServiceRequestErrorReason;
};

export type RejectMeetingServiceRequestInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  latestChangeId: Scalars['ID']['input'];
  meetingServiceRequestId: Scalars['ID']['input'];
};

export type RejectMeetingServiceRequestResponse = RejectMeetingServiceRequestErrorResponse | RejectMeetingServiceRequestSuccessResponse;

export type RejectMeetingServiceRequestSuccessResponse = {
  __typename?: 'RejectMeetingServiceRequestSuccessResponse';
  _?: Maybe<Scalars['Void']['output']>;
};

export enum RemoteTypeEnum {
  Exchange = 'EXCHANGE',
  Google = 'GOOGLE',
  Office_365 = 'OFFICE_365'
}

export type RemoveBookingWindowPolicyInput = {
  locationId: Scalars['ID']['input'];
  policyId: Scalars['ID']['input'];
};

export type RemoveSpaceFromEventError = {
  __typename?: 'RemoveSpaceFromEventError';
  message?: Maybe<Scalars['String']['output']>;
  reason: RemoveSpaceFromEventErrorReasons;
};

export enum RemoveSpaceFromEventErrorReasons {
  LackingEventUpdatePermissions = 'LACKING_EVENT_UPDATE_PERMISSIONS',
  Unknown = 'UNKNOWN'
}

export type RemoveSpaceFromEventInput = {
  eventId: Scalars['String']['input'];
  spaceId: Scalars['String']['input'];
};

export type RemoveSpaceFromEventResponse = {
  __typename?: 'RemoveSpaceFromEventResponse';
  /** If the event has not been updated, an explanation will be provided */
  error?: Maybe<RemoveSpaceFromEventError>;
  /** True if event has been updated */
  eventUpdated: Scalars['Boolean']['output'];
  /** Updated event model */
  updatedEvent?: Maybe<Event>;
};

export type ReportIssueInput = {
  description: Scalars['String']['input'];
  isUnusable: Scalars['Boolean']['input'];
};

export type ReportIssueResponse = {
  __typename?: 'ReportIssueResponse';
  id: Scalars['ID']['output'];
};

export type ReportIssueWithDeskInput = {
  deskId: Scalars['ID']['input'];
  issue: ReportIssueInput;
};

export type ReportIssueWithSpaceInput = {
  issue: ReportIssueInput;
  spaceId: Scalars['ID']['input'];
};

export type ReportUserPresenceAtIpAddressInput = {
  /** Version of the app in use, e.g. 'v3.28.3 (v1)' */
  appVersion?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier associated with user's personal device */
  deviceUuid: Scalars['ID']['input'];
  /** Purely for logging/debugging, X-Forwarded-For or source IP is the source of truth */
  ipAddressGuess?: InputMaybe<Scalars['IPAddress']['input']>;
  operatingSystem: OperatingSystem;
  /** e.g. the '10' in Android 10, but intentionally loose like a 'User-Agent' string. For logging/debugging. */
  operatingSystemVersion: Scalars['String']['input'];
  /** Unique identifier for this request, for client/server log correlation */
  requestUuid: Scalars['ID']['input'];
  /** Arbitrary string to describe what triggered this report, e.g. 'background fetch', 'background push', etc. */
  trigger?: InputMaybe<Scalars['String']['input']>;
};

export type ReportUserPresenceAtIpAddressResponse = {
  __typename?: 'ReportUserPresenceAtIPAddressResponse';
  /** Zero or more building IDs associated with this IP address. IP addresses can be assigned to multiple buildings. */
  buildingIds: Array<Scalars['ID']['output']>;
  /** What we think the IP address is, ignoring the provided guess. Could be null if we get malformed headers (rare). */
  ipAddress?: Maybe<Scalars['IPAddress']['output']>;
  /** True if reported IP address matches a known IP address associated with a building */
  matchesBuilding: Scalars['Boolean']['output'];
};

export type ReportUserPresenceFromWebClientInput = {
  clientName: Scalars['String']['input'];
  /** Purely for logging/debugging, X-Forwarded-For or source IP is the source of truth */
  ipAddressGuess?: InputMaybe<Scalars['IPAddress']['input']>;
};

export type ReportUserPresenceFromWebClientResponse = {
  __typename?: 'ReportUserPresenceFromWebClientResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type ReportUserPresenceInBuildingAtTimeInput = {
  buildingId: Scalars['ID']['input'];
  timestamp: Scalars['ISODateTime']['input'];
  trigger?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type ReportUserPresenceInBuildingAtTimeResponse = {
  __typename?: 'ReportUserPresenceInBuildingAtTimeResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export enum RequestMeetingServiceForDraftEventAtSpaceErrorReason {
  /** Admin could archive the service while requester is filling out form */
  MeetingServiceIsArchived = 'MEETING_SERVICE_IS_ARCHIVED',
  /** Admin could change availability while requester is filling out form */
  MeetingServiceNotAvailable = 'MEETING_SERVICE_NOT_AVAILABLE',
  /** Admin published a new version while requester is filling out form. Old versions are allowed to be created for a short period of time, but that time has passed. */
  MeetingServiceVersionOutdated = 'MEETING_SERVICE_VERSION_OUTDATED'
}

export type RequestMeetingServiceForDraftEventAtSpaceErrorResponse = {
  __typename?: 'RequestMeetingServiceForDraftEventAtSpaceErrorResponse';
  reason: RequestMeetingServiceForDraftEventAtSpaceErrorReason;
};

export type RequestMeetingServiceForDraftEventAtSpaceInput = {
  /** The answers to the questions on the MeetingService */
  answers: Array<AnsweredTicketQuestionInput>;
  /** The expected end time of the event instance - okay if this is wrong, will be updated on sync */
  eventEnd: DateWithTimezone;
  /** The expected start time of the event instance - okay if this is wrong, will be updated on sync */
  eventStart: DateWithTimezone;
  /** The UID that the draft Event will be created with. It is *very* important that this same UID makes it onto the Event, otherwise we cannot sync changes on the Event back to the service request. Robin events require a UUIDv4, so that is enforced here as well. */
  eventUid: Scalars['UUIDv4']['input'];
  meetingServiceId: Scalars['ID']['input'];
  /** If this is a recurring event, this identifies which instance the service is requested for. If this is omitted, it is assumed that the service is requested for the first instance. */
  recurrenceId?: InputMaybe<Scalars['ID']['input']>;
  spaceId: Scalars['ID']['input'];
  /** The version of the MeetingService you're requesting - could be different from the latest version */
  versionId: Scalars['ID']['input'];
};

export type RequestMeetingServiceForDraftEventAtSpaceResponse = RequestMeetingServiceForDraftEventAtSpaceErrorResponse | RequestMeetingServiceForDraftEventAtSpaceSuccessResponse;

export type RequestMeetingServiceForDraftEventAtSpaceSuccessResponse = {
  __typename?: 'RequestMeetingServiceForDraftEventAtSpaceSuccessResponse';
  meetingServiceRequestId: Scalars['ID']['output'];
};

export enum RequestMeetingServiceForExistingEventAtSpaceErrorReason {
  /** Not fixable, must start over and create a new event. Ideally, client would prevent getting this far in the first place. */
  EventHasNoUid = 'EVENT_HAS_NO_UID',
  /** Admin could archive the service while requester is filling out form */
  MeetingServiceIsArchived = 'MEETING_SERVICE_IS_ARCHIVED',
  /** Admin could change availability while requester is filling out form */
  MeetingServiceNotAvailable = 'MEETING_SERVICE_NOT_AVAILABLE',
  /** Admin published a new version while requester is filling out form. Old versions are allowed to be created for a short period of time, but that time has passed. */
  MeetingServiceVersionOutdated = 'MEETING_SERVICE_VERSION_OUTDATED'
}

export type RequestMeetingServiceForExistingEventAtSpaceErrorResponse = {
  __typename?: 'RequestMeetingServiceForExistingEventAtSpaceErrorResponse';
  reason: RequestMeetingServiceForExistingEventAtSpaceErrorReason;
};

export type RequestMeetingServiceForExistingEventAtSpaceInput = {
  /** The answers to the questions on the MeetingService */
  answers: Array<AnsweredTicketQuestionInput>;
  /** Event.id for the event that the service is being requested for. Could be an internal robin event ID or a proxied event ID. Must identify a specific instance - recurring service requests are not supported. */
  eventId: Scalars['ID']['input'];
  meetingServiceId: Scalars['ID']['input'];
  spaceId: Scalars['ID']['input'];
  /** The version of the MeetingService you're requesting - could be different from the latest version */
  versionId: Scalars['ID']['input'];
};

export type RequestMeetingServiceForExistingEventAtSpaceResponse = RequestMeetingServiceForExistingEventAtSpaceErrorResponse | RequestMeetingServiceForExistingEventAtSpaceSuccessResponse;

export type RequestMeetingServiceForExistingEventAtSpaceSuccessResponse = {
  __typename?: 'RequestMeetingServiceForExistingEventAtSpaceSuccessResponse';
  meetingServiceRequestId: Scalars['ID']['output'];
};

export type Reservation = {
  __typename?: 'Reservation';
  accountReservee?: Maybe<AccountReservee>;
  canCheckInNow?: Maybe<Scalars['Boolean']['output']>;
  confirmation?: Maybe<DeskConfirmation>;
  creationType?: Maybe<EventCreationType>;
  endTime?: Maybe<Scalars['String']['output']>;
  exclusions: Array<Exclusion>;
  hasStarted?: Maybe<Scalars['Boolean']['output']>;
  healthCheckpoint?: Maybe<HealthCheckpoint>;
  /** Reservation Id */
  id: Scalars['ID']['output'];
  isCheckedIn?: Maybe<Scalars['Boolean']['output']>;
  isDeclined?: Maybe<Scalars['Boolean']['output']>;
  isFullDay?: Maybe<Scalars['Boolean']['output']>;
  recurrence?: Maybe<Scalars['String']['output']>;
  /** @deprecated use the account reservee, it will be scoped to the reservations org */
  reservee?: Maybe<Reservee>;
  seat: Desk;
  seriesId?: Maybe<Scalars['ID']['output']>;
  startTime: Scalars['String']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  visibility: DeskReservationVisibility;
};

export type ReservationEdge = {
  __typename?: 'ReservationEdge';
  confirmation?: Maybe<DeskConfirmation>;
  endTime?: Maybe<Scalars['String']['output']>;
  healthCheckpoint?: Maybe<HealthCheckpoint>;
  /** Reservation Id */
  id: Scalars['ID']['output'];
  recurrence?: Maybe<Scalars['String']['output']>;
  reservee?: Maybe<Reservee>;
  seat: Desk;
  seriesId?: Maybe<Scalars['ID']['output']>;
  startTime: Scalars['String']['output'];
  timeZone?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ReservationInfo = {
  __typename?: 'ReservationInfo';
  desk: Scalars['String']['output'];
  deskId: Scalars['ID']['output'];
  end: DateTimezone;
  reservee: Scalars['String']['output'];
  start: DateTimezone;
  zone: Scalars['String']['output'];
};

/** Reservation invitee. One of the fields must be specified. */
export type ReservationInvitee = {
  /** Invitee email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Invitee slug */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Robin user ID */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Visibility of reservation */
export enum ReservationVisibility {
  Confidential = 'CONFIDENTIAL',
  Default = 'DEFAULT',
  Personal = 'PERSONAL',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** The ID of the reservation and if it is visible to the current user. Intended use is by the Status Board to use as a reference for which reservations to hide */
export type ReservationsBySpacesForStatusBoard = {
  __typename?: 'ReservationsBySpacesForStatusBoard';
  id: Scalars['ID']['output'];
  visibility: DeskReservationVisibility;
};

export type ReserveSpaceRequest = {
  /** Event end time (ISO format) */
  endTime?: InputMaybe<Scalars['Date']['input']>;
  /** True if the event creator is invited to the event */
  eventCreatorIsInvited: Scalars['Boolean']['input'];
  /** The description of the event. Defaults to empty string */
  eventDescription?: InputMaybe<Scalars['String']['input']>;
  /** The title of the event. Defaults to empty string */
  eventTitle?: InputMaybe<Scalars['String']['input']>;
  /** Set of included integration IDs */
  includedIntegrations: Array<Scalars['ID']['input']>;
  /** Event invitees */
  invitees: Array<ReservationInvitee>;
  /** Is the event an all-day event? Default false */
  isAllDay?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the location where the event will take place. Defaults to empty string */
  locationName?: InputMaybe<Scalars['String']['input']>;
  /** Is event pre-confirmed */
  preConfirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the reserved space */
  spaceId: Scalars['ID']['input'];
  /** Event start time (ISO format) */
  startTime?: InputMaybe<Scalars['Date']['input']>;
  /** Event visibility */
  visibility: ReservationVisibility;
};

/** Reservation response */
export type ReserveSpaceResponse = {
  __typename?: 'ReserveSpaceResponse';
  /** If the space has not been reserved, an explanation will be provided */
  reason?: Maybe<Scalars['String']['output']>;
  /** True if space has been reserved */
  spaceReserved: Scalars['Boolean']['output'];
};

export type Reservee = {
  __typename?: 'Reservee';
  email: Scalars['String']['output'];
  participationStatus?: Maybe<ParticipationStatus>;
  user?: Maybe<User>;
  visitorId?: Maybe<Scalars['ID']['output']>;
};

export type ResourceAllowsIssueReportsResponse = {
  __typename?: 'ResourceAllowsIssueReportsResponse';
  allowed: Scalars['Boolean']['output'];
};

export type ResourceGroupInput = {
  amenityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  policies?: InputMaybe<LayoutResourcePolicyInput>;
  subCategory?: InputMaybe<Scalars['String']['input']>;
};

export type ResourceGroupSaveUpdateInput = {
  amenityIds: Array<Scalars['ID']['input']>;
  capacity: Scalars['Int']['input'];
  groupName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isAccessible?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  policyConfiguration?: InputMaybe<CustomResourcePolicyDocumentCommand>;
  resourceGroupId: Scalars['ID']['input'];
  subCategory?: InputMaybe<Scalars['String']['input']>;
};

export type ResourcePolicy = {
  __typename?: 'ResourcePolicy';
  advancedBookingThreshold?: Maybe<ResourcePolicyEnabledWithDuration>;
  allowOverlappingBookings?: Maybe<Scalars['Boolean']['output']>;
  maxBookingLength?: Maybe<ResourcePolicyEnabledWithDuration>;
  resourceSharing?: Maybe<Scalars['Boolean']['output']>;
  restrictBookingToWorkingHours?: Maybe<Scalars['Boolean']['output']>;
};

export type ResourcePolicyDuration = {
  __typename?: 'ResourcePolicyDuration';
  type: LayoutResourcePolicyDurationType;
  value: Scalars['Int']['output'];
};

export type ResourcePolicyEnabledWithDuration = {
  __typename?: 'ResourcePolicyEnabledWithDuration';
  duration?: Maybe<ResourcePolicyDuration>;
  enabled: Scalars['Boolean']['output'];
};

export type ResourceReservation = {
  __typename?: 'ResourceReservation';
  date: Scalars['IsoDateOnlyString']['output'];
  duration: Scalars['ISO8601_Duration']['output'];
  id: Scalars['ID']['output'];
  reservedBy: Scalars['ID']['output'];
  reservee: Scalars['ID']['output'];
  resourceInstanceId: Scalars['ID']['output'];
  startTime: Scalars['IsoLocalTimeString']['output'];
};

export type ResourceReservationValidationFailure = {
  __typename?: 'ResourceReservationValidationFailure';
  /** Only one field exists for now. This will probably change later so leaving room for additions. */
  failureType: ResourceReservationValidationFailureType;
  id: Scalars['ID']['output'];
};

export enum ResourceReservationValidationFailureType {
  BookingOverlaps = 'BOOKING_OVERLAPS',
  CantBookFromKiosk = 'CANT_BOOK_FROM_KIOSK',
  ExceedsMaximumBookingLength = 'EXCEEDS_MAXIMUM_BOOKING_LENGTH',
  InvalidRequestToRescheduleRecurringReservation = 'INVALID_REQUEST_TO_RESCHEDULE_RECURRING_RESERVATION',
  OutsideOfWorkingHours = 'OUTSIDE_OF_WORKING_HOURS',
  RecurrenceNotAllowed = 'RECURRENCE_NOT_ALLOWED',
  ReservationDoesNotExist = 'RESERVATION_DOES_NOT_EXIST',
  ReservationHasEnded = 'RESERVATION_HAS_ENDED',
  ReservationTypeNotAllowed = 'RESERVATION_TYPE_NOT_ALLOWED',
  ScheduleDoesNotExist = 'SCHEDULE_DOES_NOT_EXIST',
  ViolatesMaximumBookingThreshold = 'VIOLATES_MAXIMUM_BOOKING_THRESHOLD'
}

export type ResourceReservationValidationResponse = {
  __typename?: 'ResourceReservationValidationResponse';
  errors: Array<ResourceReservationValidationFailure>;
  id: Scalars['ID']['output'];
  policiesEvaluated: Array<PolicyType>;
};

export type ResourceWeeklyBookingType = {
  __typename?: 'ResourceWeeklyBookingType';
  friday: BookingStatus;
  monday: BookingStatus;
  saturday: BookingStatus;
  sunday: BookingStatus;
  thursday: BookingStatus;
  tuesday: BookingStatus;
  wednesday: BookingStatus;
};

export type ResourceWeeklyBookingTypeInput = {
  friday: BookingStatus;
  monday: BookingStatus;
  saturday: BookingStatus;
  sunday: BookingStatus;
  thursday: BookingStatus;
  tuesday: BookingStatus;
  wednesday: BookingStatus;
};

export type ReviewDraftResponse = {
  __typename?: 'ReviewDraftResponse';
  deskStatistics: DeskStatistics;
  desksCount: Scalars['Int']['output'];
  entities: Array<Entity>;
  reservationsToCancel?: Maybe<Array<Maybe<ReservationInfo>>>;
};

export type RipplingAccount = {
  __typename?: 'RipplingAccount';
  id: Scalars['ID']['output'];
};

export type RipplingWorkLocationWithMappingToRobinBuilding = {
  __typename?: 'RipplingWorkLocationWithMappingToRobinBuilding';
  ripplingWorkLocationAddress?: Maybe<Scalars['String']['output']>;
  ripplingWorkLocationId: Scalars['ID']['output'];
  ripplingWorkLocationName: Scalars['String']['output'];
  robinBuildingId?: Maybe<Scalars['ID']['output']>;
};

export type RipplingWorkLocationsWithMappings = {
  __typename?: 'RipplingWorkLocationsWithMappings';
  locationsWithMappings: Array<RipplingWorkLocationWithMappingToRobinBuilding>;
};

export type RobinUserInfoForSlack = {
  __typename?: 'RobinUserInfoForSlack';
  token: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type Role = {
  __typename?: 'Role';
  created_at: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  is_default: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization_id: Scalars['ID']['output'];
  policies: Array<Policy>;
  /** `schedule` is used to distinguish access roles from other roles, and has value of null (which may have distinguished meaning from empty array) by default. */
  schedule?: Maybe<Array<Scalars['String']['output']>>;
  slug: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type RoleCreationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  policies?: InputMaybe<Array<RolePolicyInput>>;
  slug: Scalars['String']['input'];
};

export type RolePolicyInput = {
  is_allowed: Scalars['Boolean']['input'];
  object_id: Scalars['ID']['input'];
  object_type?: InputMaybe<Scalars['String']['input']>;
  permission_operation: Scalars['String']['input'];
};

export type ScheduleRecurringReservationResponseType = ResourceReservationValidationResponse | ScheduleRecurringReservationSuccessType;

export type ScheduleRecurringReservationSuccessType = {
  __typename?: 'ScheduleRecurringReservationSuccessType';
  reservationId: Scalars['ID']['output'];
  totalReservationEvents: Scalars['Int']['output'];
};

export type ScheduleSingleDayReservationResponseType = ResourceReservationValidationResponse | ScheduleSingleDayReservationSuccessType;

export type ScheduleSingleDayReservationSuccessType = {
  __typename?: 'ScheduleSingleDayReservationSuccessType';
  reservationId: Scalars['ID']['output'];
};

export enum ScheduleSourceType {
  Google = 'google',
  User = 'user'
}

export type SchedulingAggregateOfDesks = {
  __typename?: 'SchedulingAggregateOfDesks';
  /** Total number of desks of type Assigned that are available within the parent */
  deskAssignedAvailableTotal: Scalars['Int']['output'];
  /** Total number of desks of type Assigned that are booked within the parent */
  deskAssignedBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Assigned within the parent */
  deskAssignedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Assigned that are unavailable within the parent */
  deskAssignedUnavailableTotal: Scalars['Int']['output'];
  /** Total number of available desks within the parent */
  deskAvailableTotal: Scalars['Int']['output'];
  /** Total number of booked desks within the parent */
  deskBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hot that are available within the parent */
  deskHotAvailableTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hot that are booked within the parent */
  deskHotBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hot within the parent */
  deskHotTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hot that are unavailable within the parent */
  deskHotUnavailableTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hotel that are available within the parent */
  deskHotelAvailableTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hotel that are booked within the parent */
  deskHotelBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hotel within the parent */
  deskHotelTotal: Scalars['Int']['output'];
  /** Total number of desks of type Hotel that are unavailable within the parent */
  deskHotelUnavailableTotal: Scalars['Int']['output'];
  /** Total number of desks of type Shared that are available within the parent */
  deskSharedAvailableTotal: Scalars['Int']['output'];
  /** Total number of desks of type Shared that are booked within the parent */
  deskSharedBookedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Shared within the parent */
  deskSharedTotal: Scalars['Int']['output'];
  /** Total number of desks of type Shared that are unavailable within the parent */
  deskSharedUnavailableTotal: Scalars['Int']['output'];
  /** Total number of unavailable desks within the parent */
  deskUnavailableTotal: Scalars['Int']['output'];
};

export type SetAutoDeskBookingInput = {
  autoDeskBooking: Scalars['Boolean']['input'];
};

export type SetBuildingCidrsInput = {
  buildingId: Scalars['ID']['input'];
  cidrs: Array<Scalars['IPAddressCIDR']['input']>;
};

export type SetBuildingCidrsResponse = {
  __typename?: 'SetBuildingCidrsResponse';
  buildingCidrsUpdated: Scalars['Boolean']['output'];
  invalidCidrErrors: Array<InvalidCidrError>;
};

export type SetMyHomepageInput = {
  homepage: HomepageType;
};

export type SetMyLanguageTagInput = {
  languageTag: Scalars['String']['input'];
};

export type SetPassiveCheckInFeatureForBuildingInput = {
  buildingId: Scalars['ID']['input'];
};

export type SetShowWeekendsForMeInput = {
  showWeekends: Scalars['Boolean']['input'];
};

export type SetUserNotificationSettingInput = {
  name: Scalars['String']['input'];
  ownerId: Scalars['ID']['input'];
  values: Array<InputMaybe<NotificationSettingType>>;
};

export type SetWeeklyScheduleInput = {
  fri: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  instanceEndTime?: InputMaybe<Scalars['LocalEndTime']['input']>;
  instanceStartTime?: InputMaybe<Scalars['LocalEndTime']['input']>;
  locationId: Scalars['String']['input'];
  mon: Scalars['Boolean']['input'];
  recurrenceEndDate?: InputMaybe<Scalars['LocalDate']['input']>;
  recurrenceStartDate?: InputMaybe<Scalars['LocalDate']['input']>;
  sat: Scalars['Boolean']['input'];
  source: ScheduleSourceType;
  sun: Scalars['Boolean']['input'];
  thu: Scalars['Boolean']['input'];
  tue: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
  wed: Scalars['Boolean']['input'];
};

export type Setting = {
  __typename?: 'Setting';
  slug: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum SettingDataType {
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  Json = 'JSON',
  String = 'STRING'
}

export enum SettingOwnerType {
  User = 'USER'
}

export enum SettingValueType {
  Boolean = 'boolean',
  Number = 'number',
  String = 'string'
}

export type SharedDeskSchedule = {
  __typename?: 'SharedDeskSchedule';
  id: Scalars['ID']['output'];
  schedule: Array<DeskSchedule>;
};

export type SharedDeskSchedulesInput = {
  deskId: Scalars['ID']['input'];
  notifyUnassigned: Scalars['Boolean']['input'];
  sharedSchedules: Array<SharedScheduleInput>;
};

export type SharedScheduleInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  recurrence: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum SigmaEmbedType {
  Creator = 'CREATOR',
  Viewer = 'VIEWER'
}

export type SigmaEmbedUrl = {
  __typename?: 'SigmaEmbedUrl';
  url?: Maybe<Scalars['String']['output']>;
};

export type SigmaFile = {
  __typename?: 'SigmaFile';
  badge: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  parentUrlId: Scalars['String']['output'];
  path: Scalars['String']['output'];
  permission: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
  urlId: Scalars['String']['output'];
};

export enum SigmaLocationType {
  Personal = 'PERSONAL',
  Shared = 'SHARED'
}

export type SigmaTag = {
  __typename?: 'SigmaTag';
  name: Scalars['String']['output'];
  versionTagId: Scalars['String']['output'];
};

export type SigmaWorkbook = {
  __typename?: 'SigmaWorkbook';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  latestVersion: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  tags?: Maybe<Array<Maybe<SigmaTag>>>;
  updatedAt: Scalars['String']['output'];
  updatedBy: Scalars['String']['output'];
  url: Scalars['String']['output'];
  workbookId: Scalars['String']['output'];
  workbookUrlId: Scalars['String']['output'];
};

export type SimpleLocation = {
  __typename?: 'SimpleLocation';
  accountId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  remoteGoogleId?: Maybe<Scalars['String']['output']>;
  remoteGoogleLabel?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
};

export enum SnapTo {
  Fifteen = 'FIFTEEN',
  Sixty = 'SIXTY',
  Thirty = 'THIRTY',
  Zero = 'ZERO'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** The order with which to sort */
export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type Space = {
  __typename?: 'Space';
  amenities: Array<SpaceAmenity>;
  /** Availability of space for the next half hour. GREEN: available for the next half hour. YELLOW: event scheduled within the next thirty minutes. RED: Event currently being held */
  availableAt?: Maybe<Availability>;
  behaviors: Array<Scalars['String']['output']>;
  calendar?: Maybe<Calendar>;
  /** Capacity is unsigned 32bit integer. */
  capacity?: Maybe<Scalars['BigInt']['output']>;
  /** @deprecated Deprecated in favor of confirmedAvailabilityMultiDay */
  confirmedAvailability?: Maybe<SpaceAvailability>;
  confirmedAvailabilityMultiDay?: Maybe<SpaceAvailability>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  currentUserCanRequestMeetingServicesForEvent?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated because this field is no longer retrievable from API */
  disabledAt?: Maybe<Scalars['Date']['output']>;
  discoveryRadius?: Maybe<Scalars['Float']['output']>;
  eventWithCheckInStatus?: Maybe<EventWithCheckInStatus>;
  filteredDesksByAmenityIds: Array<Desk>;
  hasAnyAvailableMeetingServices?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isAccessible?: Maybe<Scalars['Boolean']['output']>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  isManaged: Scalars['Boolean']['output'];
  legacyAvailableWorkplaceServiceTypes: Array<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['ID']['output']>;
  locationId: Scalars['Int']['output'];
  meetingRoomWindowedAvailability: Array<SpaceWindowAvailability>;
  /** Returns non-cancelled meeting service requests for this space on the given event. Excludes draft requests, except those requested by the current user. null if current user lacks permission to see requests */
  meetingServiceRequests?: Maybe<Array<MeetingServiceRequest>>;
  /**
   * null if current user lacks permission to see requests
   * @deprecated Use meetingServiceRequests instead
   */
  meetingServiceRequestsForDraftEvent?: Maybe<Array<MeetingServiceRequest>>;
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  reservationPolicies: SpaceReservationPolicies;
  scheduleConfiguration?: Maybe<SpaceScheduleConfiguration>;
  /** The total price of all meeting service requests in this space during the given event, after any surcharges/taxes. Multiple prices can be returned if the service requests are in different currencies - no currency conversion happens. Currency codes are unique. Empty list if none of the meeting service requests have prices. */
  totalPriceOfMeetingServiceRequests?: Maybe<Array<Money>>;
  type?: Maybe<SpaceType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Deprecated in favor of meetingRoomWindowedAvailability */
  windowedAvailability: Array<SpaceWindowAvailability>;
  zones: Array<Zone>;
};


export type SpaceAvailableAtArgs = {
  time?: InputMaybe<Scalars['Date']['input']>;
};


export type SpaceConfirmedAvailabilityArgs = {
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['ISODateTime']['input']>;
};


export type SpaceConfirmedAvailabilityMultiDayArgs = {
  dates?: InputMaybe<Array<InputMaybe<Scalars['ISODateTime']['input']>>>;
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['ISODateTime']['input']>;
};


export type SpaceCurrentUserCanRequestMeetingServicesForEventArgs = {
  eventInstance: EventInstanceReference;
};


export type SpaceFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type SpaceMeetingRoomWindowedAvailabilityArgs = {
  firstWindowSplit?: InputMaybe<SnapTo>;
  meetingDurationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  numberOfWindows?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};


export type SpaceMeetingServiceRequestsArgs = {
  eventInstance: EventInstanceReference;
};


export type SpaceMeetingServiceRequestsForDraftEventArgs = {
  eventUid: Scalars['UUIDv4']['input'];
  recurrenceId?: InputMaybe<Scalars['ID']['input']>;
};


export type SpaceTotalPriceOfMeetingServiceRequestsArgs = {
  eventInstance: EventInstanceReference;
};


export type SpaceWindowedAvailabilityArgs = {
  firstWindowSplit?: InputMaybe<SnapTo>;
  intervalInMinutes?: InputMaybe<Scalars['Int']['input']>;
  meetingDurationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  numberOfWindows?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
};

export type SpaceAmenity = {
  __typename?: 'SpaceAmenity';
  amenityId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export enum SpaceAvailability {
  /** Currently available */
  Available = 'AVAILABLE',
  /** Resource has been booked and confirmed */
  BookedConfirmed = 'BOOKED_CONFIRMED',
  /** Resource has been booked and not confirmed */
  BookedUnconfirmed = 'BOOKED_UNCONFIRMED',
  /** Resource marked as unavailable for booking */
  Unavailable = 'UNAVAILABLE',
  /** Resource schedule not found */
  Unknown = 'UNKNOWN'
}

export type SpaceCalendar = {
  __typename?: 'SpaceCalendar';
  calendarId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isHealthy?: Maybe<Scalars['Boolean']['output']>;
  lastNotifiedAt?: Maybe<Scalars['String']['output']>;
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  remoteType: Scalars['String']['output'];
  space?: Maybe<Space>;
  spaceResourceEmail?: Maybe<Scalars['String']['output']>;
  spaceResourceId?: Maybe<Scalars['String']['output']>;
  subscriberChannelId?: Maybe<Scalars['String']['output']>;
  subscriberExpiresAt?: Maybe<Scalars['String']['output']>;
  subscriberId?: Maybe<Scalars['String']['output']>;
  subscriptionAttempts: Scalars['Int']['output'];
};

export type SpaceCalendarFiltersInput = {
  calendarHealth?: InputMaybe<CalendarHealthEnum>;
  hasResource?: InputMaybe<Scalars['Boolean']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  remoteType?: InputMaybe<RemoteTypeEnum>;
};

export type SpaceCalendarResponse = {
  __typename?: 'SpaceCalendarResponse';
  calendarInfo: Array<SpaceCalendar>;
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum SpaceFilters {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Scheduling = 'SCHEDULING'
}

export type SpaceReservationPolicies = {
  __typename?: 'SpaceReservationPolicies';
  /** Automatically remove ghost meetings from the room's calendar if nobody checks in. */
  abandonedMeetingProtection: Scalars['Boolean']['output'];
  /** Time to wait before an unconfirmed meeting is canceled. */
  abandonedThreshold: Scalars['ISO8601Duration']['output'];
  /** Restrict how far in advance this space can be booked. */
  advanceBookingThreshold?: Maybe<Scalars['ISO8601Duration']['output']>;
  allowRecurringEvents: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  maxReservationLength?: Maybe<Scalars['ISO8601Duration']['output']>;
  /** Show a check-in button for each meeting */
  meetingCheckins: Scalars['Boolean']['output'];
  restrictBookingToWorkingHours: Scalars['Boolean']['output'];
  /** The number of consecutive abandoned meetings before the space is permanently removed from the series. */
  strikesForRecurringEvents?: Maybe<Scalars['Int']['output']>;
};

export enum SpaceScheduleConfiguration {
  OnDemand = 'ON_DEMAND',
  RequestOnly = 'REQUEST_ONLY',
  Scheduled = 'SCHEDULED'
}

export enum SpaceType {
  Breakout = 'BREAKOUT',
  BreakRoom = 'BREAK_ROOM',
  Cafe = 'CAFE',
  Cafeteria = 'CAFETERIA',
  Call = 'CALL',
  Classroom = 'CLASSROOM',
  Conference = 'CONFERENCE',
  Event = 'EVENT',
  Fitness = 'FITNESS',
  Huddle = 'HUDDLE',
  Interview = 'INTERVIEW',
  Kitchen = 'KITCHEN',
  Lab = 'LAB',
  Lactation = 'LACTATION',
  Listening = 'LISTENING',
  Lobby = 'LOBBY',
  Lounge = 'LOUNGE',
  Meeting = 'MEETING',
  Office = 'OFFICE',
  Other = 'OTHER',
  Parking = 'PARKING',
  Project = 'PROJECT',
  Restroom = 'RESTROOM',
  RestroomFemale = 'RESTROOM_FEMALE',
  RestroomMale = 'RESTROOM_MALE',
  Studio = 'STUDIO',
  StudyRoom = 'STUDY_ROOM',
  Theater = 'THEATER',
  Utility = 'UTILITY',
  Work = 'WORK'
}

export type SpaceWindowAvailability = {
  __typename?: 'SpaceWindowAvailability';
  end?: Maybe<Scalars['Date']['output']>;
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Deprecated because the end becomes representing the pillEnd */
  pillEnd?: Maybe<Scalars['Date']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Availability>;
  unbookableReasons: Array<Maybe<UnbookableReasonType>>;
};

export type StandardWorkingHours = DateSpecificWorkingHours & {
  __typename?: 'StandardWorkingHours';
  date: Scalars['Date']['output'];
  timeFrames: Array<TimeFrame>;
};

export type Statistics = {
  __typename?: 'Statistics';
  assigned: Scalars['Int']['output'];
  available: Scalars['Int']['output'];
  hot: Scalars['Int']['output'];
  hoteled: Scalars['Int']['output'];
  unavailable: Scalars['Int']['output'];
};

export type Sticker = {
  __typename?: 'Sticker';
  associatedDeskId?: Maybe<Scalars['ID']['output']>;
  batchId: Scalars['ID']['output'];
  /** @deprecated Deprecated due to the potential circular queries that can be made via the fields on this type. Please use the `associatedDeskId` field and make a second query for desk information. */
  desk?: Maybe<Desk>;
  displayCode: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  shortCode: Scalars['String']['output'];
  shortUrl: Scalars['String']['output'];
};

export type StickerAssignment = {
  __typename?: 'StickerAssignment';
  seatId: Scalars['Int']['output'];
  stickerId: Scalars['ID']['output'];
};

export type StickerAssignmentInput = {
  seatId: Scalars['Int']['input'];
  stickerId: Scalars['ID']['input'];
};

export type StickerBatch = {
  __typename?: 'StickerBatch';
  id: Scalars['ID']['output'];
  stickers: Array<Sticker>;
};

export type SuggestedDesk = {
  __typename?: 'SuggestedDesk';
  desk: Desk;
  locationId: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['String']['output'];
};

export type SuggestedSpaceForEvent = {
  __typename?: 'SuggestedSpaceForEvent';
  eventId: Scalars['ID']['output'];
  space?: Maybe<Space>;
  suggestedSpaceRecordId?: Maybe<Scalars['String']['output']>;
};

/** Survey configuration */
export type Survey = {
  __typename?: 'Survey';
  /** Boolean value indicating if the survey allows anonymous responses */
  allowAnonymous: Scalars['Boolean']['output'];
  /** List of buildings associated with the survey */
  buildings?: Maybe<Array<Maybe<Location>>>;
  /** Date the survey was created */
  createdAt: Scalars['ISODateTime']['output'];
  /** User that created the survey */
  createdBy: User;
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** Boolean value indicating if the survey is active */
  isActive: Scalars['Boolean']['output'];
  /** Survey name */
  name: Scalars['String']['output'];
  /** Organization associated with the survey */
  organization: Organization;
  /** Date the survey was last updated */
  updatedAt: Scalars['ISODateTime']['output'];
  /** User that last updated the survey */
  updatedBy: User;
};

/** Survey configuration without building links */
export type SurveyEdge = {
  __typename?: 'SurveyEdge';
  /** Boolean value indicating if the survey allows anonymous responses */
  allowAnonymous: Scalars['Boolean']['output'];
  /** Date the survey was created */
  createdAt: Scalars['ISODateTime']['output'];
  /** User that created the survey */
  createdBy: User;
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** Boolean value indicating if the survey is active */
  isActive: Scalars['Boolean']['output'];
  /** Survey name */
  name: Scalars['String']['output'];
  /** Organization associated with the survey */
  organization: Organization;
  /** Date the survey was last updated */
  updatedAt: Scalars['ISODateTime']['output'];
  /** User that last updated the survey */
  updatedBy: User;
};

/** Response to a sentiment survey */
export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  /** Building of the reservation */
  building?: Maybe<Location>;
  /** Response comment */
  comment?: Maybe<Scalars['String']['output']>;
  /** Date the response was created */
  createdAt: Scalars['ISODateTime']['output'];
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** Date the user was in the office */
  inOfficeAt: Scalars['ISODateTime']['output'];
  /** Boolean that indicates if the response was anonymous */
  isAnonymous: Scalars['Boolean']['output'];
  /** Organization associated with the survey response */
  organization: Organization;
  /** Sentiment rating */
  rating?: Maybe<Scalars['Int']['output']>;
  /** Reservation associated with the survey response */
  reservation?: Maybe<Reservation>;
  /** Status of the survey response */
  status: SurveyResponseStatus;
  /** Date the response was submitted */
  submittedAt?: Maybe<Scalars['ISODateTime']['output']>;
  /** Survey that was responded to */
  survey: SurveyEdge;
  /** Date the resopnse was last updated */
  updatedAt: Scalars['ISODateTime']['output'];
  /** User that responded */
  user?: Maybe<User>;
};

/** Survey response rating summary */
export type SurveyResponseRatingSummary = {
  __typename?: 'SurveyResponseRatingSummary';
  /** Total number of negative responses */
  negativeCount: Scalars['Int']['output'];
  /** Total number of neutral responses */
  neutralCount: Scalars['Int']['output'];
  /** Total number of positive responses */
  positiveCount: Scalars['Int']['output'];
  /** Total number of completed responses */
  totalCount: Scalars['Int']['output'];
};

/** The key to sort survey responses by */
export enum SurveyResponseSortKey {
  InOfficeAt = 'IN_OFFICE_AT',
  Rating = 'RATING'
}

/** Status of the survey response */
export enum SurveyResponseStatus {
  Completed = 'COMPLETED',
  Dismissed = 'DISMISSED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

/** Survey response status summary */
export type SurveyResponseStatusSummary = {
  __typename?: 'SurveyResponseStatusSummary';
  /** Total number of COMPLETED responses */
  completedCount: Scalars['Int']['output'];
  /** Total number of DISMISSED responses */
  dismissedCount: Scalars['Int']['output'];
  /** Total number of EXPIRED responses */
  expiredCount: Scalars['Int']['output'];
  /** Total number of PENDING responses */
  pendingCount: Scalars['Int']['output'];
  /** Total number of responses */
  totalCount: Scalars['Int']['output'];
};

export enum SyncState {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  NeverHappened = 'NEVER_HAPPENED'
}

export type TeamsUserSearchResult = {
  __typename?: 'TeamsUserSearchResult';
  avatar?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Theme = {
  __typename?: 'Theme';
  color?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
};

export type Ticket = {
  /** Can be null if nobody is assigned */
  assignees?: Maybe<UsersAndGroups>;
  completedForm?: Maybe<CompletedTicketForm>;
  id: Scalars['ID']['output'];
  /** human-friendly identifier like 'CAT-123' */
  key: Scalars['String']['output'];
  requester?: Maybe<User>;
  title: Scalars['String']['output'];
};

export type TicketAnswerExpectingChoicesInput = {
  optionIds: Array<Scalars['ID']['input']>;
  questionId: Scalars['ID']['input'];
};

export type TicketAnswerExpectingMenuChoicesInput = {
  choices: Array<TicketAnswerMenuChoiceInput>;
  questionId: Scalars['ID']['input'];
};

export type TicketAnswerExpectingTextInput = {
  answer: Scalars['String']['input'];
  questionId: Scalars['ID']['input'];
};

export type TicketAnswerMenuChoice = {
  __typename?: 'TicketAnswerMenuChoice';
  option: TicketQuestionMenuOption;
  /** Required if option.quantifiable is true */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** The subtotal price for the entire quantity, before any surcharges/taxes. On a receipt, this is what shows up to the right of each line item: "3 Crab Rangoon: 7.50". */
  subtotalPrice?: Maybe<Money>;
};

export type TicketAnswerMenuChoiceInput = {
  optionId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type TicketQuestion = {
  id: Scalars['ID']['output'];
  prompt: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type TicketQuestionExpectingChoices = AbstractTicketQuestionExpectingChoices & TicketQuestion & {
  __typename?: 'TicketQuestionExpectingChoices';
  chooseOnlyOneOption: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  options: Array<TicketQuestionOption>;
  /** e.g. 'Which way do you want the room to be set up?' */
  prompt: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type TicketQuestionExpectingChoicesInput = {
  chooseOnlyOneOption: Scalars['Boolean']['input'];
  options: Array<TicketQuestionOptionInput>;
  /** e.g. 'Which way do you want the room to be set up?' */
  prompt: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
};

export type TicketQuestionExpectingMenuChoices = AbstractTicketQuestionExpectingChoices & TicketQuestion & {
  __typename?: 'TicketQuestionExpectingMenuChoices';
  chooseOnlyOneOption: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  options: Array<TicketQuestionMenuOption>;
  /** e.g. 'Which way do you want the room to be set up?' */
  prompt: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type TicketQuestionExpectingMenuChoicesInput = {
  chooseOnlyOneOption: Scalars['Boolean']['input'];
  options: Array<TicketQuestionMenuOptionInput>;
  /** e.g. 'Appetizers' */
  prompt: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
};

export type TicketQuestionExpectingText = TicketQuestion & {
  __typename?: 'TicketQuestionExpectingText';
  id: Scalars['ID']['output'];
  multilineAnswer: Scalars['Boolean']['output'];
  /** 'What is your expense code?' */
  prompt: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type TicketQuestionExpectingTextInput = {
  multilineAnswer: Scalars['Boolean']['input'];
  /** 'What is your expense code?' */
  prompt: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
};

export type TicketQuestionInput = {
  expectingChoices?: InputMaybe<TicketQuestionExpectingChoicesInput>;
  expectingMenuChoices?: InputMaybe<TicketQuestionExpectingMenuChoicesInput>;
  expectingText?: InputMaybe<TicketQuestionExpectingTextInput>;
};

export type TicketQuestionMenuOption = AbstractTicketQuestionOption & {
  __typename?: 'TicketQuestionMenuOption';
  /** 'A perfectly adequate sandwich with cheese' */
  description?: Maybe<Scalars['Markdown']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** 'Sandwich' */
  name: Scalars['String']['output'];
  quantifiable: Scalars['Boolean']['output'];
  /** The price of each unit, such that the subtotalPrice for this choice is quantity * unitPrice. If the option is not quantifiable, this is a flat price like if quantity were always 1. The currency code will always match the one on the MeetingService. 0 - 99_999_999 major currency units. Amounts are rounded to the nearest minor currency unit. */
  unitPrice?: Maybe<Money>;
};

export type TicketQuestionMenuOptionInput = {
  /** 'Two pieces of bread with meat in between - yum!' */
  description?: InputMaybe<Scalars['Markdown']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** 'Sandwich' */
  name: Scalars['String']['input'];
  quantifiable: Scalars['Boolean']['input'];
  /** The price of each unit, such that the total price for this option is quantity * unitPrice. If this option is not quantifiable, this is a flat price like if quantity were always 1. The currency code must match the one on the meeting service - you cannot mix currencies on the same service. */
  unitPrice?: InputMaybe<MoneyInput>;
};

export type TicketQuestionOption = AbstractTicketQuestionOption & {
  __typename?: 'TicketQuestionOption';
  /** 'We'll set up tables facing in *one* direction, evenly spaced' */
  description?: Maybe<Scalars['Markdown']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** 'Classroom' */
  name: Scalars['String']['output'];
};

export type TicketQuestionOptionInput = {
  /** 'We'll set up tables facing in *one* direction, evenly spaced' */
  description?: InputMaybe<Scalars['Markdown']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** 'Classroom' */
  name: Scalars['String']['input'];
};

export type TicketRegardsEventAtSpace = {
  __typename?: 'TicketRegardsEventAtSpace';
  event?: Maybe<Event>;
  eventEnd: Scalars['ISODateTime']['output'];
  eventStart: Scalars['ISODateTime']['output'];
  space?: Maybe<Space>;
  /** null if ticket will still be changed when event changes. Actions like rejecting or cancelling a ticket will unsubscribe from event changes. This keeps a frozen record of what the ticket looked like when it was resolved. It also prevents people from getting emails about changes to events whose tickets are resolved. */
  unsubscribedAt?: Maybe<Scalars['ISODateTime']['output']>;
};

export type TimeFrame = {
  __typename?: 'TimeFrame';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type TimeFrameInput = {
  end: Scalars['LocalEndTime']['input'];
  start: Scalars['LocalEndTime']['input'];
};

export enum TimeInclusivityType {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE'
}

export type TimeRangeInput = {
  end: Scalars['ISODateTime']['input'];
  start: Scalars['ISODateTime']['input'];
};

export type UnansweredTicketQuestion = AnsweredTicketQuestion & {
  __typename?: 'UnansweredTicketQuestion';
  question: TicketQuestion;
};

export type UnansweredTicketQuestionInput = {
  questionId: Scalars['ID']['input'];
};

export type UnassignStickerRequest = {
  stickerId: Scalars['ID']['input'];
};

export type UnauthenticatedGuest = {
  __typename?: 'UnauthenticatedGuest';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** UnauthenticatedVisitResult represents the visit for a single GuestInvite. The result is "unauthenticated" because it is available to unauthenticated queries. A UnauthenticatedVisitResult is the guest (GuestInvite) along with information about their associated Visit. */
export type UnauthenticatedVisitResult = {
  __typename?: 'UnauthenticatedVisitResult';
  arrivalInstructions?: Maybe<Scalars['String']['output']>;
  arrivalLocation: PublicLocationOrDefault;
  arrivalLocationId: Scalars['String']['output'];
  checkinOn?: Maybe<Scalars['String']['output']>;
  checkoutOn?: Maybe<Scalars['String']['output']>;
  customVisitType: Scalars['String']['output'];
  guest: UnauthenticatedGuest;
  healthCheckpointSurveyResponse?: Maybe<GenericHealthCheckpointResponse>;
  host: PublicUserWithEmail;
  /** The ID of the GuestInvite */
  id: Scalars['ID']['output'];
  organization: PublicOrganization;
  startTime: Scalars['ISODateTime']['output'];
  status: GuestInviteStatus;
  /** The ID of the associated visit */
  visitId: Scalars['ID']['output'];
  /** @deprecated replaced by the more flexible customVisitType */
  visitType: VisitType;
};

export enum UnbookableReasonType {
  AdminOnlyBooking = 'ADMIN_ONLY_BOOKING',
  ConflictWithAnotherMeeting = 'CONFLICT_WITH_ANOTHER_MEETING',
  LackingSpaceBookingPermissions = 'LACKING_SPACE_BOOKING_PERMISSIONS',
  OutsideWorkingHours = 'OUTSIDE_WORKING_HOURS',
  OverMaxLength = 'OVER_MAX_LENGTH',
  RecurrenceNotAllowed = 'RECURRENCE_NOT_ALLOWED',
  TooFarInFuture = 'TOO_FAR_IN_FUTURE'
}

export type UnconfirmableReason = {
  __typename?: 'UnconfirmableReason';
  reason: UnconfirmableReasonType;
  value?: Maybe<Scalars['String']['output']>;
};

export enum UnconfirmableReasonType {
  CannotConfirmSeriesMaster = 'CANNOT_CONFIRM_SERIES_MASTER',
  CannotConfirmSharedDesk = 'CANNOT_CONFIRM_SHARED_DESK',
  CheckinDisabled = 'CHECKIN_DISABLED',
  MissingReservationEndTime = 'MISSING_RESERVATION_END_TIME',
  OutsideOfCheckInWindow = 'OUTSIDE_OF_CHECK_IN_WINDOW'
}

export type UngroupedResourceSaveUpdateInput = {
  amenityIds: Array<Scalars['ID']['input']>;
  bookingType: WeeklyBookingCommand;
  capacity: Scalars['Int']['input'];
  customResourceInstanceId: Scalars['ID']['input'];
  floorId: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isAccessible?: InputMaybe<Scalars['Boolean']['input']>;
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  originatingCustomResourceTemplateId: Scalars['ID']['input'];
  policyConfiguration?: InputMaybe<CustomResourcePolicyDocumentCommand>;
  subCategory: Scalars['String']['input'];
};

export type UnhealthyCalendar = {
  __typename?: 'UnhealthyCalendar';
  daysSinceSpaceUpdated: Scalars['Int']['output'];
  daysSinceSynced: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  spaceId: Scalars['ID']['output'];
  spaceName: Scalars['String']['output'];
};

export type UniqueMeetingServiceRequestValues = {
  __typename?: 'UniqueMeetingServiceRequestValues';
  approvers: Array<Maybe<UserOrGroupUnion>>;
  assignees: Array<Maybe<UserOrGroupUnion>>;
  meetingServices: Array<MeetingService>;
  meetingServicesCategories: Array<MeetingServicesCategory>;
  regardingSpaces: Array<Maybe<Space>>;
  requesters: Array<Maybe<User>>;
};

export type UnlinkKisiLockFromRobinLevelInput = {
  remoteLockId: Scalars['String']['input'];
};

export type UnlinkKisiLockFromRobinLevelResponse = {
  __typename?: 'UnlinkKisiLockFromRobinLevelResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type UnlinkKisiLockFromRobinLocationInput = {
  remoteLockId: Scalars['String']['input'];
};

export type UnlinkKisiLockFromRobinLocationResponse = {
  __typename?: 'UnlinkKisiLockFromRobinLocationResponse';
  uuid?: Maybe<Scalars['ID']['output']>;
};

export enum UpdatableMeetingServiceRequestStatus {
  Blocked = 'BLOCKED',
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Todo = 'TODO'
}

export type UpdateActivityInput = {
  activityLocation?: InputMaybe<Scalars['String']['input']>;
  /** Should be a URL */
  coverImageUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['ISODateTime']['input'];
  start: Scalars['ISODateTime']['input'];
  timezone: Scalars['String']['input'];
  title: Scalars['String']['input'];
  virtualLocation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBookingWindowPolicyInput = {
  locationId: Scalars['ID']['input'];
  policy?: InputMaybe<BookingWindowPolicyDefinitionInput>;
  policyId: Scalars['ID']['input'];
};

export type UpdateDefaultPassInput = {
  isDefaultAccessPassEnabled: Scalars['Boolean']['input'];
};

export type UpdateDeliveryInput = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>;
  carrier?: InputMaybe<Scalars['String']['input']>;
  deliveryZoneId?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<RecipientInput>;
  status?: InputMaybe<DeliveryStatus>;
};

export type UpdateDeliveryZoneInput = {
  levelId?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  spaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateDeskReservationResponse = {
  __typename?: 'UpdateDeskReservationResponse';
  updatedReservations: Array<UpdatedReservation>;
};

export type UpdateDraftDataResponse = {
  __typename?: 'UpdateDraftDataResponse';
  draftAccessLevel: DraftAccessLevel;
  entities: Array<Maybe<Entity>>;
  floor: Level;
  id: Scalars['ID']['output'];
  metadata: DraftMetadata;
  queue: Array<Maybe<DraftAccountUser>>;
  settings: Array<DraftSettings>;
};

export type UpdateDraftResponse = {
  __typename?: 'UpdateDraftResponse';
  affectedUsers: Array<Maybe<DraftAccountUser>>;
  entities: Array<Maybe<Entity>>;
  id: Scalars['ID']['output'];
  metadata: DraftMetadata;
};

export type UpdateEventError = {
  __typename?: 'UpdateEventError';
  message?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<EventUpdateFailReasons>;
};

/** Reservation response */
export type UpdateEventResponse = {
  __typename?: 'UpdateEventResponse';
  /** If the event has not been updated, an explanation will be provided */
  error?: Maybe<UpdateEventError>;
  /** True if event has been updated */
  eventUpdated: Scalars['Boolean']['output'];
  /** Updated event model */
  updatedEvent?: Maybe<Event>;
};

export type UpdateLayoutDraftResponse = {
  __typename?: 'UpdateLayoutDraftResponse';
  entities: Array<Maybe<LayoutChangeEntity>>;
  groupEntities: Array<Maybe<LayoutResourceGroupEntity>>;
  id: Scalars['ID']['output'];
  metadata: DraftMetadata;
};

export type UpdateMeetingServiceChangesInput = {
  approvers?: InputMaybe<UserOrGroupInput>;
  assignees?: InputMaybe<UsersAndGroupsInput>;
  /** Use null to make unavailable everywhere, omit to avoid changes. Unlike other collections on this input, this field supports partial updates. Buildings not listed here will be unchanged. To make unavailable in an entire building, send an empty array of space IDs for that building. To change the spaces in a building, send up the entire new array of space IDs for that building. This frees the client from grabbing every space ID in every building up front, which could be an expensive operation for services available in large orgs. To make sure these rules are understood by the client, empty array is not allowed and the name is different than the addMeetingService input. Omit the field to avoid changes. */
  availableInSpacesByBuildingChanges?: InputMaybe<Array<MeetingServiceAvailableInSpacesByBuildingInput>>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  currencyCode?: InputMaybe<Scalars['CurrencyCodeISO4217']['input']>;
  description?: InputMaybe<Scalars['Markdown']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Empty array and null have the same effect: remove all questions */
  questions?: InputMaybe<Array<TicketQuestionInput>>;
};

export type UpdateMeetingServiceErrorResponse = {
  __typename?: 'UpdateMeetingServiceErrorResponse';
  reason?: Maybe<UpdateMeetingServiceErrorResponseReason>;
};

export enum UpdateMeetingServiceErrorResponseReason {
  CategoryMissing = 'CATEGORY_MISSING',
  /** meeting service version ID is not the latest version for the meeting service */
  Conflict = 'CONFLICT'
}

export type UpdateMeetingServiceInput = {
  changes: UpdateMeetingServiceChangesInput;
  meetingServiceId: Scalars['ID']['input'];
  /** The latest version ID for the meeting service you want to change. If this is not the latest version, say because someone edited the service out from under you, then the update will fail. The user should consult the latest version to avoid blowing away updates made by other people. */
  meetingServiceVersionId: Scalars['ID']['input'];
};

export enum UpdateMeetingServiceRequestStatusErrorReason {
  /** Events that have ended can only change to Canceled/Done */
  EventAlreadyEnded = 'EVENT_ALREADY_ENDED',
  /** Cannot un-cancel status because event or space no longer exists */
  EventOrSpaceNoLongerExists = 'EVENT_OR_SPACE_NO_LONGER_EXISTS',
  /** Another user already changed the status */
  StatusAlreadyChanged = 'STATUS_ALREADY_CHANGED',
  /** The user is not assigned to the ticket */
  UserNotAssignee = 'USER_NOT_ASSIGNEE'
}

export type UpdateMeetingServiceRequestStatusErrorResponse = {
  __typename?: 'UpdateMeetingServiceRequestStatusErrorResponse';
  reason: UpdateMeetingServiceRequestStatusErrorReason;
};

export type UpdateMeetingServiceRequestStatusInput = {
  meetingServiceRequestId: Scalars['ID']['input'];
  updatableMeetingServiceRequestStatus: UpdatableMeetingServiceRequestStatus;
};

export type UpdateMeetingServiceRequestStatusSuccessResponse = {
  __typename?: 'UpdateMeetingServiceRequestStatusSuccessResponse';
  _?: Maybe<Scalars['Void']['output']>;
  /** @deprecated Does not change */
  meetingServiceRequestId: Scalars['ID']['output'];
  /** @deprecated Refetch status with getMeetingServiceRequestByIdentifier instead */
  meetingServiceRequestStatus: MeetingServiceRequestStatus;
};

export type UpdateMeetingServiceResponse = UpdateMeetingServiceErrorResponse | UpdateMeetingServiceSuccessResponse;

export type UpdateMeetingServiceSuccessResponse = {
  __typename?: 'UpdateMeetingServiceSuccessResponse';
  meetingServiceVersionId: Scalars['ID']['output'];
};

/** Set a field to null to avoid changing it on the reservation. */
export type UpdateMyReservationDeskAndTimeFrameInput = {
  end?: InputMaybe<DateWithTimezone>;
  reservationId: Scalars['ID']['input'];
  seatId?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<DateWithTimezone>;
};

export type UpdateWorkAreaInput = {
  deskIds: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateWorkAreasInput = {
  workAreasToCreate: Array<CreateWorkAreaInput>;
  workAreasToDelete: Array<Scalars['ID']['input']>;
  workAreasToUpdate: Array<UpdateWorkAreaInput>;
};

export type UpdatedReservation = {
  __typename?: 'UpdatedReservation';
  id: Scalars['ID']['output'];
};

export type UploadCsvForcePushRecords = {
  uuid: Scalars['ID']['input'];
};

export type UploadCsvVendor = {
  __typename?: 'UploadCSVVendor';
  name: Scalars['ID']['output'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  defaultLocation?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated in favor of primary_email */
  email?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationState: LocationState;
  name?: Maybe<Scalars['String']['output']>;
  notificationPreferences: NotificationPreferences;
  officeAccess: Array<OfficeAccess>;
  primaryEmail?: Maybe<Email>;
  reservations: Array<Reservation>;
  reservationsAtLocationGroupedByDateAscending: Array<UserDeskReservationByDate>;
  reservationsGroupedByDate: Array<UserDeskReservationByDate>;
  slug?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  workingHours?: Maybe<Array<Maybe<UserWorkingHour>>>;
};


export type UserLocationStateArgs = {
  campusId?: InputMaybe<Scalars['ID']['input']>;
  endTime: Scalars['Date']['input'];
  locationId: Scalars['ID']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
};


export type UserReservationsArgs = {
  endTime: Scalars['Date']['input'];
  startTime: Scalars['Date']['input'];
};


export type UserReservationsAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime']['input'];
  locationId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
};


export type UserReservationsGroupedByDateArgs = {
  endTime: Scalars['Date']['input'];
  startTime: Scalars['Date']['input'];
};

export type UserAssignment = {
  __typename?: 'UserAssignment';
  assignments: Array<Maybe<DeskAssignmentSchedule>>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserDeskReservation = {
  __typename?: 'UserDeskReservation';
  reservations: Array<Reservation>;
  userId: Scalars['ID']['output'];
};

export type UserDeskReservationByDate = {
  __typename?: 'UserDeskReservationByDate';
  date: Scalars['Date']['output'];
  reservations: Array<UserTruncatedReservation>;
};

export type UserInOffice = {
  __typename?: 'UserInOffice';
  deskReservations: Array<Reservation>;
  employeeVisits: Array<EmployeeVisit>;
  userId: Scalars['ID']['output'];
};

export type UserInOfficeByDay = {
  __typename?: 'UserInOfficeByDay';
  date: Scalars['String']['output'];
  userInOffice?: Maybe<UserInOffice>;
};

export type UserNotificationsSetting = {
  __typename?: 'UserNotificationsSetting';
  name: Scalars['String']['output'];
  ownerId: Scalars['ID']['output'];
  values: Array<Maybe<NotificationSettingType>>;
};

export type UserOrGroup = {
  __typename?: 'UserOrGroup';
  userOrGroup?: Maybe<UserOrGroupUnion>;
};

/** Either a group ID or a user ID is required */
export type UserOrGroupInput = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserOrGroupUnion = Group | User;

export type UserSetting = {
  __typename?: 'UserSetting';
  dataType: SettingDataType;
  ownerId: Scalars['ID']['output'];
  ownerType: SettingOwnerType;
  slug: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum UserSortBy {
  Favorite = 'FAVORITE',
  Name = 'NAME'
}

export enum UserSortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type UserSyncStatus = {
  __typename?: 'UserSyncStatus';
  state: SyncState;
  userCount: Scalars['Int']['output'];
};

export type UserTimeFrame = {
  __typename?: 'UserTimeFrame';
  end?: Maybe<Scalars['Date']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
};

export type UserTruncatedDeskConfirmation = {
  __typename?: 'UserTruncatedDeskConfirmation';
  confirmedAt?: Maybe<Scalars['String']['output']>;
  deviceId?: Maybe<Scalars['ID']['output']>;
  seatReservationId: Scalars['ID']['output'];
};

export type UserTruncatedReservation = {
  __typename?: 'UserTruncatedReservation';
  /** @deprecated Broken, use `truncatedConfirmation` */
  confirmation?: Maybe<DeskConfirmation>;
  endTime?: Maybe<Scalars['String']['output']>;
  healthCheckpointStatus?: Maybe<HealthCheckpointStatus>;
  /** Reservation Id */
  id: Scalars['ID']['output'];
  recurrence?: Maybe<Scalars['String']['output']>;
  reservee: UserTruncatedReservee;
  seat: Desk;
  seriesId?: Maybe<Scalars['ID']['output']>;
  startTime: Scalars['String']['output'];
  truncatedConfirmation?: Maybe<UserTruncatedDeskConfirmation>;
  type: Scalars['String']['output'];
  visibility: DeskReservationVisibility;
};

export type UserTruncatedReservee = {
  __typename?: 'UserTruncatedReservee';
  email: Scalars['String']['output'];
  participationStatus?: Maybe<ParticipationStatus>;
  userId?: Maybe<Scalars['ID']['output']>;
  visitorId?: Maybe<Scalars['ID']['output']>;
};

export type UserWithDefault = {
  __typename?: 'UserWithDefault';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  defaultLocation?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** If true, no user by this ID can be found in the user system */
  isDefault: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<Email>;
  slug?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  workingHours?: Maybe<Array<Maybe<UserWorkingHour>>>;
};

export type UserWorkingHour = {
  __typename?: 'UserWorkingHour';
  day?: Maybe<Scalars['Int']['output']>;
  timeFrames: Array<Maybe<UserTimeFrame>>;
};

export type UsersAndGroups = {
  __typename?: 'UsersAndGroups';
  groups: Array<Maybe<Group>>;
  /** Users and Groups in one list - use fragments to select fields */
  usersAndGroups: Array<Maybe<UserOrGroupUnion>>;
  /** groups also contain users, but these are in addition to the users from the groups */
  usersOutsideOfGroups: Array<Maybe<User>>;
};

/** At least one group or user ID is required */
export type UsersAndGroupsInput = {
  groupIds: Array<Scalars['ID']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};

export enum Visit_Document_Status {
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE',
  Live = 'LIVE'
}

export enum VerkadaTenancy {
  Au = 'AU',
  Eu = 'EU',
  Gov = 'GOV',
  Us = 'US'
}

export type Visit = {
  __typename?: 'Visit';
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions?: Maybe<Scalars['String']['output']>;
  /** Arrival location */
  arrivalLocation: Location;
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID']['output'];
  /** The custom fields for the visit */
  customFields?: Maybe<Array<CustomField>>;
  /** Custom reason for this visit */
  customVisitType?: Maybe<Scalars['String']['output']>;
  /** A custom email configuration associated with the visit's location */
  emailConfiguration?: Maybe<EmailConfiguration>;
  /** Number of guests in the visit */
  guestCount?: Maybe<Scalars['Int']['output']>;
  /** Guest invitations */
  guestInvites: Array<GuestInvite>;
  /** Guests for this visit */
  guests: Array<Guest>;
  /** Host robin user */
  host: UserWithDefault;
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID']['output'];
  /** Visit unique identifier */
  id: Scalars['ID']['output'];
  /** Organization */
  organization: Organization;
  /** How was the visit created? */
  organizer: VisitOrganizer;
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime']['output'];
  /**
   * Reason for this visit
   * @deprecated replaced by the more flexible customVisitType
   */
  visitType: VisitType;
};


export type VisitGuestInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitGuestsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum VisitAsyncRequestReport {
  Done = 'DONE',
  Error = 'ERROR',
  HcpCreated = 'HCP_CREATED',
  VisitCreated = 'VISIT_CREATED'
}

export enum VisitAsyncRequestStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  RequestReceived = 'REQUEST_RECEIVED'
}

export type VisitAsyncRequestStatusResponse = {
  __typename?: 'VisitAsyncRequestStatusResponse';
  errorText: Array<Scalars['String']['output']>;
  status?: Maybe<VisitAsyncRequestStatus>;
};

export type VisitDocumentAgreementOutcome = {
  __typename?: 'VisitDocumentAgreementOutcome';
  id: Scalars['ID']['output'];
  outcome: VisitDocumentAgreementStatus;
};

export type VisitDocumentAgreementResponse = {
  __typename?: 'VisitDocumentAgreementResponse';
  completionStatus: VisitDocumentCompletionStatus;
  documentBody: Scalars['DocumentBody']['output'];
  documentName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  possibleOutcomes: Array<VisitDocumentAgreementOutcome>;
  selectedOutcome?: Maybe<VisitDocumentAgreementOutcome>;
};

export enum VisitDocumentAgreementStatus {
  Accept = 'ACCEPT'
}

export type VisitDocumentAgreementWithNextDocumentResponse = {
  __typename?: 'VisitDocumentAgreementWithNextDocumentResponse';
  completionStatus: VisitDocumentCompletionStatus;
  documentBody: Scalars['DocumentBody']['output'];
  documentName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  /** If NULL, there is no next document */
  nextDocumentId?: Maybe<Scalars['ID']['output']>;
  possibleOutcomes: Array<VisitDocumentAgreementOutcome>;
  selectedOutcome?: Maybe<VisitDocumentAgreementOutcome>;
  workflowId: Scalars['ID']['output'];
};

export type VisitDocumentByDecisionResponse = {
  __typename?: 'VisitDocumentByDecisionResponse';
  /** Date and time the document was accepted for specified decision */
  agreedOn: Scalars['ISODateTime']['output'];
  /** Document body */
  body: Scalars['DocumentBody']['output'];
  /** ID of document associated with descition */
  id: Scalars['ID']['output'];
  /** Document name */
  name: Scalars['String']['output'];
  /** Email addresses of individuals notified via email */
  notificationEmails: Array<Scalars['String']['output']>;
  /** Associated visit model */
  visit: VisitOrDefault;
};

export enum VisitDocumentCompletionStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export enum VisitDocumentNameStatusAndTypesField {
  LocationId = 'LOCATION_ID',
  Status = 'STATUS',
  VisitType = 'VISIT_TYPE'
}

export type VisitDocumentNameStatusAndTypesFilter = {
  filterOn: VisitDocumentNameStatusAndTypesField;
  filterValues: Array<Scalars['String']['input']>;
};

export type VisitDocumentNameStatusAndTypesQueryResponse = {
  __typename?: 'VisitDocumentNameStatusAndTypesQueryResponse';
  /** ISO encoded timestamp representing date/time when this document was added */
  addedOn: Scalars['ISODateTime']['output'];
  id: Scalars['ID']['output'];
  /** Name of the document */
  name: Scalars['String']['output'];
  /** Association status of this document to active or archived workflows */
  status: Visit_Document_Status;
  /** The types of visits, if any, which are actively associated with this document */
  visitTypes: Array<Scalars['String']['output']>;
};

export type VisitDocumentResponse = {
  __typename?: 'VisitDocumentResponse';
  /** The full body text of the document. */
  documentBody: Scalars['String']['output'];
  /** The unique name of the document */
  documentName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The locations which are associated with this document */
  locationIds: Array<Scalars['String']['output']>;
  /** Notification emails associated with document */
  notificationEmails: Array<Scalars['String']['output']>;
  /** Association status of this document to active or archived workflows */
  status: Visit_Document_Status;
  /** The types of visits which are associated with this document */
  visitTypes: Array<Scalars['String']['output']>;
};

export enum VisitEventAcknowledgement {
  VisitStartingSoon = 'VISIT_STARTING_SOON'
}

export type VisitInputType = {
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions?: InputMaybe<Scalars['String']['input']>;
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID']['input'];
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID']['input'];
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime']['input'];
  /** Reason for this visit */
  visitType: VisitType;
};

export type VisitMagicLinkAuthResponse = {
  __typename?: 'VisitMagicLinkAuthResponse';
  id: Scalars['ID']['output'];
  tenantId: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type VisitOrDefault = {
  __typename?: 'VisitOrDefault';
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions?: Maybe<Scalars['String']['output']>;
  /** Arrival location */
  arrivalLocation?: Maybe<Location>;
  /** Visit unique identifier */
  arrivalLocationId?: Maybe<Scalars['ID']['output']>;
  /** Number of guests in the visit */
  guestCount?: Maybe<Scalars['Int']['output']>;
  /** Guest invitations */
  guestInvites?: Maybe<Array<GuestInvite>>;
  /** Guests for this visit */
  guests?: Maybe<Array<Guest>>;
  /** Host robin user */
  host?: Maybe<UserWithDefault>;
  /** ID of user acting as host for this visit */
  hostUserId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  /** Organization */
  organization?: Maybe<Organization>;
  /** How was the visit created? */
  organizer?: Maybe<VisitOrganizer>;
  /** Start time of visit represented by ISO 8601 date-time */
  startTime?: Maybe<Scalars['ISODateTime']['output']>;
  /** Reason for this visit */
  visitType?: Maybe<VisitType>;
};


export type VisitOrDefaultGuestInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitOrDefaultGuestsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VisitOrganizer = {
  __typename?: 'VisitOrganizer';
  /** The arrival display that the visit was created on, if exists */
  arrivalDisplay?: Maybe<PublicArrivalDisplay>;
  /** The ID of the entity from where the visit was created */
  id: Scalars['ID']['output'];
  /** How the visit was created */
  type: VisitOrganizerType;
};

export enum VisitOrganizerType {
  ArrivalDisplay = 'ARRIVAL_DISPLAY',
  User = 'USER'
}

export enum VisitType {
  CustomerVisit = 'CUSTOMER_VISIT',
  Event = 'EVENT',
  JobInterview = 'JOB_INTERVIEW',
  Maintenance = 'MAINTENANCE',
  Other = 'OTHER',
  VipVisit = 'VIP_VISIT'
}

export type VisitWithIdInputType = {
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID']['input'];
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID']['input'];
  /** ID of the visit */
  id: Scalars['ID']['input'];
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime']['input'];
  /** Reason for this visit */
  visitType: VisitType;
};

export type VisitWorkflowOverview = {
  __typename?: 'VisitWorkflowOverview';
  /** Workflow documents */
  documents: Array<WorkflowDocumentOverviewForVisitor>;
  /** Workflow ID */
  id: Scalars['ID']['output'];
};

export type VisitorManagementPermissionScopes = {
  __typename?: 'VisitorManagementPermissionScopes';
  /** A user with this permission has read/write access to any visit */
  canManageAllVisits?: Maybe<PermissionScope>;
  /** A user with this permission has read/write access to any visit they host */
  canManageOwnVisits?: Maybe<PermissionScope>;
  /** A user with this permission can manage visitor management itself */
  canManageVisits?: Maybe<PermissionScope>;
  /** A user with this permission has read access to any visit */
  canViewAllVisits?: Maybe<PermissionScope>;
  /** A user with this permission has read access to any visit they host */
  canViewOwnVisits?: Maybe<PermissionScope>;
};

export type VisitorManagementPermissions = {
  __typename?: 'VisitorManagementPermissions';
  canManageAllVisits?: Maybe<Scalars['Boolean']['output']>;
  canManageOwnVisits?: Maybe<Scalars['Boolean']['output']>;
  canManageVisits?: Maybe<Scalars['Boolean']['output']>;
  canViewAllVisits?: Maybe<Scalars['Boolean']['output']>;
  canViewOwnVisits?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated No longer supported */
  canViewVisits?: Maybe<Scalars['Boolean']['output']>;
};

export type WeeklyBookingCommand = {
  friday: BookingType;
  monday: BookingType;
  saturday: BookingType;
  sunday: BookingType;
  thursday: BookingType;
  tuesday: BookingType;
  wednesday: BookingType;
};

export type WeeklyBookingType = {
  __typename?: 'WeeklyBookingType';
  friday: BookingType;
  monday: BookingType;
  saturday: BookingType;
  sunday: BookingType;
  thursday: BookingType;
  tuesday: BookingType;
  wednesday: BookingType;
};

export type WeeklyScheduleResult = {
  __typename?: 'WeeklyScheduleResult';
  fri: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  instanceEndTime: Scalars['UtcTime']['output'];
  instanceStartTime: Scalars['UtcTime']['output'];
  location?: Maybe<Location>;
  mon: Scalars['Boolean']['output'];
  sat: Scalars['Boolean']['output'];
  sun: Scalars['Boolean']['output'];
  thu: Scalars['Boolean']['output'];
  tue: Scalars['Boolean']['output'];
  wed: Scalars['Boolean']['output'];
};

export type WhosInData = {
  __typename?: 'WhosInData';
  byLocation?: Maybe<Array<WhosInDataByLocation>>;
  totalNumberOfWhosIn: Scalars['Int']['output'];
  whosIn?: Maybe<Array<WhosInUser>>;
};


export type WhosInDataTotalNumberOfWhosInArgs = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type WhosInDataWhosInArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WhosInDataByDay = {
  __typename?: 'WhosInDataByDay';
  date: Scalars['ISODateTime']['output'];
  whosInData?: Maybe<WhosInData>;
  /** @deprecated Deprecated in favor of whosInData.byLocation */
  whosInDataByLocation?: Maybe<Array<WhosInDataByLocation>>;
};

export type WhosInDataByLocation = {
  __typename?: 'WhosInDataByLocation';
  locationId: Scalars['ID']['output'];
  totalNumberOfWhosIn: Scalars['Int']['output'];
  whosIn?: Maybe<Array<WhosInUser>>;
  /** @deprecated Deprecated in favor of totalNumberOfWhosIn and whosIn */
  whosInData: WhosInData;
};


export type WhosInDataByLocationWhosInArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WhosInUser = {
  __typename?: 'WhosInUser';
  deskReservationIds: Array<Scalars['String']['output']>;
  employeeVisitId?: Maybe<Scalars['String']['output']>;
  isAnonymous: Scalars['Boolean']['output'];
  locationId: Scalars['ID']['output'];
  userEmail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type WindowAvailability = {
  __typename?: 'WindowAvailability';
  end?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Deprecated because the end becomes representing the pillEnd */
  pillEnd?: Maybe<Scalars['Date']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Availability>;
};

export type WorkArea = {
  __typename?: 'WorkArea';
  /** Total desks within the level that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  desks: Array<Desk>;
  id: Scalars['ID']['output'];
  level: Level;
  name: Scalars['String']['output'];
};

export type WorkPolicy = {
  __typename?: 'WorkPolicy';
  createdAt?: Maybe<Scalars['ISODateTime']['output']>;
  id: Scalars['ID']['output'];
  interval: WorkPolicyInterval;
  isDefault: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
  status: WorkPolicyStatus;
  tooltipMessage?: Maybe<Scalars['String']['output']>;
};

export type WorkPolicyInput = {
  interval: WorkPolicyIntervalInput;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status: WorkPolicyStatus;
  tooltipMessage?: InputMaybe<Scalars['String']['input']>;
};

export type WorkPolicyInterval = {
  __typename?: 'WorkPolicyInterval';
  frequency?: Maybe<Scalars['Int']['output']>;
  intervalType: WorkPolicyIntervalType;
  specificDays?: Maybe<Array<Scalars['Int']['output']>>;
};

export type WorkPolicyIntervalInput = {
  frequency?: InputMaybe<Scalars['Int']['input']>;
  intervalType: WorkPolicyIntervalType;
  specificDays?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum WorkPolicyIntervalType {
  Monthly = 'MONTHLY',
  SpecificDays = 'SPECIFIC_DAYS',
  Weekly = 'WEEKLY'
}

export enum WorkPolicyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Scheduled = 'SCHEDULED'
}

export type WorkPolicyWithProgress = {
  __typename?: 'WorkPolicyWithProgress';
  id: Scalars['ID']['output'];
  interval: WorkPolicyInterval;
  message?: Maybe<Scalars['String']['output']>;
  minimumDaysProgress?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use minimumDaysProgress instead. */
  progress: Scalars['Int']['output'];
  specificDaysProgress?: Maybe<Array<Scalars['Int']['output']>>;
  tooltipMessage?: Maybe<Scalars['String']['output']>;
};

export type WorkflowDocumentOverviewForVisitor = {
  __typename?: 'WorkflowDocumentOverviewForVisitor';
  /** Is document complete for this visit */
  completionStatus: VisitDocumentCompletionStatus;
  id: Scalars['ID']['output'];
  /** Is document required in workflow */
  isRequired: Scalars['Boolean']['output'];
  /** Name of the document */
  name: Scalars['String']['output'];
};

export type WorkingHours = {
  __typename?: 'WorkingHours';
  day?: Maybe<Scalars['Int']['output']>;
  timeFrames: Array<TimeFrame>;
};

export type WorkingHoursInput = {
  day?: InputMaybe<DayOfWeek>;
  timeFrames: Array<TimeFrameInput>;
};

/** A zone is an area within a space that represents a collection of desks or a table */
export type Zone = {
  __typename?: 'Zone';
  desks: Array<Desk>;
  filteredDesksByAmenityIds: Array<Desk>;
  /** Zone ID is a unique identifier for the zone */
  id: Scalars['ID']['output'];
  /** Name of the zone */
  name: Scalars['String']['output'];
  /** Identifies the type of this zone. Zones can be Pods or Table */
  type: ZoneType;
};


/** A zone is an area within a space that represents a collection of desks or a table */
export type ZoneDesksArgs = {
  filters?: InputMaybe<Array<DeskFilters>>;
};


/** A zone is an area within a space that represents a collection of desks or a table */
export type ZoneFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type ZoneDeskReservation = {
  __typename?: 'ZoneDeskReservation';
  reservations: Array<Reservation>;
  zoneId: Scalars['ID']['output'];
};

/** Describes the type of zone */
export enum ZoneType {
  /** A pod is a collection of desks */
  Pod = 'POD',
  /** A zone with the table type is typically a large table with many seats */
  Table = 'TABLE'
}

export type ForceResolveCsvUploadRecordsResponse = {
  __typename?: 'forceResolveCSVUploadRecordsResponse';
  status: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type SetOpenpathCredentialProvisioningEnabledForIntegrationInput = {
  integrationUuid: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};

export type SetOpenpathCredentialProvisioningEnabledForIntegrationResponse = {
  __typename?: 'setOpenpathCredentialProvisioningEnabledForIntegrationResponse';
  uuid: Scalars['ID']['output'];
};

export type SetOpenpathPresenceReportingEnabledForIntegrationInput = {
  integrationUuid: Scalars['ID']['input'];
  isEnabled: Scalars['Boolean']['input'];
};

export type SetOpenpathPresenceReportingEnabledForIntegrationResponse = {
  __typename?: 'setOpenpathPresenceReportingEnabledForIntegrationResponse';
  uuid: Scalars['ID']['output'];
};

export type UpdateMeetingServiceRequestStatusResponse = UpdateMeetingServiceRequestStatusErrorResponse | UpdateMeetingServiceRequestStatusSuccessResponse;

export type DeskReservationsForCancelModalQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
}>;


export type DeskReservationsForCancelModalQuery = { __typename?: 'Query', getDeskReservationsByUserId: { __typename?: 'UserDeskReservation', userId: string, reservations: Array<{ __typename?: 'Reservation', id: string, startTime: string }> } };

export type EndOrCancelDelegatedReservationByIdMutationVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;


export type EndOrCancelDelegatedReservationByIdMutation = { __typename?: 'Mutation', endOrCancelDelegatedReservation: { __typename?: 'EndOrCancelReservationResponse', reservationId: string } };

export type EndOrCancelReservationByIdMutationVariables = Exact<{
  reservationId: Scalars['ID']['input'];
}>;


export type EndOrCancelReservationByIdMutation = { __typename?: 'Mutation', endOrCancelReservation: { __typename?: 'EndOrCancelReservationResponse', reservationId: string } };

export type EndReservationOrVisitsInRangeMutationVariables = Exact<{
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
  cancelFutureVisits?: InputMaybe<Scalars['Boolean']['input']>;
  creationType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type EndReservationOrVisitsInRangeMutation = { __typename?: 'Mutation', endReservationOrVisitsInRange?: { __typename?: 'EndReservationOrVisitInRangeResponse', success: boolean, visitsCancelledAttempts?: Array<{ __typename?: 'CancelledVisitOrReservationResult', id: string, success: boolean, error?: string | null } | null> | null, reservationsCancelledAttempts?: Array<{ __typename?: 'CancelledVisitOrReservationResult', id: string, success: boolean, error?: string | null } | null> | null } | null };

export type UseSeriesStartTimesForCancelQueryVariables = Exact<{
  seriesId: Scalars['ID']['input'];
}>;


export type UseSeriesStartTimesForCancelQuery = { __typename?: 'Query', getDeskReservationInstancesById: Array<{ __typename?: 'Reservation', id: string, startTime: string }> };

export type DeskLocationDetailsQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
}>;


export type DeskLocationDetailsQuery = { __typename?: 'Query', getDeskById?: { __typename?: 'Desk', id: string, name: string, level?: { __typename?: 'Level', name: string } | null, location: { __typename?: 'Location', name: string } } | null };

export type ReportIssueWithDeskMutationVariables = Exact<{
  input: ReportIssueWithDeskInput;
}>;


export type ReportIssueWithDeskMutation = { __typename?: 'Mutation', reportIssueWithDesk: { __typename?: 'ReportIssueResponse', id: string } };

export type ReportIssueWithSpaceMutationVariables = Exact<{
  input: ReportIssueWithSpaceInput;
}>;


export type ReportIssueWithSpaceMutation = { __typename?: 'Mutation', reportIssueWithSpace: { __typename?: 'ReportIssueResponse', id: string } };

export type SpaceLocationDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SpaceLocationDetailsQuery = { __typename?: 'Query', getSpaceById?: { __typename?: 'Space', id: string, name: string, locationId: number } | null };

export type LocationDetailsQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type LocationDetailsQuery = { __typename?: 'Query', getLocationById?: { __typename?: 'Location', id: string, name: string, levels: Array<{ __typename?: 'Level', id: string, name: string }> } | null };

export type DeskFilterQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
  filters?: InputMaybe<Array<DeskFilters> | DeskFilters>;
}>;


export type DeskFilterQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', desks: Array<{ __typename?: 'Desk', id: string }> } | null };

export type SpaceFilterQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
}>;


export type SpaceFilterQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', spaces: Array<{ __typename?: 'Space', id: string }> } | null };

export type DeskAmenityFilterQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
  filters?: InputMaybe<Array<DeskFilters> | DeskFilters>;
}>;


export type DeskAmenityFilterQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', desks: Array<{ __typename?: 'Desk', id: string, amenities: Array<{ __typename?: 'DeskAmenity', id: string, name: string, amenityId?: number | null }> }> } | null };

export type SpaceAmenityFilterQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
}>;


export type SpaceAmenityFilterQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', spaces: Array<{ __typename?: 'Space', id: string, amenities: Array<{ __typename?: 'SpaceAmenity', id: string, name: string, amenityId?: number | null }> }> } | null };

export type SpaceCapacityFilterQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
}>;


export type SpaceCapacityFilterQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', spaces: Array<{ __typename?: 'Space', id: string, capacity?: number | null }> } | null };

export type SpaceTypeFilterQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
}>;


export type SpaceTypeFilterQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', spaces: Array<{ __typename?: 'Space', id: string, type?: SpaceType | null }> } | null };

export type DeskPermissionsQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
}>;


export type DeskPermissionsQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', id: string, desks: Array<{ __typename?: 'Desk', permissions: Array<{ __typename?: 'Permission', name: string, value: boolean }> }> } | null };

export type DeleteDeskExclusionsMutationVariables = Exact<{
  seriesId: Scalars['ID']['input'];
  exclusionIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  dates: Array<DateRangeWithTimeZone> | DateRangeWithTimeZone;
  hasDates: Scalars['Boolean']['input'];
  hasDeletions: Scalars['Boolean']['input'];
}>;


export type DeleteDeskExclusionsMutation = { __typename?: 'Mutation', deleteDeskExclusions?: void | null, createDeskExclusions?: Array<string> | null };

export type GetReleaseDeskExclusionsQueryVariables = Exact<{
  reservationSeriesId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
}>;


export type GetReleaseDeskExclusionsQuery = { __typename?: 'Query', getReservationExclusions: Array<{ __typename?: 'Exclusion', id: string, startTime: string, endTime?: string | null }> };

export type GetReleaseDeskScheduleByDeskIdQueryVariables = Exact<{
  getSharedDeskScheduleIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type GetReleaseDeskScheduleByDeskIdQuery = { __typename?: 'Query', getSharedDesksSchedules?: Array<{ __typename?: 'SharedDeskSchedule', id: string, schedule: Array<{ __typename?: 'DeskSchedule', id?: string | null, recurrence: string }> } | null> | null };

export type DeskBookingControlsQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
}>;


export type DeskBookingControlsQuery = { __typename?: 'Query', getDeskById?: { __typename?: 'Desk', id: string, name: string, type: BookingPolicyType, settings?: Array<{ __typename?: 'Setting', slug: string, value: string }> | null, permissions: Array<{ __typename?: 'Permission', name: string, value: boolean }> } | null };

export type ReserveDelegatedDeskMutationVariables = Exact<{
  seatId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  type: Scalars['String']['input'];
  start: DateWithTimezone;
  end: DateWithTimezone;
  recurrence?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  visibility: DeskReservationVisibility;
}>;


export type ReserveDelegatedDeskMutation = { __typename?: 'Mutation', reserveDelegatedDesk: { __typename?: 'Reservation', id: string } };

export type ReserveDeskForMeMutationVariables = Exact<{
  seatId: Scalars['Int']['input'];
  type: Scalars['String']['input'];
  start: DateWithTimezone;
  end: DateWithTimezone;
  visibility?: InputMaybe<DeskReservationVisibility>;
  recurrence?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ReserveDeskForMeMutation = { __typename?: 'Mutation', reserveDeskForMe: { __typename?: 'Reservation', id: string } };

export type DeskSharedScheduleQueryVariables = Exact<{
  deskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type DeskSharedScheduleQuery = { __typename?: 'Query', getDesksByIds: Array<{ __typename?: 'Desk', id: string, name: string, level?: { __typename?: 'Level', id: string, name: string } | null, location: { __typename?: 'Location', id: string, name: string } }>, getSharedDesksSchedules?: Array<{ __typename?: 'SharedDeskSchedule', id: string, schedule: Array<{ __typename?: 'DeskSchedule', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, recurrence: string, avatar?: string | null }> } | null> | null };

export type PublishSharedScheduleForDeskMutationVariables = Exact<{
  input: SharedDeskSchedulesInput;
}>;


export type PublishSharedScheduleForDeskMutation = { __typename?: 'Mutation', publishSharedScheduleForDesk?: void | null };

export type UpcomingDeskReservationsByDeskIdQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  duration: Scalars['Int']['input'];
}>;


export type UpcomingDeskReservationsByDeskIdQuery = { __typename?: 'Query', getDeskReservationsBySeatId: Array<{ __typename?: 'Reservation', startTime: string, endTime?: string | null, type: string, accountReservee?: { __typename?: 'AccountReservee', user?: { __typename?: 'AccountUser', id: string, name?: string | null, avatar?: string | null, primaryEmail?: { __typename?: 'Email', email: string } | null } | null } | null }> };

export type CanEditDesksQueryVariables = Exact<{ [key: string]: never; }>;


export type CanEditDesksQuery = { __typename?: 'Query', getCurrentUserAndOrgs?: { __typename?: 'CurrentUserAndOrgs', userId: string, hasPlacesManageForCurrentTenant: boolean } | null };

export type ConfirmDeskReservationFromDashboardMutationVariables = Exact<{
  reservationId: Scalars['ID']['input'];
  type: DeskConfirmationType;
}>;


export type ConfirmDeskReservationFromDashboardMutation = { __typename?: 'Mutation', confirmDeskReservation?: void | null };

export type DeskAmenitiesQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
}>;


export type DeskAmenitiesQuery = { __typename?: 'Query', getDeskById?: { __typename?: 'Desk', id: string, amenities: Array<{ __typename?: 'DeskAmenity', id: string, name: string, quantity: number }> } | null };

export type DeskDetailsByIdQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
  userId: Scalars['Int']['input'];
  startTimeMoment: Scalars['Date']['input'];
  endTimeMoment: Scalars['Date']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeskDetailsByIdQuery = { __typename?: 'Query', getDeskById?: { __typename?: 'Desk', id: string, rawType: Array<DeskType>, name: string, zone?: { __typename?: 'Zone', name: string } | null, location: { __typename?: 'Location', name: string }, level?: { __typename?: 'Level', name: string } | null, permissions: Array<{ __typename?: 'Permission', name: string, value: boolean }>, state: { __typename?: 'DeskState', exclusions: Array<{ __typename?: 'Exclusion', startTime: string, endTime?: string | null }> } } | null };

export type DeskListItemDataQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
  userId: Scalars['Int']['input'];
  startTimeMoment: Scalars['Date']['input'];
  endTimeMoment: Scalars['Date']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeskListItemDataQuery = { __typename?: 'Query', getAnyDeskById?: { __typename?: 'Desk', id: string, rawType: Array<DeskType>, state: { __typename?: 'DeskState', reservations: Array<{ __typename?: 'Reservation', id: string, startTime: string, endTime?: string | null, visibility: DeskReservationVisibility, reservee?: { __typename?: 'Reservee', user?: { __typename?: 'User', name?: string | null, avatar?: string | null, id: string } | null } | null }> } } | null };

export type DeskReservationPoliciesQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
}>;


export type DeskReservationPoliciesQuery = { __typename?: 'Query', getDeskById?: { __typename?: 'Desk', id: string, location: { __typename?: 'Location', isHealthCheckpointRequired: boolean }, reservationPolicies?: { __typename?: 'DeskReservationPolicies', seatReservationTypesAllowed?: string | null, allowExclusions?: boolean | null, seatReservationAdvancedBookingThreshold?: string | null, seatReservationMaxLength?: string | null, allowOverlappingReservations?: boolean | null, seatReservationVisibilityEnabled?: boolean | null, abandonedDeskProtectionThreshold?: string | null, abandonedDeskProtectionEnabled?: boolean | null, checkinPolicies?: { __typename?: 'DeskReservationCheckinPolicies', enforceLocalCheckinOnly: boolean, checkInWindow?: string | null, assignedCheckInEnabled?: boolean | null } | null } | null } | null };

export type DeskReservationReserveeQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  duration: Scalars['Int']['input'];
}>;


export type DeskReservationReserveeQuery = { __typename?: 'Query', getDeskReservationsBySeatId: Array<{ __typename?: 'Reservation', id: string, visibility: DeskReservationVisibility, reservee?: { __typename?: 'Reservee', user?: { __typename?: 'User', id: string } | null } | null }> };

export type DeskReservationsBySeatIdQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
  startTime: Scalars['ISODateTime']['input'];
  duration: Scalars['Int']['input'];
}>;


export type DeskReservationsBySeatIdQuery = { __typename?: 'Query', getDeskReservationsBySeatId: Array<{ __typename?: 'Reservation', id: string, seriesId?: string | null, startTime: string, endTime?: string | null, timeZone?: string | null, canCheckInNow?: boolean | null, isCheckedIn?: boolean | null, visibility: DeskReservationVisibility, reservee?: { __typename?: 'Reservee', user?: { __typename?: 'User', id: string, name?: string | null, avatar?: string | null, primaryEmail?: { __typename?: 'Email', email: string } | null } | null } | null, healthCheckpoint?: { __typename?: 'HealthCheckpoint', status: HealthCheckpointStatus } | null }> };

export type DeskReservationsForDeskPolicyCardQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  start: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
}>;


export type DeskReservationsForDeskPolicyCardQuery = { __typename?: 'Query', getDeskReservationsByUserId: { __typename?: 'UserDeskReservation', userId: string, reservations: Array<{ __typename?: 'Reservation', id: string, startTime: string, seat: { __typename?: 'Desk', id: string } }> } };

export type DeskResourceDetailsQueryVariables = Exact<{
  deskId: Scalars['ID']['input'];
  deskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  dates: Array<Scalars['LocalDate']['input']> | Scalars['LocalDate']['input'];
  startTime: Scalars['LocalTime']['input'];
  durationInMinutes: Scalars['Int']['input'];
  timezone: Scalars['IANATimezone']['input'];
  userId: Scalars['Int']['input'];
  startTimeMoment: Scalars['Date']['input'];
  endTimeMoment: Scalars['Date']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeskResourceDetailsQuery = { __typename?: 'Query', getAnyDeskById?: { __typename?: 'Desk', id: string, name: string, rawType: Array<DeskType>, isReservable: boolean, isDisabled?: boolean | null, permissions: Array<{ __typename?: 'Permission', name: string, value: boolean }>, level?: { __typename?: 'Level', name: string } | null, location: { __typename?: 'Location', name: string }, zone?: { __typename?: 'Zone', name: string } | null, stickers: Array<{ __typename?: 'Sticker', displayCode: number }>, settings?: Array<{ __typename?: 'Setting', slug: string, value: string }> | null, state: { __typename?: 'DeskState', reservations: Array<{ __typename?: 'Reservation', startTime: string }>, exclusions: Array<{ __typename?: 'Exclusion', startTime: string, endTime?: string | null }> } } | null, reservationsMultiDayGroupedByDate: Array<{ __typename?: 'DeskAvailabilityLocalizedDate', deskId: string, availability: Availability, unbookableReasons: Array<{ __typename?: 'DeskunbookableReasons', reason: DeskUnbookableReasonType, value?: string | null } | null> }> };

export type GetDeskSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDeskSettingsByIdQuery = { __typename?: 'Query', getDeskById?: { __typename?: 'Desk', id: string, settings?: Array<{ __typename?: 'Setting', slug: string, value: string }> | null, permissions: Array<{ __typename?: 'Permission', name: string, value: boolean }> } | null };

export type DesksOnFloorQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
  filters?: InputMaybe<Array<DeskFilters> | DeskFilters>;
}>;


export type DesksOnFloorQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', id: string, zones: Array<{ __typename?: 'Zone', id: string, name: string, desks: Array<{ __typename?: 'Desk', id: string, name: string }> }> } | null };

export type EditBookingMutationVariables = Exact<{
  input: UpdateMyReservationDeskAndTimeFrameInput;
}>;


export type EditBookingMutation = { __typename?: 'Mutation', updateMyReservationDeskAndTimeFrame: { __typename?: 'UpdateDeskReservationResponse', updatedReservations: Array<{ __typename?: 'UpdatedReservation', id: string }> } };

export type GetEditReservationSummaryQueryVariables = Exact<{
  reservationId: Scalars['ID']['input'];
  deskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  dates: Array<Scalars['LocalDate']['input']> | Scalars['LocalDate']['input'];
  startTime: Scalars['LocalTime']['input'];
  durationInMinutes: Scalars['Int']['input'];
  timezone: Scalars['IANATimezone']['input'];
  userId: Scalars['Int']['input'];
  startTimeMoment: Scalars['Date']['input'];
  endTimeMoment: Scalars['Date']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEditReservationSummaryQuery = { __typename?: 'Query', getDesksByIds: Array<{ __typename?: 'Desk', id: string, name: string, rawType: Array<DeskType>, level?: { __typename?: 'Level', id: string, name: string } | null, location: { __typename?: 'Location', id: string, name: string }, zone?: { __typename?: 'Zone', name: string } | null, state: { __typename?: 'DeskState', reservations: Array<{ __typename?: 'Reservation', id: string, startTime: string }>, exclusions: Array<{ __typename?: 'Exclusion', startTime: string, endTime?: string | null }> } }>, reservationsMultiDayGroupedByDate: Array<{ __typename?: 'DeskAvailabilityLocalizedDate', deskId: string, availability: Availability, unbookableReasons: Array<{ __typename?: 'DeskunbookableReasons', reason: DeskUnbookableReasonType, value?: string | null } | null> }>, getDeskReservationById?: { __typename?: 'Reservation', id: string, seriesId?: string | null, startTime: string, endTime?: string | null } | null };

export type UseSeriesStartTimesQueryVariables = Exact<{
  seriesId: Scalars['ID']['input'];
}>;


export type UseSeriesStartTimesQuery = { __typename?: 'Query', getDeskReservationInstancesById: Array<{ __typename?: 'Reservation', id: string, startTime: string }> };

export type GetSharedDesksScheduleByDeskIdQueryVariables = Exact<{
  getSharedDeskScheduleIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type GetSharedDesksScheduleByDeskIdQuery = { __typename?: 'Query', getSharedDesksSchedules?: Array<{ __typename?: 'SharedDeskSchedule', id: string, schedule: Array<{ __typename?: 'DeskSchedule', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, recurrence: string, uniqueId: string, avatar?: string | null }> } | null> | null };

export type GetUsersByKeywordQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  keyword: Scalars['String']['input'];
}>;


export type GetUsersByKeywordQuery = { __typename?: 'Query', getUsersByKeyword: Array<{ __typename?: 'User', id: string, name?: string | null, avatar?: string | null, givenName?: string | null, familyName?: string | null, primaryEmail?: { __typename?: 'Email', email: string } | null }> };

export type BookingEnabledQueryVariables = Exact<{
  deskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  dates: Array<Scalars['LocalDate']['input']> | Scalars['LocalDate']['input'];
  startTime: Scalars['LocalTime']['input'];
  durationInMinutes: Scalars['Int']['input'];
  timezone: Scalars['IANATimezone']['input'];
  userId: Scalars['Int']['input'];
  startTimeMoment: Scalars['Date']['input'];
  endTimeMoment: Scalars['Date']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
}>;


export type BookingEnabledQuery = { __typename?: 'Query', getDesksByIds: Array<{ __typename?: 'Desk', id: string, rawType: Array<DeskType>, isReservable: boolean, isDisabled?: boolean | null, settings?: Array<{ __typename?: 'Setting', slug: string, value: string }> | null, state: { __typename?: 'DeskState', reservations: Array<{ __typename?: 'Reservation', id: string, reservee?: { __typename?: 'Reservee', user?: { __typename?: 'User', id: string, name?: string | null } | null } | null }>, exclusions: Array<{ __typename?: 'Exclusion', startTime: string, endTime?: string | null }> } }>, reservationsMultiDayGroupedByDate: Array<{ __typename?: 'DeskAvailabilityLocalizedDate', deskId: string, availability: Availability, unbookableReasons: Array<{ __typename?: 'DeskunbookableReasons', reason: DeskUnbookableReasonType } | null> }> };

export type SpaceAmenitiesDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SpaceAmenitiesDetailsQuery = { __typename?: 'Query', getSpaceById?: { __typename?: 'Space', id: string, amenities: Array<{ __typename?: 'SpaceAmenity', id: string, name: string, quantity: number }> } | null, getCurrentUserAndOrgs?: { __typename?: 'CurrentUserAndOrgs', userId: string, hasPlacesManageForCurrentTenant: boolean } | null };

export type GetSpacesByLevelIdQueryVariables = Exact<{
  levelId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
  numberOfWindows?: InputMaybe<Scalars['Int']['input']>;
  meetingDurationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  snapTo?: InputMaybe<SnapTo>;
}>;


export type GetSpacesByLevelIdQuery = { __typename?: 'Query', getLevelById?: { __typename?: 'Level', id: string, spaces: Array<{ __typename?: 'Space', id: string, description?: string | null, name: string, image?: string | null, type?: SpaceType | null, capacity?: number | null, isAccessible?: boolean | null, locationId: number, scheduleConfiguration?: SpaceScheduleConfiguration | null, amenities: Array<{ __typename?: 'SpaceAmenity', id: string, name: string, amenityId?: number | null }>, meetingRoomWindowedAvailability: Array<{ __typename?: 'SpaceWindowAvailability', start?: string | null, end?: string | null, status?: Availability | null }> }> } | null };

export type SpaceReservationPoliciesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SpaceReservationPoliciesQuery = { __typename?: 'Query', getSpaceById?: { __typename?: 'Space', reservationPolicies: { __typename?: 'SpaceReservationPolicies', id: string, meetingCheckins: boolean, abandonedMeetingProtection: boolean, abandonedThreshold: string, strikesForRecurringEvents?: number | null, maxReservationLength?: string | null, advanceBookingThreshold?: string | null, allowRecurringEvents: boolean, restrictBookingToWorkingHours: boolean } } | null };

export type SpaceResourceDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SpaceResourceDetailsQuery = { __typename?: 'Query', getSpaceById?: { __typename?: 'Space', id: string, name: string, description?: string | null, image?: string | null, type?: SpaceType | null, capacity?: number | null, isAccessible?: boolean | null, locationId: number, isManaged: boolean, calendar?: { __typename?: 'Calendar', calendarId: string } | null, permissions: Array<{ __typename?: 'Permission', name: string, value: boolean }> } | null };

export type SpaceWindowedAvailabilityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
  numberOfWindows: Scalars['Int']['input'];
  meetingDurationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  snapTo?: InputMaybe<SnapTo>;
}>;


export type SpaceWindowedAvailabilityQuery = { __typename?: 'Query', getSpaceById?: { __typename?: 'Space', id: string, isManaged: boolean, calendar?: { __typename?: 'Calendar', calendarId: string } | null, meetingRoomWindowedAvailability: Array<{ __typename?: 'SpaceWindowAvailability', start?: string | null, end?: string | null, status?: Availability | null }> } | null };

export type GetLocationWorkingHoursQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type GetLocationWorkingHoursQuery = { __typename?: 'Query', getLocationById?: { __typename?: 'Location', id: string, name: string, timezone?: string | null, workingHours: Array<{ __typename?: 'WorkingHours', day?: number | null, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }>, customWorkingHours: Array<{ __typename?: 'CustomWorkingHours', id: string, date: string, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }> } | null };

export type GetMyCalendarEventsQueryVariables = Exact<{
  startTime: Scalars['Date']['input'];
  endTime: Scalars['Date']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetMyCalendarEventsQuery = { __typename?: 'Query', getMyEventsInTheRange: Array<{ __typename?: 'Event', id: string, title?: string | null, startTime?: string | null, endTime?: string | null, isAllDay: boolean, visibility?: string | null, invitees: Array<{ __typename?: 'EventInvitee', id?: string | null, responseStatus: EventResponseStatus, user?: { __typename?: 'User', id: string } | null }>, spaces: Array<{ __typename?: 'Space', id: string }> }> };

export type AuthContextQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthContextQuery = { __typename?: 'Query', getCurrentUserAndOrgs?: { __typename?: 'CurrentUserAndOrgs', userId: string, user: { __typename?: 'User', id: string, name?: string | null, timeZone?: string | null, avatar?: string | null, primaryEmail?: { __typename?: 'Email', email: string } | null }, organizations: Array<{ __typename?: 'Organization', id: string, name?: string | null, slug?: string | null, avatar?: string | null }> } | null };

export type GetOrSuggestMyDefaultLocationOrLevelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrSuggestMyDefaultLocationOrLevelQuery = { __typename?: 'Query', getOrSuggestMyDefaultLocationOrLevel?: { __typename?: 'DefaultLocationAndLevel', levelId?: string | null, locationId?: string | null } | null };

export type UseEndTimeQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type UseEndTimeQuery = { __typename?: 'Query', getLocationById?: { __typename?: 'Location', id: string, timezone?: string | null, workingHours: Array<{ __typename?: 'WorkingHours', day?: number | null, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }>, customWorkingHours: Array<{ __typename?: 'CustomWorkingHours', id: string, date: string, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }> } | null };

export type UserPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPermissionsQuery = { __typename?: 'Query', getCurrentUserAndOrgs?: { __typename?: 'CurrentUserAndOrgs', userId: string, hasOfficeAccessManagePermissionForCurrentTenant: boolean, hasSeatsReservePublicPermissionForCurrentTenant: boolean } | null };

export type UseLocationLevelsQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type UseLocationLevelsQuery = { __typename?: 'Query', getLocationById?: { __typename?: 'Location', id: string, name: string, timezone?: string | null, levels: Array<{ __typename?: 'Level', id: string, name: string, mapIsAvailable?: boolean | null }>, workingHours: Array<{ __typename?: 'WorkingHours', day?: number | null, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }>, customWorkingHours: Array<{ __typename?: 'CustomWorkingHours', id: string, date: string, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }> } | null };

export type UseLocationWorkingHoursQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
  startTime: Scalars['Date']['input'];
  endTime: Scalars['Date']['input'];
  userId: Scalars['ID']['input'];
  recurrence?: InputMaybe<Scalars['String']['input']>;
}>;


export type UseLocationWorkingHoursQuery = { __typename?: 'Query', getLocationById?: { __typename?: 'Location', id: string, name: string, address?: string | null, timezone?: string | null, state: { __typename?: 'LocationState', availability: { __typename?: 'PlaceAvailability', unavailableReasons: Array<PlaceUnavailableReason> } }, workingHours: Array<{ __typename?: 'WorkingHours', day?: number | null, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }>, customWorkingHours: Array<{ __typename?: 'CustomWorkingHours', id: string, date: string, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }> } | null };

export type GetOrganizationLocationsQueryVariables = Exact<{
  orgID: Scalars['ID']['input'];
}>;


export type GetOrganizationLocationsQuery = { __typename?: 'Query', getOrganizationById?: { __typename?: 'Organization', id: string, campuses: Array<{ __typename?: 'Campus', id: string, name: string }>, locations: Array<{ __typename?: 'Location', id: string, name: string, campus?: { __typename?: 'Campus', id: string, name: string } | null }> } | null };


export const DeskReservationsForCancelModalDocument = gql`
    query DeskReservationsForCancelModal($userId: ID!, $start: Date!, $end: Date!) {
  getDeskReservationsByUserId(id: $userId, startTime: $start, endTime: $end) {
    userId
    reservations {
      id
      startTime
    }
  }
}
    `;

/**
 * __useDeskReservationsForCancelModalQuery__
 *
 * To run a query within a React component, call `useDeskReservationsForCancelModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskReservationsForCancelModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskReservationsForCancelModalQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useDeskReservationsForCancelModalQuery(baseOptions: Apollo.QueryHookOptions<DeskReservationsForCancelModalQuery, DeskReservationsForCancelModalQueryVariables> & ({ variables: DeskReservationsForCancelModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskReservationsForCancelModalQuery, DeskReservationsForCancelModalQueryVariables>(DeskReservationsForCancelModalDocument, options);
      }
export function useDeskReservationsForCancelModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskReservationsForCancelModalQuery, DeskReservationsForCancelModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskReservationsForCancelModalQuery, DeskReservationsForCancelModalQueryVariables>(DeskReservationsForCancelModalDocument, options);
        }
export function useDeskReservationsForCancelModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskReservationsForCancelModalQuery, DeskReservationsForCancelModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskReservationsForCancelModalQuery, DeskReservationsForCancelModalQueryVariables>(DeskReservationsForCancelModalDocument, options);
        }
export type DeskReservationsForCancelModalQueryHookResult = ReturnType<typeof useDeskReservationsForCancelModalQuery>;
export type DeskReservationsForCancelModalLazyQueryHookResult = ReturnType<typeof useDeskReservationsForCancelModalLazyQuery>;
export type DeskReservationsForCancelModalSuspenseQueryHookResult = ReturnType<typeof useDeskReservationsForCancelModalSuspenseQuery>;
export type DeskReservationsForCancelModalQueryResult = Apollo.QueryResult<DeskReservationsForCancelModalQuery, DeskReservationsForCancelModalQueryVariables>;
export const EndOrCancelDelegatedReservationByIdDocument = gql`
    mutation EndOrCancelDelegatedReservationById($reservationId: ID!) {
  endOrCancelDelegatedReservation(reservationId: $reservationId) {
    reservationId
  }
}
    `;
export type EndOrCancelDelegatedReservationByIdMutationFn = Apollo.MutationFunction<EndOrCancelDelegatedReservationByIdMutation, EndOrCancelDelegatedReservationByIdMutationVariables>;

/**
 * __useEndOrCancelDelegatedReservationByIdMutation__
 *
 * To run a mutation, you first call `useEndOrCancelDelegatedReservationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndOrCancelDelegatedReservationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endOrCancelDelegatedReservationByIdMutation, { data, loading, error }] = useEndOrCancelDelegatedReservationByIdMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useEndOrCancelDelegatedReservationByIdMutation(baseOptions?: Apollo.MutationHookOptions<EndOrCancelDelegatedReservationByIdMutation, EndOrCancelDelegatedReservationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndOrCancelDelegatedReservationByIdMutation, EndOrCancelDelegatedReservationByIdMutationVariables>(EndOrCancelDelegatedReservationByIdDocument, options);
      }
export type EndOrCancelDelegatedReservationByIdMutationHookResult = ReturnType<typeof useEndOrCancelDelegatedReservationByIdMutation>;
export type EndOrCancelDelegatedReservationByIdMutationResult = Apollo.MutationResult<EndOrCancelDelegatedReservationByIdMutation>;
export type EndOrCancelDelegatedReservationByIdMutationOptions = Apollo.BaseMutationOptions<EndOrCancelDelegatedReservationByIdMutation, EndOrCancelDelegatedReservationByIdMutationVariables>;
export const EndOrCancelReservationByIdDocument = gql`
    mutation EndOrCancelReservationById($reservationId: ID!) {
  endOrCancelReservation(reservationId: $reservationId) {
    reservationId
  }
}
    `;
export type EndOrCancelReservationByIdMutationFn = Apollo.MutationFunction<EndOrCancelReservationByIdMutation, EndOrCancelReservationByIdMutationVariables>;

/**
 * __useEndOrCancelReservationByIdMutation__
 *
 * To run a mutation, you first call `useEndOrCancelReservationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndOrCancelReservationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endOrCancelReservationByIdMutation, { data, loading, error }] = useEndOrCancelReservationByIdMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useEndOrCancelReservationByIdMutation(baseOptions?: Apollo.MutationHookOptions<EndOrCancelReservationByIdMutation, EndOrCancelReservationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndOrCancelReservationByIdMutation, EndOrCancelReservationByIdMutationVariables>(EndOrCancelReservationByIdDocument, options);
      }
export type EndOrCancelReservationByIdMutationHookResult = ReturnType<typeof useEndOrCancelReservationByIdMutation>;
export type EndOrCancelReservationByIdMutationResult = Apollo.MutationResult<EndOrCancelReservationByIdMutation>;
export type EndOrCancelReservationByIdMutationOptions = Apollo.BaseMutationOptions<EndOrCancelReservationByIdMutation, EndOrCancelReservationByIdMutationVariables>;
export const EndReservationOrVisitsInRangeDocument = gql`
    mutation EndReservationOrVisitsInRange($orgId: ID!, $userId: ID!, $start: Date!, $end: Date!, $cancelFutureVisits: Boolean, $creationType: [String]) {
  endReservationOrVisitsInRange(
    orgId: $orgId
    userId: $userId
    start: $start
    end: $end
    cancelFutureVisits: $cancelFutureVisits
    creationType: $creationType
  ) {
    success
    visitsCancelledAttempts {
      id
      success
      error
    }
    reservationsCancelledAttempts {
      id
      success
      error
    }
  }
}
    `;
export type EndReservationOrVisitsInRangeMutationFn = Apollo.MutationFunction<EndReservationOrVisitsInRangeMutation, EndReservationOrVisitsInRangeMutationVariables>;

/**
 * __useEndReservationOrVisitsInRangeMutation__
 *
 * To run a mutation, you first call `useEndReservationOrVisitsInRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndReservationOrVisitsInRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endReservationOrVisitsInRangeMutation, { data, loading, error }] = useEndReservationOrVisitsInRangeMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      cancelFutureVisits: // value for 'cancelFutureVisits'
 *      creationType: // value for 'creationType'
 *   },
 * });
 */
export function useEndReservationOrVisitsInRangeMutation(baseOptions?: Apollo.MutationHookOptions<EndReservationOrVisitsInRangeMutation, EndReservationOrVisitsInRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndReservationOrVisitsInRangeMutation, EndReservationOrVisitsInRangeMutationVariables>(EndReservationOrVisitsInRangeDocument, options);
      }
export type EndReservationOrVisitsInRangeMutationHookResult = ReturnType<typeof useEndReservationOrVisitsInRangeMutation>;
export type EndReservationOrVisitsInRangeMutationResult = Apollo.MutationResult<EndReservationOrVisitsInRangeMutation>;
export type EndReservationOrVisitsInRangeMutationOptions = Apollo.BaseMutationOptions<EndReservationOrVisitsInRangeMutation, EndReservationOrVisitsInRangeMutationVariables>;
export const UseSeriesStartTimesForCancelDocument = gql`
    query useSeriesStartTimesForCancel($seriesId: ID!) {
  getDeskReservationInstancesById(id: $seriesId) {
    id
    startTime
  }
}
    `;

/**
 * __useUseSeriesStartTimesForCancelQuery__
 *
 * To run a query within a React component, call `useUseSeriesStartTimesForCancelQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseSeriesStartTimesForCancelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseSeriesStartTimesForCancelQuery({
 *   variables: {
 *      seriesId: // value for 'seriesId'
 *   },
 * });
 */
export function useUseSeriesStartTimesForCancelQuery(baseOptions: Apollo.QueryHookOptions<UseSeriesStartTimesForCancelQuery, UseSeriesStartTimesForCancelQueryVariables> & ({ variables: UseSeriesStartTimesForCancelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseSeriesStartTimesForCancelQuery, UseSeriesStartTimesForCancelQueryVariables>(UseSeriesStartTimesForCancelDocument, options);
      }
export function useUseSeriesStartTimesForCancelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseSeriesStartTimesForCancelQuery, UseSeriesStartTimesForCancelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseSeriesStartTimesForCancelQuery, UseSeriesStartTimesForCancelQueryVariables>(UseSeriesStartTimesForCancelDocument, options);
        }
export function useUseSeriesStartTimesForCancelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseSeriesStartTimesForCancelQuery, UseSeriesStartTimesForCancelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseSeriesStartTimesForCancelQuery, UseSeriesStartTimesForCancelQueryVariables>(UseSeriesStartTimesForCancelDocument, options);
        }
export type UseSeriesStartTimesForCancelQueryHookResult = ReturnType<typeof useUseSeriesStartTimesForCancelQuery>;
export type UseSeriesStartTimesForCancelLazyQueryHookResult = ReturnType<typeof useUseSeriesStartTimesForCancelLazyQuery>;
export type UseSeriesStartTimesForCancelSuspenseQueryHookResult = ReturnType<typeof useUseSeriesStartTimesForCancelSuspenseQuery>;
export type UseSeriesStartTimesForCancelQueryResult = Apollo.QueryResult<UseSeriesStartTimesForCancelQuery, UseSeriesStartTimesForCancelQueryVariables>;
export const DeskLocationDetailsDocument = gql`
    query DeskLocationDetails($deskId: ID!) {
  getDeskById(id: $deskId) {
    id
    name
    level {
      name
    }
    location {
      name
    }
  }
}
    `;

/**
 * __useDeskLocationDetailsQuery__
 *
 * To run a query within a React component, call `useDeskLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskLocationDetailsQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
export function useDeskLocationDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeskLocationDetailsQuery, DeskLocationDetailsQueryVariables> & ({ variables: DeskLocationDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskLocationDetailsQuery, DeskLocationDetailsQueryVariables>(DeskLocationDetailsDocument, options);
      }
export function useDeskLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskLocationDetailsQuery, DeskLocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskLocationDetailsQuery, DeskLocationDetailsQueryVariables>(DeskLocationDetailsDocument, options);
        }
export function useDeskLocationDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskLocationDetailsQuery, DeskLocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskLocationDetailsQuery, DeskLocationDetailsQueryVariables>(DeskLocationDetailsDocument, options);
        }
export type DeskLocationDetailsQueryHookResult = ReturnType<typeof useDeskLocationDetailsQuery>;
export type DeskLocationDetailsLazyQueryHookResult = ReturnType<typeof useDeskLocationDetailsLazyQuery>;
export type DeskLocationDetailsSuspenseQueryHookResult = ReturnType<typeof useDeskLocationDetailsSuspenseQuery>;
export type DeskLocationDetailsQueryResult = Apollo.QueryResult<DeskLocationDetailsQuery, DeskLocationDetailsQueryVariables>;
export const ReportIssueWithDeskDocument = gql`
    mutation ReportIssueWithDesk($input: ReportIssueWithDeskInput!) {
  reportIssueWithDesk(input: $input) {
    id
  }
}
    `;
export type ReportIssueWithDeskMutationFn = Apollo.MutationFunction<ReportIssueWithDeskMutation, ReportIssueWithDeskMutationVariables>;

/**
 * __useReportIssueWithDeskMutation__
 *
 * To run a mutation, you first call `useReportIssueWithDeskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportIssueWithDeskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportIssueWithDeskMutation, { data, loading, error }] = useReportIssueWithDeskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportIssueWithDeskMutation(baseOptions?: Apollo.MutationHookOptions<ReportIssueWithDeskMutation, ReportIssueWithDeskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportIssueWithDeskMutation, ReportIssueWithDeskMutationVariables>(ReportIssueWithDeskDocument, options);
      }
export type ReportIssueWithDeskMutationHookResult = ReturnType<typeof useReportIssueWithDeskMutation>;
export type ReportIssueWithDeskMutationResult = Apollo.MutationResult<ReportIssueWithDeskMutation>;
export type ReportIssueWithDeskMutationOptions = Apollo.BaseMutationOptions<ReportIssueWithDeskMutation, ReportIssueWithDeskMutationVariables>;
export const ReportIssueWithSpaceDocument = gql`
    mutation ReportIssueWithSpace($input: ReportIssueWithSpaceInput!) {
  reportIssueWithSpace(input: $input) {
    id
  }
}
    `;
export type ReportIssueWithSpaceMutationFn = Apollo.MutationFunction<ReportIssueWithSpaceMutation, ReportIssueWithSpaceMutationVariables>;

/**
 * __useReportIssueWithSpaceMutation__
 *
 * To run a mutation, you first call `useReportIssueWithSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportIssueWithSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportIssueWithSpaceMutation, { data, loading, error }] = useReportIssueWithSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportIssueWithSpaceMutation(baseOptions?: Apollo.MutationHookOptions<ReportIssueWithSpaceMutation, ReportIssueWithSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportIssueWithSpaceMutation, ReportIssueWithSpaceMutationVariables>(ReportIssueWithSpaceDocument, options);
      }
export type ReportIssueWithSpaceMutationHookResult = ReturnType<typeof useReportIssueWithSpaceMutation>;
export type ReportIssueWithSpaceMutationResult = Apollo.MutationResult<ReportIssueWithSpaceMutation>;
export type ReportIssueWithSpaceMutationOptions = Apollo.BaseMutationOptions<ReportIssueWithSpaceMutation, ReportIssueWithSpaceMutationVariables>;
export const SpaceLocationDetailsDocument = gql`
    query SpaceLocationDetails($id: ID!) {
  getSpaceById(id: $id) {
    id
    name
    locationId
  }
}
    `;

/**
 * __useSpaceLocationDetailsQuery__
 *
 * To run a query within a React component, call `useSpaceLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceLocationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceLocationDetailsQuery(baseOptions: Apollo.QueryHookOptions<SpaceLocationDetailsQuery, SpaceLocationDetailsQueryVariables> & ({ variables: SpaceLocationDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceLocationDetailsQuery, SpaceLocationDetailsQueryVariables>(SpaceLocationDetailsDocument, options);
      }
export function useSpaceLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceLocationDetailsQuery, SpaceLocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceLocationDetailsQuery, SpaceLocationDetailsQueryVariables>(SpaceLocationDetailsDocument, options);
        }
export function useSpaceLocationDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceLocationDetailsQuery, SpaceLocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceLocationDetailsQuery, SpaceLocationDetailsQueryVariables>(SpaceLocationDetailsDocument, options);
        }
export type SpaceLocationDetailsQueryHookResult = ReturnType<typeof useSpaceLocationDetailsQuery>;
export type SpaceLocationDetailsLazyQueryHookResult = ReturnType<typeof useSpaceLocationDetailsLazyQuery>;
export type SpaceLocationDetailsSuspenseQueryHookResult = ReturnType<typeof useSpaceLocationDetailsSuspenseQuery>;
export type SpaceLocationDetailsQueryResult = Apollo.QueryResult<SpaceLocationDetailsQuery, SpaceLocationDetailsQueryVariables>;
export const LocationDetailsDocument = gql`
    query LocationDetails($locationId: ID!) {
  getLocationById(id: $locationId) {
    id
    name
    levels {
      id
      name
    }
  }
}
    `;

/**
 * __useLocationDetailsQuery__
 *
 * To run a query within a React component, call `useLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDetailsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationDetailsQuery(baseOptions: Apollo.QueryHookOptions<LocationDetailsQuery, LocationDetailsQueryVariables> & ({ variables: LocationDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationDetailsQuery, LocationDetailsQueryVariables>(LocationDetailsDocument, options);
      }
export function useLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationDetailsQuery, LocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationDetailsQuery, LocationDetailsQueryVariables>(LocationDetailsDocument, options);
        }
export function useLocationDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LocationDetailsQuery, LocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LocationDetailsQuery, LocationDetailsQueryVariables>(LocationDetailsDocument, options);
        }
export type LocationDetailsQueryHookResult = ReturnType<typeof useLocationDetailsQuery>;
export type LocationDetailsLazyQueryHookResult = ReturnType<typeof useLocationDetailsLazyQuery>;
export type LocationDetailsSuspenseQueryHookResult = ReturnType<typeof useLocationDetailsSuspenseQuery>;
export type LocationDetailsQueryResult = Apollo.QueryResult<LocationDetailsQuery, LocationDetailsQueryVariables>;
export const DeskFilterDocument = gql`
    query deskFilter($levelId: ID!, $filters: [DeskFilters!]) {
  getLevelById(id: $levelId) {
    desks(filters: $filters) {
      id
    }
  }
}
    `;

/**
 * __useDeskFilterQuery__
 *
 * To run a query within a React component, call `useDeskFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskFilterQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDeskFilterQuery(baseOptions: Apollo.QueryHookOptions<DeskFilterQuery, DeskFilterQueryVariables> & ({ variables: DeskFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskFilterQuery, DeskFilterQueryVariables>(DeskFilterDocument, options);
      }
export function useDeskFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskFilterQuery, DeskFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskFilterQuery, DeskFilterQueryVariables>(DeskFilterDocument, options);
        }
export function useDeskFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskFilterQuery, DeskFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskFilterQuery, DeskFilterQueryVariables>(DeskFilterDocument, options);
        }
export type DeskFilterQueryHookResult = ReturnType<typeof useDeskFilterQuery>;
export type DeskFilterLazyQueryHookResult = ReturnType<typeof useDeskFilterLazyQuery>;
export type DeskFilterSuspenseQueryHookResult = ReturnType<typeof useDeskFilterSuspenseQuery>;
export type DeskFilterQueryResult = Apollo.QueryResult<DeskFilterQuery, DeskFilterQueryVariables>;
export const SpaceFilterDocument = gql`
    query spaceFilter($levelId: ID!) {
  getLevelById(id: $levelId) {
    spaces {
      id
    }
  }
}
    `;

/**
 * __useSpaceFilterQuery__
 *
 * To run a query within a React component, call `useSpaceFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceFilterQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *   },
 * });
 */
export function useSpaceFilterQuery(baseOptions: Apollo.QueryHookOptions<SpaceFilterQuery, SpaceFilterQueryVariables> & ({ variables: SpaceFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceFilterQuery, SpaceFilterQueryVariables>(SpaceFilterDocument, options);
      }
export function useSpaceFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceFilterQuery, SpaceFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceFilterQuery, SpaceFilterQueryVariables>(SpaceFilterDocument, options);
        }
export function useSpaceFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceFilterQuery, SpaceFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceFilterQuery, SpaceFilterQueryVariables>(SpaceFilterDocument, options);
        }
export type SpaceFilterQueryHookResult = ReturnType<typeof useSpaceFilterQuery>;
export type SpaceFilterLazyQueryHookResult = ReturnType<typeof useSpaceFilterLazyQuery>;
export type SpaceFilterSuspenseQueryHookResult = ReturnType<typeof useSpaceFilterSuspenseQuery>;
export type SpaceFilterQueryResult = Apollo.QueryResult<SpaceFilterQuery, SpaceFilterQueryVariables>;
export const DeskAmenityFilterDocument = gql`
    query deskAmenityFilter($levelId: ID!, $filters: [DeskFilters!]) {
  getLevelById(id: $levelId) {
    desks(filters: $filters) {
      id
      amenities {
        id
        name
        amenityId
      }
    }
  }
}
    `;

/**
 * __useDeskAmenityFilterQuery__
 *
 * To run a query within a React component, call `useDeskAmenityFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskAmenityFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskAmenityFilterQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDeskAmenityFilterQuery(baseOptions: Apollo.QueryHookOptions<DeskAmenityFilterQuery, DeskAmenityFilterQueryVariables> & ({ variables: DeskAmenityFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskAmenityFilterQuery, DeskAmenityFilterQueryVariables>(DeskAmenityFilterDocument, options);
      }
export function useDeskAmenityFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskAmenityFilterQuery, DeskAmenityFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskAmenityFilterQuery, DeskAmenityFilterQueryVariables>(DeskAmenityFilterDocument, options);
        }
export function useDeskAmenityFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskAmenityFilterQuery, DeskAmenityFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskAmenityFilterQuery, DeskAmenityFilterQueryVariables>(DeskAmenityFilterDocument, options);
        }
export type DeskAmenityFilterQueryHookResult = ReturnType<typeof useDeskAmenityFilterQuery>;
export type DeskAmenityFilterLazyQueryHookResult = ReturnType<typeof useDeskAmenityFilterLazyQuery>;
export type DeskAmenityFilterSuspenseQueryHookResult = ReturnType<typeof useDeskAmenityFilterSuspenseQuery>;
export type DeskAmenityFilterQueryResult = Apollo.QueryResult<DeskAmenityFilterQuery, DeskAmenityFilterQueryVariables>;
export const SpaceAmenityFilterDocument = gql`
    query spaceAmenityFilter($levelId: ID!) {
  getLevelById(id: $levelId) {
    spaces {
      id
      amenities {
        id
        name
        amenityId
      }
    }
  }
}
    `;

/**
 * __useSpaceAmenityFilterQuery__
 *
 * To run a query within a React component, call `useSpaceAmenityFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceAmenityFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceAmenityFilterQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *   },
 * });
 */
export function useSpaceAmenityFilterQuery(baseOptions: Apollo.QueryHookOptions<SpaceAmenityFilterQuery, SpaceAmenityFilterQueryVariables> & ({ variables: SpaceAmenityFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceAmenityFilterQuery, SpaceAmenityFilterQueryVariables>(SpaceAmenityFilterDocument, options);
      }
export function useSpaceAmenityFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceAmenityFilterQuery, SpaceAmenityFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceAmenityFilterQuery, SpaceAmenityFilterQueryVariables>(SpaceAmenityFilterDocument, options);
        }
export function useSpaceAmenityFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceAmenityFilterQuery, SpaceAmenityFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceAmenityFilterQuery, SpaceAmenityFilterQueryVariables>(SpaceAmenityFilterDocument, options);
        }
export type SpaceAmenityFilterQueryHookResult = ReturnType<typeof useSpaceAmenityFilterQuery>;
export type SpaceAmenityFilterLazyQueryHookResult = ReturnType<typeof useSpaceAmenityFilterLazyQuery>;
export type SpaceAmenityFilterSuspenseQueryHookResult = ReturnType<typeof useSpaceAmenityFilterSuspenseQuery>;
export type SpaceAmenityFilterQueryResult = Apollo.QueryResult<SpaceAmenityFilterQuery, SpaceAmenityFilterQueryVariables>;
export const SpaceCapacityFilterDocument = gql`
    query spaceCapacityFilter($levelId: ID!) {
  getLevelById(id: $levelId) {
    spaces {
      id
      capacity
    }
  }
}
    `;

/**
 * __useSpaceCapacityFilterQuery__
 *
 * To run a query within a React component, call `useSpaceCapacityFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceCapacityFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceCapacityFilterQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *   },
 * });
 */
export function useSpaceCapacityFilterQuery(baseOptions: Apollo.QueryHookOptions<SpaceCapacityFilterQuery, SpaceCapacityFilterQueryVariables> & ({ variables: SpaceCapacityFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceCapacityFilterQuery, SpaceCapacityFilterQueryVariables>(SpaceCapacityFilterDocument, options);
      }
export function useSpaceCapacityFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceCapacityFilterQuery, SpaceCapacityFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceCapacityFilterQuery, SpaceCapacityFilterQueryVariables>(SpaceCapacityFilterDocument, options);
        }
export function useSpaceCapacityFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceCapacityFilterQuery, SpaceCapacityFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceCapacityFilterQuery, SpaceCapacityFilterQueryVariables>(SpaceCapacityFilterDocument, options);
        }
export type SpaceCapacityFilterQueryHookResult = ReturnType<typeof useSpaceCapacityFilterQuery>;
export type SpaceCapacityFilterLazyQueryHookResult = ReturnType<typeof useSpaceCapacityFilterLazyQuery>;
export type SpaceCapacityFilterSuspenseQueryHookResult = ReturnType<typeof useSpaceCapacityFilterSuspenseQuery>;
export type SpaceCapacityFilterQueryResult = Apollo.QueryResult<SpaceCapacityFilterQuery, SpaceCapacityFilterQueryVariables>;
export const SpaceTypeFilterDocument = gql`
    query spaceTypeFilter($levelId: ID!) {
  getLevelById(id: $levelId) {
    spaces {
      id
      type
    }
  }
}
    `;

/**
 * __useSpaceTypeFilterQuery__
 *
 * To run a query within a React component, call `useSpaceTypeFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceTypeFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceTypeFilterQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *   },
 * });
 */
export function useSpaceTypeFilterQuery(baseOptions: Apollo.QueryHookOptions<SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables> & ({ variables: SpaceTypeFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables>(SpaceTypeFilterDocument, options);
      }
export function useSpaceTypeFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables>(SpaceTypeFilterDocument, options);
        }
export function useSpaceTypeFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables>(SpaceTypeFilterDocument, options);
        }
export type SpaceTypeFilterQueryHookResult = ReturnType<typeof useSpaceTypeFilterQuery>;
export type SpaceTypeFilterLazyQueryHookResult = ReturnType<typeof useSpaceTypeFilterLazyQuery>;
export type SpaceTypeFilterSuspenseQueryHookResult = ReturnType<typeof useSpaceTypeFilterSuspenseQuery>;
export type SpaceTypeFilterQueryResult = Apollo.QueryResult<SpaceTypeFilterQuery, SpaceTypeFilterQueryVariables>;
export const DeskPermissionsDocument = gql`
    query DeskPermissions($levelId: ID!) {
  getLevelById(id: $levelId) {
    id
    desks {
      permissions {
        name
        value
      }
    }
  }
}
    `;

/**
 * __useDeskPermissionsQuery__
 *
 * To run a query within a React component, call `useDeskPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskPermissionsQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *   },
 * });
 */
export function useDeskPermissionsQuery(baseOptions: Apollo.QueryHookOptions<DeskPermissionsQuery, DeskPermissionsQueryVariables> & ({ variables: DeskPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskPermissionsQuery, DeskPermissionsQueryVariables>(DeskPermissionsDocument, options);
      }
export function useDeskPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskPermissionsQuery, DeskPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskPermissionsQuery, DeskPermissionsQueryVariables>(DeskPermissionsDocument, options);
        }
export function useDeskPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskPermissionsQuery, DeskPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskPermissionsQuery, DeskPermissionsQueryVariables>(DeskPermissionsDocument, options);
        }
export type DeskPermissionsQueryHookResult = ReturnType<typeof useDeskPermissionsQuery>;
export type DeskPermissionsLazyQueryHookResult = ReturnType<typeof useDeskPermissionsLazyQuery>;
export type DeskPermissionsSuspenseQueryHookResult = ReturnType<typeof useDeskPermissionsSuspenseQuery>;
export type DeskPermissionsQueryResult = Apollo.QueryResult<DeskPermissionsQuery, DeskPermissionsQueryVariables>;
export const DeleteDeskExclusionsDocument = gql`
    mutation deleteDeskExclusions($seriesId: ID!, $exclusionIds: [ID!]!, $dates: [DateRangeWithTimeZone!]!, $hasDates: Boolean!, $hasDeletions: Boolean!) {
  deleteDeskExclusions(seriesId: $seriesId, exclusionIds: $exclusionIds) @include(if: $hasDeletions)
  createDeskExclusions(seriesId: $seriesId, dates: $dates) @include(if: $hasDates)
}
    `;
export type DeleteDeskExclusionsMutationFn = Apollo.MutationFunction<DeleteDeskExclusionsMutation, DeleteDeskExclusionsMutationVariables>;

/**
 * __useDeleteDeskExclusionsMutation__
 *
 * To run a mutation, you first call `useDeleteDeskExclusionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeskExclusionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeskExclusionsMutation, { data, loading, error }] = useDeleteDeskExclusionsMutation({
 *   variables: {
 *      seriesId: // value for 'seriesId'
 *      exclusionIds: // value for 'exclusionIds'
 *      dates: // value for 'dates'
 *      hasDates: // value for 'hasDates'
 *      hasDeletions: // value for 'hasDeletions'
 *   },
 * });
 */
export function useDeleteDeskExclusionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeskExclusionsMutation, DeleteDeskExclusionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeskExclusionsMutation, DeleteDeskExclusionsMutationVariables>(DeleteDeskExclusionsDocument, options);
      }
export type DeleteDeskExclusionsMutationHookResult = ReturnType<typeof useDeleteDeskExclusionsMutation>;
export type DeleteDeskExclusionsMutationResult = Apollo.MutationResult<DeleteDeskExclusionsMutation>;
export type DeleteDeskExclusionsMutationOptions = Apollo.BaseMutationOptions<DeleteDeskExclusionsMutation, DeleteDeskExclusionsMutationVariables>;
export const GetReleaseDeskExclusionsDocument = gql`
    query GetReleaseDeskExclusions($reservationSeriesId: ID!, $startDate: Date!, $endDate: Date!) {
  getReservationExclusions(
    reservationSeriesId: $reservationSeriesId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    startTime
    endTime
  }
}
    `;

/**
 * __useGetReleaseDeskExclusionsQuery__
 *
 * To run a query within a React component, call `useGetReleaseDeskExclusionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseDeskExclusionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseDeskExclusionsQuery({
 *   variables: {
 *      reservationSeriesId: // value for 'reservationSeriesId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetReleaseDeskExclusionsQuery(baseOptions: Apollo.QueryHookOptions<GetReleaseDeskExclusionsQuery, GetReleaseDeskExclusionsQueryVariables> & ({ variables: GetReleaseDeskExclusionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReleaseDeskExclusionsQuery, GetReleaseDeskExclusionsQueryVariables>(GetReleaseDeskExclusionsDocument, options);
      }
export function useGetReleaseDeskExclusionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReleaseDeskExclusionsQuery, GetReleaseDeskExclusionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReleaseDeskExclusionsQuery, GetReleaseDeskExclusionsQueryVariables>(GetReleaseDeskExclusionsDocument, options);
        }
export function useGetReleaseDeskExclusionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReleaseDeskExclusionsQuery, GetReleaseDeskExclusionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReleaseDeskExclusionsQuery, GetReleaseDeskExclusionsQueryVariables>(GetReleaseDeskExclusionsDocument, options);
        }
export type GetReleaseDeskExclusionsQueryHookResult = ReturnType<typeof useGetReleaseDeskExclusionsQuery>;
export type GetReleaseDeskExclusionsLazyQueryHookResult = ReturnType<typeof useGetReleaseDeskExclusionsLazyQuery>;
export type GetReleaseDeskExclusionsSuspenseQueryHookResult = ReturnType<typeof useGetReleaseDeskExclusionsSuspenseQuery>;
export type GetReleaseDeskExclusionsQueryResult = Apollo.QueryResult<GetReleaseDeskExclusionsQuery, GetReleaseDeskExclusionsQueryVariables>;
export const GetReleaseDeskScheduleByDeskIdDocument = gql`
    query GetReleaseDeskScheduleByDeskId($getSharedDeskScheduleIds: [ID!]!) {
  getSharedDesksSchedules(deskIds: $getSharedDeskScheduleIds) {
    id
    schedule {
      id
      recurrence
    }
  }
}
    `;

/**
 * __useGetReleaseDeskScheduleByDeskIdQuery__
 *
 * To run a query within a React component, call `useGetReleaseDeskScheduleByDeskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseDeskScheduleByDeskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseDeskScheduleByDeskIdQuery({
 *   variables: {
 *      getSharedDeskScheduleIds: // value for 'getSharedDeskScheduleIds'
 *   },
 * });
 */
export function useGetReleaseDeskScheduleByDeskIdQuery(baseOptions: Apollo.QueryHookOptions<GetReleaseDeskScheduleByDeskIdQuery, GetReleaseDeskScheduleByDeskIdQueryVariables> & ({ variables: GetReleaseDeskScheduleByDeskIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReleaseDeskScheduleByDeskIdQuery, GetReleaseDeskScheduleByDeskIdQueryVariables>(GetReleaseDeskScheduleByDeskIdDocument, options);
      }
export function useGetReleaseDeskScheduleByDeskIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReleaseDeskScheduleByDeskIdQuery, GetReleaseDeskScheduleByDeskIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReleaseDeskScheduleByDeskIdQuery, GetReleaseDeskScheduleByDeskIdQueryVariables>(GetReleaseDeskScheduleByDeskIdDocument, options);
        }
export function useGetReleaseDeskScheduleByDeskIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReleaseDeskScheduleByDeskIdQuery, GetReleaseDeskScheduleByDeskIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReleaseDeskScheduleByDeskIdQuery, GetReleaseDeskScheduleByDeskIdQueryVariables>(GetReleaseDeskScheduleByDeskIdDocument, options);
        }
export type GetReleaseDeskScheduleByDeskIdQueryHookResult = ReturnType<typeof useGetReleaseDeskScheduleByDeskIdQuery>;
export type GetReleaseDeskScheduleByDeskIdLazyQueryHookResult = ReturnType<typeof useGetReleaseDeskScheduleByDeskIdLazyQuery>;
export type GetReleaseDeskScheduleByDeskIdSuspenseQueryHookResult = ReturnType<typeof useGetReleaseDeskScheduleByDeskIdSuspenseQuery>;
export type GetReleaseDeskScheduleByDeskIdQueryResult = Apollo.QueryResult<GetReleaseDeskScheduleByDeskIdQuery, GetReleaseDeskScheduleByDeskIdQueryVariables>;
export const DeskBookingControlsDocument = gql`
    query DeskBookingControls($deskId: ID!) {
  getDeskById(id: $deskId) {
    id
    name
    type
    settings {
      slug
      value
    }
    permissions {
      name
      value
    }
  }
}
    `;

/**
 * __useDeskBookingControlsQuery__
 *
 * To run a query within a React component, call `useDeskBookingControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskBookingControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskBookingControlsQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
export function useDeskBookingControlsQuery(baseOptions: Apollo.QueryHookOptions<DeskBookingControlsQuery, DeskBookingControlsQueryVariables> & ({ variables: DeskBookingControlsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskBookingControlsQuery, DeskBookingControlsQueryVariables>(DeskBookingControlsDocument, options);
      }
export function useDeskBookingControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskBookingControlsQuery, DeskBookingControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskBookingControlsQuery, DeskBookingControlsQueryVariables>(DeskBookingControlsDocument, options);
        }
export function useDeskBookingControlsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskBookingControlsQuery, DeskBookingControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskBookingControlsQuery, DeskBookingControlsQueryVariables>(DeskBookingControlsDocument, options);
        }
export type DeskBookingControlsQueryHookResult = ReturnType<typeof useDeskBookingControlsQuery>;
export type DeskBookingControlsLazyQueryHookResult = ReturnType<typeof useDeskBookingControlsLazyQuery>;
export type DeskBookingControlsSuspenseQueryHookResult = ReturnType<typeof useDeskBookingControlsSuspenseQuery>;
export type DeskBookingControlsQueryResult = Apollo.QueryResult<DeskBookingControlsQuery, DeskBookingControlsQueryVariables>;
export const ReserveDelegatedDeskDocument = gql`
    mutation ReserveDelegatedDesk($seatId: Int!, $email: String!, $type: String!, $start: DateWithTimezone!, $end: DateWithTimezone!, $recurrence: [String!], $notify: Boolean, $visibility: DeskReservationVisibility!) {
  reserveDelegatedDesk(
    seatId: $seatId
    email: $email
    type: $type
    start: $start
    end: $end
    notify: $notify
    recurrence: $recurrence
    visibility: $visibility
  ) {
    id
  }
}
    `;
export type ReserveDelegatedDeskMutationFn = Apollo.MutationFunction<ReserveDelegatedDeskMutation, ReserveDelegatedDeskMutationVariables>;

/**
 * __useReserveDelegatedDeskMutation__
 *
 * To run a mutation, you first call `useReserveDelegatedDeskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveDelegatedDeskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveDelegatedDeskMutation, { data, loading, error }] = useReserveDelegatedDeskMutation({
 *   variables: {
 *      seatId: // value for 'seatId'
 *      email: // value for 'email'
 *      type: // value for 'type'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      recurrence: // value for 'recurrence'
 *      notify: // value for 'notify'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useReserveDelegatedDeskMutation(baseOptions?: Apollo.MutationHookOptions<ReserveDelegatedDeskMutation, ReserveDelegatedDeskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveDelegatedDeskMutation, ReserveDelegatedDeskMutationVariables>(ReserveDelegatedDeskDocument, options);
      }
export type ReserveDelegatedDeskMutationHookResult = ReturnType<typeof useReserveDelegatedDeskMutation>;
export type ReserveDelegatedDeskMutationResult = Apollo.MutationResult<ReserveDelegatedDeskMutation>;
export type ReserveDelegatedDeskMutationOptions = Apollo.BaseMutationOptions<ReserveDelegatedDeskMutation, ReserveDelegatedDeskMutationVariables>;
export const ReserveDeskForMeDocument = gql`
    mutation ReserveDeskForMe($seatId: Int!, $type: String!, $start: DateWithTimezone!, $end: DateWithTimezone!, $visibility: DeskReservationVisibility, $recurrence: [String!]) {
  reserveDeskForMe(
    seatId: $seatId
    type: $type
    start: $start
    end: $end
    visibility: $visibility
    recurrence: $recurrence
  ) {
    id
  }
}
    `;
export type ReserveDeskForMeMutationFn = Apollo.MutationFunction<ReserveDeskForMeMutation, ReserveDeskForMeMutationVariables>;

/**
 * __useReserveDeskForMeMutation__
 *
 * To run a mutation, you first call `useReserveDeskForMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveDeskForMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveDeskForMeMutation, { data, loading, error }] = useReserveDeskForMeMutation({
 *   variables: {
 *      seatId: // value for 'seatId'
 *      type: // value for 'type'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      visibility: // value for 'visibility'
 *      recurrence: // value for 'recurrence'
 *   },
 * });
 */
export function useReserveDeskForMeMutation(baseOptions?: Apollo.MutationHookOptions<ReserveDeskForMeMutation, ReserveDeskForMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveDeskForMeMutation, ReserveDeskForMeMutationVariables>(ReserveDeskForMeDocument, options);
      }
export type ReserveDeskForMeMutationHookResult = ReturnType<typeof useReserveDeskForMeMutation>;
export type ReserveDeskForMeMutationResult = Apollo.MutationResult<ReserveDeskForMeMutation>;
export type ReserveDeskForMeMutationOptions = Apollo.BaseMutationOptions<ReserveDeskForMeMutation, ReserveDeskForMeMutationVariables>;
export const DeskSharedScheduleDocument = gql`
    query DeskSharedSchedule($deskIds: [ID!]!) {
  getDesksByIds(ids: $deskIds) {
    id
    name
    level {
      id
      name
    }
    location {
      id
      name
    }
  }
  getSharedDesksSchedules(deskIds: $deskIds) {
    id
    schedule {
      id
      firstName
      lastName
      email
      recurrence
      avatar
    }
  }
}
    `;

/**
 * __useDeskSharedScheduleQuery__
 *
 * To run a query within a React component, call `useDeskSharedScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskSharedScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskSharedScheduleQuery({
 *   variables: {
 *      deskIds: // value for 'deskIds'
 *   },
 * });
 */
export function useDeskSharedScheduleQuery(baseOptions: Apollo.QueryHookOptions<DeskSharedScheduleQuery, DeskSharedScheduleQueryVariables> & ({ variables: DeskSharedScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskSharedScheduleQuery, DeskSharedScheduleQueryVariables>(DeskSharedScheduleDocument, options);
      }
export function useDeskSharedScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskSharedScheduleQuery, DeskSharedScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskSharedScheduleQuery, DeskSharedScheduleQueryVariables>(DeskSharedScheduleDocument, options);
        }
export function useDeskSharedScheduleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskSharedScheduleQuery, DeskSharedScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskSharedScheduleQuery, DeskSharedScheduleQueryVariables>(DeskSharedScheduleDocument, options);
        }
export type DeskSharedScheduleQueryHookResult = ReturnType<typeof useDeskSharedScheduleQuery>;
export type DeskSharedScheduleLazyQueryHookResult = ReturnType<typeof useDeskSharedScheduleLazyQuery>;
export type DeskSharedScheduleSuspenseQueryHookResult = ReturnType<typeof useDeskSharedScheduleSuspenseQuery>;
export type DeskSharedScheduleQueryResult = Apollo.QueryResult<DeskSharedScheduleQuery, DeskSharedScheduleQueryVariables>;
export const PublishSharedScheduleForDeskDocument = gql`
    mutation PublishSharedScheduleForDesk($input: SharedDeskSchedulesInput!) {
  publishSharedScheduleForDesk(input: $input)
}
    `;
export type PublishSharedScheduleForDeskMutationFn = Apollo.MutationFunction<PublishSharedScheduleForDeskMutation, PublishSharedScheduleForDeskMutationVariables>;

/**
 * __usePublishSharedScheduleForDeskMutation__
 *
 * To run a mutation, you first call `usePublishSharedScheduleForDeskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSharedScheduleForDeskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSharedScheduleForDeskMutation, { data, loading, error }] = usePublishSharedScheduleForDeskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishSharedScheduleForDeskMutation(baseOptions?: Apollo.MutationHookOptions<PublishSharedScheduleForDeskMutation, PublishSharedScheduleForDeskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishSharedScheduleForDeskMutation, PublishSharedScheduleForDeskMutationVariables>(PublishSharedScheduleForDeskDocument, options);
      }
export type PublishSharedScheduleForDeskMutationHookResult = ReturnType<typeof usePublishSharedScheduleForDeskMutation>;
export type PublishSharedScheduleForDeskMutationResult = Apollo.MutationResult<PublishSharedScheduleForDeskMutation>;
export type PublishSharedScheduleForDeskMutationOptions = Apollo.BaseMutationOptions<PublishSharedScheduleForDeskMutation, PublishSharedScheduleForDeskMutationVariables>;
export const UpcomingDeskReservationsByDeskIdDocument = gql`
    query UpcomingDeskReservationsByDeskId($deskId: ID!, $startTime: ISODateTime!, $duration: Int!) {
  getDeskReservationsBySeatId(
    deskId: $deskId
    startTime: $startTime
    duration: $duration
  ) {
    startTime
    endTime
    type
    accountReservee {
      user {
        id
        name
        primaryEmail {
          email
        }
        avatar
      }
    }
  }
}
    `;

/**
 * __useUpcomingDeskReservationsByDeskIdQuery__
 *
 * To run a query within a React component, call `useUpcomingDeskReservationsByDeskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingDeskReservationsByDeskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingDeskReservationsByDeskIdQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *      startTime: // value for 'startTime'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useUpcomingDeskReservationsByDeskIdQuery(baseOptions: Apollo.QueryHookOptions<UpcomingDeskReservationsByDeskIdQuery, UpcomingDeskReservationsByDeskIdQueryVariables> & ({ variables: UpcomingDeskReservationsByDeskIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingDeskReservationsByDeskIdQuery, UpcomingDeskReservationsByDeskIdQueryVariables>(UpcomingDeskReservationsByDeskIdDocument, options);
      }
export function useUpcomingDeskReservationsByDeskIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingDeskReservationsByDeskIdQuery, UpcomingDeskReservationsByDeskIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingDeskReservationsByDeskIdQuery, UpcomingDeskReservationsByDeskIdQueryVariables>(UpcomingDeskReservationsByDeskIdDocument, options);
        }
export function useUpcomingDeskReservationsByDeskIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UpcomingDeskReservationsByDeskIdQuery, UpcomingDeskReservationsByDeskIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UpcomingDeskReservationsByDeskIdQuery, UpcomingDeskReservationsByDeskIdQueryVariables>(UpcomingDeskReservationsByDeskIdDocument, options);
        }
export type UpcomingDeskReservationsByDeskIdQueryHookResult = ReturnType<typeof useUpcomingDeskReservationsByDeskIdQuery>;
export type UpcomingDeskReservationsByDeskIdLazyQueryHookResult = ReturnType<typeof useUpcomingDeskReservationsByDeskIdLazyQuery>;
export type UpcomingDeskReservationsByDeskIdSuspenseQueryHookResult = ReturnType<typeof useUpcomingDeskReservationsByDeskIdSuspenseQuery>;
export type UpcomingDeskReservationsByDeskIdQueryResult = Apollo.QueryResult<UpcomingDeskReservationsByDeskIdQuery, UpcomingDeskReservationsByDeskIdQueryVariables>;
export const CanEditDesksDocument = gql`
    query CanEditDesks {
  getCurrentUserAndOrgs {
    userId
    hasPlacesManageForCurrentTenant
  }
}
    `;

/**
 * __useCanEditDesksQuery__
 *
 * To run a query within a React component, call `useCanEditDesksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanEditDesksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanEditDesksQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanEditDesksQuery(baseOptions?: Apollo.QueryHookOptions<CanEditDesksQuery, CanEditDesksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanEditDesksQuery, CanEditDesksQueryVariables>(CanEditDesksDocument, options);
      }
export function useCanEditDesksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanEditDesksQuery, CanEditDesksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanEditDesksQuery, CanEditDesksQueryVariables>(CanEditDesksDocument, options);
        }
export function useCanEditDesksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanEditDesksQuery, CanEditDesksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanEditDesksQuery, CanEditDesksQueryVariables>(CanEditDesksDocument, options);
        }
export type CanEditDesksQueryHookResult = ReturnType<typeof useCanEditDesksQuery>;
export type CanEditDesksLazyQueryHookResult = ReturnType<typeof useCanEditDesksLazyQuery>;
export type CanEditDesksSuspenseQueryHookResult = ReturnType<typeof useCanEditDesksSuspenseQuery>;
export type CanEditDesksQueryResult = Apollo.QueryResult<CanEditDesksQuery, CanEditDesksQueryVariables>;
export const ConfirmDeskReservationFromDashboardDocument = gql`
    mutation ConfirmDeskReservationFromDashboard($reservationId: ID!, $type: DeskConfirmationType!) {
  confirmDeskReservation(reservationId: $reservationId, type: $type)
}
    `;
export type ConfirmDeskReservationFromDashboardMutationFn = Apollo.MutationFunction<ConfirmDeskReservationFromDashboardMutation, ConfirmDeskReservationFromDashboardMutationVariables>;

/**
 * __useConfirmDeskReservationFromDashboardMutation__
 *
 * To run a mutation, you first call `useConfirmDeskReservationFromDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDeskReservationFromDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDeskReservationFromDashboardMutation, { data, loading, error }] = useConfirmDeskReservationFromDashboardMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useConfirmDeskReservationFromDashboardMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmDeskReservationFromDashboardMutation, ConfirmDeskReservationFromDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmDeskReservationFromDashboardMutation, ConfirmDeskReservationFromDashboardMutationVariables>(ConfirmDeskReservationFromDashboardDocument, options);
      }
export type ConfirmDeskReservationFromDashboardMutationHookResult = ReturnType<typeof useConfirmDeskReservationFromDashboardMutation>;
export type ConfirmDeskReservationFromDashboardMutationResult = Apollo.MutationResult<ConfirmDeskReservationFromDashboardMutation>;
export type ConfirmDeskReservationFromDashboardMutationOptions = Apollo.BaseMutationOptions<ConfirmDeskReservationFromDashboardMutation, ConfirmDeskReservationFromDashboardMutationVariables>;
export const DeskAmenitiesDocument = gql`
    query DeskAmenities($deskId: ID!) {
  getDeskById(id: $deskId) {
    id
    amenities {
      id
      name
      quantity
    }
  }
}
    `;

/**
 * __useDeskAmenitiesQuery__
 *
 * To run a query within a React component, call `useDeskAmenitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskAmenitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskAmenitiesQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
export function useDeskAmenitiesQuery(baseOptions: Apollo.QueryHookOptions<DeskAmenitiesQuery, DeskAmenitiesQueryVariables> & ({ variables: DeskAmenitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskAmenitiesQuery, DeskAmenitiesQueryVariables>(DeskAmenitiesDocument, options);
      }
export function useDeskAmenitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskAmenitiesQuery, DeskAmenitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskAmenitiesQuery, DeskAmenitiesQueryVariables>(DeskAmenitiesDocument, options);
        }
export function useDeskAmenitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskAmenitiesQuery, DeskAmenitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskAmenitiesQuery, DeskAmenitiesQueryVariables>(DeskAmenitiesDocument, options);
        }
export type DeskAmenitiesQueryHookResult = ReturnType<typeof useDeskAmenitiesQuery>;
export type DeskAmenitiesLazyQueryHookResult = ReturnType<typeof useDeskAmenitiesLazyQuery>;
export type DeskAmenitiesSuspenseQueryHookResult = ReturnType<typeof useDeskAmenitiesSuspenseQuery>;
export type DeskAmenitiesQueryResult = Apollo.QueryResult<DeskAmenitiesQuery, DeskAmenitiesQueryVariables>;
export const DeskDetailsByIdDocument = gql`
    query DeskDetailsById($deskId: ID!, $userId: Int!, $startTimeMoment: Date!, $endTimeMoment: Date!, $recurrence: String) {
  getDeskById(id: $deskId) {
    id
    rawType
    name
    zone {
      name
    }
    location {
      name
    }
    level {
      name
    }
    permissions {
      name
      value
    }
    state(
      startTime: $startTimeMoment
      endTime: $endTimeMoment
      userId: $userId
      recurrence: $recurrence
    ) {
      exclusions {
        startTime
        endTime
      }
    }
  }
}
    `;

/**
 * __useDeskDetailsByIdQuery__
 *
 * To run a query within a React component, call `useDeskDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskDetailsByIdQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *      userId: // value for 'userId'
 *      startTimeMoment: // value for 'startTimeMoment'
 *      endTimeMoment: // value for 'endTimeMoment'
 *      recurrence: // value for 'recurrence'
 *   },
 * });
 */
export function useDeskDetailsByIdQuery(baseOptions: Apollo.QueryHookOptions<DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables> & ({ variables: DeskDetailsByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables>(DeskDetailsByIdDocument, options);
      }
export function useDeskDetailsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables>(DeskDetailsByIdDocument, options);
        }
export function useDeskDetailsByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables>(DeskDetailsByIdDocument, options);
        }
export type DeskDetailsByIdQueryHookResult = ReturnType<typeof useDeskDetailsByIdQuery>;
export type DeskDetailsByIdLazyQueryHookResult = ReturnType<typeof useDeskDetailsByIdLazyQuery>;
export type DeskDetailsByIdSuspenseQueryHookResult = ReturnType<typeof useDeskDetailsByIdSuspenseQuery>;
export type DeskDetailsByIdQueryResult = Apollo.QueryResult<DeskDetailsByIdQuery, DeskDetailsByIdQueryVariables>;
export const DeskListItemDataDocument = gql`
    query DeskListItemData($deskId: ID!, $userId: Int!, $startTimeMoment: Date!, $endTimeMoment: Date!, $recurrence: String) {
  getAnyDeskById(id: $deskId) {
    id
    rawType
    state(
      startTime: $startTimeMoment
      endTime: $endTimeMoment
      userId: $userId
      recurrence: $recurrence
    ) {
      reservations {
        id
        startTime
        endTime
        visibility
        reservee {
          user {
            name
            avatar
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDeskListItemDataQuery__
 *
 * To run a query within a React component, call `useDeskListItemDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskListItemDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskListItemDataQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *      userId: // value for 'userId'
 *      startTimeMoment: // value for 'startTimeMoment'
 *      endTimeMoment: // value for 'endTimeMoment'
 *      recurrence: // value for 'recurrence'
 *   },
 * });
 */
export function useDeskListItemDataQuery(baseOptions: Apollo.QueryHookOptions<DeskListItemDataQuery, DeskListItemDataQueryVariables> & ({ variables: DeskListItemDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskListItemDataQuery, DeskListItemDataQueryVariables>(DeskListItemDataDocument, options);
      }
export function useDeskListItemDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskListItemDataQuery, DeskListItemDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskListItemDataQuery, DeskListItemDataQueryVariables>(DeskListItemDataDocument, options);
        }
export function useDeskListItemDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskListItemDataQuery, DeskListItemDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskListItemDataQuery, DeskListItemDataQueryVariables>(DeskListItemDataDocument, options);
        }
export type DeskListItemDataQueryHookResult = ReturnType<typeof useDeskListItemDataQuery>;
export type DeskListItemDataLazyQueryHookResult = ReturnType<typeof useDeskListItemDataLazyQuery>;
export type DeskListItemDataSuspenseQueryHookResult = ReturnType<typeof useDeskListItemDataSuspenseQuery>;
export type DeskListItemDataQueryResult = Apollo.QueryResult<DeskListItemDataQuery, DeskListItemDataQueryVariables>;
export const DeskReservationPoliciesDocument = gql`
    query DeskReservationPolicies($deskId: ID!) {
  getDeskById(id: $deskId) {
    id
    location {
      isHealthCheckpointRequired
    }
    reservationPolicies {
      checkinPolicies {
        enforceLocalCheckinOnly
        checkInWindow
        assignedCheckInEnabled
      }
      seatReservationTypesAllowed
      allowExclusions
      seatReservationAdvancedBookingThreshold
      seatReservationMaxLength
      allowOverlappingReservations
      seatReservationVisibilityEnabled
      abandonedDeskProtectionThreshold
      abandonedDeskProtectionEnabled
    }
  }
}
    `;

/**
 * __useDeskReservationPoliciesQuery__
 *
 * To run a query within a React component, call `useDeskReservationPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskReservationPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskReservationPoliciesQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
export function useDeskReservationPoliciesQuery(baseOptions: Apollo.QueryHookOptions<DeskReservationPoliciesQuery, DeskReservationPoliciesQueryVariables> & ({ variables: DeskReservationPoliciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskReservationPoliciesQuery, DeskReservationPoliciesQueryVariables>(DeskReservationPoliciesDocument, options);
      }
export function useDeskReservationPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskReservationPoliciesQuery, DeskReservationPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskReservationPoliciesQuery, DeskReservationPoliciesQueryVariables>(DeskReservationPoliciesDocument, options);
        }
export function useDeskReservationPoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskReservationPoliciesQuery, DeskReservationPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskReservationPoliciesQuery, DeskReservationPoliciesQueryVariables>(DeskReservationPoliciesDocument, options);
        }
export type DeskReservationPoliciesQueryHookResult = ReturnType<typeof useDeskReservationPoliciesQuery>;
export type DeskReservationPoliciesLazyQueryHookResult = ReturnType<typeof useDeskReservationPoliciesLazyQuery>;
export type DeskReservationPoliciesSuspenseQueryHookResult = ReturnType<typeof useDeskReservationPoliciesSuspenseQuery>;
export type DeskReservationPoliciesQueryResult = Apollo.QueryResult<DeskReservationPoliciesQuery, DeskReservationPoliciesQueryVariables>;
export const DeskReservationReserveeDocument = gql`
    query DeskReservationReservee($deskId: ID!, $startTime: ISODateTime!, $duration: Int!) {
  getDeskReservationsBySeatId(
    deskId: $deskId
    startTime: $startTime
    duration: $duration
  ) {
    id
    visibility
    reservee {
      user {
        id
      }
    }
  }
}
    `;

/**
 * __useDeskReservationReserveeQuery__
 *
 * To run a query within a React component, call `useDeskReservationReserveeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskReservationReserveeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskReservationReserveeQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *      startTime: // value for 'startTime'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useDeskReservationReserveeQuery(baseOptions: Apollo.QueryHookOptions<DeskReservationReserveeQuery, DeskReservationReserveeQueryVariables> & ({ variables: DeskReservationReserveeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskReservationReserveeQuery, DeskReservationReserveeQueryVariables>(DeskReservationReserveeDocument, options);
      }
export function useDeskReservationReserveeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskReservationReserveeQuery, DeskReservationReserveeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskReservationReserveeQuery, DeskReservationReserveeQueryVariables>(DeskReservationReserveeDocument, options);
        }
export function useDeskReservationReserveeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskReservationReserveeQuery, DeskReservationReserveeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskReservationReserveeQuery, DeskReservationReserveeQueryVariables>(DeskReservationReserveeDocument, options);
        }
export type DeskReservationReserveeQueryHookResult = ReturnType<typeof useDeskReservationReserveeQuery>;
export type DeskReservationReserveeLazyQueryHookResult = ReturnType<typeof useDeskReservationReserveeLazyQuery>;
export type DeskReservationReserveeSuspenseQueryHookResult = ReturnType<typeof useDeskReservationReserveeSuspenseQuery>;
export type DeskReservationReserveeQueryResult = Apollo.QueryResult<DeskReservationReserveeQuery, DeskReservationReserveeQueryVariables>;
export const DeskReservationsBySeatIdDocument = gql`
    query DeskReservationsBySeatId($deskId: ID!, $startTime: ISODateTime!, $duration: Int!) {
  getDeskReservationsBySeatId(
    deskId: $deskId
    startTime: $startTime
    duration: $duration
  ) {
    id
    seriesId
    startTime
    endTime
    timeZone
    canCheckInNow
    isCheckedIn
    visibility
    reservee {
      user {
        id
        name
        avatar
        primaryEmail {
          email
        }
      }
    }
    healthCheckpoint {
      status
    }
  }
}
    `;

/**
 * __useDeskReservationsBySeatIdQuery__
 *
 * To run a query within a React component, call `useDeskReservationsBySeatIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskReservationsBySeatIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskReservationsBySeatIdQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *      startTime: // value for 'startTime'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useDeskReservationsBySeatIdQuery(baseOptions: Apollo.QueryHookOptions<DeskReservationsBySeatIdQuery, DeskReservationsBySeatIdQueryVariables> & ({ variables: DeskReservationsBySeatIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskReservationsBySeatIdQuery, DeskReservationsBySeatIdQueryVariables>(DeskReservationsBySeatIdDocument, options);
      }
export function useDeskReservationsBySeatIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskReservationsBySeatIdQuery, DeskReservationsBySeatIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskReservationsBySeatIdQuery, DeskReservationsBySeatIdQueryVariables>(DeskReservationsBySeatIdDocument, options);
        }
export function useDeskReservationsBySeatIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskReservationsBySeatIdQuery, DeskReservationsBySeatIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskReservationsBySeatIdQuery, DeskReservationsBySeatIdQueryVariables>(DeskReservationsBySeatIdDocument, options);
        }
export type DeskReservationsBySeatIdQueryHookResult = ReturnType<typeof useDeskReservationsBySeatIdQuery>;
export type DeskReservationsBySeatIdLazyQueryHookResult = ReturnType<typeof useDeskReservationsBySeatIdLazyQuery>;
export type DeskReservationsBySeatIdSuspenseQueryHookResult = ReturnType<typeof useDeskReservationsBySeatIdSuspenseQuery>;
export type DeskReservationsBySeatIdQueryResult = Apollo.QueryResult<DeskReservationsBySeatIdQuery, DeskReservationsBySeatIdQueryVariables>;
export const DeskReservationsForDeskPolicyCardDocument = gql`
    query DeskReservationsForDeskPolicyCard($userId: ID!, $start: Date!, $end: Date!) {
  getDeskReservationsByUserId(id: $userId, startTime: $start, endTime: $end) {
    userId
    reservations {
      id
      startTime
      seat {
        id
      }
    }
  }
}
    `;

/**
 * __useDeskReservationsForDeskPolicyCardQuery__
 *
 * To run a query within a React component, call `useDeskReservationsForDeskPolicyCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskReservationsForDeskPolicyCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskReservationsForDeskPolicyCardQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useDeskReservationsForDeskPolicyCardQuery(baseOptions: Apollo.QueryHookOptions<DeskReservationsForDeskPolicyCardQuery, DeskReservationsForDeskPolicyCardQueryVariables> & ({ variables: DeskReservationsForDeskPolicyCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskReservationsForDeskPolicyCardQuery, DeskReservationsForDeskPolicyCardQueryVariables>(DeskReservationsForDeskPolicyCardDocument, options);
      }
export function useDeskReservationsForDeskPolicyCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskReservationsForDeskPolicyCardQuery, DeskReservationsForDeskPolicyCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskReservationsForDeskPolicyCardQuery, DeskReservationsForDeskPolicyCardQueryVariables>(DeskReservationsForDeskPolicyCardDocument, options);
        }
export function useDeskReservationsForDeskPolicyCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskReservationsForDeskPolicyCardQuery, DeskReservationsForDeskPolicyCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskReservationsForDeskPolicyCardQuery, DeskReservationsForDeskPolicyCardQueryVariables>(DeskReservationsForDeskPolicyCardDocument, options);
        }
export type DeskReservationsForDeskPolicyCardQueryHookResult = ReturnType<typeof useDeskReservationsForDeskPolicyCardQuery>;
export type DeskReservationsForDeskPolicyCardLazyQueryHookResult = ReturnType<typeof useDeskReservationsForDeskPolicyCardLazyQuery>;
export type DeskReservationsForDeskPolicyCardSuspenseQueryHookResult = ReturnType<typeof useDeskReservationsForDeskPolicyCardSuspenseQuery>;
export type DeskReservationsForDeskPolicyCardQueryResult = Apollo.QueryResult<DeskReservationsForDeskPolicyCardQuery, DeskReservationsForDeskPolicyCardQueryVariables>;
export const DeskResourceDetailsDocument = gql`
    query DeskResourceDetails($deskId: ID!, $deskIds: [ID!]!, $dates: [LocalDate!]!, $startTime: LocalTime!, $durationInMinutes: Int!, $timezone: IANATimezone!, $userId: Int!, $startTimeMoment: Date!, $endTimeMoment: Date!, $recurrence: String) {
  getAnyDeskById(id: $deskId) {
    id
    name
    rawType
    isReservable
    isDisabled
    permissions {
      name
      value
    }
    level {
      name
    }
    location {
      name
    }
    zone {
      name
    }
    stickers {
      displayCode
    }
    settings {
      slug
      value
    }
    state(
      startTime: $startTimeMoment
      endTime: $endTimeMoment
      userId: $userId
      recurrence: $recurrence
    ) {
      reservations {
        startTime
      }
      exclusions {
        startTime
        endTime
      }
    }
  }
  reservationsMultiDayGroupedByDate(
    deskIds: $deskIds
    dates: $dates
    startTime: $startTime
    durationInMinutes: $durationInMinutes
    timezone: $timezone
    userId: $userId
  ) {
    deskId
    availability
    unbookableReasons {
      reason
      value
    }
  }
}
    `;

/**
 * __useDeskResourceDetailsQuery__
 *
 * To run a query within a React component, call `useDeskResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskResourceDetailsQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *      deskIds: // value for 'deskIds'
 *      dates: // value for 'dates'
 *      startTime: // value for 'startTime'
 *      durationInMinutes: // value for 'durationInMinutes'
 *      timezone: // value for 'timezone'
 *      userId: // value for 'userId'
 *      startTimeMoment: // value for 'startTimeMoment'
 *      endTimeMoment: // value for 'endTimeMoment'
 *      recurrence: // value for 'recurrence'
 *   },
 * });
 */
export function useDeskResourceDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeskResourceDetailsQuery, DeskResourceDetailsQueryVariables> & ({ variables: DeskResourceDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeskResourceDetailsQuery, DeskResourceDetailsQueryVariables>(DeskResourceDetailsDocument, options);
      }
export function useDeskResourceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeskResourceDetailsQuery, DeskResourceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeskResourceDetailsQuery, DeskResourceDetailsQueryVariables>(DeskResourceDetailsDocument, options);
        }
export function useDeskResourceDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeskResourceDetailsQuery, DeskResourceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeskResourceDetailsQuery, DeskResourceDetailsQueryVariables>(DeskResourceDetailsDocument, options);
        }
export type DeskResourceDetailsQueryHookResult = ReturnType<typeof useDeskResourceDetailsQuery>;
export type DeskResourceDetailsLazyQueryHookResult = ReturnType<typeof useDeskResourceDetailsLazyQuery>;
export type DeskResourceDetailsSuspenseQueryHookResult = ReturnType<typeof useDeskResourceDetailsSuspenseQuery>;
export type DeskResourceDetailsQueryResult = Apollo.QueryResult<DeskResourceDetailsQuery, DeskResourceDetailsQueryVariables>;
export const GetDeskSettingsByIdDocument = gql`
    query GetDeskSettingsById($id: ID!) {
  getDeskById(id: $id) {
    id
    settings {
      slug
      value
    }
    permissions {
      name
      value
    }
  }
}
    `;

/**
 * __useGetDeskSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetDeskSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeskSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeskSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDeskSettingsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDeskSettingsByIdQuery, GetDeskSettingsByIdQueryVariables> & ({ variables: GetDeskSettingsByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeskSettingsByIdQuery, GetDeskSettingsByIdQueryVariables>(GetDeskSettingsByIdDocument, options);
      }
export function useGetDeskSettingsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeskSettingsByIdQuery, GetDeskSettingsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeskSettingsByIdQuery, GetDeskSettingsByIdQueryVariables>(GetDeskSettingsByIdDocument, options);
        }
export function useGetDeskSettingsByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDeskSettingsByIdQuery, GetDeskSettingsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDeskSettingsByIdQuery, GetDeskSettingsByIdQueryVariables>(GetDeskSettingsByIdDocument, options);
        }
export type GetDeskSettingsByIdQueryHookResult = ReturnType<typeof useGetDeskSettingsByIdQuery>;
export type GetDeskSettingsByIdLazyQueryHookResult = ReturnType<typeof useGetDeskSettingsByIdLazyQuery>;
export type GetDeskSettingsByIdSuspenseQueryHookResult = ReturnType<typeof useGetDeskSettingsByIdSuspenseQuery>;
export type GetDeskSettingsByIdQueryResult = Apollo.QueryResult<GetDeskSettingsByIdQuery, GetDeskSettingsByIdQueryVariables>;
export const DesksOnFloorDocument = gql`
    query DesksOnFloor($levelId: ID!, $filters: [DeskFilters!]) {
  getLevelById(id: $levelId) {
    id
    zones {
      id
      name
      desks(filters: $filters) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useDesksOnFloorQuery__
 *
 * To run a query within a React component, call `useDesksOnFloorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesksOnFloorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesksOnFloorQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDesksOnFloorQuery(baseOptions: Apollo.QueryHookOptions<DesksOnFloorQuery, DesksOnFloorQueryVariables> & ({ variables: DesksOnFloorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DesksOnFloorQuery, DesksOnFloorQueryVariables>(DesksOnFloorDocument, options);
      }
export function useDesksOnFloorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesksOnFloorQuery, DesksOnFloorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DesksOnFloorQuery, DesksOnFloorQueryVariables>(DesksOnFloorDocument, options);
        }
export function useDesksOnFloorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DesksOnFloorQuery, DesksOnFloorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DesksOnFloorQuery, DesksOnFloorQueryVariables>(DesksOnFloorDocument, options);
        }
export type DesksOnFloorQueryHookResult = ReturnType<typeof useDesksOnFloorQuery>;
export type DesksOnFloorLazyQueryHookResult = ReturnType<typeof useDesksOnFloorLazyQuery>;
export type DesksOnFloorSuspenseQueryHookResult = ReturnType<typeof useDesksOnFloorSuspenseQuery>;
export type DesksOnFloorQueryResult = Apollo.QueryResult<DesksOnFloorQuery, DesksOnFloorQueryVariables>;
export const EditBookingDocument = gql`
    mutation EditBooking($input: UpdateMyReservationDeskAndTimeFrameInput!) {
  updateMyReservationDeskAndTimeFrame(input: $input) {
    updatedReservations {
      id
    }
  }
}
    `;
export type EditBookingMutationFn = Apollo.MutationFunction<EditBookingMutation, EditBookingMutationVariables>;

/**
 * __useEditBookingMutation__
 *
 * To run a mutation, you first call `useEditBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBookingMutation, { data, loading, error }] = useEditBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBookingMutation(baseOptions?: Apollo.MutationHookOptions<EditBookingMutation, EditBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditBookingMutation, EditBookingMutationVariables>(EditBookingDocument, options);
      }
export type EditBookingMutationHookResult = ReturnType<typeof useEditBookingMutation>;
export type EditBookingMutationResult = Apollo.MutationResult<EditBookingMutation>;
export type EditBookingMutationOptions = Apollo.BaseMutationOptions<EditBookingMutation, EditBookingMutationVariables>;
export const GetEditReservationSummaryDocument = gql`
    query GetEditReservationSummary($reservationId: ID!, $deskIds: [ID!]!, $dates: [LocalDate!]!, $startTime: LocalTime!, $durationInMinutes: Int!, $timezone: IANATimezone!, $userId: Int!, $startTimeMoment: Date!, $endTimeMoment: Date!, $recurrence: String) {
  getDesksByIds(ids: $deskIds) {
    id
    name
    rawType
    level {
      id
      name
    }
    location {
      id
      name
    }
    zone {
      name
    }
    state(
      startTime: $startTimeMoment
      endTime: $endTimeMoment
      userId: $userId
      recurrence: $recurrence
    ) {
      reservations {
        id
        startTime
      }
      exclusions {
        startTime
        endTime
      }
    }
  }
  reservationsMultiDayGroupedByDate(
    deskIds: $deskIds
    dates: $dates
    startTime: $startTime
    durationInMinutes: $durationInMinutes
    timezone: $timezone
    userId: $userId
  ) {
    deskId
    availability
    unbookableReasons {
      reason
      value
    }
  }
  getDeskReservationById(id: $reservationId) {
    id
    seriesId
    startTime
    endTime
  }
}
    `;

/**
 * __useGetEditReservationSummaryQuery__
 *
 * To run a query within a React component, call `useGetEditReservationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditReservationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditReservationSummaryQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      deskIds: // value for 'deskIds'
 *      dates: // value for 'dates'
 *      startTime: // value for 'startTime'
 *      durationInMinutes: // value for 'durationInMinutes'
 *      timezone: // value for 'timezone'
 *      userId: // value for 'userId'
 *      startTimeMoment: // value for 'startTimeMoment'
 *      endTimeMoment: // value for 'endTimeMoment'
 *      recurrence: // value for 'recurrence'
 *   },
 * });
 */
export function useGetEditReservationSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetEditReservationSummaryQuery, GetEditReservationSummaryQueryVariables> & ({ variables: GetEditReservationSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditReservationSummaryQuery, GetEditReservationSummaryQueryVariables>(GetEditReservationSummaryDocument, options);
      }
export function useGetEditReservationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditReservationSummaryQuery, GetEditReservationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditReservationSummaryQuery, GetEditReservationSummaryQueryVariables>(GetEditReservationSummaryDocument, options);
        }
export function useGetEditReservationSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEditReservationSummaryQuery, GetEditReservationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEditReservationSummaryQuery, GetEditReservationSummaryQueryVariables>(GetEditReservationSummaryDocument, options);
        }
export type GetEditReservationSummaryQueryHookResult = ReturnType<typeof useGetEditReservationSummaryQuery>;
export type GetEditReservationSummaryLazyQueryHookResult = ReturnType<typeof useGetEditReservationSummaryLazyQuery>;
export type GetEditReservationSummarySuspenseQueryHookResult = ReturnType<typeof useGetEditReservationSummarySuspenseQuery>;
export type GetEditReservationSummaryQueryResult = Apollo.QueryResult<GetEditReservationSummaryQuery, GetEditReservationSummaryQueryVariables>;
export const UseSeriesStartTimesDocument = gql`
    query useSeriesStartTimes($seriesId: ID!) {
  getDeskReservationInstancesById(id: $seriesId) {
    id
    startTime
  }
}
    `;

/**
 * __useUseSeriesStartTimesQuery__
 *
 * To run a query within a React component, call `useUseSeriesStartTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseSeriesStartTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseSeriesStartTimesQuery({
 *   variables: {
 *      seriesId: // value for 'seriesId'
 *   },
 * });
 */
export function useUseSeriesStartTimesQuery(baseOptions: Apollo.QueryHookOptions<UseSeriesStartTimesQuery, UseSeriesStartTimesQueryVariables> & ({ variables: UseSeriesStartTimesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseSeriesStartTimesQuery, UseSeriesStartTimesQueryVariables>(UseSeriesStartTimesDocument, options);
      }
export function useUseSeriesStartTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseSeriesStartTimesQuery, UseSeriesStartTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseSeriesStartTimesQuery, UseSeriesStartTimesQueryVariables>(UseSeriesStartTimesDocument, options);
        }
export function useUseSeriesStartTimesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseSeriesStartTimesQuery, UseSeriesStartTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseSeriesStartTimesQuery, UseSeriesStartTimesQueryVariables>(UseSeriesStartTimesDocument, options);
        }
export type UseSeriesStartTimesQueryHookResult = ReturnType<typeof useUseSeriesStartTimesQuery>;
export type UseSeriesStartTimesLazyQueryHookResult = ReturnType<typeof useUseSeriesStartTimesLazyQuery>;
export type UseSeriesStartTimesSuspenseQueryHookResult = ReturnType<typeof useUseSeriesStartTimesSuspenseQuery>;
export type UseSeriesStartTimesQueryResult = Apollo.QueryResult<UseSeriesStartTimesQuery, UseSeriesStartTimesQueryVariables>;
export const GetSharedDesksScheduleByDeskIdDocument = gql`
    query GetSharedDesksScheduleByDeskId($getSharedDeskScheduleIds: [ID!]!) {
  getSharedDesksSchedules(deskIds: $getSharedDeskScheduleIds) {
    id
    schedule {
      id
      firstName
      lastName
      email
      recurrence
      uniqueId
      avatar
    }
  }
}
    `;

/**
 * __useGetSharedDesksScheduleByDeskIdQuery__
 *
 * To run a query within a React component, call `useGetSharedDesksScheduleByDeskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedDesksScheduleByDeskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedDesksScheduleByDeskIdQuery({
 *   variables: {
 *      getSharedDeskScheduleIds: // value for 'getSharedDeskScheduleIds'
 *   },
 * });
 */
export function useGetSharedDesksScheduleByDeskIdQuery(baseOptions: Apollo.QueryHookOptions<GetSharedDesksScheduleByDeskIdQuery, GetSharedDesksScheduleByDeskIdQueryVariables> & ({ variables: GetSharedDesksScheduleByDeskIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSharedDesksScheduleByDeskIdQuery, GetSharedDesksScheduleByDeskIdQueryVariables>(GetSharedDesksScheduleByDeskIdDocument, options);
      }
export function useGetSharedDesksScheduleByDeskIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharedDesksScheduleByDeskIdQuery, GetSharedDesksScheduleByDeskIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSharedDesksScheduleByDeskIdQuery, GetSharedDesksScheduleByDeskIdQueryVariables>(GetSharedDesksScheduleByDeskIdDocument, options);
        }
export function useGetSharedDesksScheduleByDeskIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSharedDesksScheduleByDeskIdQuery, GetSharedDesksScheduleByDeskIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSharedDesksScheduleByDeskIdQuery, GetSharedDesksScheduleByDeskIdQueryVariables>(GetSharedDesksScheduleByDeskIdDocument, options);
        }
export type GetSharedDesksScheduleByDeskIdQueryHookResult = ReturnType<typeof useGetSharedDesksScheduleByDeskIdQuery>;
export type GetSharedDesksScheduleByDeskIdLazyQueryHookResult = ReturnType<typeof useGetSharedDesksScheduleByDeskIdLazyQuery>;
export type GetSharedDesksScheduleByDeskIdSuspenseQueryHookResult = ReturnType<typeof useGetSharedDesksScheduleByDeskIdSuspenseQuery>;
export type GetSharedDesksScheduleByDeskIdQueryResult = Apollo.QueryResult<GetSharedDesksScheduleByDeskIdQuery, GetSharedDesksScheduleByDeskIdQueryVariables>;
export const GetUsersByKeywordDocument = gql`
    query getUsersByKeyword($organizationId: ID!, $keyword: String!) {
  getUsersByKeyword(organizationId: $organizationId, keyword: $keyword) {
    id
    name
    avatar
    givenName
    familyName
    primaryEmail {
      email
    }
  }
}
    `;

/**
 * __useGetUsersByKeywordQuery__
 *
 * To run a query within a React component, call `useGetUsersByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByKeywordQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetUsersByKeywordQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByKeywordQuery, GetUsersByKeywordQueryVariables> & ({ variables: GetUsersByKeywordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByKeywordQuery, GetUsersByKeywordQueryVariables>(GetUsersByKeywordDocument, options);
      }
export function useGetUsersByKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByKeywordQuery, GetUsersByKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByKeywordQuery, GetUsersByKeywordQueryVariables>(GetUsersByKeywordDocument, options);
        }
export function useGetUsersByKeywordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersByKeywordQuery, GetUsersByKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByKeywordQuery, GetUsersByKeywordQueryVariables>(GetUsersByKeywordDocument, options);
        }
export type GetUsersByKeywordQueryHookResult = ReturnType<typeof useGetUsersByKeywordQuery>;
export type GetUsersByKeywordLazyQueryHookResult = ReturnType<typeof useGetUsersByKeywordLazyQuery>;
export type GetUsersByKeywordSuspenseQueryHookResult = ReturnType<typeof useGetUsersByKeywordSuspenseQuery>;
export type GetUsersByKeywordQueryResult = Apollo.QueryResult<GetUsersByKeywordQuery, GetUsersByKeywordQueryVariables>;
export const BookingEnabledDocument = gql`
    query BookingEnabled($deskIds: [ID!]!, $dates: [LocalDate!]!, $startTime: LocalTime!, $durationInMinutes: Int!, $timezone: IANATimezone!, $userId: Int!, $startTimeMoment: Date!, $endTimeMoment: Date!, $recurrence: String) {
  getDesksByIds(ids: $deskIds) {
    id
    rawType
    isReservable
    isDisabled
    settings {
      slug
      value
    }
    state(
      startTime: $startTimeMoment
      endTime: $endTimeMoment
      userId: $userId
      recurrence: $recurrence
    ) {
      reservations {
        id
        reservee {
          user {
            id
            name
          }
        }
      }
      exclusions {
        startTime
        endTime
      }
    }
  }
  reservationsMultiDayGroupedByDate(
    deskIds: $deskIds
    dates: $dates
    startTime: $startTime
    durationInMinutes: $durationInMinutes
    timezone: $timezone
    userId: $userId
  ) {
    deskId
    availability
    unbookableReasons {
      reason
    }
  }
}
    `;

/**
 * __useBookingEnabledQuery__
 *
 * To run a query within a React component, call `useBookingEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingEnabledQuery({
 *   variables: {
 *      deskIds: // value for 'deskIds'
 *      dates: // value for 'dates'
 *      startTime: // value for 'startTime'
 *      durationInMinutes: // value for 'durationInMinutes'
 *      timezone: // value for 'timezone'
 *      userId: // value for 'userId'
 *      startTimeMoment: // value for 'startTimeMoment'
 *      endTimeMoment: // value for 'endTimeMoment'
 *      recurrence: // value for 'recurrence'
 *   },
 * });
 */
export function useBookingEnabledQuery(baseOptions: Apollo.QueryHookOptions<BookingEnabledQuery, BookingEnabledQueryVariables> & ({ variables: BookingEnabledQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingEnabledQuery, BookingEnabledQueryVariables>(BookingEnabledDocument, options);
      }
export function useBookingEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingEnabledQuery, BookingEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingEnabledQuery, BookingEnabledQueryVariables>(BookingEnabledDocument, options);
        }
export function useBookingEnabledSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BookingEnabledQuery, BookingEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BookingEnabledQuery, BookingEnabledQueryVariables>(BookingEnabledDocument, options);
        }
export type BookingEnabledQueryHookResult = ReturnType<typeof useBookingEnabledQuery>;
export type BookingEnabledLazyQueryHookResult = ReturnType<typeof useBookingEnabledLazyQuery>;
export type BookingEnabledSuspenseQueryHookResult = ReturnType<typeof useBookingEnabledSuspenseQuery>;
export type BookingEnabledQueryResult = Apollo.QueryResult<BookingEnabledQuery, BookingEnabledQueryVariables>;
export const SpaceAmenitiesDetailsDocument = gql`
    query SpaceAmenitiesDetails($id: ID!) {
  getSpaceById(id: $id) {
    id
    amenities {
      id
      name
      quantity
    }
  }
  getCurrentUserAndOrgs {
    userId
    hasPlacesManageForCurrentTenant
  }
}
    `;

/**
 * __useSpaceAmenitiesDetailsQuery__
 *
 * To run a query within a React component, call `useSpaceAmenitiesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceAmenitiesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceAmenitiesDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceAmenitiesDetailsQuery(baseOptions: Apollo.QueryHookOptions<SpaceAmenitiesDetailsQuery, SpaceAmenitiesDetailsQueryVariables> & ({ variables: SpaceAmenitiesDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceAmenitiesDetailsQuery, SpaceAmenitiesDetailsQueryVariables>(SpaceAmenitiesDetailsDocument, options);
      }
export function useSpaceAmenitiesDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceAmenitiesDetailsQuery, SpaceAmenitiesDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceAmenitiesDetailsQuery, SpaceAmenitiesDetailsQueryVariables>(SpaceAmenitiesDetailsDocument, options);
        }
export function useSpaceAmenitiesDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceAmenitiesDetailsQuery, SpaceAmenitiesDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceAmenitiesDetailsQuery, SpaceAmenitiesDetailsQueryVariables>(SpaceAmenitiesDetailsDocument, options);
        }
export type SpaceAmenitiesDetailsQueryHookResult = ReturnType<typeof useSpaceAmenitiesDetailsQuery>;
export type SpaceAmenitiesDetailsLazyQueryHookResult = ReturnType<typeof useSpaceAmenitiesDetailsLazyQuery>;
export type SpaceAmenitiesDetailsSuspenseQueryHookResult = ReturnType<typeof useSpaceAmenitiesDetailsSuspenseQuery>;
export type SpaceAmenitiesDetailsQueryResult = Apollo.QueryResult<SpaceAmenitiesDetailsQuery, SpaceAmenitiesDetailsQueryVariables>;
export const GetSpacesByLevelIdDocument = gql`
    query getSpacesByLevelId($levelId: ID!, $startTime: Date, $numberOfWindows: Int, $meetingDurationInMinutes: Int, $snapTo: SnapTo) {
  getLevelById(id: $levelId) {
    id
    spaces {
      id
      description
      name
      image
      type
      capacity
      isAccessible
      locationId
      scheduleConfiguration
      amenities {
        id
        name
        amenityId
      }
      meetingRoomWindowedAvailability(
        startTime: $startTime
        numberOfWindows: $numberOfWindows
        meetingDurationInMinutes: $meetingDurationInMinutes
        firstWindowSplit: $snapTo
      ) {
        start
        end
        status
      }
    }
  }
}
    `;

/**
 * __useGetSpacesByLevelIdQuery__
 *
 * To run a query within a React component, call `useGetSpacesByLevelIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesByLevelIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesByLevelIdQuery({
 *   variables: {
 *      levelId: // value for 'levelId'
 *      startTime: // value for 'startTime'
 *      numberOfWindows: // value for 'numberOfWindows'
 *      meetingDurationInMinutes: // value for 'meetingDurationInMinutes'
 *      snapTo: // value for 'snapTo'
 *   },
 * });
 */
export function useGetSpacesByLevelIdQuery(baseOptions: Apollo.QueryHookOptions<GetSpacesByLevelIdQuery, GetSpacesByLevelIdQueryVariables> & ({ variables: GetSpacesByLevelIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpacesByLevelIdQuery, GetSpacesByLevelIdQueryVariables>(GetSpacesByLevelIdDocument, options);
      }
export function useGetSpacesByLevelIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpacesByLevelIdQuery, GetSpacesByLevelIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpacesByLevelIdQuery, GetSpacesByLevelIdQueryVariables>(GetSpacesByLevelIdDocument, options);
        }
export function useGetSpacesByLevelIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSpacesByLevelIdQuery, GetSpacesByLevelIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpacesByLevelIdQuery, GetSpacesByLevelIdQueryVariables>(GetSpacesByLevelIdDocument, options);
        }
export type GetSpacesByLevelIdQueryHookResult = ReturnType<typeof useGetSpacesByLevelIdQuery>;
export type GetSpacesByLevelIdLazyQueryHookResult = ReturnType<typeof useGetSpacesByLevelIdLazyQuery>;
export type GetSpacesByLevelIdSuspenseQueryHookResult = ReturnType<typeof useGetSpacesByLevelIdSuspenseQuery>;
export type GetSpacesByLevelIdQueryResult = Apollo.QueryResult<GetSpacesByLevelIdQuery, GetSpacesByLevelIdQueryVariables>;
export const SpaceReservationPoliciesDocument = gql`
    query SpaceReservationPolicies($id: ID!) {
  getSpaceById(id: $id) {
    reservationPolicies {
      id
      meetingCheckins
      abandonedMeetingProtection
      abandonedThreshold
      strikesForRecurringEvents
      maxReservationLength
      advanceBookingThreshold
      allowRecurringEvents
      restrictBookingToWorkingHours
    }
  }
}
    `;

/**
 * __useSpaceReservationPoliciesQuery__
 *
 * To run a query within a React component, call `useSpaceReservationPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceReservationPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceReservationPoliciesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceReservationPoliciesQuery(baseOptions: Apollo.QueryHookOptions<SpaceReservationPoliciesQuery, SpaceReservationPoliciesQueryVariables> & ({ variables: SpaceReservationPoliciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceReservationPoliciesQuery, SpaceReservationPoliciesQueryVariables>(SpaceReservationPoliciesDocument, options);
      }
export function useSpaceReservationPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceReservationPoliciesQuery, SpaceReservationPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceReservationPoliciesQuery, SpaceReservationPoliciesQueryVariables>(SpaceReservationPoliciesDocument, options);
        }
export function useSpaceReservationPoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceReservationPoliciesQuery, SpaceReservationPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceReservationPoliciesQuery, SpaceReservationPoliciesQueryVariables>(SpaceReservationPoliciesDocument, options);
        }
export type SpaceReservationPoliciesQueryHookResult = ReturnType<typeof useSpaceReservationPoliciesQuery>;
export type SpaceReservationPoliciesLazyQueryHookResult = ReturnType<typeof useSpaceReservationPoliciesLazyQuery>;
export type SpaceReservationPoliciesSuspenseQueryHookResult = ReturnType<typeof useSpaceReservationPoliciesSuspenseQuery>;
export type SpaceReservationPoliciesQueryResult = Apollo.QueryResult<SpaceReservationPoliciesQuery, SpaceReservationPoliciesQueryVariables>;
export const SpaceResourceDetailsDocument = gql`
    query SpaceResourceDetails($id: ID!) {
  getSpaceById(id: $id) {
    id
    name
    description
    image
    type
    capacity
    isAccessible
    locationId
    isManaged
    calendar {
      calendarId
    }
    permissions {
      name
      value
    }
  }
}
    `;

/**
 * __useSpaceResourceDetailsQuery__
 *
 * To run a query within a React component, call `useSpaceResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceResourceDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceResourceDetailsQuery(baseOptions: Apollo.QueryHookOptions<SpaceResourceDetailsQuery, SpaceResourceDetailsQueryVariables> & ({ variables: SpaceResourceDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceResourceDetailsQuery, SpaceResourceDetailsQueryVariables>(SpaceResourceDetailsDocument, options);
      }
export function useSpaceResourceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceResourceDetailsQuery, SpaceResourceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceResourceDetailsQuery, SpaceResourceDetailsQueryVariables>(SpaceResourceDetailsDocument, options);
        }
export function useSpaceResourceDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceResourceDetailsQuery, SpaceResourceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceResourceDetailsQuery, SpaceResourceDetailsQueryVariables>(SpaceResourceDetailsDocument, options);
        }
export type SpaceResourceDetailsQueryHookResult = ReturnType<typeof useSpaceResourceDetailsQuery>;
export type SpaceResourceDetailsLazyQueryHookResult = ReturnType<typeof useSpaceResourceDetailsLazyQuery>;
export type SpaceResourceDetailsSuspenseQueryHookResult = ReturnType<typeof useSpaceResourceDetailsSuspenseQuery>;
export type SpaceResourceDetailsQueryResult = Apollo.QueryResult<SpaceResourceDetailsQuery, SpaceResourceDetailsQueryVariables>;
export const SpaceWindowedAvailabilityDocument = gql`
    query SpaceWindowedAvailability($id: ID!, $startTime: Date, $numberOfWindows: Int!, $meetingDurationInMinutes: Int, $snapTo: SnapTo) {
  getSpaceById(id: $id) {
    id
    isManaged
    calendar {
      calendarId
    }
    meetingRoomWindowedAvailability(
      startTime: $startTime
      numberOfWindows: $numberOfWindows
      meetingDurationInMinutes: $meetingDurationInMinutes
      firstWindowSplit: $snapTo
    ) {
      start
      end
      status
    }
  }
}
    `;

/**
 * __useSpaceWindowedAvailabilityQuery__
 *
 * To run a query within a React component, call `useSpaceWindowedAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceWindowedAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceWindowedAvailabilityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startTime: // value for 'startTime'
 *      numberOfWindows: // value for 'numberOfWindows'
 *      meetingDurationInMinutes: // value for 'meetingDurationInMinutes'
 *      snapTo: // value for 'snapTo'
 *   },
 * });
 */
export function useSpaceWindowedAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<SpaceWindowedAvailabilityQuery, SpaceWindowedAvailabilityQueryVariables> & ({ variables: SpaceWindowedAvailabilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpaceWindowedAvailabilityQuery, SpaceWindowedAvailabilityQueryVariables>(SpaceWindowedAvailabilityDocument, options);
      }
export function useSpaceWindowedAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpaceWindowedAvailabilityQuery, SpaceWindowedAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpaceWindowedAvailabilityQuery, SpaceWindowedAvailabilityQueryVariables>(SpaceWindowedAvailabilityDocument, options);
        }
export function useSpaceWindowedAvailabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SpaceWindowedAvailabilityQuery, SpaceWindowedAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpaceWindowedAvailabilityQuery, SpaceWindowedAvailabilityQueryVariables>(SpaceWindowedAvailabilityDocument, options);
        }
export type SpaceWindowedAvailabilityQueryHookResult = ReturnType<typeof useSpaceWindowedAvailabilityQuery>;
export type SpaceWindowedAvailabilityLazyQueryHookResult = ReturnType<typeof useSpaceWindowedAvailabilityLazyQuery>;
export type SpaceWindowedAvailabilitySuspenseQueryHookResult = ReturnType<typeof useSpaceWindowedAvailabilitySuspenseQuery>;
export type SpaceWindowedAvailabilityQueryResult = Apollo.QueryResult<SpaceWindowedAvailabilityQuery, SpaceWindowedAvailabilityQueryVariables>;
export const GetLocationWorkingHoursDocument = gql`
    query GetLocationWorkingHours($locationId: ID!) {
  getLocationById(id: $locationId) {
    id
    name
    timezone
    workingHours {
      day
      timeFrames {
        start
        end
      }
    }
    customWorkingHours {
      id
      date
      timeFrames {
        start
        end
      }
    }
  }
}
    `;

/**
 * __useGetLocationWorkingHoursQuery__
 *
 * To run a query within a React component, call `useGetLocationWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationWorkingHoursQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationWorkingHoursQuery(baseOptions: Apollo.QueryHookOptions<GetLocationWorkingHoursQuery, GetLocationWorkingHoursQueryVariables> & ({ variables: GetLocationWorkingHoursQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationWorkingHoursQuery, GetLocationWorkingHoursQueryVariables>(GetLocationWorkingHoursDocument, options);
      }
export function useGetLocationWorkingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationWorkingHoursQuery, GetLocationWorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationWorkingHoursQuery, GetLocationWorkingHoursQueryVariables>(GetLocationWorkingHoursDocument, options);
        }
export function useGetLocationWorkingHoursSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLocationWorkingHoursQuery, GetLocationWorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLocationWorkingHoursQuery, GetLocationWorkingHoursQueryVariables>(GetLocationWorkingHoursDocument, options);
        }
export type GetLocationWorkingHoursQueryHookResult = ReturnType<typeof useGetLocationWorkingHoursQuery>;
export type GetLocationWorkingHoursLazyQueryHookResult = ReturnType<typeof useGetLocationWorkingHoursLazyQuery>;
export type GetLocationWorkingHoursSuspenseQueryHookResult = ReturnType<typeof useGetLocationWorkingHoursSuspenseQuery>;
export type GetLocationWorkingHoursQueryResult = Apollo.QueryResult<GetLocationWorkingHoursQuery, GetLocationWorkingHoursQueryVariables>;
export const GetMyCalendarEventsDocument = gql`
    query GetMyCalendarEvents($startTime: Date!, $endTime: Date!, $limit: Int) {
  getMyEventsInTheRange(startTime: $startTime, endTime: $endTime, limit: $limit) {
    id
    title
    startTime
    endTime
    isAllDay
    visibility
    invitees {
      id
      responseStatus
      user {
        id
      }
    }
    spaces {
      id
    }
  }
}
    `;

/**
 * __useGetMyCalendarEventsQuery__
 *
 * To run a query within a React component, call `useGetMyCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCalendarEventsQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMyCalendarEventsQuery(baseOptions: Apollo.QueryHookOptions<GetMyCalendarEventsQuery, GetMyCalendarEventsQueryVariables> & ({ variables: GetMyCalendarEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCalendarEventsQuery, GetMyCalendarEventsQueryVariables>(GetMyCalendarEventsDocument, options);
      }
export function useGetMyCalendarEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCalendarEventsQuery, GetMyCalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCalendarEventsQuery, GetMyCalendarEventsQueryVariables>(GetMyCalendarEventsDocument, options);
        }
export function useGetMyCalendarEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCalendarEventsQuery, GetMyCalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCalendarEventsQuery, GetMyCalendarEventsQueryVariables>(GetMyCalendarEventsDocument, options);
        }
export type GetMyCalendarEventsQueryHookResult = ReturnType<typeof useGetMyCalendarEventsQuery>;
export type GetMyCalendarEventsLazyQueryHookResult = ReturnType<typeof useGetMyCalendarEventsLazyQuery>;
export type GetMyCalendarEventsSuspenseQueryHookResult = ReturnType<typeof useGetMyCalendarEventsSuspenseQuery>;
export type GetMyCalendarEventsQueryResult = Apollo.QueryResult<GetMyCalendarEventsQuery, GetMyCalendarEventsQueryVariables>;
export const AuthContextDocument = gql`
    query AuthContext {
  getCurrentUserAndOrgs {
    userId
    user {
      id
      name
      timeZone
      avatar
      primaryEmail {
        email
      }
    }
    organizations {
      id
      name
      slug
      avatar
    }
  }
}
    `;

/**
 * __useAuthContextQuery__
 *
 * To run a query within a React component, call `useAuthContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthContextQuery(baseOptions?: Apollo.QueryHookOptions<AuthContextQuery, AuthContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthContextQuery, AuthContextQueryVariables>(AuthContextDocument, options);
      }
export function useAuthContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthContextQuery, AuthContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthContextQuery, AuthContextQueryVariables>(AuthContextDocument, options);
        }
export function useAuthContextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AuthContextQuery, AuthContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AuthContextQuery, AuthContextQueryVariables>(AuthContextDocument, options);
        }
export type AuthContextQueryHookResult = ReturnType<typeof useAuthContextQuery>;
export type AuthContextLazyQueryHookResult = ReturnType<typeof useAuthContextLazyQuery>;
export type AuthContextSuspenseQueryHookResult = ReturnType<typeof useAuthContextSuspenseQuery>;
export type AuthContextQueryResult = Apollo.QueryResult<AuthContextQuery, AuthContextQueryVariables>;
export const GetOrSuggestMyDefaultLocationOrLevelDocument = gql`
    query GetOrSuggestMyDefaultLocationOrLevel {
  getOrSuggestMyDefaultLocationOrLevel {
    levelId
    locationId
  }
}
    `;

/**
 * __useGetOrSuggestMyDefaultLocationOrLevelQuery__
 *
 * To run a query within a React component, call `useGetOrSuggestMyDefaultLocationOrLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrSuggestMyDefaultLocationOrLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrSuggestMyDefaultLocationOrLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrSuggestMyDefaultLocationOrLevelQuery(baseOptions?: Apollo.QueryHookOptions<GetOrSuggestMyDefaultLocationOrLevelQuery, GetOrSuggestMyDefaultLocationOrLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrSuggestMyDefaultLocationOrLevelQuery, GetOrSuggestMyDefaultLocationOrLevelQueryVariables>(GetOrSuggestMyDefaultLocationOrLevelDocument, options);
      }
export function useGetOrSuggestMyDefaultLocationOrLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrSuggestMyDefaultLocationOrLevelQuery, GetOrSuggestMyDefaultLocationOrLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrSuggestMyDefaultLocationOrLevelQuery, GetOrSuggestMyDefaultLocationOrLevelQueryVariables>(GetOrSuggestMyDefaultLocationOrLevelDocument, options);
        }
export function useGetOrSuggestMyDefaultLocationOrLevelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrSuggestMyDefaultLocationOrLevelQuery, GetOrSuggestMyDefaultLocationOrLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrSuggestMyDefaultLocationOrLevelQuery, GetOrSuggestMyDefaultLocationOrLevelQueryVariables>(GetOrSuggestMyDefaultLocationOrLevelDocument, options);
        }
export type GetOrSuggestMyDefaultLocationOrLevelQueryHookResult = ReturnType<typeof useGetOrSuggestMyDefaultLocationOrLevelQuery>;
export type GetOrSuggestMyDefaultLocationOrLevelLazyQueryHookResult = ReturnType<typeof useGetOrSuggestMyDefaultLocationOrLevelLazyQuery>;
export type GetOrSuggestMyDefaultLocationOrLevelSuspenseQueryHookResult = ReturnType<typeof useGetOrSuggestMyDefaultLocationOrLevelSuspenseQuery>;
export type GetOrSuggestMyDefaultLocationOrLevelQueryResult = Apollo.QueryResult<GetOrSuggestMyDefaultLocationOrLevelQuery, GetOrSuggestMyDefaultLocationOrLevelQueryVariables>;
export const UseEndTimeDocument = gql`
    query useEndTime($locationId: ID!) {
  getLocationById(id: $locationId) {
    id
    timezone
    workingHours {
      day
      timeFrames {
        start
        end
      }
    }
    customWorkingHours {
      id
      date
      timeFrames {
        start
        end
      }
    }
  }
}
    `;

/**
 * __useUseEndTimeQuery__
 *
 * To run a query within a React component, call `useUseEndTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseEndTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseEndTimeQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useUseEndTimeQuery(baseOptions: Apollo.QueryHookOptions<UseEndTimeQuery, UseEndTimeQueryVariables> & ({ variables: UseEndTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseEndTimeQuery, UseEndTimeQueryVariables>(UseEndTimeDocument, options);
      }
export function useUseEndTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseEndTimeQuery, UseEndTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseEndTimeQuery, UseEndTimeQueryVariables>(UseEndTimeDocument, options);
        }
export function useUseEndTimeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseEndTimeQuery, UseEndTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseEndTimeQuery, UseEndTimeQueryVariables>(UseEndTimeDocument, options);
        }
export type UseEndTimeQueryHookResult = ReturnType<typeof useUseEndTimeQuery>;
export type UseEndTimeLazyQueryHookResult = ReturnType<typeof useUseEndTimeLazyQuery>;
export type UseEndTimeSuspenseQueryHookResult = ReturnType<typeof useUseEndTimeSuspenseQuery>;
export type UseEndTimeQueryResult = Apollo.QueryResult<UseEndTimeQuery, UseEndTimeQueryVariables>;
export const UserPermissionsDocument = gql`
    query UserPermissions {
  getCurrentUserAndOrgs {
    userId
    hasOfficeAccessManagePermissionForCurrentTenant
    hasSeatsReservePublicPermissionForCurrentTenant
  }
}
    `;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(UserPermissionsDocument, options);
      }
export function useUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(UserPermissionsDocument, options);
        }
export function useUserPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(UserPermissionsDocument, options);
        }
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>;
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>;
export type UserPermissionsSuspenseQueryHookResult = ReturnType<typeof useUserPermissionsSuspenseQuery>;
export type UserPermissionsQueryResult = Apollo.QueryResult<UserPermissionsQuery, UserPermissionsQueryVariables>;
export const UseLocationLevelsDocument = gql`
    query useLocationLevels($locationId: ID!) {
  getLocationById(id: $locationId) {
    id
    name
    levels {
      id
      name
      mapIsAvailable
    }
    timezone
    workingHours {
      day
      timeFrames {
        start
        end
      }
    }
    customWorkingHours {
      id
      date
      timeFrames {
        start
        end
      }
    }
  }
}
    `;

/**
 * __useUseLocationLevelsQuery__
 *
 * To run a query within a React component, call `useUseLocationLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseLocationLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseLocationLevelsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useUseLocationLevelsQuery(baseOptions: Apollo.QueryHookOptions<UseLocationLevelsQuery, UseLocationLevelsQueryVariables> & ({ variables: UseLocationLevelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>(UseLocationLevelsDocument, options);
      }
export function useUseLocationLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>(UseLocationLevelsDocument, options);
        }
export function useUseLocationLevelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>(UseLocationLevelsDocument, options);
        }
export type UseLocationLevelsQueryHookResult = ReturnType<typeof useUseLocationLevelsQuery>;
export type UseLocationLevelsLazyQueryHookResult = ReturnType<typeof useUseLocationLevelsLazyQuery>;
export type UseLocationLevelsSuspenseQueryHookResult = ReturnType<typeof useUseLocationLevelsSuspenseQuery>;
export type UseLocationLevelsQueryResult = Apollo.QueryResult<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>;
export const UseLocationWorkingHoursDocument = gql`
    query useLocationWorkingHours($locationId: ID!, $startTime: Date!, $endTime: Date!, $userId: ID!, $recurrence: String) {
  getLocationById(id: $locationId) {
    id
    name
    address
    timezone
    state(
      startTime: $startTime
      endTime: $endTime
      userId: $userId
      recurrence: $recurrence
    ) {
      availability {
        unavailableReasons
      }
    }
    workingHours {
      day
      timeFrames {
        start
        end
      }
    }
    customWorkingHours {
      id
      date
      timeFrames {
        start
        end
      }
    }
  }
}
    `;

/**
 * __useUseLocationWorkingHoursQuery__
 *
 * To run a query within a React component, call `useUseLocationWorkingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseLocationWorkingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseLocationWorkingHoursQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      userId: // value for 'userId'
 *      recurrence: // value for 'recurrence'
 *   },
 * });
 */
export function useUseLocationWorkingHoursQuery(baseOptions: Apollo.QueryHookOptions<UseLocationWorkingHoursQuery, UseLocationWorkingHoursQueryVariables> & ({ variables: UseLocationWorkingHoursQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseLocationWorkingHoursQuery, UseLocationWorkingHoursQueryVariables>(UseLocationWorkingHoursDocument, options);
      }
export function useUseLocationWorkingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseLocationWorkingHoursQuery, UseLocationWorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseLocationWorkingHoursQuery, UseLocationWorkingHoursQueryVariables>(UseLocationWorkingHoursDocument, options);
        }
export function useUseLocationWorkingHoursSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseLocationWorkingHoursQuery, UseLocationWorkingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseLocationWorkingHoursQuery, UseLocationWorkingHoursQueryVariables>(UseLocationWorkingHoursDocument, options);
        }
export type UseLocationWorkingHoursQueryHookResult = ReturnType<typeof useUseLocationWorkingHoursQuery>;
export type UseLocationWorkingHoursLazyQueryHookResult = ReturnType<typeof useUseLocationWorkingHoursLazyQuery>;
export type UseLocationWorkingHoursSuspenseQueryHookResult = ReturnType<typeof useUseLocationWorkingHoursSuspenseQuery>;
export type UseLocationWorkingHoursQueryResult = Apollo.QueryResult<UseLocationWorkingHoursQuery, UseLocationWorkingHoursQueryVariables>;
export const GetOrganizationLocationsDocument = gql`
    query GetOrganizationLocations($orgID: ID!) {
  getOrganizationById(id: $orgID) {
    id
    campuses {
      id
      name
    }
    locations {
      id
      name
      campus {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationLocationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationLocationsQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *   },
 * });
 */
export function useGetOrganizationLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables> & ({ variables: GetOrganizationLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
      }
export function useGetOrganizationLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
        }
export function useGetOrganizationLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
        }
export type GetOrganizationLocationsQueryHookResult = ReturnType<typeof useGetOrganizationLocationsQuery>;
export type GetOrganizationLocationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationLocationsLazyQuery>;
export type GetOrganizationLocationsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationLocationsSuspenseQuery>;
export type GetOrganizationLocationsQueryResult = Apollo.QueryResult<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>;