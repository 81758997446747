import { CalendarProps, Skeleton, Typography } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { DayStatus, useReleaseDeskModalContext } from './contexts';
import { MomentCalendar } from 'components/map/MomentCalendar';
import { ModalFooter } from 'components/global/ModalFooter';
import { LocationDateTimeMoment } from 'utils';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { SELECTED_DATE_FORMAT } from 'constants/timeFormat';
import { usePatchExclusions } from './graphql/usePatchExclusions';
import moment from 'moment';
import { useTimezone } from 'atoms/resource';

type ReleaseDeskModalContentProps = {
  onClose: () => void;
};

export function ReleaseDeskModalContent({
  onClose,
}: ReleaseDeskModalContentProps) {
  const { t } = useTranslation('resourceDetails');
  const { timezone } = useTimezone();
  const {
    days,
    toggleDayStatus,
    setCurrentDate,
    currentDate,
    exclusions,
    seriesId,
    setDays,
    loading,
    resetChanges,
    hasChanges,
  } = useReleaseDeskModalContext();

  const { reserveDesk, isReserving } = usePatchExclusions(
    seriesId || '',
    [],
    [],
    false,
    false
  );

  const cellRender: CalendarProps<LocationDateTimeMoment>['cellRender'] = (
    current,
    info
  ) => {
    if (info.type !== 'date') {
      return null;
    }

    //Don't render days outside of the current month
    //Its just confusing UX and doesn't pair well with calendars click handling
    if (!current.isSame(currentDate, 'month')) {
      return <div style={{ visibility: 'hidden' }} />;
    }

    const dateStr = current.format(SELECTED_DATE_FORMAT);
    const day = days.get(dateStr);

    let CellContentComponent = CellContentDefault;

    switch (day?.currentStatus) {
      case 'assigned-to-me':
        CellContentComponent = CellContentAssigned;
        break;
      case 'released':
        CellContentComponent = CellContentReleased;
        break;
      case 'in-use':
        CellContentComponent = CellContentDisabled;
        break;
      case undefined:
        CellContentComponent = CellContentDefault;
        break;
      default:
        CellContentComponent = CellContentDefault;
    }

    return (
      <CellWrapper>
        <CellContentComponent>
          <CellText
            status={day?.currentStatus || 'default'}
            onClick={() => {
              if (day?.currentStatus !== 'in-use') {
                toggleDayStatus(dateStr);
              }
            }}
          >
            {current.date()}
          </CellText>
        </CellContentComponent>
      </CellWrapper>
    );
  };

  const headerRender = ({
    value,
    onChange,
  }: {
    value: LocationDateTimeMoment;
    onChange: (date: LocationDateTimeMoment) => void;
  }) => {
    const current = value.clone();

    const prevMonth = () => {
      const newValue = current.clone().subtract(1, 'months');
      onChange(newValue);
      setCurrentDate(newValue);
    };

    const nextMonth = () => {
      const newValue = current.clone().add(1, 'months');
      onChange(newValue);
      setCurrentDate(newValue);
    };

    return (
      <div
        style={{
          padding: 8,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <LeftOutlined
          onClick={prevMonth}
          style={{ fontSize: '16px', cursor: 'pointer' }}
        />
        <span style={{ fontSize: 16 }}>{current.format('MMMM YYYY')}</span>
        <RightOutlined
          onClick={nextMonth}
          style={{ fontSize: '16px', cursor: 'pointer' }}
        />
      </div>
    );
  };

  const handleConfirm = async () => {
    const modifiedDays = Array.from(days.entries()).filter(
      ([_, day]) => day.currentStatus !== day.originalStatus
    );

    const datesToCreate = modifiedDays
      .filter(([_, day]) => day.currentStatus === 'released')
      .map(([date]) => {
        const startDateTime = moment.tz(`${date}T00:00:00`, timezone).format();
        const endDateTime = moment.tz(`${date}T23:59:59`, timezone).format();
        return {
          start: { dateTime: startDateTime, timeZone: timezone },
          end: { dateTime: endDateTime, timeZone: timezone },
        };
      });

    const exclusionIdsToDelete = modifiedDays
      .filter(([_, day]) => day.currentStatus === 'assigned-to-me')
      .map(([date]) => {
        const exclusion = exclusions.find((ex) =>
          ex.startTime.startsWith(date)
        );
        return exclusion ? exclusion.id : null;
      })
      .filter((id): id is string => id !== null);

    const result = await reserveDesk({
      variables: {
        seriesId: seriesId || '',
        exclusionIds: exclusionIdsToDelete,
        dates: datesToCreate,
        hasDates: datesToCreate.length > 0,
        hasDeletions: exclusionIdsToDelete.length > 0,
      },
    });

    //If mutations are successful, sync the days map so the state of truth
    //is the same that the server has
    if (result) {
      setDays((prevDays) => {
        const newDays = new Map(prevDays);
        newDays.forEach((day) => {
          day.originalStatus = day.currentStatus;
        });
        return newDays;
      });
    }

    onClose();
  };

  return (
    <>
      <div>{t('release_desk_modal.instruction')}</div>
      {loading ? (
        <CalendarLoadingContainer data-testid="skeleton">
          <Skeleton />
          <Skeleton />
        </CalendarLoadingContainer>
      ) : (
        <MomentCalendar
          style={{ paddingTop: '16px', paddingBottom: '16px' }}
          fullscreen={false}
          fullCellRender={cellRender}
          onPanelChange={(value, mode) => {
            setCurrentDate(value);
          }}
          headerRender={headerRender}
        />
      )}
      <KeyWrapper>
        <Key>
          <KeyColor status="assigned-to-me" />
          <Typography.Text>
            {t('release_desk_modal.assigned_to_me')}
          </Typography.Text>
        </Key>
        <Key>
          <KeyColor status="released" />
          <Typography.Text>{t('release_desk_modal.released')}</Typography.Text>
        </Key>
        <Key>
          <KeyColor status="in-use" />
          <Typography.Text>
            {t('release_desk_modal.released_and_booked')}
          </Typography.Text>
        </Key>
      </KeyWrapper>
      <ModalFooter
        actions={{
          primary: {
            label: t('release_desk_modal.save'),
            onClick: handleConfirm,
            disabled: isReserving || !hasChanges,
            loading: isReserving,
          },
          secondary: {
            label: t('release_desk_modal.discard_changes'),
            onClick: resetChanges,
            disabled: !hasChanges,
          },
          tertiary: {
            label: t('release_desk_modal.close'),
            onClick: onClose,
            disabled: isReserving,
          },
        }}
      ></ModalFooter>
    </>
  );
}

const CellWrapper = styled.div`
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: flex-start;
`;

const sharedCellContentStyles = `
  display: flex;
  width: 28px;
  height: var(--Components-DatePicker-Component-cellHeight, 28px);
  min-width: var(--Components-DatePicker-Component-cellHeight, 28px);
  padding: 0px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: var(--Components-DatePicker-Global-borderRadius, 4px);
`;

const CellContentAssigned = styled.div`
  ${sharedCellContentStyles}
  background: var(--Components-DatePicker-Global-colorPrimary, #2774c1);
`;

const CellContentReleased = styled.div`
  ${sharedCellContentStyles}
  border: var(--Components-DatePicker-Global-lineWidth, 1px) solid
    var(--Components-DatePicker-Component-activeBorderColor, #2774c1);
`;

const CellContentDisabled = styled.div`
  ${sharedCellContentStyles}
  background: #f5f5f5;
`;

const CellContentDefault = styled.div`
  ${sharedCellContentStyles}
  background: transparent;
`;

const CellText = styled(Typography.Text)<{ status: string }>`
  width: 24px;
  color: ${(props) =>
    props.status === 'assigned-to-me'
      ? 'var(--Components-DatePicker-Global-colorTextLightSolid, #fff)'
      : '#000000F2'};
  text-align: center;
  line-height: var(--Components-DatePicker-Global-lineHeight, 22px);
  cursor: ${(props) => (props.status === 'in-use' ? 'not-allowed' : 'pointer')};
`;

const KeyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const Key = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: var(--Space-Margin-marginXS, 8px);
`;

const KeyColor = styled.div<{ status: DayStatus }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 100px;
  background: ${(props) =>
    props.status === 'assigned-to-me' ? '#2774C1' : '#FFF'};
  border: 1px solid
    ${(props) =>
      props.status === 'assigned-to-me'
        ? 'none'
        : props.status === 'in-use'
        ? '#D9D9D9'
        : '#2774C1'};
`;

const CalendarLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
`;
