import { leftSidebarOpenState } from '../../atoms/sidebar/atoms';
import { useRecoilValue } from 'recoil';
// import { BookButton } from './BookButton';
import { useSetLeftSidebarOpen } from '../../atoms/sidebar/hooks';
import { useCallback } from 'react';
import { EnableOfficeV1Card } from './EnableOfficeV1Card';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { Sidebar } from '../global/sidebar/Sidebar';
// import { SpaceCalendar } from 'components/space-calendar/SpaceCalendar';
import { Alert } from '@robinpowered/ui-kit';
import { SidebarContent } from 'components/global/sidebar/SidebarContent';

export const LeftSidebar = (): JSX.Element => {
  const isOpen = useRecoilValue(leftSidebarOpenState);
  const setLeftSidebarOpen = useSetLeftSidebarOpen();
  const enableOfficeV2FeatureFlag = useFeatureFlag('talos(office-V2)', false);
  const handleOpen = useCallback(() => {
    setLeftSidebarOpen(true);
  }, [setLeftSidebarOpen]);

  const handleClose = useCallback(() => {
    setLeftSidebarOpen(false);
  }, [setLeftSidebarOpen]);

  return (
    <Sidebar
      side="left"
      collapsed={!isOpen}
      collapsedWidth={20}
      width={340}
      onCollapse={isOpen ? handleClose : handleOpen}
      mask={isOpen ? true : false}
    >
      <SidebarContent>
        {enableOfficeV2FeatureFlag.value && <EnableOfficeV1Card />}

        {/* <BookButton /> */}
        {/* <SpaceCalendar /> */}
        <Alert
          message="Under Construction. The left sidebar work is in progress."
          type="warning"
        />
      </SidebarContent>
    </Sidebar>
  );
};
