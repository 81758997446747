import { useLazyQuery, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  SpaceLocationDetailsQuery,
  SpaceLocationDetailsQueryVariables,
} from 'generated';
import gql from 'graphql-tag';
import { useEffect } from 'react';

const SPACE_LOCATION_DETAILS = gql`
  query SpaceLocationDetails($id: ID!) {
    getSpaceById(id: $id) {
      id
      name
      locationId
    }
  }
`;

const LOCATION_DETAILS = gql`
  query LocationDetails($locationId: ID!) {
    getLocationById(id: $locationId) {
      id
      name
      levels {
        id
        name
      }
    }
  }
`;

export const useSpaceLocationDetails = (spaceId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data: spaceData } = useQuery<
    SpaceLocationDetailsQuery,
    SpaceLocationDetailsQueryVariables
  >(SPACE_LOCATION_DETAILS, {
    skip: !tenantId || !spaceId,
    fetchPolicy: 'cache-first',
    variables: {
      id: spaceId || '',
    },
  });

  const [
    fetchLocationDetails,
    { loading: locationLoading, data: locationData },
  ] = useLazyQuery(LOCATION_DETAILS, { fetchPolicy: 'cache-first' });

  useEffect(() => {
    if (spaceData?.getSpaceById?.locationId) {
      void fetchLocationDetails({
        variables: { locationId: spaceData.getSpaceById.locationId },
      });
    }
  }, [spaceData, fetchLocationDetails]);

  const spaceLocationDetails = {
    id: spaceData?.getSpaceById?.id,
    name: spaceData?.getSpaceById?.name,
    location: {
      name: locationData?.getLocationById?.name,
    },
    level: {
      name: locationData?.getLocationById?.levels[0]?.name,
    },
  };

  return {
    loading: loading || locationLoading,
    spaceLocationDetails,
  };
};
