import { useCallback, useEffect, useMemo, useState } from 'react';
import { DeskSetting } from './graphql/useDeskBookingControlsData';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

export const usePrivacySettings = (
  deskSettings: DeskSetting[],
  hasSeatsReservePublicPermissionForCurrentTenant: boolean | undefined
) => {
  const [isPrivate, setIsPrivate] = useState<boolean>(false);

  const reservationVisibilityEnabled = useMemo(
    () =>
      deskSettings.find((s) => s.slug === 'desk-reservation-visibility-enabled')
        ?.value === 'true',
    [deskSettings]
  );

  useEffect(() => {
    if (hasSeatsReservePublicPermissionForCurrentTenant !== undefined) {
      setIsPrivate(!hasSeatsReservePublicPermissionForCurrentTenant);
    }
  }, [hasSeatsReservePublicPermissionForCurrentTenant]);

  const handlePrivacyChange = useCallback(
    (e: CheckboxChangeEvent) => {
      setIsPrivate(e.target.checked);
    },
    [setIsPrivate]
  );

  return {
    isPrivate,
    reservationVisibilityEnabled,
    handlePrivacyChange,
  };
};
