import { useBookingEnabledForDesks } from './useBookingEnabledForDesks';

export const useBookingEnabledForDesk = (deskId: string | null | undefined) => {
  const { data, loading } = useBookingEnabledForDesks(deskId ? [deskId] : []);

  const desk = deskId ? data?.[deskId] : undefined;

  return {
    isBookable: desk?.isBookable || false,
    isBookedByCurrentUser: desk?.isBookedByCurrentUser || false,
    isSelectedStartTimeDuringExclusion:
      desk?.isSelectedStartTimeDuringExclusion || false,
    noAccessOrPermission: desk?.noAccessOrPermission || false,
    requiresAssignmentByAdmin: desk?.requiresAssignmentByAdmin || false,
    loading,
  };
};
