import { Avatar, Card, Skeleton, Typography } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { useTimezone } from 'atoms/resource/hooks';
import { momentToLocationDateTime } from 'utils/dateUtils';
import moment from 'moment';
import { DATE_TIME_FORMAT, SHORT_TIME_FORMAT } from 'constants/timeFormat';
import styled from '@emotion/styled';
import { useUpcomingDeskReservations } from './graphql/useUpcomingDeskReservations';
import { DeskType } from 'generated';
import { useMemo } from 'react';
const { Text } = Typography;

export const UpcomingDeskBookings = ({
  deskId,
}: {
  deskId: string | undefined;
}) => {
  const { t } = useTranslation('resourceDetails');
  const { timezone } = useTimezone();
  const { loading, data: upcomingBookings } =
    useUpcomingDeskReservations(deskId);

  const upcomingFlexBookings = useMemo(
    () =>
      upcomingBookings.filter((booking) => booking.type !== DeskType.Shared),
    [upcomingBookings]
  );
  if (loading) {
    return <Skeleton active />;
  }

  return (
    <BookingListWrapper>
      <Typography.Title level={5}>
        <div>{t('assigned_desk.upcoming_bookings')}</div>
      </Typography.Title>
      <BookingList>
        {upcomingFlexBookings.length === 0 ? (
          <div>{t('assigned_desk.no_bookings')}</div>
        ) : (
          upcomingFlexBookings.map((booking) => {
            const startTime = momentToLocationDateTime(
              moment(booking.startTime),
              timezone
            );
            const endTime = momentToLocationDateTime(
              moment(booking.endTime),
              timezone
            );
            return (
              <BookingRow key={booking.startTime}>
                <BookingTime type="secondary">
                  {startTime.format(DATE_TIME_FORMAT) +
                    ' - ' +
                    endTime.format(SHORT_TIME_FORMAT)}
                </BookingTime>
                <UserDetails>
                  <Card.Meta
                    avatar={
                      <Avatar src={booking.accountReservee?.user?.avatar} />
                    }
                    title={
                      <UserName>
                        {booking.accountReservee?.user?.name ||
                          booking.accountReservee?.user?.primaryEmail?.email}
                      </UserName>
                    }
                  />
                </UserDetails>
              </BookingRow>
            );
          })
        )}
      </BookingList>
    </BookingListWrapper>
  );
};
const BookingListWrapper = styled.div`
  width: 100%;
`;
const BookingTime = styled(Text)``;
const BookingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const UserName = styled.div`
  max-width: 240px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  font-weight: bold;
`;

const UserDetails = styled.div`
  .ant-card-meta {
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 150px !important;
    white-space: nowrap;
  }
`;
const BookingRow = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
`;
