import { gql, useMutation, MutationTuple } from '@apollo/client';
import {
  ReportIssueWithSpaceInput,
  ReportIssueWithSpaceMutationVariables,
} from 'generated';

const REPORT_ISSUE_WITH_SPACE_MUTATION = gql`
  mutation ReportIssueWithSpace($input: ReportIssueWithSpaceInput!) {
    reportIssueWithSpace(input: $input) {
      id
    }
  }
`;

export const useReportIssueWithSpace = (): MutationTuple<
  ReportIssueWithSpaceInput,
  ReportIssueWithSpaceMutationVariables
> => {
  return useMutation<
    ReportIssueWithSpaceInput,
    ReportIssueWithSpaceMutationVariables
  >(REPORT_ISSUE_WITH_SPACE_MUTATION);
};
