import { useCallback } from 'react';
import moment from 'moment';
import { message } from '@robinpowered/ui-kit';
import { t } from 'i18next';
import { LocationDateTimeMoment, momentToLocationDateTime } from 'utils';
import { useAuthContext } from 'contexts';
import { useTimezone } from 'atoms/resource';
import { redirectToOldComposer } from 'utils/legacyDashboard';

export const useBookingComposer = () => {
  const { currentOrg } = useAuthContext();
  const { timezone } = useTimezone();

  const linkToComposer = useCallback(
    (
      spaceId: string,
      start: string | LocationDateTimeMoment | undefined,
      end: string | LocationDateTimeMoment | undefined
    ) => {
      if (!currentOrg || !currentOrg.slug || currentOrg.slug === '') {
        void message.error(t('error'));
        return;
      }

      redirectToOldComposer(
        currentOrg.slug,
        spaceId,
        momentToLocationDateTime(moment(start), timezone),
        momentToLocationDateTime(moment(end), timezone)
      );
    },
    [currentOrg, timezone]
  );

  return { linkToComposer };
};
