import styled from '@emotion/styled';
import { Avatar, Card } from '@robinpowered/ui-kit';

type PropTypes = {
  name: string | undefined | null;
  email: string | undefined | null;
  avatar: string | undefined | null;
  onRemove?: () => void | undefined;
  onClick?: () => void | undefined;
};

export const UserSelectCard = ({
  name,
  email,
  avatar,
  onClick,
}: PropTypes): JSX.Element => {
  return (
    <UserSelectCardWrapper onClick={onClick}>
      <StyledCard
        size="default"
        type="inner"
        bordered={false}
        style={{ boxShadow: 'none' }}
      >
        <Card.Meta
          avatar={<Avatar src={avatar} />}
          title={<TextWrapper>{name}</TextWrapper>}
          description={<TextWrapper>{email}</TextWrapper>}
        />
      </StyledCard>
    </UserSelectCardWrapper>
  );
};

const UserSelectCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const TextWrapper = styled.div`
  max-width: 130px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
`;

const StyledCard = styled(Card)`
  background-color: transparent !important;
  .ant-card-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;
    padding: 0px;
    white-space: nowrap;
    flex-direction: row;
    height: 40px;
  }
  .ant-card-meta-avatar {
    padding-right: 12px !important;
  }
  .ant-card-meta-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0px !important;
  }
  .ant-card-meta-description {
    font-size: 12px;
  }
`;
