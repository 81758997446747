import { useRef, KeyboardEventHandler, KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import { Input } from '@robinpowered/ui-kit';
import { ApolloError } from '@apollo/client';
import { useClickAway } from 'react-use';
import { UserSelectResults } from './UserSelectResults';
import { useKeyboardNavigation } from './useKeyboardNavigation';
import { UserSearch } from '../../graphql/useUserSearch';

const HTML_MENU_ID = 'user-select-results';

type PropTypes = {
  loading: boolean;
  value: string;
  users: null | UserSearch[];
  error?: ApolloError;
  formError?: boolean;
  errorText?: string;
  placeholder?: string;
  showResults: boolean;
  onChange: (value: string) => void;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onFocus: () => void;
  onSelectUser: (user: UserSearch) => void;
  onClickAway: () => void;
};

export const UserSelect = ({
  loading,
  value,
  users,
  error,
  placeholder,
  showResults,
  errorText,
  onSelectUser,
  onChange,
  onFocus,
  onKeyDown,
  onClickAway,
}: PropTypes): JSX.Element => {
  const containerRef = useRef(null);
  useClickAway(containerRef, onClickAway);
  const { moveThroughMenu, focusedIndex } = useKeyboardNavigation(
    showResults,
    users
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(event);

    if (event.key === 'Escape' && showResults) {
      // Close menu on esc
      onClickAway();
      event.stopPropagation();
      return;
    }
    if (event.key === 'Enter' && focusedIndex !== null && users) {
      // Select a user on enter
      onSelectUser(users[focusedIndex]);
      return;
    }
    if (users) {
      // Move up/down through menu items
      moveThroughMenu(event.key);
    }
  };

  return (
    <Container ref={containerRef} onFocus={onFocus} onKeyDown={handleKeyDown}>
      <Input
        name="userId"
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {showResults && (
        <ResultsWrapper>
          <UserSelectResults
            id={HTML_MENU_ID}
            loading={loading}
            error={error}
            users={users}
            focusedIndex={focusedIndex}
            onSelectUser={onSelectUser}
          />
        </ResultsWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const ResultsWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--Colors-Neutral-colorWhite, #fff);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 225px;
  overflow-y: auto;
`;
