import { useCallback } from 'react';
import { useSetCurrentlySelectedResource } from 'atoms/resource';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';

export const useViewDeskDetails = () => {
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setView = useSetDeskSidebarView();

  return useCallback(
    (deskId: string) => {
      setCurrentlySelectedResource({ id: deskId, type: 'seats' });
      setView('desk-details');
    },
    [setCurrentlySelectedResource, setView]
  );
};
