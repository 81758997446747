import { InMemoryCache } from '@apollo/client';
import { config } from 'config';
import { datadogRum } from '@datadog/browser-rum';

export class CustomInMemorycache extends InMemoryCache {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private log(action: string, object: any) {
    if (config.robinEnv === 'staging') {
      datadogRum.addAction(action, object);
    } else {
      if (process.env.REACT_APP_ENABLE_LOCAL_CACHE_LOGGING)
        //eslint-disable-next-line no-console
        console.log(action, object);
    }
  }
  // override diff to log cache hits and misses
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  diff<T>(options: any): any {
    const result = super.diff<T>(options);

    // perform logging tasks
    if (config.robinEnv !== 'production') {
      if (options.query) {
        const readOptions = {
          query: options.dataId ? undefined : options.query,
          variables: options.variables,
          optimistic: options.optimistic,
          id: options.dataId,
        };

        const originalCacheValue = this.read(readOptions);

        if (result.complete) {
          this.log('apollo_cache_access_hit', {
            operation: options.query?.definitions[0]?.name?.value,
            variables: options.variables,
          });
        } else {
          this.log('apollo_cache_access_miss', {
            operation: options.query?.definitions[0]?.name?.value,
            variables: options.variables,
            missing: result.missing, // shows what fields were missing from cache
            next: result.result,
            prev: originalCacheValue,
          });
        }
      }
    }
    return result;
  }
}
