import { useDeskAmenities } from './graphql/useDeskAmenities';
import { ResourceTags } from '../ResourceTags';
import { Skeleton, Typography } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

export function DeskAmenities({
  deskId,
}: {
  deskId: string | null | undefined;
}): JSX.Element | null {
  const { t } = useTranslation('resourceDetails');
  const { deskDetails, loading } = useDeskAmenities(deskId);

  if (loading) return <Skeleton active />;
  if (!deskDetails?.amenities || deskDetails?.amenities.length === 0)
    return null;

  return (
    <AmenitiesContainer>
      <Typography.Title level={5}>{t('amenities.title')}</Typography.Title>
      <ResourceTags resources={deskDetails.amenities} />
    </AmenitiesContainer>
  );
}

const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
