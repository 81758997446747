import { useCallback } from 'react';
import { Button } from '@robinpowered/ui-kit';
import { ChevronLeftOutline, ChevronRightOutline } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { Z_INDEX } from '../../../constants';

type SidebarTriggerProps = {
  collapsed: boolean;
  onCollapse: () => void;
};

export const SidebarTrigger = ({
  collapsed,
  onCollapse,
}: SidebarTriggerProps) => {
  // Icon does not take into account the side placement
  // Our use case is just the left sidebar as of right now
  const getTriggerIcon = useCallback(() => {
    return collapsed ? (
      <ChevronRightOutline data-testid="right-icon" size={12} />
    ) : (
      <ChevronLeftOutline data-testid="left-icon" size={12} />
    );
  }, [collapsed]);

  return (
    <TriggerButton shape="circle" onClick={onCollapse} size="small">
      {getTriggerIcon()}
    </TriggerButton>
  );
};

// Button placement does not take into account the side placement
// Our use case is just the left sidebar as of right now
const TriggerButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: -14px;
  z-index: ${Z_INDEX.SIDEBAR_TRIGGER};
`;
