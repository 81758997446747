import { useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  SpaceAmenitiesDetailsQuery,
  SpaceAmenitiesDetailsQueryVariables,
} from 'generated';
import gql from 'graphql-tag';

const SPACE_RESOURCE_DETAILS = gql`
  query SpaceAmenitiesDetails($id: ID!) {
    getSpaceById(id: $id) {
      id
      amenities {
        id
        name
        quantity
      }
    }
    getCurrentUserAndOrgs {
      userId
      hasPlacesManageForCurrentTenant
    }
  }
`;

export const useSpaceAmenitiesDetails = (spaceId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    SpaceAmenitiesDetailsQuery,
    SpaceAmenitiesDetailsQueryVariables
  >(SPACE_RESOURCE_DETAILS, {
    skip: !tenantId || !spaceId,
    variables: {
      id: spaceId || '',
    },
  });

  return {
    loading,
    amenities: data?.getSpaceById?.amenities,
    canManage: data?.getCurrentUserAndOrgs?.hasPlacesManageForCurrentTenant,
  };
};
