import { MutationTuple, useMutation, gql } from '@apollo/client';
import { PERSEUS_AVAILABILITY_QUERIES } from 'constants/perseus';
import { useBookingControlsContext } from 'contexts/BookingControlsContext';
import {
  EndOrCancelReservationByIdMutationResult,
  EndOrCancelReservationByIdMutationVariables,
} from 'generated';

const END_OR_CANCEL_RESERVATION = gql`
  mutation EndOrCancelReservationById($reservationId: ID!) {
    endOrCancelReservation(reservationId: $reservationId) {
      reservationId
    }
  }
`;

export const useEndOrCancelReservation = (): MutationTuple<
  EndOrCancelReservationByIdMutationResult,
  EndOrCancelReservationByIdMutationVariables
> => {
  const { setIsBookingControlsSticky } = useBookingControlsContext();

  return useMutation<
    EndOrCancelReservationByIdMutationResult,
    EndOrCancelReservationByIdMutationVariables
  >(END_OR_CANCEL_RESERVATION, {
    refetchQueries: [
      'useSeriesStartTimesForCancel',
      'DeskResourceDetails',
      'DeskReservationsBySeatId',
      'DeskReservationsForCancelModal',
      'DeskReservationsForDeskPolicyCard',
      ...PERSEUS_AVAILABILITY_QUERIES,
    ],
    // We always want to show booking controls after cancelation
    onCompleted: () => {
      setIsBookingControlsSticky(true);
    },
  });
};
