import { PlaceUnavailableReason } from 'generated';

export const isLocationClosed = (
  locationUnavailableReasons: PlaceUnavailableReason[] | undefined
) => {
  if (!locationUnavailableReasons) {
    return false;
  }

  return locationUnavailableReasons.includes(
    PlaceUnavailableReason.BuildingClosed
  );
};
