import { gql } from '@apollo/client';
import { useQueryCachedLoad } from 'hooks';
import {
  useGetDeskEndTimesForSelectedDates,
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useSelectedLocation,
} from 'atoms/resource';
import {
  UseLocationWorkingHoursQuery,
  UseLocationWorkingHoursQueryVariables,
} from 'generated';
import { useAuthContext } from 'contexts';
import { ISO_DATE_FORMAT } from 'constants/timeFormat';
import { useCurrentFilter } from 'atoms/mapInteractions';
import { useMemo } from 'react';
import { LocationDateTimeMoment } from 'utils';
import { RRuleSet } from 'rrule';

export const OFFICE_HOURS_QUERY = gql`
  query useLocationWorkingHours(
    $locationId: ID!
    $startTime: Date!
    $endTime: Date!
    $userId: ID!
    $recurrence: String
  ) {
    getLocationById(id: $locationId) {
      id
      name
      address
      timezone
      state(
        startTime: $startTime
        endTime: $endTime
        userId: $userId
        recurrence: $recurrence
      ) {
        availability {
          unavailableReasons
        }
      }
      workingHours {
        day
        timeFrames {
          start
          end
        }
      }
      customWorkingHours {
        id
        date
        timeFrames {
          start
          end
        }
      }
    }
  }
`;

export const useLocationWorkingHours = () => {
  const [selectedLocation] = useSelectedLocation();
  const { currentUser } = useAuthContext();
  const currentFilter = useCurrentFilter();

  const startTimesForSelectedDates = useGetStartTimesForSelectedDates();
  const deskEndTimesForSelectedDates = useGetDeskEndTimesForSelectedDates();
  const spaceEndTimesForSelectedDates = useGetSpaceEndTimesForSelectedDates();

  const startTime = useMemo(() => {
    return normalizeTime(startTimesForSelectedDates?.[0])?.format(
      ISO_DATE_FORMAT
    );
  }, [startTimesForSelectedDates]);

  const endTime = useMemo(() => {
    if (currentFilter === 'spaces') {
      return normalizeTime(
        spaceEndTimesForSelectedDates?.[
          spaceEndTimesForSelectedDates.length - 1
        ]
      )?.format(ISO_DATE_FORMAT);
    }

    return normalizeTime(
      deskEndTimesForSelectedDates?.[deskEndTimesForSelectedDates.length - 1]
    )?.format(ISO_DATE_FORMAT);
  }, [
    currentFilter,
    deskEndTimesForSelectedDates,
    spaceEndTimesForSelectedDates,
  ]);

  const recurrence = useMemo(() => {
    if (
      !startTimesForSelectedDates ||
      startTimesForSelectedDates.length === 1
    ) {
      return;
    }

    const rrule = new RRuleSet();

    startTimesForSelectedDates.forEach((date) => {
      rrule.rdate(
        new Date(date.clone().startOf('minute').utc().format(ISO_DATE_FORMAT))
      );
    });

    return rrule.toString();
  }, [startTimesForSelectedDates]);

  const result = useQueryCachedLoad<
    UseLocationWorkingHoursQuery,
    UseLocationWorkingHoursQueryVariables
  >(OFFICE_HOURS_QUERY, {
    variables: {
      locationId: selectedLocation || '',
      startTime: startTime || '',
      endTime: endTime || '',
      userId: currentUser?.id || '',
      recurrence,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip:
      !selectedLocation ||
      !startTimesForSelectedDates?.[0] ||
      !endTime ||
      !currentUser?.id,
  });

  return {
    loading: result.loading,
    location: result.data?.getLocationById,
  };
};

const normalizeTime = (moment: LocationDateTimeMoment | undefined) => {
  return moment?.clone().utc().startOf('minute');
};
