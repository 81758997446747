import { gql, MutationTuple, useMutation } from '@apollo/client';
import { PERSEUS_AVAILABILITY_QUERIES } from 'constants/perseus';
import { useBookingControlsContext } from 'contexts/BookingControlsContext';
import {
  EndOrCancelDelegatedReservationByIdMutation,
  EndOrCancelDelegatedReservationByIdMutationVariables,
} from 'generated';

const END_OR_CANCEL_DELEGATED_RESERVATION = gql`
  mutation EndOrCancelDelegatedReservationById($reservationId: ID!) {
    endOrCancelDelegatedReservation(reservationId: $reservationId) {
      reservationId
    }
  }
`;

export function useEndOrCancelDelegatedReservation(): MutationTuple<
  EndOrCancelDelegatedReservationByIdMutation,
  EndOrCancelDelegatedReservationByIdMutationVariables
> {
  const { setIsBookingControlsSticky } = useBookingControlsContext();
  return useMutation(END_OR_CANCEL_DELEGATED_RESERVATION, {
    refetchQueries: [
      'useSeriesStartTimesForCancel',
      'DeskResourceDetails',
      'DeskReservationsBySeatId',
      'DeskReservationsForCancelModal',
      'DeskReservationsForDeskPolicyCard',
      ...PERSEUS_AVAILABILITY_QUERIES,
    ],
    // We always want to show booking controls after cancelation
    onCompleted: () => {
      setIsBookingControlsSticky(true);
    },
  });
}
