import { gql, useMutation } from '@apollo/client';
import { message } from '@robinpowered/ui-kit';
import { AmplitudeEvents } from 'constants/amplitudeEvents';
import { PERSEUS_AVAILABILITY_QUERIES } from 'constants/perseus';
import { useAmplitude } from 'contexts';
import {
  ReserveDelegatedDeskMutation,
  ReserveDelegatedDeskMutationVariables,
} from 'generated';
import { useTranslation } from 'react-i18next';

const RESERVE_DELEGATED_DESK = gql`
  mutation ReserveDelegatedDesk(
    $seatId: Int!
    $email: String!
    $type: String!
    $start: DateWithTimezone!
    $end: DateWithTimezone!
    $recurrence: [String!]
    $notify: Boolean
    $visibility: DeskReservationVisibility!
  ) {
    reserveDelegatedDesk(
      seatId: $seatId
      email: $email
      type: $type
      start: $start
      end: $end
      notify: $notify
      recurrence: $recurrence
      visibility: $visibility
    ) {
      id
    }
  }
`;

export const useReserveDelegatedDesk = () => {
  const { t } = useTranslation('resourceDetails');
  const { trackEvent } = useAmplitude();
  const [reserveDelegatedDesk, { loading: isReserving }] = useMutation<
    ReserveDelegatedDeskMutation,
    ReserveDelegatedDeskMutationVariables
  >(RESERVE_DELEGATED_DESK, {
    refetchQueries: [
      'DeskResourceDetails',
      'DeskReservationsBySeatId',
      'DeskReservationsForDeskPolicyCard',
      'DeskReservationsForCancelModal',
      ...PERSEUS_AVAILABILITY_QUERIES,
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      trackEvent(AmplitudeEvents.BOOKED_DESK_BY_ADMIN);
      void message.open({
        type: 'success',
        content: t('desk_booking_controls.success'),
      });
    },
    onError: (error) => {
      //No need to log error message here, error link handles it
      //We have no expected errors here given delegate booking uses super permissions
      void message.open({
        type: 'error',
        content: t('desk_booking_controls.failure'),
      });
    },
  });

  return {
    reserveDelegatedDesk,
    isReserving,
  };
};
