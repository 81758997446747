import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Typography, Skeleton } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSpaceReservationPolicies } from './graphql';

const { Title, Text } = Typography;

interface SpaceBookingPoliciesProps {
  spaceId: string | undefined;
}

export const SpaceBookingPolicies: React.FC<SpaceBookingPoliciesProps> = ({
  spaceId,
}) => {
  const { t } = useTranslation('resourceDetails');
  const { policies, loading } = useSpaceReservationPolicies(spaceId);

  const renderCheckInAndAbandonProtectionPolicies = useCallback(() => {
    if (policies?.meetingCheckins && policies.abandonedThreshold) {
      return (
        <Policy>
          {t('space_reservation_policies.abandonedMeetingThreshold', {
            autounbookThreshold: moment
              .duration(policies.abandonedThreshold)
              .asMinutes(),
          })}
        </Policy>
      );
    }

    return null;
  }, [policies, t]);

  if (loading) {
    return <Skeleton active />;
  }

  if (!policies) {
    return null;
  }

  const {
    meetingCheckins,
    abandonedMeetingProtection,
    strikesForRecurringEvents,
    maxReservationLength,
    advanceBookingThreshold,
  } = policies;

  const hasPolicies =
    meetingCheckins ||
    abandonedMeetingProtection ||
    maxReservationLength ||
    advanceBookingThreshold;

  if (!hasPolicies) {
    return null;
  }

  return (
    <BookingPoliciesContainer>
      <BookingPoliciesTitle level={5}>{t('policies')}</BookingPoliciesTitle>

      {meetingCheckins && (
        <AdminSubTitle>
          {t('space_reservation_policies.adminEnforcesCheckIns')}
        </AdminSubTitle>
      )}

      <PoliciesContainer>
        {renderCheckInAndAbandonProtectionPolicies()}

        {abandonedMeetingProtection && strikesForRecurringEvents && (
          <Policy>
            {t('space_reservation_policies.abandonedSeriesThreshold', {
              count: strikesForRecurringEvents,
            })}
          </Policy>
        )}

        {maxReservationLength && (
          <Policy>
            {t('space_reservation_policies.spaceReservationMaxLength', {
              spaceReservationMaxLength: moment
                .duration(maxReservationLength)
                .humanize(),
            })}
          </Policy>
        )}

        {advanceBookingThreshold && (
          <Policy>
            {t(
              'space_reservation_policies.spaceReservationAdvancedBookingThreshold',
              {
                spaceReservationAdvancedBookingThreshold: moment
                  .duration(advanceBookingThreshold)
                  .humanize(),
              }
            )}
          </Policy>
        )}
      </PoliciesContainer>
    </BookingPoliciesContainer>
  );
};

const BookingPoliciesContainer = styled.div`
  display: flex;
  width: 276px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const BookingPoliciesTitle = styled(Title)``;

const AdminSubTitle = styled(Text)``;

const PoliciesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const Policy = styled(Text)``;
