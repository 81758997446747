import { useMemo } from 'react';
import { DeskType } from 'generated';
import { DeskSettings } from '../../graphql/useDeskSettings';
import { Desk, Reservation } from '../../graphql';
import { CheckInButton } from './CheckInButton';
import { ReleaseDeskButton } from './ReleaseDeskButton';
import { CancelDeskButton } from './CancelDeskButton';
import { EditDeskButton } from './EditDeskButton';
import { useDeskReservationSettings } from '../hooks';
import styled from '@emotion/styled';

export function DeskReservationCardActions({
  isScheduled,
  isDuringExclusion,
  reservation,
  desk,
  deskSettings,
  isUsersReservation,
}: {
  isScheduled: boolean;
  isDuringExclusion: boolean | undefined;
  reservation: Reservation;
  desk: Desk;
  deskSettings: DeskSettings;
  isUsersReservation: boolean;
}) {
  const { allowExclusions } = useDeskReservationSettings(deskSettings);

  const canCheckInNow = useMemo(
    () => !!reservation.canCheckInNow,
    [reservation]
  );
  const deskId = useMemo(() => desk?.id, [desk]);
  const deskType = useMemo(() => desk?.rawType, [desk]);
  const seriesId = useMemo(() => reservation.seriesId, [reservation]);
  const reservationId = useMemo(() => reservation.id, [reservation]);

  const isAssigned = useMemo(() => {
    return (
      deskType?.length === 2 &&
      (deskType.includes(DeskType.Assigned) ||
        deskType.includes(DeskType.Hoteled)) &&
      deskType.includes(DeskType.Shared)
    );
  }, [deskType]);

  const canDelegate = useMemo(
    () =>
      desk?.permissions.some(
        (permission) =>
          permission.name === 'seats:delegate' && permission.value === true
      ),
    [desk?.permissions]
  );

  return (
    <>
      <DeskReservationCardButtonContainer>
        {isUsersReservation && (
          <>
            {canCheckInNow && !isDuringExclusion && (
              <CheckInButton reservationId={reservationId} />
            )}
            {isAssigned &&
              isScheduled &&
              allowExclusions &&
              !isDuringExclusion && (
                <ReleaseDeskButton deskId={deskId} seriesId={seriesId} />
              )}
            {!isScheduled && (
              <>
                {!seriesId && <EditDeskButton reservationId={reservationId} />}
                <CancelDeskButton reservation={reservation} desk={desk} />
              </>
            )}
          </>
        )}
        {!isUsersReservation && !isScheduled && canDelegate && (
          <CancelDeskButton reservation={reservation} desk={desk} />
        )}
      </DeskReservationCardButtonContainer>
    </>
  );
}

const DeskReservationCardButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  width: 100%;
  flex-wrap: wrap;
`;
