import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useApolloContext } from 'contexts';
import {
  GetDeskSettingsByIdQuery,
  GetDeskSettingsByIdQueryVariables,
} from 'generated';

export enum DeskSettingSlug {
  SeatReservationTypesAllowed = 'seat-reservation-types-allowed',
  AllowSeatCheckInsFeature = 'allow-seat-check-ins-feature', //Dont use - should be cleaned up
  SeatReservationCheckInWindow = 'seat-reservation-check-in-window',
  AssignedReservationCheckInsEnabled = 'assigned-reservation-check-ins-enabled',
  EnforceLocalCheckInOnly = 'enforce-local-check-in-only',
  AbandonedDeskProtectionThreshold = 'abandoned-desk-protection-threshold',
  AbandonedDeskProtectionEnabled = 'abandoned-desk-protection-enabled',
  AllowExclusions = 'allow-exclusions',
  SeatReservationAdvancedBookingThreshold = 'seat-reservation-advanced-booking-threshold',
  SeatReservationMaxLength = 'seat-reservation-max-length',
  DeskReservationVisibilityEnabled = 'desk-reservation-visibility-enabled',
}

export type DeskSettings = {
  [key in DeskSettingSlug]?: string;
} & {
  [key: string]: string | undefined;
};

const DESK_SETTINGS = gql`
  query GetDeskSettingsById($id: ID!) {
    getDeskById(id: $id) {
      id
      settings {
        slug
        value
      }
      permissions {
        name
        value
      }
    }
  }
`;

export const useDeskSettings = (deskId: string | undefined) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    GetDeskSettingsByIdQuery,
    GetDeskSettingsByIdQueryVariables
  >(DESK_SETTINGS, {
    skip: !tenantId || !deskId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: deskId || '',
    },
  });

  const deskSettings: DeskSettings = (data?.getDeskById?.settings || []).reduce(
    (acc, setting) => {
      acc[setting.slug as DeskSettingSlug] = setting.value;
      return acc;
    },
    {} as DeskSettings
  );

  return {
    loading,
    deskSettings,
    permissions: data?.getDeskById?.permissions,
  };
};
