import { rightSidebarView } from '../../atoms/sidebar/atoms';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Sidebar } from '../global/sidebar/Sidebar';
import {
  DeskReportIssue,
  SpaceReportIssue,
} from '../../components/issue-reporting';
import { useSetIsCameraResetting } from 'atoms/mapInteractions';
import { DeskSidebar } from 'components/resource-details/desk';
import { BookingControlsProvider } from 'contexts/BookingControlsContext';
import { SpaceSidebar } from 'components/resource-details/space/SpaceSidebar';

export const RightSidebar = (): JSX.Element => {
  const sidebarView = useRecoilValue(rightSidebarView);
  const setIsCameraResetting = useSetIsCameraResetting();

  useEffect(() => {
    // Zoom out the camera whenever the right sidebar is closed
    if (!sidebarView) {
      setIsCameraResetting(true);
    }
  }, [sidebarView, setIsCameraResetting]);

  const isCollapsed = useMemo(() => {
    return sidebarView === undefined;
  }, [sidebarView]);

  return (
    <BookingControlsProvider>
      <Sidebar
        side="right"
        collapsed={isCollapsed}
        collapsedWidth={0}
        width={340}
        mask={sidebarView === 'report-desk' || sidebarView === 'report-space'}
        data-testid="right-sidebar"
      >
        {sidebarView === 'report-desk' && <DeskReportIssue />}
        {sidebarView === 'report-space' && <SpaceReportIssue />}
        {sidebarView === 'desks' && <DeskSidebar />}
        {sidebarView === 'composer' && <div data-testid="composer"></div>}
        {sidebarView === 'spaces' && <SpaceSidebar />}
      </Sidebar>
    </BookingControlsProvider>
  );
};
