import { LocationDateTimeMoment } from 'utils';

export type CancelOptions = 'single' | 'multiple';

export type DateRange = {
  start: LocationDateTimeMoment;
  end: LocationDateTimeMoment;
};

export type CancelReservationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  reservationInProgress: boolean;
  reservationStartDate: LocationDateTimeMoment;
  reservationTimezone: string;
  reservationId: string;
  reservationSeriesId: string | null | undefined;
  deskName: string;
  buildingName: string;
  floorName?: string;
  reservationUserId?: string;
  reservationUserName?: string | null;
  isDelegated?: boolean;
};

export type CancelReservationSeriesModalProps = CancelReservationModalProps & {
  reservationSeriesId: string;
};

export const isSeriesModalProps = (
  props: CancelReservationModalProps
): props is CancelReservationSeriesModalProps => {
  if ('reservationSeriesId' in props) {
    return !!props.reservationSeriesId;
  }

  return false;
};
