import styled from '@emotion/styled';
import { StickerSolid } from '@robinpowered/icons';
import { Typography } from '@robinpowered/ui-kit';
import { FC } from 'react';
import { DeskSticker } from './graphql';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts';

export const DeskStickers: FC<{ stickers: DeskSticker[] }> = ({ stickers }) => {
  const { t } = useTranslation('resourceDetails');
  const { isAdmin } = useAuthContext();

  if (!isAdmin || stickers.length === 0) {
    return null;
  }

  return (
    <Sticker>
      <StickerHeader level={5}>{t('sticker_id')}</StickerHeader>
      <StickerMainContent>
        {stickers.map((sticker, index) => (
          <StickerContent key={index}>
            <StickerIcon />
            <StickerText>{sticker.displayCode}</StickerText>
          </StickerContent>
        ))}
      </StickerMainContent>
    </Sticker>
  );
};

const Sticker = styled.div`
  display: flex;
  width: 276px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const StickerHeader = styled(Typography.Title)`
  && {
    margin: 0;
  }
`;

const StickerMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const StickerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const StickerIcon = styled(StickerSolid)`
  width: 16px;
  height: 16px;
`;

const StickerText = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;
