import gql from 'graphql-tag';
import {
  PublishSharedScheduleForDeskMutation,
  PublishSharedScheduleForDeskMutationVariables,
} from 'generated';
import { useMutation } from '@apollo/client';
import { PERSEUS_AVAILABILITY_QUERIES } from 'constants/perseus';

export const PUBLISH_SHARED_SCHEDULE_FOR_DESK = gql`
  mutation PublishSharedScheduleForDesk($input: SharedDeskSchedulesInput!) {
    publishSharedScheduleForDesk(input: $input)
  }
`;

export const usePublishSharedScheduleForDesk = () => {
  return useMutation<
    PublishSharedScheduleForDeskMutation,
    PublishSharedScheduleForDeskMutationVariables
  >(PUBLISH_SHARED_SCHEDULE_FOR_DESK, {
    refetchQueries: [
      'DeskSharedSchedule',
      'DeskResourceDetails',
      'DeskReservationsForDeskPolicyCard',
      ...PERSEUS_AVAILABILITY_QUERIES,
    ],
  });
};
