import styled from '@emotion/styled';
import { PopoutWindowOutline } from '@robinpowered/icons';
import { Button, ButtonProps, theme } from '@robinpowered/ui-kit';

export const ExternalLink = (buttonProps: ButtonProps) => {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Button
      type="link"
      icon={<AdjustedPopoutIcon size={16} color={token.colorLink} />}
      size="small"
      {...buttonProps}
    />
  );
};

const AdjustedPopoutIcon = styled(PopoutWindowOutline)`
  margin-top: 3px;
`;
