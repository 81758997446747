import { useState, useEffect } from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { useDebounce } from 'react-use';
import { useAuthContext } from 'contexts';
import {
  GetUsersByKeywordQuery,
  GetUsersByKeywordQueryVariables,
} from 'generated';

const KEYWORD_DEBOUNCE_MS = 500;
export const MIN_KEYWORD_LENGTH = 2;

export type UserSearch = NonNullable<
  NonNullable<GetUsersByKeywordQuery['getUsersByKeyword']>[number]
>;

export const GET_USERS_BY_KEYWORD = gql`
  query getUsersByKeyword($organizationId: ID!, $keyword: String!) {
    getUsersByKeyword(organizationId: $organizationId, keyword: $keyword) {
      id
      name
      avatar
      givenName
      familyName
      primaryEmail {
        email
      }
    }
  }
`;

type UseUserSearch = {
  keyword: string;
  setKeyword: (keyword: string) => void;
  users: UserSearch[] | null;
  loading: boolean;
  error?: ApolloError;
};

export const useUserSearch = (): UseUserSearch => {
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  useDebounce(() => setDebouncedKeyword(keyword), KEYWORD_DEBOUNCE_MS, [
    keyword,
  ]);

  // State is controlled manually instead of by Apollo's useQuery..
  // We require finer control over when state is updated; for example,
  // if more letters are added to a keyword after the first results have come
  // back, we want to replace the old data with new. Apollo will set the state
  // to null before updating with new results, causing jank in the menu.
  const [state, setState] = useState<{
    loading: boolean;
    error?: ApolloError;
    users: UserSearch[] | null;
  }>({
    loading: false,
    users: null,
  });
  const setLoading = (loading: boolean) =>
    setState((state) => ({ ...state, loading }));
  const setUsers = (users: UserSearch[] | null) =>
    setState((state) => ({
      ...state,
      loading: false,
      users,
      error: undefined,
    }));
  const setError = (error: ApolloError) =>
    setState((state) => ({ ...state, loading: false, error }));
  const { currentOrg } = useAuthContext();

  // Skip initially so we can control when to fetch users.
  const { refetch: getUsers } = useQuery<
    GetUsersByKeywordQuery,
    GetUsersByKeywordQueryVariables
  >(GET_USERS_BY_KEYWORD, {
    skip: true,
  });

  useEffect(() => {
    let cancelled = false;
    if (!currentOrg) {
      return;
    }
    if (keyword.length >= MIN_KEYWORD_LENGTH) {
      setLoading(true);
      getUsers({ organizationId: currentOrg.id, keyword })
        .then((result) => {
          !cancelled && setUsers(result.data.getUsersByKeyword ?? []);
        })
        .catch((err) => !cancelled && setError(err));
    } else {
      setUsers(null);
    }
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword, currentOrg, getUsers]);

  return {
    keyword,
    setKeyword,
    ...state,
  };
};
