import { useAuthContext } from 'contexts';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { locationDateTimeMoment, momentToLocationDateTime } from 'utils';
import {
  CancelOptions,
  CancelReservationModalProps,
  DateRange,
} from '../types';
import {
  useDeskReservationsByUserId,
  useEndOrCancelDelegatedReservation,
  useEndOrCancelReservation,
  useEndReservationOrVisitsInRange,
} from '../graphql';
import moment from 'moment';
import { message, Modal } from '@robinpowered/ui-kit';
import { Z_INDEX } from 'constants/zIndex';
import {
  CancelReservationCancelVisits,
  CancelReservationDetailsSection,
  CancelReservationModalFooter,
  CancelReservationModalTitle,
  CancelReservationOptionsSection,
  ModalBody,
} from '../common';

export const CancelReservationSingleModal = ({
  isOpen,
  onClose,
  onConfirm,
  reservationInProgress,
  reservationUserId,
  reservationUserName,
  reservationStartDate,
  reservationTimezone,
  reservationId,
  deskName,
  buildingName,
  floorName,
  isDelegated,
}: CancelReservationModalProps) => {
  const { t } = useTranslation('resourceDetails');
  const [cancelOption, setCancelOption] = useState<CancelOptions>('single');
  const [cancelVisits, setCancelVisits] = useState(!reservationInProgress);
  const { currentOrg } = useAuthContext();

  const now = locationDateTimeMoment(reservationTimezone).startOf('minute');
  const startOfWeek =
    locationDateTimeMoment(reservationTimezone).startOf('week');
  const endOfWeek = locationDateTimeMoment(reservationTimezone).endOf('week');

  const weekDateRange: DateRange = reservationStartDate.isBetween(
    startOfWeek,
    endOfWeek,
    null,
    '[]'
  )
    ? { start: now, end: endOfWeek }
    : {
        start: reservationStartDate.clone().startOf('week'),
        end: reservationStartDate.clone().endOf('week'),
      };

  const { data: reservationWeekData, loading } = useDeskReservationsByUserId(
    weekDateRange.start,
    weekDateRange.end,
    reservationUserId
  );

  const reservationDateList = useMemo(() => {
    if (reservationWeekData?.getDeskReservationsByUserId.reservations) {
      const dates =
        reservationWeekData.getDeskReservationsByUserId.reservations.map(
          (res) =>
            momentToLocationDateTime(moment(res.startTime), reservationTimezone)
        );

      dates.sort((a, b) => a.diff(b));

      const isContinuous = dates.reduce(
        (isContinuous, currentDate, currentIndex) => {
          if (currentIndex === dates.length - 1) return isContinuous;
          return (
            isContinuous &&
            dates[currentIndex + 1].diff(currentDate, 'days') === 1
          );
        },
        dates.length > 1
      );

      return isContinuous
        ? `${dates[0].format('M/D')} - ${dates[dates.length - 1].format('M/D')}`
        : [...new Set(dates.map((res) => res.format('M/D')))].join(', ');
    }

    return '';
  }, [
    reservationTimezone,
    reservationWeekData?.getDeskReservationsByUserId.reservations,
  ]);

  const handleRadioButton = useCallback(
    (e) => {
      const value = e.target.value;
      if (reservationInProgress && value === 'single') setCancelVisits(false);
      setCancelOption(value);
    },
    [reservationInProgress]
  );

  const [endReservationOrVisitsInRange, endReservationOrVisitsInRangeRequest] =
    useEndReservationOrVisitsInRange();

  const [endOrCancelReservation, endOrCancelReservationRequest] =
    useEndOrCancelReservation();

  const [
    endOrCancelDelegatedReservation,
    endOrCancelDelegatedReservationRequest,
  ] = useEndOrCancelDelegatedReservation();

  const [endOrCancelReservationAction, endOrCancelReservationActionRequest] =
    isDelegated
      ? [
          endOrCancelDelegatedReservation,
          endOrCancelDelegatedReservationRequest,
        ]
      : [endOrCancelReservation, endOrCancelReservationRequest];

  const handleCheckboxClick = () => {
    if (reservationInProgress && cancelOption === 'single')
      setCancelVisits(false);
    else setCancelVisits(!cancelVisits);
  };

  const handleConfirm = useCallback(async () => {
    if (!currentOrg) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const executeCancel = async (action: any, variables: any) => {
      try {
        await action({ variables });
        onConfirm();
        await message.success(
          reservationInProgress
            ? t('cancel_modal.end_success')
            : t('cancel_modal.cancel_success')
        );
      } catch (error) {
        await message.error(
          reservationInProgress
            ? t('cancel_modal.end_failure')
            : t('cancel_modal.cancel_failure')
        );
      }
    };

    if (cancelOption === 'single') {
      if (cancelVisits) {
        await executeCancel(endReservationOrVisitsInRange, {
          orgId: currentOrg?.id,
          userId: reservationUserId,
          start: reservationStartDate.toISOString(),
          end: reservationStartDate.add(1, 'days').toISOString(),
          cancelFutureVisits: true,
          creationType: ['MANUAL', 'AUTOMATIC'],
        });
      } else {
        await executeCancel(endOrCancelReservationAction, { reservationId });
      }
    } else if (cancelOption === 'multiple') {
      await executeCancel(endReservationOrVisitsInRange, {
        orgId: currentOrg?.id,
        userId: reservationUserId,
        start: weekDateRange.start.toISOString(),
        end: weekDateRange.end.toISOString(),
        cancelFutureVisits: cancelVisits,
        creationType: ['MANUAL', 'AUTOMATIC'],
      });
    }
  }, [
    cancelOption,
    cancelVisits,
    currentOrg,
    onConfirm,
    reservationId,
    reservationInProgress,
    reservationStartDate,
    reservationUserId,
    endOrCancelReservationAction,
    endReservationOrVisitsInRange,
    t,
    weekDateRange.end,
    weekDateRange.start,
  ]);

  return (
    <Modal
      zIndex={Z_INDEX.CANCEL_RESERVATION_MODAL}
      title={
        <CancelReservationModalTitle
          reservationInProgress={reservationInProgress}
        />
      }
      open={isOpen}
      onOk={handleConfirm}
      onCancel={onClose}
      footer={
        <CancelReservationModalFooter
          onClose={onClose}
          handleConfirm={handleConfirm}
          loading={
            endOrCancelReservationActionRequest.loading ||
            endReservationOrVisitsInRangeRequest.loading
          }
        />
      }
    >
      <ModalBody>
        <div>{t('cancel_modal.confirm_cancel')}</div>

        <CancelReservationDetailsSection
          reservationUserName={reservationUserName}
          deskName={deskName}
          floorName={floorName}
          buildingName={buildingName}
        >
          {cancelOption === 'single'
            ? reservationStartDate.format('dddd, MMMM DD')
            : loading
            ? t('cancel_modal.loading')
            : reservationDateList}
        </CancelReservationDetailsSection>

        {reservationWeekData?.getDeskReservationsByUserId.reservations
          ?.length &&
          reservationWeekData?.getDeskReservationsByUserId.reservations
            ?.length > 1 && (
            <CancelReservationOptionsSection
              selectedOption={cancelOption}
              isSeries={false}
              reservationStartDate={reservationStartDate}
              reservationInProgress={reservationInProgress}
              loading={loading}
              handleRadioButton={handleRadioButton}
            />
          )}

        <CancelReservationCancelVisits
          selectedOption={cancelOption}
          reservationInProgress={reservationInProgress}
          shouldCancelVisits={cancelVisits}
          handleCheckboxClick={handleCheckboxClick}
        />
      </ModalBody>
    </Modal>
  );
};
