import { JSX } from '@emotion/react/types/jsx-runtime';
import {
  MapComponent,
  ReactMapGl,
  MapLoadingContextProvider,
  MapDataLoadingContextProvider,
} from '@robinpowered/perseus';
import { useAuthContext, useMapControlsContext } from 'contexts';
import { useAccessToken } from 'hooks';
import { MapData } from './MapData';
import { MapInteractions } from './MapInteractions';
import { useStyleSpec } from './hooks';
import { MapLoadingMessage } from './MapLoadingMessage';

export const Map = (): JSX.Element => {
  const accessToken = useAccessToken();
  const { currentOrg, currentUser } = useAuthContext();
  const { selectedLevelId, selectedLocationId } = useMapControlsContext();
  const { styleUrl, backgroundColor } = useStyleSpec(selectedLevelId);

  return (
    <>
      {currentOrg && currentUser && styleUrl && (
        <MapLoadingContextProvider>
          <MapDataLoadingContextProvider>
            <MapLoadingMessage />

            <ReactMapGl.MapProvider>
              <MapComponent
                userId={currentUser?.id}
                tenantId={currentOrg?.id}
                id={`${currentOrg?.id}-${selectedLevelId}`}
                levelId={selectedLevelId}
                locationId={selectedLocationId}
                defaultCursor="default"
                authorization={`Access-Token ${accessToken}`}
                orgSlug={'robin'}
                style={{
                  backgroundColor,
                  height: '100%',
                  width: '100%',
                }}
                styleUrl={styleUrl}
                dashboardUrl="https://dashboard.staging.robinpowered.com"
                locationTimezone={'America/New_York'}
              >
                <MapData />
                <MapInteractions />
              </MapComponent>
            </ReactMapGl.MapProvider>
          </MapDataLoadingContextProvider>
        </MapLoadingContextProvider>
      )}
    </>
  );
};
