import { atom } from 'recoil';
import { DeskSidebarView } from './types';

export type SidebarView =
  | undefined
  | 'composer'
  | 'desks'
  | 'spaces'
  | 'report-desk'
  | 'report-space';

export const leftSidebarOpenState = atom<boolean>({
  key: 'leftSidebarOpenState',
  default: false,
});

export const rightSidebarView = atom<SidebarView>({
  key: 'rightSidebarView',
  default: undefined,
});

export const deskSidebarView = atom<DeskSidebarView>({
  key: 'deskSidebarView',
  default: 'desk-details',
});
