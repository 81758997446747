import { gql } from '@apollo/client';
import { CanEditDesksQueryVariables } from 'generated/graphql';
import { CanEditDesksQuery } from 'generated/graphql';
import { useQueryCachedLoad } from 'hooks';

const QUERY = gql`
  query CanEditDesks {
    getCurrentUserAndOrgs {
      userId
      hasPlacesManageForCurrentTenant
    }
  }
`;

export const useCanEditDesks = () => {
  const { data, loading } = useQueryCachedLoad<
    CanEditDesksQuery,
    CanEditDesksQueryVariables
  >(QUERY);

  return {
    loading,
    canEditDesks: data?.getCurrentUserAndOrgs?.hasPlacesManageForCurrentTenant,
  };
};
