import { useMemo } from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';

export type UseUserPermissionsData = {
  canManageOfficeAccess: boolean;
  hasPublicSeatReservationPermission: boolean;
};

type UseUserPermissionsState = {
  error?: ApolloError;
  isLoading: boolean;
};

type UseUserPermissions = UseUserPermissionsState & UseUserPermissionsData;

const GET_USER_PERMISSIONS = gql`
  query UserPermissions {
    getCurrentUserAndOrgs {
      userId
      hasOfficeAccessManagePermissionForCurrentTenant
      hasSeatsReservePublicPermissionForCurrentTenant
    }
  }
`;

export const useGetUserPermissions = (
  tenantId: string | undefined
): UseUserPermissions => {
  const { called, data, loading, error } = useQuery(GET_USER_PERMISSIONS, {
    skip: !tenantId,
    variables: {
      id: tenantId,
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const permissions = useMemo(() => {
    const { getCurrentUserAndOrgs } = data || {};

    return {
      canManageOfficeAccess:
        !!getCurrentUserAndOrgs?.hasOfficeAccessManagePermissionForCurrentTenant,
      hasPublicSeatReservationPermission:
        !!getCurrentUserAndOrgs?.hasSeatsReservePublicPermissionForCurrentTenant,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this next time file is edited
  }, [data, tenantId]);

  return {
    ...permissions,
    error,
    isLoading: tenantId ? loading || !called : loading,
  };
};
