import gql from 'graphql-tag';
import { useApolloContext } from 'contexts';
import {
  SnapTo,
  SpaceWindowedAvailabilityQuery,
  SpaceWindowedAvailabilityQueryVariables,
} from 'generated';
import { LocationDateTimeMoment } from 'utils';
import { useIsUnmodifiedSpaceEndTime } from '../space-availability-pills/hooks/useIsUnmodifiedSpaceEndTime';
import { useSpaceAvailabilityParameters } from 'hooks/useSpaceAvailabilityParameters';
import { useQueryCachedLoad } from 'hooks';

export type SpaceWindowAvailability =
  SpaceWindowedAvailabilityQuery['getSpaceById'];

const NUMBER_OF_WINDOWS = 4;

const SPACE_WINDOWED_AVAILABILITY = gql`
  query SpaceWindowedAvailability(
    $id: ID!
    $startTime: Date
    $numberOfWindows: Int!
    $meetingDurationInMinutes: Int
    $snapTo: SnapTo
  ) {
    getSpaceById(id: $id) {
      id
      isManaged
      calendar {
        calendarId
      }
      meetingRoomWindowedAvailability(
        startTime: $startTime
        numberOfWindows: $numberOfWindows
        meetingDurationInMinutes: $meetingDurationInMinutes
        firstWindowSplit: $snapTo
      ) {
        start
        end
        status
      }
    }
  }
`;

export const useSpaceWindowedAvailability = (
  spaceId: string | undefined,
  startTime: LocationDateTimeMoment | undefined
) => {
  const { tenantId } = useApolloContext();
  const isUnmodifiedSpaceEndTime = useIsUnmodifiedSpaceEndTime();
  const { durationInMinutes } = useSpaceAvailabilityParameters();
  const { loading, data } = useQueryCachedLoad<
    SpaceWindowedAvailabilityQuery,
    SpaceWindowedAvailabilityQueryVariables
  >(
    SPACE_WINDOWED_AVAILABILITY,
    {
      skip: !tenantId || !spaceId || !startTime,
      variables: {
        id: spaceId || '',
        numberOfWindows: isUnmodifiedSpaceEndTime ? NUMBER_OF_WINDOWS : 1,
        startTime: startTime?.startOf('minute').toISOString(),
        meetingDurationInMinutes: isUnmodifiedSpaceEndTime
          ? 30
          : durationInMinutes,
        snapTo: isUnmodifiedSpaceEndTime ? SnapTo.Thirty : SnapTo.Zero,
      },
    },
    ['startTime', 'meetingDurationInMinutes']
  );

  return {
    loading,
    spaceDetails: data?.getSpaceById,
  };
};
