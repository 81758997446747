import { config } from 'config';
import { Moment } from 'moment';
import queryString from 'query-string';

export const redirectToOldComposer = (
  orgSlug: string,
  spaceId: string,
  startTime: Moment,
  endTime: Moment
) => {
  if (!startTime.isValid() || !endTime.isValid()) {
    throw new Error('Invalid startTime or endTime');
  }

  const queryParams = {
    title: 'Untitled Event',
    is_private: true,
    org: orgSlug,
    started_at: startTime.toJSON(),
    ended_at: endTime.toJSON(),
    space_id: spaceId,
    redirectTo: window.location.href,
  };

  window.location.href = `${
    config.dashboardUrl
  }/event-composer?${queryString.stringify(queryParams)}`;
};

export const redirectToOldSpaceDetails = (
  orgSlug: string,
  spaceId: string,
  locationId: string
) => {
  window.location.href = `${config.dashboardUrl}/${orgSlug}/locations/${locationId}/spaces/${spaceId}`;
};
