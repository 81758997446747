import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAuthContext, useMapControlsContext } from 'contexts';
import { DeskPermissionsQuery, DeskPermissionsQueryVariables } from 'generated';

export type UseManageMapPermissionData = {
  data: {
    hasManageAllDeskPermission: boolean;
    hasManageOfficeAccess: boolean;
  };
};

type UseManageMapPermissionState = {
  isLoading: boolean;
};

type UseDeskPermissions = UseManageMapPermissionState &
  UseManageMapPermissionData;

const GET_DESK_PERMISSIONS = gql`
  query DeskPermissions($levelId: ID!) {
    getLevelById(id: $levelId) {
      id
      desks {
        permissions {
          name
          value
        }
      }
    }
  }
`;

export const useManageMapPermissions = (): UseDeskPermissions => {
  const { selectedLevelId } = useMapControlsContext();
  const { permissions } = useAuthContext();
  const { called, data, loading } = useQuery<
    DeskPermissionsQuery,
    DeskPermissionsQueryVariables
  >(GET_DESK_PERMISSIONS, {
    skip: !selectedLevelId,
    variables: {
      levelId: selectedLevelId || '',
    },
    nextFetchPolicy: 'cache-and-network',
  });
  const hasManageAllDeskPermission = useMemo(() => {
    return data?.getLevelById?.desks?.every(
      (desk) =>
        desk.permissions.some(
          (permission) =>
            permission.name === 'seats:delegate' && permission.value === true
        ) &&
        desk.permissions.some(
          (permission) =>
            permission.name === 'seats:assign' && permission.value === true
        )
    );
  }, [data]);
  return {
    data: {
      hasManageAllDeskPermission: hasManageAllDeskPermission || false,
      hasManageOfficeAccess: permissions.canManageOfficeAccess || false,
    },
    isLoading: selectedLevelId ? loading || !called : loading,
  };
};
