import { gql, useMutation, MutationTuple } from '@apollo/client';
import { AmplitudeEvents } from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts';
import {
  ReportIssueWithDeskInput,
  ReportIssueWithDeskMutationVariables,
} from 'generated';

const REPORT_ISSUE_WITH_DESK_MUTATION = gql`
  mutation ReportIssueWithDesk($input: ReportIssueWithDeskInput!) {
    reportIssueWithDesk(input: $input) {
      id
    }
  }
`;

export const useReportIssueWithDesk = (): MutationTuple<
  ReportIssueWithDeskInput,
  ReportIssueWithDeskMutationVariables
> => {
  const { trackEvent } = useAmplitude();

  return useMutation<
    ReportIssueWithDeskInput,
    ReportIssueWithDeskMutationVariables
  >(REPORT_ISSUE_WITH_DESK_MUTATION, {
    onCompleted: () => {
      trackEvent(AmplitudeEvents.REPORT_ISSUE);
    },
  });
};
