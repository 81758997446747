import { useRef, useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { AutoComplete, Button, theme } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { Add } from '@robinpowered/icons';
import { UserSelectCard } from './UserSelectCard';
import { useUserSearch } from '../graphql/useUserSearch';

type PropTypes = {
  onSelectUser: (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    avatar: string
  ) => void;
};

export const UnassignedDeskUserSelect = ({ onSelectUser }: PropTypes) => {
  const { t } = useTranslation('resourceDetails');
  const { keyword, setKeyword, users } = useUserSearch();
  const [onAssignClick, setOnAssignClick] = useState(false); // New state for controlling view
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const autoCompleteRef = useRef<any>(null);

  //focus the input when the user clicks on the assign button
  useEffect(() => {
    if (onAssignClick && autoCompleteRef.current) {
      autoCompleteRef.current.focus();
    }
  }, [onAssignClick]);

  const onTextChange = (value: string) => {
    setKeyword(value);
  };

  const userOptions = users?.map((user) => ({
    key: `key-${user.id}`,
    id: user.id,
    value: user.id,
    label: (
      <UserSelectCard
        name={user.name}
        email={user.primaryEmail?.email}
        avatar={user.avatar}
      />
    ),
  }));
  const onSelect = useCallback(
    (value: string) => {
      const user = users?.find((user) => user.id === value);
      user &&
        onSelectUser(
          user.id,
          user.givenName ?? '',
          user.familyName ?? '',
          user.primaryEmail?.email ?? '',
          user.avatar ?? ''
        );
    },
    [onSelectUser, users]
  );
  return (
    <UnassignedDeskUserSelectWrapper>
      {onAssignClick ? (
        <StyledAutoComplete
          ref={autoCompleteRef}
          bordered={false}
          value={keyword === '' ? undefined : keyword}
          placeholder={t('assigned_desk.user_search_placeholder')}
          onChange={(value: unknown) =>
            typeof value === 'string' ? onTextChange(value) : undefined
          }
          onSelect={(value: unknown) =>
            typeof value === 'string' ? onSelect(value) : undefined
          }
          options={userOptions}
          onBlur={() => setOnAssignClick(false)}
        ></StyledAutoComplete>
      ) : (
        <ClickToAssign onClick={() => setOnAssignClick(true)} />
      )}
    </UnassignedDeskUserSelectWrapper>
  );
};

const StyledAutoComplete = styled(AutoComplete)`
  min-width: 220px !important;
  width: 100%;
  height: 100%;
`;

const ClickToAssign = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('resourceDetails');
  const { useToken } = theme;
  const { token } = useToken();
  const [isHover, setIsHover] = useState(false);
  const defaultColor = token.Button?.defaultColor ?? 'rgba(0, 0, 0, 0.95)';
  const hoverColor = token.Button?.defaultHoverColor ?? '#cc4189';

  return (
    <ClickToAssignWrapper
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Add size={14} color={isHover ? hoverColor : defaultColor} />
      <>{`${t('assigned_desk.user_search_assign')}`}</>
    </ClickToAssignWrapper>
  );
};

const UnassignedDeskUserSelectWrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: flex-start;
`;

const ClickToAssignWrapper = styled(Button)`
  align-self: flex-start;
  padding-left: 5px;
  padding-top: 5px;
  border: none;
  box-shadow: none;
  background-color: transparent !important;
`;
