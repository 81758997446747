import { useCallback, useMemo } from 'react';
import { useAuthContext } from 'contexts';
import { DeskReservationVisibility } from 'generated';
import { SharedDeskSchedules } from '../graphql/useSharedDeskSchedule';
import { Desk, DeskReservationsBySeatId } from '../graphql';
import { DeskReservationCard } from './DeskReservationCard';

interface NonScheduledReservationsProps {
  deskSchedules: SharedDeskSchedules | undefined;
  reservations: DeskReservationsBySeatId | undefined;
  desk: Desk;
}

export function NonScheduledReservations({
  deskSchedules,
  reservations,
  desk,
}: NonScheduledReservationsProps): JSX.Element | null {
  const { isAdmin, currentUser } = useAuthContext();

  const showReservee = useCallback(
    ({ visibility, reservee }) =>
      visibility === DeskReservationVisibility.Everyone ||
      reservee?.user?.id === currentUser?.id ||
      isAdmin,
    [currentUser?.id, isAdmin]
  );

  const filteredReservations = useMemo(() => {
    return reservations?.filter((reservation) => {
      const isScheduled = deskSchedules?.some((sharedDeskSchedule) =>
        sharedDeskSchedule?.schedule?.some((schedule) => {
          return reservation.reservee?.user?.id === schedule.id;
        })
      );
      return !isScheduled;
    });
  }, [reservations, deskSchedules]);

  return (
    <>
      {filteredReservations?.map((reservation) => (
        <DeskReservationCard
          key={reservation.id}
          reservation={reservation}
          desk={desk}
          showUserDetails={showReservee(reservation)}
          showReservationDetails={true}
          schedule={null}
        />
      ))}
    </>
  );
}
