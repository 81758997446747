import { useMemo } from 'react';
import { useViewDeskDetails } from './useViewDeskDetails';
import { useMapMode } from 'atoms/mapInteractions';
import { checkIsExhaustive } from 'utils/isExhaustive';

export const useDefaultDeskListItemClick = (deskId: string) => {
  const mapMode = useMapMode();
  const viewDetails = useViewDeskDetails();

  return useMemo(() => {
    switch (mapMode) {
      case 'browse':
        return () => viewDetails(deskId);

      case 'edit-desk-reservation':
        return undefined;

      default:
        checkIsExhaustive(mapMode);
    }
  }, [mapMode, viewDetails, deskId]);
};
