import { MutationTuple, useMutation, gql } from '@apollo/client';
import { PERSEUS_AVAILABILITY_QUERIES } from 'constants/perseus';
import { useBookingControlsContext } from 'contexts/BookingControlsContext';
import {
  EndReservationOrVisitsInRangeMutationResult,
  EndReservationOrVisitsInRangeMutationVariables,
} from 'generated';

const END_RESERVATION_OR_VISITS_IN_RANGE = gql`
  mutation EndReservationOrVisitsInRange(
    $orgId: ID!
    $userId: ID!
    $start: Date!
    $end: Date!
    $cancelFutureVisits: Boolean
    $creationType: [String]
  ) {
    endReservationOrVisitsInRange(
      orgId: $orgId
      userId: $userId
      start: $start
      end: $end
      cancelFutureVisits: $cancelFutureVisits
      creationType: $creationType
    ) {
      success
      visitsCancelledAttempts {
        id
        success
        error
      }
      reservationsCancelledAttempts {
        id
        success
        error
      }
    }
  }
`;

export const useEndReservationOrVisitsInRange = (): MutationTuple<
  EndReservationOrVisitsInRangeMutationResult,
  EndReservationOrVisitsInRangeMutationVariables
> => {
  const { setIsBookingControlsSticky } = useBookingControlsContext();

  return useMutation<
    EndReservationOrVisitsInRangeMutationResult,
    EndReservationOrVisitsInRangeMutationVariables
  >(END_RESERVATION_OR_VISITS_IN_RANGE, {
    refetchQueries: [
      'useSeriesStartTimesForCancel',
      'DeskReservationsBySeatId',
      'DeskResourceDetails',
      'DeskReservationsForCancelModal',
      'DeskReservationsForDeskPolicyCard',
      ...PERSEUS_AVAILABILITY_QUERIES,
    ],
    // We always want to show booking controls after cancelation
    onCompleted: () => {
      setIsBookingControlsSticky(true);
    },
  });
};
