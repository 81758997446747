import { ApolloLink } from '@apollo/client';
import { config } from 'config';
import { datadogRum } from '@datadog/browser-rum';

export const cacheWriteLink = new ApolloLink((operation, forward) => {
  if (config.robinEnv !== 'production') {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const log = (action: string, object: any) => {
      if (config.robinEnv === 'staging') {
        datadogRum.addAction(action, object);
      } else {
        if (process.env.REACT_APP_ENABLE_LOCAL_CACHE_LOGGING)
          //eslint-disable-next-line no-console
          console.log(action, object);
      }
    };
    const isMutation = operation.query.definitions.some(
      (definition) =>
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'mutation'
    );

    // Skip if this is a mutation
    // readQuery will fail because the operation.query is not a valid query
    if (isMutation) {
      return forward(operation);
    }

    const cachedData = operation.getContext()?.cache?.read({
      query: operation.query,
      variables: operation.variables,
    });

    // Return the forward chain with an additional tap to see the API response
    return forward(operation).map((response) => {
      log('apollo_cache_write', {
        operation: operation.operationName,
        variables: operation.variables,
        prev: cachedData,
        next: response.data,
      });

      return response;
    });
  }
  return forward(operation);
});
