import { RRule, Weekday } from 'rrule';

export const rruleDayIndexMap = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
};

export const rruleDayIndexList = Object.values(rruleDayIndexMap);

export const createWeeklyRrule = (days: number[] | number) => {
  const daysArray = Array.isArray(days) ? days : [days];
  const sortedDays = daysArray.sort((a, b) => a - b);
  const allDaysRule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: sortedDays,
    byhour: 0,
    byminute: 0,
    bysecond: 0,
  });
  return allDaysRule.toString();
};

export const addDayToWeeklyRRule = (reccurence: string, day: number) => {
  const rrule = RRule.fromString(reccurence);
  const sortedDays = rrule.options.byweekday.concat(day).sort((a, b) => a - b);
  const newRRule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: sortedDays,
    byhour: 0,
    byminute: 0,
    bysecond: 0,
  });
  return newRRule.toString();
};

export const removeDayFromWeeklyRRule = (reccurence: string, day: number) => {
  const rrule = RRule.fromString(reccurence);
  const scheduleDays = rrule.origOptions.byweekday
    ? Array.isArray(rrule.origOptions.byweekday)
      ? rrule.origOptions.byweekday
      : [rrule.origOptions.byweekday]
    : [];
  const updatedDays = scheduleDays
    .filter((w) => w instanceof Weekday && w.weekday !== day)
    .sort((a, b) => (a as Weekday).weekday - (b as Weekday).weekday);

  const newRRule = new RRule({
    freq: RRule.WEEKLY,
    byweekday: updatedDays,
    byhour: 0,
    byminute: 0,
    bysecond: 0,
  });
  return newRRule.toString();
};
