import styled from '@emotion/styled';
import { DetailedHTMLProps, FC, HTMLAttributes, useMemo } from 'react';
import { Skeleton, Typography } from '@robinpowered/ui-kit';
import { DeskAltSolid } from '@robinpowered/icons';

type DeskInformationProps = {
  name: string | undefined;
  levelName: string | undefined;
  locationName: string | undefined;
  zoneName?: string | undefined;
  loading?: boolean;
  disabled?: boolean;
};

export const DeskInformation: FC<
  DeskInformationProps &
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({
  name,
  zoneName,
  levelName,
  locationName,
  loading,
  disabled,
  ...divProps
}) => {
  const deskLocation = useMemo(
    () => `${levelName}, ${locationName}`,
    [levelName, locationName]
  );

  const deskName = useMemo(
    () => `${name}${zoneName ? ', ' + zoneName : ''}`,
    [name, zoneName]
  );

  return (
    <Container {...divProps}>
      <DeskTitle>
        <DeskIcon>
          <DeskAltSolid />
        </DeskIcon>

        <DeskTitleWrapper>
          {loading && (
            <>
              <DeskNameSkeleton active />
              <DeskLocationSkeleton active />
            </>
          )}

          {!loading && (
            <>
              <DeskName data-testid="desk-name" level={4} disabled={disabled}>
                {deskName}
              </DeskName>

              <DeskLocation
                data-testid="desk-location"
                type="secondary"
                disabled={disabled}
              >
                {deskLocation}
              </DeskLocation>
            </>
          )}
        </DeskTitleWrapper>
      </DeskTitle>

      {divProps.children && <div>{divProps.children}</div>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeskTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const DeskIcon = styled.div`
  display: flex;
  width: 44px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Color-Bg-Primary, #f7f6f6);
`;

const DeskTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`;

const DeskName = styled(Typography.Title)<{ disabled: boolean | undefined }>`
  ${({ disabled }) =>
    disabled
      ? 'color: var(--Colors-Neutral-Text-colorTextDisabled, rgba(0, 0, 0, 0.35));'
      : ''}

  && {
    margin: 0;
  }
`;

const DeskLocation = styled(Typography.Text)<{ disabled: boolean | undefined }>`
  ${({ disabled }) =>
    disabled
      ? 'color: var(--Colors-Neutral-Text-colorTextDisabled, rgba(0, 0, 0, 0.35));'
      : ''}

  && {
    margin: 0;
  }
`;

const DeskNameSkeleton = styled(Skeleton.Button)`
  .ant-skeleton-button {
    height: 26px !important;
  }
`;

const DeskLocationSkeleton = styled(Skeleton.Button)`
  .ant-skeleton-button {
    height: 22px !important;
    width: 80% !important;
  }

  width: 100% !important;
`;
