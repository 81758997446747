import {
  useResetMapInteractiveLayesrs,
  useSetCurrentFilter,
  useSetMapInteractiveLayers,
} from 'atoms/mapInteractions/hooks';
import { FilterType, InteractiveLayer } from 'atoms/resource/types';
import { AmplitudeEvents } from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts';
import { useCallback } from 'react';

export const useFilterTagClick = ({
  type,
  active,
  activeLayers,
}: {
  type: FilterType | null;
  active: boolean;
  activeLayers: Set<InteractiveLayer>;
}) => {
  const { trackEvent } = useAmplitude();
  const setMapInteractiveLayers = useSetMapInteractiveLayers();
  const resetMapInteractiveLayers = useResetMapInteractiveLayesrs();
  const setCurrentFilter = useSetCurrentFilter();

  const handleClick = useCallback(() => {
    if (!active) {
      setCurrentFilter(type);
      setMapInteractiveLayers(activeLayers);

      switch (type) {
        case 'seats':
          trackEvent(AmplitudeEvents.SELECTED_DESKS_FILTER);
          break;
        case 'spaces':
          trackEvent(AmplitudeEvents.SELECTED_SPACES_FILTER);
          break;
        default:
          break;
      }
    } else {
      setCurrentFilter(null);
      resetMapInteractiveLayers();
    }
  }, [
    active,
    activeLayers,
    resetMapInteractiveLayers,
    setCurrentFilter,
    setMapInteractiveLayers,
    trackEvent,
    type,
  ]);

  return { handleClick };
};
