import { useMemo } from 'react';
import { Button, Dropdown, Typography } from '@robinpowered/ui-kit';
import { useAuthContext, useMapControlsContext } from 'contexts';
import ChevronDown from '@robinpowered/icons/ChevronDownOutline';
import { useManageMapPermissions } from '../hooks/useManageMapPermissions';
import { assignmentToolUrl, layoutToolUrl } from 'constants/url';
import { useTranslation } from 'react-i18next';

export function ManageFloorDropdown(): JSX.Element | null {
  const { currentOrg } = useAuthContext();
  const { t } = useTranslation('mapControls');
  const { selectedLevelId } = useMapControlsContext();
  const { data: managePermissions } = useManageMapPermissions();

  const orgSlug = useMemo(
    () => currentOrg?.slug || currentOrg?.id || '',
    [currentOrg]
  );

  const layoutUrl = useMemo(() => {
    return orgSlug && selectedLevelId
      ? layoutToolUrl(orgSlug, selectedLevelId)
      : undefined;
  }, [orgSlug, selectedLevelId]);

  const assignmentUrl = useMemo(() => {
    return orgSlug && selectedLevelId
      ? assignmentToolUrl(orgSlug, selectedLevelId)
      : undefined;
  }, [orgSlug, selectedLevelId]);

  const items = useMemo(() => {
    const itemsList = [];

    if (managePermissions.hasManageAllDeskPermission) {
      itemsList.push({
        key: '1',
        label: (
          <a rel="noopener noreferrer" href={assignmentUrl}>
            {t('manage_floor.assignments')}
          </a>
        ),
      });
    }

    if (managePermissions.hasManageOfficeAccess) {
      itemsList.push({
        key: '2',
        label: <a href={layoutUrl}>{t('manage_floor.layout')}</a>,
      });
    }

    return itemsList;
  }, [managePermissions, t, assignmentUrl, layoutUrl]);

  return (
    <Dropdown menu={{ items }}>
      <Button data-testid="manage-floor-dropdown">
        <Typography.Text
          style={{
            textAlign: 'center',
          }}
        >
          <div>{t('manage_floor.edit_floor')}</div>
        </Typography.Text>
        <ChevronDown size={12} />
      </Button>
    </Dropdown>
  );
}
