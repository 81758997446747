import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { UserSearch } from '../../graphql/useUserSearch';
import { Avatar, Typography, List, Spin, Empty } from '@robinpowered/ui-kit';

type PropTypes = {
  id: string;
  loading: boolean;
  users: null | UserSearch[];
  error?: ApolloError;
  focusedIndex: number | null;
  onSelectUser: (user: UserSearch) => void;
};

export const UserSelectResults = ({
  loading,
  users,
  error,
  focusedIndex,
  onSelectUser,
}: PropTypes): JSX.Element => {
  const { t } = useTranslation('spaceResourceDetails');
  const focusedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    focusedRef.current?.scrollIntoView({ block: 'end' });
  }, [focusedIndex]);

  if (loading) {
    return (
      <EmptyStateContainer aria-selected={false}>
        <Spin style={{ margin: 8 }} /> {t('user_select.loading')}
      </EmptyStateContainer>
    );
  }

  return (
    <>
      {!users?.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('user_select.no_results')}
          aria-selected={false}
        />
      ) : error ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('user_select.error')}
          aria-selected={false}
        />
      ) : (
        <List
          dataSource={users ?? []}
          renderItem={(user, index) => {
            const isFocused = index === focusedIndex;
            return (
              <StyledListItem
                ref={isFocused ? focusedRef : undefined}
                $focused={isFocused}
                onClick={() => onSelectUser(user)}
                aria-selected={isFocused}
                role="option"
              >
                <List.Item.Meta
                  avatar={
                    <Avatar src={user.avatar ?? undefined}>
                      {!user.avatar &&
                        (user.name?.[0] ||
                          user.primaryEmail?.email?.[0] ||
                          '?')}
                    </Avatar>
                  }
                  title={user.name && <StyledText>{user.name}</StyledText>}
                  description={
                    <GrayText $focused={isFocused}>
                      {user.primaryEmail?.email}
                    </GrayText>
                  }
                />
              </StyledListItem>
            );
          }}
        />
      )}
    </>
  );
};

const StyledListItem = styled(List.Item)<{ $focused: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: none !important;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;

  .ant-list-item-meta {
    display: flex;
    align-items: center !important;
    flex: 1;
    min-width: 0;
  }

  .ant-list-item-meta-content {
    flex: 1;
    min-width: 0;
    padding: 0 8px;
  }

  .ant-list-item-meta-title {
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-list-item-meta-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-list-item-meta-avatar {
    margin-right: 4px !important;
    margin-left: 8px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
  }

  &:hover {
    background-color: ${(props) => !props.$focused && '#f5f5f5'};
  }

  ${(props) =>
    props.$focused &&
    `
    background-color: #1890ff;
    color: #fff;
  `}
`;

const StyledText = styled(Typography.Text)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GrayText = styled.span<{ $focused: boolean }>`
  color: ${(props) =>
    props.$focused
      ? '#fff'
      : 'var(--Colors-Neutral-Text-colorTextSecondary, #515151)'};
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
  color: var(--Colors-Neutral-Text-colorTextSecondary, #515151);
`;
